import { PromocodeErrorType } from '@package/sdk/src/api';

import useLocale from '@/platform/localization/use-locale';

export function getPromocodeErrorMessage(errorCode: string) {
  const { translate } = useLocale();

  const errorMessages: Record<PromocodeErrorType, string> = {
    [PromocodeErrorType.EntityNotFound]: translate('promocode.errorMessage.entityNotFound'),
    [PromocodeErrorType.BonusRestrictedForCountry]: translate('promocode.errorMessage.bonusRestrictedForCountry'),
    [PromocodeErrorType.BonusDateIsInvalid]: translate('promocode.errorMessage.bonusDateIsInvalid'),
    [PromocodeErrorType.UserFromPartner]: translate('promocode.errorMessage.userFromPartner'),
    [PromocodeErrorType.UserHasExternalSubscription]: translate('promocode.errorMessage.userHasExternalSubscription'),
    [PromocodeErrorType.UserHadSubscriptionBefore]: translate('promocode.errorMessage.userHadSubscriptionBefore'),
    [PromocodeErrorType.BonusIsNotProfitable]: translate('promocode.errorMessage.bonusIsNotProfitable'),
    [PromocodeErrorType.BonusTypeIsInvalid]: translate('promocode.errorMessage.bonusTypeIsInvalid'),
    [PromocodeErrorType.BonusAlreadyActivated]: translate('promocode.errorMessage.bonusAlreadyActivated'),
    [PromocodeErrorType.BonusAlreadyRejected]: translate('promocode.errorMessage.bonusAlreadyActivated'),
    [PromocodeErrorType.BonusIsInvalid]: translate('promocode.errorMessage.bonusIsInvalid'),
  };

  const message = errorMessages[errorCode as PromocodeErrorType];

  return message || translate('promocode.errorMessage.defaultError');
}
