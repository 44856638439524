import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';

import getDeviceInfo from '@/code/environment/get-device-info';
import { usePresenceApi } from '@/code/user/use-presence-api';
import type { IUpdateUser, IUserCreateSession } from '@/code/user/use-users-api';
import { useUsersApi } from '@/code/user/use-users-api';
import { pick } from '@/platform/base/function';
import { UtmQueryParameter } from '@/platform/base/utm';
import { ApiError } from '@/platform/http/errors';
import type { HttpRequestOptions } from '@/platform/http/http-request';

export enum UserPresence {
  Exist = 'exist',
  NotFound = 'not-found',
}

export type AuthLoginType = 'email' | 'phone';

export function getUtmParams() {
  const route = useRoute();
  const query = route.query;
  const utmParams = pick(query, Object.values(UtmQueryParameter));

  if (utmParams && Object.values(utmParams).length) {
    return utmParams;
  }

  return undefined;
}

export function useSessionActions() {
  const userApi = useUsersApi();
  const presenceApi = usePresenceApi();

  /**
   *
   * @description
   * Запрос, которой по логину юзера говорит нам о том, зареган юзер на сервисе, или нет.
   * Если не зареган - возвращается 404 ошибка
   *
   * @param {AuthLoginType} type
   * @param {string} login
   * @return {Promise<UserPresence.Exist | UserPresence.NotFound>}
   */
  const requestUserPresence = async (type: AuthLoginType, login: string) => {
    const body: Partial<IUserCreateSession> = {};

    const _login = login.replace(' ', '');

    if (type === 'email') {
      Reflect.set(body, 'email', _login);
    } else {
      Reflect.set(body, 'phone_number', _login);
    }

    try {
      await presenceApi.checkPresence(body);

      return UserPresence.Exist;
    } catch (error) {
      if (error instanceof ApiError) {
        const { status } = error;

        switch (status) {
          case HTTPStatusCode.NotFound: {
            return UserPresence.NotFound;
          }
        }
      }

      throw error;
    }
  };

  /**
   * @description Запрос на регистрацию (создание сессии)
   *
   * @param {Partial<IUserCreateSession>} body
   * @return {Promise<Session>}
   */
  const requestRegistration = (body: Partial<IUserCreateSession>) => {
    return userApi.signUp({
      ...getDeviceInfo(),
      user_parameters: getUtmParams(),
      ...body,
    });
  };

  /**
   * Запрос на создание сессии с помощью логина/пароля
   * @param {Partial<IUserCreateSession>} body
   * @return {Promise<Session>}
   */
  const requestLogin = (body: Partial<IUserCreateSession>) => {
    return userApi.signIn({
      ...getDeviceInfo(),
      user_parameters: getUtmParams(),
      ...body,
    });
  };

  /**
   * Запрос на создание сессии с помощью oauth оператора
   * @param {Partial<IUserCreateSession>} body
   * @return {Promise<Session>}
   */
  const requestLoginSocialAuth = (body: Partial<IUserCreateSession>) => {
    if (!body.credentials) {
      throw new UnexpectedComponentStateError('body.credentials');
    }

    return userApi.signIn({ ...getDeviceInfo(), ...body });
  };

  const requestLogout = (userId: string) => userApi.signOut(userId);

  const requestResetPasswordEmail = (login: string) => userApi.sendResetPasswordEmail(login);

  const requestResetPassword = (password: string, token: string) => userApi.resetPassword(password, token);

  const requestUserUpdate = (userInfo: IUpdateUser, options?: HttpRequestOptions<'SESSIONS_USERS'>) =>
    userApi.updateUser(userInfo, options);

  return {
    requestUserPresence,
    requestLogin,
    requestLoginSocialAuth,
    requestRegistration,
    requestResetPasswordEmail,
    requestLogout,
    requestUserUpdate,
    requestResetPassword,
  };
}
