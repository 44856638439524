<template>
  <div
    :class="{
      [$style.myChannelKinomCard]: true,
      [$style.selected]: isSelected,
      [$style.pressedToTop]: isCardPressedToTop,
    }"
    :title="info.title"
    :aria-label="info.title"
    :data-selected-card="isSelected ? 'selected' : undefined"
    tabindex="0"
  >
    <div :class="$style.imageWrapper">
      <app-img :width="300" :src="info.preview" :image-class="$style.kinomImage" lazy />
    </div>

    <div :class="$style.info">
      <p :class="$style.title">{{ info.title }}</p>
      <p v-if="info.genre || info.year" :class="$style.subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MyChannelKinomCardInfo } from '@/code/my-channel/my-channel';
import AppImg from '@/components/ui/AppImg.vue';

const props = withDefaults(
  defineProps<{
    info: MyChannelKinomCardInfo;
    isSelected: boolean;
    isCardPressedToTop: boolean;
  }>(),
  {
    isSelected: false,
  },
);
const subtitle = computed(() => {
  if (props.info.genre) {
    return `${props.info.year}, ${props.info.genre}`;
  }

  return props.info.year;
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.myChannelKinomCard {
  position: relative;
  display: grid;
  grid-template-columns: 132px 100%;
  column-gap: var(--g-spacing-16);
  padding: var(--g-spacing-12) var(--g-spacing-24);
  width: 100%;
  height: 100%;
  max-height: 104px;
  background: var(--color-notheme-bg-stream);
  transition: all 0.2s ease;
  cursor: pointer;

  &:after,
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;
    content: '';
  }

  &:after {
    background-color: var(--color-notheme-bg-selected-80);
  }

  &:before {
    background-color: var(--color-notheme-bg-hover-80);
  }

  &:hover:before {
    opacity: 1;
  }

  &:focus:before {
    opacity: 1;
  }
}

.selected {
  position: sticky;
  top: 0;
  z-index: 3;

  &:after {
    opacity: 1;
  }
}

.pressedToTop {
  max-height: 88px;

  .imageWrapper {
    height: 64px;
  }
}

.imageWrapper,
.info {
  z-index: 2;
}

.kinomImage {
  transform: scale(1.6);
  object-fit: cover;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 84px;
  border-radius: var(--g-border-round-16);
  overflow: hidden;
  transition: all 0.2s ease;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.title {
  max-width: 204px;
  overflow: hidden;
  color: var(--color-text-primary);
  white-space: nowrap;
  text-overflow: ellipsis;

  @include fonts.WebSubtitle-2;
}

.subtitle {
  color: var(--color-text-tertiary);

  @include fonts.WebLabel-3;
}

@include breakpoints.max-width-480 {
  .title {
    max-width: 180px;
  }
}
</style>
