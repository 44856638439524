import { UnexpectedComponentStateError } from '@package/sdk/src/core';

export default function useSafeScrollableBodyElement() {
  const bodyEl = inject<Ref<HTMLElement>>('bodyEl');

  if (!bodyEl) {
    throw new UnexpectedComponentStateError('bodyEl');
  }

  const scrollBodyToTop = () => bodyEl.value.scrollTo({ top: 0 });

  return { bodyEl, scrollBodyToTop };
}
