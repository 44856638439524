import { OfferPageFrom, useAuthPageAnalytics, useSubscribeAnalytics } from '@package/sdk/src/analytics';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import useProfile from '@/code/profile/use-profile';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import useLocale from '@/platform/localization/use-locale';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import useHostVariation from '@/platform/variation/use-host-variation';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';
import { useUserStore } from '@/stores/use-user-store';

const availableRoutes = [
  AppRoute.Index,
  AppRoute.Movies,
  AppRoute.MovieCard,
  AppRoute.Serials,
  AppRoute.SerialCard,
  AppRoute.SerialEpisodeCard,
  AppRoute.SerialSeasonCard,
  AppRoute.TV,
  AppRoute.TVChannel,
  AppRoute.MyCollection,
  AppRoute.Playlists,
  AppRoute.MyChannel,
  AppRoute.MyChannelMoment,
  AppRoute.PlaylistItem,
  AppRoute.Search,
  AppRoute.Compilations,
  AppRoute.CompilationsItem,
  AppRoute.MyCollectionContent,
  AppRoute.MyCollectionKinom,
  AppRoute.MovieFilter,
  AppRoute.SerialFilter,
  AppRoute.Offers,
];

export const useHeaderActionButtons = () => {
  const { $analyticSender } = useNuxtApp();

  const currentRoute = useRoute();

  const { translate } = useLocale();
  const { isPromocodeEnabled } = useHostVariation();

  const { isChildProfile } = useProfile();
  const { injectAdriverScript } = useKmzaDriverEvents();

  const authPageAnalytics = useAuthPageAnalytics($analyticSender);
  const subscribeAnalytics = useSubscribeAnalytics($analyticSender);

  const { isAuth } = storeToRefs(useSessionStore());
  const { savedPromocode } = storeToRefs(usePromocodeStore());
  const { isUserPartner, isUserWithActiveSubscription } = storeToRefs(useUserStore());

  const promocodeCookie = useAppCookie(CookieName.Promocode);

  const isPromocodeButtonShown = computed(() => {
    if (!isPromocodeEnabled) {
      return false;
    }

    const canShowRoute = availableRoutes.includes(currentRoute.name as AppRoute);

    // Показываем не на всех страницах, а лишь на некоторых
    if (!canShowRoute) {
      return false;
    }

    // Для юзеров-партнеров кнопка не доступна
    if (isUserPartner.value) {
      return false;
    }

    // Для детского профиля скрываем
    if (isChildProfile.value) {
      return false;
    }

    // Для пользователя с активной подпиской скрываем кнопку
    return !isUserWithActiveSubscription.value;
  });

  const isActionButtonShown = computed(() => {
    if (currentRoute.name === AppRoute.Offers) {
      return false;
    }

    return isPromocodeButtonShown.value;
  });

  // Текст кнопки (сказали пока захардкодить)
  const promocodeButtonText = translate('offer.promocode.activation');

  // Маршрут на страницу промо-оффера
  const promoOfferRoute = computed(() => ({
    name: AppRoute.OffersPromo,
    params: {
      promocode: promocodeCookie.value,
    },
  }));

  const promocodeButtonRoute = computed(() => {
    const backUrl = currentRoute.query[RouteQuery.BackRoute] || currentRoute.fullPath;
    const query = { [RouteQuery.BackRoute]: backUrl };

    const route = {
      name: AppRoute.OffersPromoApply,
      query,
    };

    if (!isAuth.value && savedPromocode.value) {
      Reflect.set(route, 'name', AppRoute.AuthEnter);
    }

    if (isAuth.value && promocodeCookie.value) {
      return promoOfferRoute.value;
    }

    return route;
  });

  const actionButtonRoute = computed(() => {
    const backUrl = currentRoute.fullPath;
    const query = { [RouteQuery.BackRoute]: backUrl };

    const route = {
      name: AppRoute.Offers,
      query,
    };

    if (!isAuth.value) {
      Reflect.set(route, 'name', AppRoute.AuthEnter);
    }

    if (isAuth.value && promocodeCookie.value) {
      return promoOfferRoute.value;
    }

    return route;
  });

  const onPromocodeButtonClick = () => {
    const page = getKmzaPageValue(currentRoute.name as AppRoute);

    if (!isAuth.value && savedPromocode.value) {
      authPageAnalytics.onGotoAuthPage(page, 'promocode_button');
    }

    injectAdriverScript(AdriverAnalyticEvent.PromocodeButton);

    return navigateTo(promocodeButtonRoute.value);
  };

  const onActionButtonClick = () => {
    const page = getKmzaPageValue(currentRoute.name as AppRoute);

    if (isAuth.value) {
      subscribeAnalytics.onGotoOfferPage({
        page,
        from: OfferPageFrom.ActionButton,
      });
    } else {
      authPageAnalytics.onGotoAuthPage(page, 'action_button');
    }

    return navigateTo(actionButtonRoute.value);
  };

  return {
    isPromocodeButtonShown,
    isActionButtonShown,
    promocodeButtonText,
    onPromocodeButtonClick,
    onActionButtonClick,
  };
};
