<template>
  <div :class="$style.menu" @mouseleave="isMenuShown = false">
    <div
      ref="button"
      role="button"
      :class="$style.dots"
      tabindex="0"
      @mouseover="isMenuShown = true"
      @focus="isMenuShown = true"
    >
      <icon-more size="medium" />
    </div>

    <transition name="fade">
      <div v-show="isMenuShown" :class="$style.wrapper">
        <ul role="list" :class="$style.links">
          <li v-for="link in links" :key="link.name" :class="$style.link">
            <app-link
              :to="link.target"
              :aria-label="link.ariaLabel"
              :data-link-type="link.name"
              tabindex="0"
              :class="{
                [$style.dropdownLink]: true,
                [$style.linkActive]: isLinkActive(link),
              }"
              @click="onAppHeaderLinkClick(link)"
              @focus="onFocus(link)"
              @blur="onBlur(link)"
            >
              <template v-if="link.name === AppRoute.MyChannel">
                <icon-kinom size="small" :class="$style.iconKinom" />
              </template>

              {{ link.description }}
            </app-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

import type { AppHeaderItem } from '@/components/app-header/use-app-header-variables';
import useAppHeaderVariables from '@/components/app-header/use-app-header-variables';
import IconKinom from '@/components/icons/IconKinom.vue';
import IconMore from '@/components/icons/IconMore.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { AppRoute } from '@/platform/router/routes';

interface AppMoreButtonProps {
  links: AppHeaderItem[];
  currentRoute: RouteLocationNormalizedLoaded;
}

defineProps<AppMoreButtonProps>();

const emit = defineEmits<{
  (e: 'click', link: AppHeaderItem): void;
  (e: 'focus', link: AppHeaderItem): void;
  (e: 'blur', link: AppHeaderItem): void;
}>();

const { isLinkActive } = useAppHeaderVariables();

const isMenuShown = ref(false);
const button = ref<HTMLDivElement>();

onClickOutside(button, () => {
  isMenuShown.value = false;
});

const onAppHeaderLinkClick = (link: AppHeaderItem): void => {
  emit('click', link);
};

const onFocus = (link: AppHeaderItem): void => {
  emit('focus', link);
};

const onBlur = (link: AppHeaderItem): void => {
  emit('blur', link);
};
</script>

<style lang="scss" module>
@use './AppHeader';

.menu {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.dots {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-content: center;
  grid-gap: 2px;
  width: 32px;
  height: 100%;
}

.wrapper {
  position: absolute;
  top: var(--g-spacing-20);
  left: 50%;
  transform: translate(-50%);
}

.links {
  margin-top: var(--g-spacing-20);
  padding: var(--g-spacing-16);
  width: 260px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary);
}

.dropdownLink {
  margin-bottom: var(--g-spacing-24);
  white-space: nowrap;
  color: var(--color-text-secondary);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--color-text-primary);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.linkActive {
  color: var(--color-text-accent);
}
</style>
