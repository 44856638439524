<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 2.75C1 2.33579 1.33579 2 1.75 2H10.25C10.6642 2 11 2.33579 11 2.75V4.58535C10.4174 4.79127 10 5.34689 10 6C10 6.65311 10.4174 7.20873 11 7.41465V9.25C11 9.66421 10.6642 10 10.25 10H1.75C1.33579 10 1 9.66421 1 9.25V7.41465C1.5826 7.20873 2 6.65311 2 6C2 5.34689 1.5826 4.79127 1 4.58535V2.75ZM6.23307 3.60078C6.15053 3.38801 5.84947 3.38801 5.76692 3.60078L5.28043 4.85479C5.2445 4.94741 5.15713 5.00996 5.05787 5.01414L3.68832 5.07182C3.45488 5.08166 3.36131 5.37784 3.54672 5.52L4.60474 6.33121C4.68633 6.39377 4.7213 6.49991 4.69288 6.59872L4.32874 7.86445C4.26505 8.08582 4.50924 8.26822 4.70344 8.14434L5.86555 7.40303C5.94755 7.35072 6.05245 7.35072 6.13445 7.40303L7.29656 8.14434C7.49076 8.26822 7.73495 8.08582 7.67126 7.86445L7.30712 6.59872C7.2787 6.49991 7.31367 6.39377 7.39526 6.33121L8.45328 5.52C8.63869 5.37784 8.54512 5.08166 8.31168 5.07182L6.94212 5.01414C6.84287 5.00996 6.7555 4.94741 6.71957 4.85479L6.23307 3.60078Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.5 3.125C0.5 2.50368 1.00368 2 1.625 2H14.375C14.9963 2 15.5 2.50368 15.5 3.125V5.87803C14.6261 6.18691 14 7.02034 14 8C14 8.97966 14.6261 9.81309 15.5 10.122V12.875C15.5 13.4963 14.9963 14 14.375 14H1.625C1.00368 14 0.5 13.4963 0.5 12.875V10.122C1.37389 9.81309 2 8.97966 2 8C2 7.02034 1.37389 6.18691 0.5 5.87803V3.125ZM8.34961 4.40117C8.22579 4.08202 7.77421 4.08202 7.65039 4.40117L6.92065 6.28218C6.86675 6.42111 6.7357 6.51495 6.58681 6.52122L4.53247 6.60774C4.18232 6.62248 4.04196 7.06676 4.32008 7.28L5.9071 8.49681C6.0295 8.59066 6.08195 8.74987 6.03931 8.89809L5.49311 10.7967C5.39758 11.1287 5.76387 11.4023 6.05516 11.2165L7.79833 10.1045C7.92133 10.0261 8.07867 10.0261 8.20167 10.1045L9.94483 11.2165C10.2361 11.4023 10.6024 11.1287 10.5069 10.7967L9.96069 8.89809C9.91805 8.74987 9.9705 8.59066 10.0929 8.49681L11.6799 7.28C11.958 7.06675 11.8177 6.62248 11.4675 6.60774L9.41319 6.52122C9.2643 6.51495 9.13325 6.42111 9.07935 6.28218L8.34961 4.40117Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 5.5C2 4.67157 2.67157 4 3.5 4H20.5C21.3284 4 22 4.67157 22 5.5V9.17071C20.8348 9.58254 20 10.6938 20 12C20 13.3062 20.8348 14.4175 22 14.8293V18.5C22 19.3284 21.3284 20 20.5 20H3.5C2.67157 20 2 19.3284 2 18.5V14.8293C3.16519 14.4175 4 13.3062 4 12C4 10.6938 3.16519 9.58254 2 9.17071V5.5ZM12.4661 7.20156C12.3011 6.77602 11.6989 6.77602 11.5338 7.20156L10.5609 9.70957C10.489 9.89482 10.3143 10.0199 10.1157 10.0283L7.37663 10.1436C6.90976 10.1633 6.72261 10.7557 7.09344 11.04L9.20947 12.6624C9.37266 12.7875 9.44261 12.9998 9.38575 13.1974L8.65748 15.7289C8.53011 16.1716 9.01849 16.5364 9.40689 16.2887L11.7311 14.8061C11.8951 14.7014 12.1049 14.7014 12.2689 14.8061L14.5931 16.2887C14.9815 16.5364 15.4699 16.1716 15.3425 15.7289L14.6142 13.1974C14.5574 12.9998 14.6273 12.7875 14.7905 12.6624L16.9066 11.04C17.2774 10.7557 17.0902 10.1633 16.6234 10.1436L13.8842 10.0283C13.6857 10.0199 13.511 9.89482 13.4391 9.70957L12.4661 7.20156Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.83325 6.7915C1.83325 5.6179 2.78465 4.6665 3.95825 4.6665H28.0416C29.2152 4.6665 30.1666 5.6179 30.1666 6.7915V11.9918C28.5159 12.5753 27.3333 14.1495 27.3333 16C27.3333 17.8505 28.5159 19.4247 30.1666 20.0082V25.2082C30.1666 26.3818 29.2152 27.3332 28.0416 27.3332H3.95825C2.78465 27.3332 1.83325 26.3818 1.83325 25.2082V20.0082C3.48398 19.4248 4.66667 17.8505 4.66667 16C4.66667 14.1495 3.48398 12.5752 1.83325 11.9918V6.7915ZM16.6604 9.20221C16.4265 8.59936 15.5735 8.59936 15.3396 9.20221L13.9612 12.7552C13.8594 13.0177 13.6119 13.1949 13.3306 13.2067L9.45023 13.3702C8.78883 13.398 8.5237 14.2372 9.04904 14.64L12.0468 16.9384C12.2779 17.1157 12.377 17.4164 12.2965 17.6964L11.2648 21.2826C11.0843 21.9098 11.7762 22.4266 12.3264 22.0756L15.6191 19.9752C15.8514 19.827 16.1486 19.827 16.3809 19.9752L19.6736 22.0756C20.2238 22.4266 20.9157 21.9098 20.7352 21.2826L19.7035 17.6964C19.623 17.4164 19.7221 17.1157 19.9532 16.9384L22.951 14.64C23.4763 14.2372 23.2112 13.398 22.5498 13.3702L18.6694 13.2067C18.3881 13.1949 18.1406 13.0177 18.0388 12.7552L16.6604 9.20221Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
