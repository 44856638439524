<template>
  <section
    :class="{
      [$style.appModal]: true,
      [$style.appModalBackground]: true,
      [$style.appModalFullscreen]: modalOptions?.isFullscreen,
    }"
    role="dialog"
    @click="onClick"
  >
    <component :is="currentModalName" :ref="(comp) => (modalEl = comp?.$el)" />
  </section>
</template>

<script lang="ts" setup>
import useSafeScrollableBodyElement from '@/code/layout/use-safe-scrollable-body-element';
import { useLayoutStore } from '@/stores/use-layout-store';

const layoutStore = useLayoutStore();

const { currentModalName, hasModalCloseIcon, modalOptions } = storeToRefs(layoutStore);
const modalEl = ref<HTMLElement>();
const { bodyEl } = useSafeScrollableBodyElement();

const onClick = () => {
  if (hasModalCloseIcon.value) {
    layoutStore.setCurrentModalName(null);
  }
};

onMounted(() => {
  bodyEl.value?.classList.add('hide-global-overflow');
});

onUnmounted(() => {
  bodyEl.value?.classList.remove('hide-global-overflow');
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.appModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: var(--z-index-modal);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--g-spacing-40) 0;
  overflow: auto;
}

.appModalBackground {
  backdrop-filter: blur(6px);
}

.appModalFullscreen {
  padding: 0;
  height: 100%;
}

@include breakpoints.max-width-800 {
  .appModal {
    padding: 0;
    height: 100%;
  }
}
</style>
