import type { DsmlValue } from '@package/dsml-js/src/core/dsml';
import useLogger from '@package/logger/src/use-logger';
import type { AnalyticEventNameNew } from '@package/sdk/src/analytics';

const logger = useLogger('google-sender');

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export class GoogleEventSender {
  private isDev = false;

  public sendEvent(eventName: AnalyticEventNameNew, values: DsmlValue[] = []) {
    if (this.isDev || !window.dataLayer) {
      return;
    }

    try {
      const dict: Record<string, any> = {};

      for (const { value, property } of values) {
        dict[property] = value;
      }

      window.dataLayer.push({ event: eventName, ...dict });
    } catch (error) {
      logger.error(error);
    }
  }

  public initialize(options: { isDev: boolean }) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    this.isDev = options.isDev;
  }
}
