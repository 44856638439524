import useLogger from '@package/logger/src/use-logger';
import type { CancelReason, CancelSubscriptionObjectionStrings } from '@package/sdk/src/api';
import { PageSlug } from '@package/sdk/src/api';

import type { ICancelSubscriptionPayload } from '@/code/subscription/use-subscription-cancel-api';
import { useSubscriptionCancelApi } from '@/code/subscription/use-subscription-cancel-api';
import { AppRoute } from '@/platform/router/routes';
import { usePageStringsStore } from '@/stores/use-page-strings-store';
import { useSubscriptionStore } from '@/stores/use-subscription-store';

export function useSubscriptionCancel() {
  const router = useRouter();
  const { subscription } = storeToRefs(useSubscriptionStore());
  const { fetchFrontendPages } = usePageStringsStore();
  const logger = useLogger('use-subscription-cancel', 'web');
  const { checkCancelBonuses, applyCancelBonuses } = useSubscriptionCancelApi();

  type ModalState =
    | 'cancelConfirm'
    | 'cancelReasons'
    | 'cancelSubscriptionWithBonus'
    | 'cancelSubscriptionWithoutBonus';

  // Общее состояние всех модалок на странице, вынес в одну переменную чтобы не запутаться
  const modalViewState = reactive<Record<ModalState, boolean>>({
    cancelConfirm: false,
    cancelReasons: false,
    cancelSubscriptionWithBonus: false,
    cancelSubscriptionWithoutBonus: false,
  });

  // Взаимодействовал ли пользователь с модалками
  const userHasInteractWithModals = shallowRef(true);

  const setModalView = (type: ModalState, state: boolean) => {
    // Вырубаем все модалки, а потом включаем только то, что нам нужно
    modalViewState.cancelConfirm = false;
    modalViewState.cancelReasons = false;
    modalViewState.cancelSubscriptionWithBonus = false;
    modalViewState.cancelSubscriptionWithoutBonus = false;

    modalViewState[type] = state;

    if (type === 'cancelSubscriptionWithBonus' || type === 'cancelSubscriptionWithoutBonus') {
      // Как только нужные нам модалки были открыты, выставляем флаг в false
      userHasInteractWithModals.value = false;
    }
  };

  const selectedReasons = ref<CancelReason[]>([]);
  // Флаг доступности бонусного предложения
  const canUseCancelSubscriptionBonus = ref(false);
  // Текст который юзер САМ вводит в инпут, где указывает причину почему отказывается от подписки
  const cancelSubscriptionUserText = ref<string>('');
  // Cancel texts, приходят с frontend-pages
  const cancelTexts = ref<CancelSubscriptionObjectionStrings>({} as CancelSubscriptionObjectionStrings);

  const getTexts = async () => {
    cancelTexts.value = await fetchFrontendPages<CancelSubscriptionObjectionStrings>(
      PageSlug.CancelSubscriptionObjection,
    );
  };

  const isSingleCancelReason = computed(() => selectedReasons.value.length === 1);

  // Заголовок
  const cancelTextTitle = computed(() => {
    if (!canUseCancelSubscriptionBonus.value) {
      return cancelTexts.value.titleWithoutBonus;
    }

    if (isSingleCancelReason.value) {
      return selectedReasons.value[0].texts.title;
    }

    return cancelTexts.value.titleWithBonus;
  });

  const cancelTextSubtitle = computed(() => {
    if (!canUseCancelSubscriptionBonus.value) {
      return cancelTexts.value.subtitleWithoutBonus;
    }

    if (isSingleCancelReason.value) {
      return selectedReasons.value[0].texts.subtitle;
    }

    return cancelTexts.value.subtitleWithBonus;
  });

  const cancelTextAcceptButton = computed(() => {
    if (!canUseCancelSubscriptionBonus.value) {
      return cancelTexts.value.acceptButtonWithoutBonus;
    }

    if (isSingleCancelReason.value) {
      return selectedReasons.value[0].texts.acceptButton;
    }

    return cancelTexts.value.acceptButtonWithBonus;
  });

  const cancelTextRefuseButton = computed(() => {
    if (!canUseCancelSubscriptionBonus.value) {
      return cancelTexts.value.refuseButtonWithoutBonus;
    }

    if (isSingleCancelReason.value) {
      return selectedReasons.value[0].texts.refuseButton;
    }

    return cancelTexts.value.refuseButtonWithBonus;
  });

  const getNormalizedPayload = () => {
    if (!subscription?.value?.id) {
      throw new TypeError(`Expect subscription id to be defined. Got - ${subscription?.value?.id}`);
    }

    const payload: ICancelSubscriptionPayload = { subscriptionId: subscription.value.id };
    const cancelReasonIds = selectedReasons.value.map((reason) => reason.id);

    if (cancelReasonIds.length > 0) {
      Reflect.set(payload, 'cancel_reason_ids', cancelReasonIds);
    }

    if (cancelSubscriptionUserText.value) {
      Reflect.set(payload, 'cancel_reason', cancelSubscriptionUserText.value);
    }

    return payload;
  };

  const applyCancelSubscriptionObjection = async () => {
    const payload = getNormalizedPayload();
    userHasInteractWithModals.value = true;

    try {
      await applyCancelBonuses(payload);
    } catch (error) {
      // По идее - мы не должны дойти до этого пункта, так как проверка на check вернет нам ошибку
      // Что делать тут - не понятно, выводить юзеру текстом? Уточню у продактов
      logger.error(error);
    } finally {
      router.push({ name: AppRoute.Index });
    }
  };

  const safeCheckCancelSubscriptionBonus = async () => {
    if (!subscription?.value?.id) {
      throw new TypeError(`Expect subscription id to be defined. Got - ${subscription?.value?.id}`);
    }
    canUseCancelSubscriptionBonus.value = await checkCancelBonuses(subscription.value.id);
  };

  onMounted(async () => {
    // Тексты для кнопок
    await getTexts();
  });

  return {
    setModalView,
    applyCancelSubscriptionObjection,
    safeCheckCancelSubscriptionBonus,
    selectedReasons,
    userHasInteractWithModals,
    cancelTextTitle,
    cancelTextSubtitle,
    cancelTextAcceptButton,
    cancelTextRefuseButton,
    cancelSubscriptionUserText,
    canUseCancelSubscriptionBonus,
    modalViewState,
  };
}
