import { v4 } from 'uuid';

import { BaseOAuthProvider } from '@/code/o-auth//base-provider';
import { OAuthProviderType, OAuthState } from '@/code/o-auth//o-auth';
import type { OAuthResponse } from '@/code/o-auth//types/o-auth';
import type { VKSilentAuthPayload } from '@/code/o-auth//vk-auth';

export class VkOAuthProvider extends BaseOAuthProvider {
  type = OAuthProviderType.VK;
  public responseQueryName = 'payload';

  createOAuthUri(): string {
    const config = useRuntimeConfig();

    const url = new URL('https://id.vk.com/auth');
    url.searchParams.set('app_id', String(config.public.OAUTH_VK_APP_ID));
    url.searchParams.set('redirect_uri', this.redirectUri);
    url.searchParams.set('redirect_state', this.type);
    url.searchParams.set('uuid', v4());

    return url.href;
  }

  public override handleOAuthResponseUri() {
    const response = { provider: this.type } as OAuthResponse;
    const payloadStr = new URLSearchParams(window.location.search).get(this.responseQueryName) as string;

    try {
      const payload = JSON.parse(payloadStr) as VKSilentAuthPayload;

      response.state = payload ? OAuthState.Success : OAuthState.Failed;
      response.token = payload?.token;
      response.uuid = payload?.uuid;
    } catch (error) {
      response.state = OAuthState.Failed;
    }

    window.opener?.postMessage(JSON.stringify(response), window.location.origin);
  }
}
