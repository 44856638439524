import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import useLogger from '@package/logger/src/use-logger';
import { MediaContentType, type WatchingItem } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';

import useFetchCatalogContent from '@/code/content/use-fetch-catalog-content';
import type { LocalWatchingDbStoredItem } from '@/code/watching-items/local-watching-item-database';
import { useWatchingItemsApi } from '@/code/watching-items/use-watching-items-api';
import { useSessionStore } from '@/stores/use-session-store';

interface UpdateWatchingItemsOptions {
  contentId: string;
  offset: number;
  duration: number;
  token?: string;
  contentType: MediaContentType;
}

export default function useWatchingItems() {
  const { $localWatchingItemDb } = useNuxtApp();
  const { isAuth } = storeToRefs(useSessionStore());
  const watchingItemsApi = useWatchingItemsApi();
  const logger = useLogger('use-watching-items.ts');
  const { fetchSerialManifest, fetchMovieManifest } = useFetchCatalogContent();

  const update = (options: UpdateWatchingItemsOptions) => {
    const { contentId, offset, duration, contentType, token } = options;

    if (!isAuth.value) {
      const localWatchingItem: WatchingItem = {
        offset,
        contentId,
        id: contentId,
        updatedAt: 0,
      };

      const localWatchingItemForDb = { localWatchingItem, duration, contentType };
      const progress = localWatchingItem.offset / duration;

      if (progress >= ConstantsConfigInstanceWeb.getProperty('userWatchingItemUpdateThreshold')) {
        localWatchingItem.offset = 0;
      }

      return $localWatchingItemDb.add(localWatchingItem.contentId, localWatchingItemForDb);
    }

    if (!token) {
      throw new UnexpectedComponentStateError('token');
    }

    watchingItemsApi.updateWatchingItem(options);
  };

  const uploadLocalWatchingItems = async () => {
    const localWatchingItems = (await $localWatchingItemDb.readAll<LocalWatchingDbStoredItem>()) || [];

    if (!localWatchingItems) {
      return;
    }

    try {
      const promises = localWatchingItems.map((item) => {
        const { localWatchingItem, duration, contentType } = item.value;
        const { offset, contentId } = localWatchingItem;

        const fetchManifestFunction =
          item.value.contentType === MediaContentType.MOVIE ? fetchMovieManifest : fetchSerialManifest;

        return fetchManifestFunction(contentId).then((manifest) => {
          update({ offset, contentId, duration, token: manifest.token, contentType });
        });
      });

      await Promise.all(promises);

      $localWatchingItemDb.clear();
    } catch (error) {
      logger.error(error);
    }
  };

  return {
    update,
    uploadLocalWatchingItems,
  };
}
