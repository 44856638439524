import useLogger from '@package/logger/src/use-logger';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { UserSubscriptionStore } from '@SDK/api/subscriptions/types/subscriptionV2-enums';

import { useOffersApi } from '@/code/offer/use-offers-api';
import useLocale from '@/platform/localization/use-locale';
import { useSessionStore } from '@/stores/use-session-store';
import { useSubscriptionStore } from '@/stores/use-subscription-store';

export function useSubscriptionManagement() {
  const offersApi = useOffersApi();
  const sessionStore = useSessionStore();
  const { subscription, isSubscriptionRecurrent } = storeToRefs(useSubscriptionStore());
  const { setSubscriptionData } = useSubscriptionStore();
  const { formatDate } = useLocale();
  const { translate } = useLocale();

  const { user } = storeToRefs(sessionStore);

  const logger = useLogger('use-subscription-management.ts');

  async function fetchUserSubscriptionInfo() {
    if (user?.value) {
      try {
        const subscriptionData = await offersApi.getSubscriptionInfo(user.value.id);
        setSubscriptionData(subscriptionData);
      } catch (error) {
        logger.warn(error);
      }
    }
  }

  function getCancellationSubscriptionText(store: UserSubscriptionStore) {
    const { translate } = useLocale();

    switch (store) {
      case UserSubscriptionStore.Apple:
        return translate('lkPage.account.appleSubscriptionCancel');
      case UserSubscriptionStore.Google:
        return translate('lkPage.account.googleSubscriptionCancel');
      case UserSubscriptionStore.Partner:
        return translate('lkPage.account.partnerSubscriptionCancel');
      default:
        return '';
    }
  }

  function getRenewalSubscriptionText(store: UserSubscriptionStore) {
    const { translate } = useLocale();

    switch (store) {
      case UserSubscriptionStore.Partner:
        return translate('lkPage.account.partnerSubscriptionRenewal');
      default:
        return '';
    }
  }

  const getPaymentDate = () => {
    const endsAt = subscription.value?.endsAt;

    if (!endsAt) {
      throw new UnexpectedComponentStateError('endsAt');
    }

    return formatDate(new Date(endsAt));
  };

  const getSubscriptionBannerSubtitle = () => {
    return `${
      isSubscriptionRecurrent.value
        ? translate('lk.subscriptionManage.autoRenewal')
        : translate('lk.subscriptionCard.activeAt')
    } ${getPaymentDate()}`;
  };

  const toggleAutoRenew = async (cancelReasonsIds?: string[], reasonText?: string) => {
    if (!subscription.value?.id) {
      return;
    }

    return await offersApi.toggleAutoRenew(subscription.value.id, cancelReasonsIds, reasonText).then(async () => {
      await sessionStore.updateCurrentUser();
      await fetchUserSubscriptionInfo();
    });
  };

  const cancelSberAutoRenew = async () => {
    if (subscription.value?.id) {
      return await offersApi
        .cancelSberAutoRenew(subscription.value.id)
        .then(async () => await sessionStore.updateCurrentUser());
    }
  };

  return {
    fetchUserSubscriptionInfo,
    getPaymentDate,
    toggleAutoRenew,
    cancelSberAutoRenew,
    getRenewalSubscriptionText,
    getCancellationSubscriptionText,
    getSubscriptionBannerSubtitle,
  };
}
