<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.25 9.4999H8.44198C8.77318 9.4999 9.06518 9.28265 9.16035 8.96541L10.1138 5.78724C10.3063 5.14563 9.82583 4.4999 9.15597 4.4999H7.28825C7.13612 4.4999 7.01925 4.36515 7.04076 4.21454L7.30927 2.33498C7.37487 1.87579 6.83413 1.58254 6.48505 1.88798L3.75612 4.27579C3.59336 4.41821 3.5 4.62395 3.5 4.84022V8.7499C3.5 9.16411 3.83579 9.4999 4.25 9.4999ZM2.625 4.9999C2.625 4.65472 2.34518 4.3749 2 4.3749C1.65482 4.3749 1.375 4.65472 1.375 4.9999V8.9999C1.375 9.34507 1.65482 9.6249 2 9.6249C2.34518 9.6249 2.625 9.34507 2.625 8.9999V4.9999Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.375 13.2498H11.663C12.1598 13.2498 12.5978 12.924 12.7405 12.4481L14.1707 7.68087C14.4594 6.71845 13.7387 5.74984 12.734 5.74984H9.93238C9.70417 5.74984 9.52887 5.54772 9.56115 5.32181L9.96391 2.50247C10.0623 1.81369 9.25119 1.3738 8.72757 1.83197L4.63418 5.41369C4.39004 5.62731 4.25 5.93593 4.25 6.26033V12.1248C4.25 12.7462 4.75368 13.2498 5.375 13.2498ZM2.875 6.49984C2.875 6.0166 2.48325 5.62484 2 5.62484C1.51675 5.62484 1.125 6.0166 1.125 6.49984V12.4998C1.125 12.9831 1.51675 13.3748 2 13.3748C2.48325 13.3748 2.875 12.9831 2.875 12.4998V6.49984Z"
          fill="white"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.5 18.9998H16.884C17.5464 18.9998 18.1304 18.5653 18.3207 17.9308L20.2276 11.5745C20.6126 10.2913 19.6517 8.99979 18.3119 8.99979H14.5765C14.2722 8.99979 14.0385 8.7303 14.0815 8.42908L14.6185 4.66996C14.7497 3.75159 13.6683 3.16507 12.9701 3.77596L7.51224 8.55158C7.18672 8.83641 7 9.2479 7 9.68045V17.4998C7 18.3282 7.67157 18.9998 8.5 18.9998ZM5 9.99979C5 9.44751 4.55228 8.99979 4 8.99979C3.44772 8.99979 3 9.44751 3 9.99979V17.9998C3 18.5521 3.44772 18.9998 4 18.9998C4.55228 18.9998 5 18.5521 5 17.9998V9.99979Z"
          fill="white"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.0417 25.9167H22.919C23.8574 25.9167 24.6847 25.3012 24.9544 24.4023L27.6558 15.3975C28.2012 13.5796 26.8399 11.75 24.942 11.75H19.6501C19.2191 11.75 18.888 11.3682 18.9489 10.9415L19.7097 5.6161C19.8956 4.31508 18.3634 3.48418 17.3744 4.3496L9.64242 11.1151C9.18127 11.5186 8.91675 12.1015 8.91675 12.7143V23.7917C8.91675 24.9653 9.86814 25.9167 11.0417 25.9167ZM5.91675 13.1667C5.91675 12.4763 5.3571 11.9167 4.66675 11.9167C3.97639 11.9167 3.41675 12.4763 3.41675 13.1667V24.5C3.41675 25.1904 3.97639 25.75 4.66675 25.75C5.3571 25.75 5.91675 25.1904 5.91675 24.5V13.1667Z"
          fill="white"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.06461 1.875C6.87665 1.875 6.69464 1.9409 6.55025 2.06123L3.77979 4.36994C3.77778 4.37162 3.77577 4.37331 3.77376 4.375H2.5C1.87868 4.375 1.375 4.87868 1.375 5.5V9C1.375 9.62132 1.87868 10.125 2.5 10.125H4.5H8.63962C9.12385 10.125 9.55376 9.81514 9.70689 9.35576L10.6542 6.51387C11.0049 5.46163 10.2217 4.375 9.11257 4.375H7.76238L8.02748 3.0495C8.149 2.44189 7.68426 1.875 7.06461 1.875ZM3.375 8.875V5.625H2.625V8.875H3.375ZM4.625 5.29273L6.63989 3.61366L6.38714 4.87743C6.35042 5.06104 6.39795 5.25144 6.51666 5.39624C6.63537 5.54105 6.81275 5.625 7 5.625H9.11257C9.36853 5.625 9.54927 5.87576 9.46833 6.11859L8.54953 8.875H4.625V5.29273Z"
          fill="white"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.59692 1.875C9.3296 1.875 9.07074 1.96872 8.86538 2.13985L4.7097 5.60292C4.70097 5.6102 4.69232 5.61756 4.68376 5.625H2.75C1.85254 5.625 1.125 6.35254 1.125 7.25V12.5C1.125 13.3975 1.85254 14.125 2.75 14.125H5.75H11.9594C12.6589 14.125 13.2799 13.6774 13.501 13.0139L14.922 8.75104C15.4346 7.21315 14.2899 5.625 12.6689 5.625H10.5673L10.9799 3.562C11.1545 2.68926 10.4869 1.875 9.59692 1.875ZM4.125 12.375V7.375H2.875V12.375H4.125ZM5.875 6.90983L9.05585 4.25912L8.64199 6.3284C8.59058 6.58545 8.65713 6.85201 8.82333 7.05474C8.98952 7.25747 9.23786 7.375 9.5 7.375H12.6689C13.0955 7.375 13.3967 7.79294 13.2618 8.19764L11.8693 12.375H5.875V6.90983Z"
          fill="white"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.1292 4C13.8118 4 13.5044 4.11129 13.2605 4.31451L7.71963 8.93193C7.69313 8.95401 7.66728 8.97671 7.6421 9H5C3.89543 9 3 9.89543 3 11V18C3 19.1046 3.89543 20 5 20H9H17.2792C18.1401 20 18.9044 19.4491 19.1766 18.6325L21.0712 12.9487C21.7187 11.0061 20.2728 9 18.2251 9H15.2198L15.8098 6.04998C16.0219 4.98946 15.2108 4 14.1292 4ZM7 18V11H5V18H7ZM9 18L17.2792 18L19.1738 12.3162C19.3897 11.6687 18.9077 11 18.2251 11H14C13.7004 11 13.4166 10.8657 13.2267 10.634C13.0367 10.4023 12.9607 10.0977 13.0194 9.80388L13.6638 6.58185L9 10.4684L9 18Z"
          fill="white"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.0165 4.8335C18.6058 4.8335 18.2081 4.97749 17.8925 5.24042L10.0429 11.7818C9.99098 11.8251 9.94092 11.8701 9.89278 11.9168H6.08341C4.61066 11.9168 3.41675 13.1107 3.41675 14.5835V24.5002C3.41675 25.9729 4.61065 27.1668 6.08341 27.1668H11.7501H23.479C24.6268 27.1668 25.6459 26.4323 26.0088 25.3434L28.6928 17.2914C29.5742 14.6473 27.6061 11.9168 24.819 11.9168H20.3582L21.2339 7.53828C21.5137 6.139 20.4435 4.8335 19.0165 4.8335ZM9.08854 24.6668H6.08341C5.99137 24.6668 5.91675 24.5922 5.91675 24.5002V14.5835C5.91675 14.4914 5.99137 14.4168 6.08341 14.4168H9.08341V24.5002C9.08341 24.5561 9.08514 24.6117 9.08854 24.6668ZM11.7501 24.6668H23.479C23.5507 24.6668 23.6144 24.6209 23.6371 24.5529L26.3211 16.5009C26.6629 15.4756 25.8998 14.4168 24.819 14.4168H18.8334C18.4589 14.4168 18.1042 14.2489 17.8667 13.9593C17.6293 13.6697 17.5342 13.2889 17.6077 12.9217L18.6132 7.89415L11.6434 13.7023C11.6054 13.734 11.5834 13.7809 11.5834 13.8304V24.5002C11.5834 24.5922 11.658 24.6668 11.7501 24.6668Z"
          fill="white"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
