
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexncpV7JUWZJH3YmZFeBrI2_6vPlziThoKgoMr_tqcn9cMeta } from "/srv/app/apps/web/src/pages/index.vue?macro=true";
import { default as searchXlAe0LGoRrDdmRV5FyUjWChmpWvku5HSKnPG9tuHr9YMeta } from "/srv/app/apps/web/src/pages/search.vue?macro=true";
import { default as sitemapqudigdyYeLh1CNMn_6x7iSZngQidQ7Ugx4NK12x8W_454Meta } from "/srv/app/apps/web/src/pages/sitemap.vue?macro=true";
import { default as contacts0z2eBwQs_Yagr9_siuFu86ZDHtF6Dx3K658SD6F1lD8Meta } from "/srv/app/apps/web/src/pages/contacts.vue?macro=true";
import { default as index8UzzT29igrUgNsBdqh7Rwbs7jvIGvg8B3WkNixWBLusMeta } from "/srv/app/apps/web/src/pages/lk/index.vue?macro=true";
import { default as enterU2uCVhfiAZICOvk3KxsU09JwQAxTkIXPPjnbgVwsErYMeta } from "/srv/app/apps/web/src/pages/auth/enter.vue?macro=true";
import { default as indexPhPHVW5VGoY12hYmppCkpUkFL5HAF_lxPNvWZZo6OzEMeta } from "/srv/app/apps/web/src/pages/auth/index.vue?macro=true";
import { default as indexkmN3MLIu5Did9SawaeerOA2lNOGHX3aogyYr_455DFTMwMeta } from "/srv/app/apps/web/src/pages/code/index.vue?macro=true";
import { default as _91id_93hr8zr2j1eKOIaDs0rZ3kiyy1vBspI5uHhGQf3_455DvFQMeta } from "/srv/app/apps/web/src/pages/filmy/[id].vue?macro=true";
import { default as indexy1Hh3pSMQBW8yZJzRd_45LPEXxOEu0I440WmMNueJ2sYEMeta } from "/srv/app/apps/web/src/pages/gotv/index.vue?macro=true";
import { default as account3vW91DM4O22p25V_45ACcqcoK80LKMq_45w_45l8_CueP6SmEMeta } from "/srv/app/apps/web/src/pages/lk/account.vue?macro=true";
import { default as devicesA0oM1qbBZYUOf3ijWiBQGARMjPye1fX5vAZRE9itfmYMeta } from "/srv/app/apps/web/src/pages/lk/devices.vue?macro=true";
import { default as indexXpD9bMYXYAr_45pza_45yOpSDkTzqGnzJfnmsvu8dFBNZ_458Meta } from "/srv/app/apps/web/src/pages/filmy/index.vue?macro=true";
import { default as profiles8lgAhywk3POIx79V_45mEMelnN6lXRE3nOi8ICmu14CCsMeta } from "/srv/app/apps/web/src/pages/lk/profiles.vue?macro=true";
import { default as infoqfhGU6U9IISMiSjTOdVgO54oEyH3_45ZfNvz9OYAeQVzsMeta } from "/srv/app/apps/web/src/pages/account/info.vue?macro=true";
import { default as _91f_93_45hd7x7vGge3nsxAwmhHth9ePNs5FHMXtUw_bVsUfWwyk_AMeta } from "/srv/app/apps/web/src/pages/filmy/[f]-hd.vue?macro=true";
import { default as indexP49QyxmV3RpRCSZ0yf8xO_Z2rmm_453H7tatBrj23kDuwMeta } from "/srv/app/apps/web/src/pages/movies/index.vue?macro=true";
import { default as indexGGsBOpVSQgC5i1x1jNizd6ubDMI1EVVdfNVNaf_45Jy0QMeta } from "/srv/app/apps/web/src/pages/offers/index.vue?macro=true";
import { default as _91episodeId_93_45seriya40vbtVI7xqOr7BZwH6rgnz_zPCgyGPKT35su_SY85GgMeta } from "/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon/[episodeId]-seriya.vue?macro=true";
import { default as _91seasonId_93_45sezonrJkSski8qexBOWpxPL_45FyVIuAlf_pkopuyGp_45o7_Sw4Meta } from "/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon.vue?macro=true";
import { default as _91id_93_452r1M6oFvqMZHUnXXFbAwGpklasKu0FBqlUZXa5hZh0Meta } from "/srv/app/apps/web/src/pages/serialy/[id].vue?macro=true";
import { default as index_459jyZsNv9dFoxOa1qqB3PvQUurF67zDmbi2TnJjjTxIMeta } from "/srv/app/apps/web/src/pages/series/index.vue?macro=true";
import { default as index5ycp3Fixuwp0wFQpz4vJl3aEalZDl7xwwhKppXb3NacMeta } from "/srv/app/apps/web/src/pages/account/index.vue?macro=true";
import { default as _91f_93_45hd9OWGyXG0_45KoEt3gEO2_45w0Vx3sHVR8qkiDMQTLgNf0_45QMeta } from "/srv/app/apps/web/src/pages/movies/[f]-hd.vue?macro=true";
import { default as iconsm3C4Ynptyx_dtg5jn_QRS36oaUeIcJy2SP0yivY2JZ8Meta } from "/srv/app/apps/web/src/pages/sandbox/icons.vue?macro=true";
import { default as indexO_uwTMmHqVqh9URF2KoGnHsn_TCS6_F5LDUJhC7fwkgMeta } from "/srv/app/apps/web/src/pages/serialy/index.vue?macro=true";
import { default as _91f_93_45hdsE5362PADSi96rmoMsbkRX_3HvP9BsBQYkQyOuNBwkoMeta } from "/srv/app/apps/web/src/pages/series/[f]-hd.vue?macro=true";
import { default as indexINEm6DGuJx3aKexTKj6T_45VO8bLO0gPOX08ROZLxQJ_450Meta } from "/srv/app/apps/web/src/pages/download/index.vue?macro=true";
import { default as _91id_93q_DuQd24oCYhILBKzGXvV5a_s3j_hm_hPhCT0eXW9UwMeta } from "/srv/app/apps/web/src/pages/playlists/[id].vue?macro=true";
import { default as _91id_93l9QGgO_eEYjfEsy7_2iJ1NAxzb01dGs8MmC6bCpVgpMMeta } from "/srv/app/apps/web/src/pages/playlisty/[id].vue?macro=true";
import { default as indexjMHE1zgGaYsBoTOjjspKLOfa1n9sL77IyQz1zy1qwk4Meta } from "/srv/app/apps/web/src/pages/referral/index.vue?macro=true";
import { default as _91f_93_45hdC1Ape0peh0w39RhYWZt0kP_4569KcXGkKteLSazPoIUlIMeta } from "/srv/app/apps/web/src/pages/serialy/[f]-hd.vue?macro=true";
import { default as devicesA93p4XY12PEBfMUhBFN8wT3r_LyNdtevyVB__45y_451_CcMeta } from "/srv/app/apps/web/src/pages/account/devices.vue?macro=true";
import { default as _91id_93NtjYwIqdedbP95AMF6R_45dpTDTXqUqym5cVsN_45lbzuhsMeta } from "/srv/app/apps/web/src/pages/my-channel/[id].vue?macro=true";
import { default as indexJXyN9pKG3FkgEUPXaDqOhQ_1_arqnoaVx2puWFugAt4Meta } from "/srv/app/apps/web/src/pages/playlists/index.vue?macro=true";
import { default as index_ELmUE2pweN8rF7zpPyrWiJxqDf28_45YPKyVxgzlIcoEMeta } from "/srv/app/apps/web/src/pages/playlisty/index.vue?macro=true";
import { default as _91id_93y3GXoWYr_45ltiDHr_UkaDkDfsh1JBNHaNMjSOn1j1ZdcMeta } from "/srv/app/apps/web/src/pages/telekanaly/[id].vue?macro=true";
import { default as indexhK_A4TecSm8g77WlvkcynP_fadTfNkeGE2ZbJmJRnV4Meta } from "/srv/app/apps/web/src/pages/thank-you/index.vue?macro=true";
import { default as languageQA_effjULT_4FzW6R_45zPsuRmlb2xgil8uERVWtw4_45bcMeta } from "/srv/app/apps/web/src/pages/account/language.vue?macro=true";
import { default as profilesyHc1nwapRMoshXkDFljEt4k6Su98F6yHMhYGiHENeXsMeta } from "/srv/app/apps/web/src/pages/account/profiles.vue?macro=true";
import { default as _91id_93oaZDR7QszohtDvpyOV_45lQztOubsD7SK_45NjUq9yXhZ5sMeta } from "/srv/app/apps/web/src/pages/collections/[id].vue?macro=true";
import { default as mob_45appvryzyNdwV5UiLrvYE8eZ3uJR0VkNVyEYbJv74dK6xUIMeta } from "/srv/app/apps/web/src/pages/download/mob-app.vue?macro=true";
import { default as indexrn7IebpdmxYAzJ0rJ8KcMy4NH6VRDeMPzNRgX4iI9OEMeta } from "/srv/app/apps/web/src/pages/lk/bonuses/index.vue?macro=true";
import { default as indexjNtqEUS3oZSXrFPCnEl0ne9dS4H9mytYfO4IAcwkf7EMeta } from "/srv/app/apps/web/src/pages/my-channel/index.vue?macro=true";
import { default as indexErfsBz42xJJLPsrkwS1eRvvLcMsCsCt37KILDFFrftsMeta } from "/srv/app/apps/web/src/pages/telekanaly/index.vue?macro=true";
import { default as _91id_93Ag61c8ExmNliTfxKxxgHJo3xWZYqaKciJgTlXwU44csMeta } from "/srv/app/apps/web/src/pages/tv-channels/[id].vue?macro=true";
import { default as indexVEzBmgmy2EfKD8po5mXHk4dQdDZPe91obEjpfbJQW7wMeta } from "/srv/app/apps/web/src/pages/collections/index.vue?macro=true";
import { default as certificates9FXFzth41yUjY_Xm270yGSxsoibkn8r5jfNGil64G50Meta } from "/srv/app/apps/web/src/pages/docs/certificates.vue?macro=true";
import { default as indexLx9cCQxz_45bJQo6Ur0jgWTvYgbfVBsV6Ot72RJEQlR_450Meta } from "/srv/app/apps/web/src/pages/movies/[id]/index.vue?macro=true";
import { default as index3rSSIaciy9q_45DQI_LIhcY_wbTrbwe_gHAto_XLU_45iSUMeta } from "/srv/app/apps/web/src/pages/series/[id]/index.vue?macro=true";
import { default as indexZUdIQgU6ENJS3RlkSIVxnWtZWFbUq2_yqt3W8Jc5I_45gMeta } from "/srv/app/apps/web/src/pages/tv-channels/index.vue?macro=true";
import { default as lgXXR8W_45ope_QOvGwEumJ8uOfees86bpclQjvyz8BLjScMeta } from "/srv/app/apps/web/src/pages/docs/connect-tv/lg.vue?macro=true";
import { default as mailing_45rules3_1CjJ_2K3_45EgkCdd_45Du3opLpDWqfXbjNKNvzSWVRPEMeta } from "/srv/app/apps/web/src/pages/docs/mailing-rules.vue?macro=true";
import { default as player9nMy9_45TVRWfa4JBEZZb_726h5OXRndLtguBZssdICDAMeta } from "/srv/app/apps/web/src/pages/movies/[id]/player.vue?macro=true";
import { default as applyq81LzxOBP8AO9ZqX4S335bJOV93sCqNmY82Ora4Gt_45AMeta } from "/srv/app/apps/web/src/pages/offers/promo/apply.vue?macro=true";
import { default as enter_45passwordEd_45oyUUvPQNqj7Cw_zKHTcc9Q6y_453wFYGvUmZRoSs40Meta } from "/srv/app/apps/web/src/pages/auth/enter-password.vue?macro=true";
import { default as privacy_45policyRSeDNFfncPgiR4qQ9cYCVC5NOfkFcTkGpml4ZPA9I_sMeta } from "/srv/app/apps/web/src/pages/docs/privacy-policy.vue?macro=true";
import { default as user_45agreementyGhCg4k6FK1c_45Rvh8NSJAqZKsBH4prNt3IirlFLFC9cMeta } from "/srv/app/apps/web/src/pages/docs/user-agreement.vue?macro=true";
import { default as referralflxruHcDlekIHTz_zm35_7UnH04XuY2xUdBqCwFCvm4Meta } from "/srv/app/apps/web/src/pages/lk/bonuses/referral.vue?macro=true";
import { default as index4iZusfp5GctRTcGsmmRlA46JE3gqZ0N4ranVYDQ4ADUMeta } from "/srv/app/apps/web/src/pages/my-collection/index.vue?macro=true";
import { default as sberPwFqXDb17iMO16_RlYu1hE3prrRwKhUX_fk0ClO6zxsMeta } from "/srv/app/apps/web/src/pages/docs/connect-tv/sber.vue?macro=true";
import { default as promocodep0RnueB_vA1wL08ocZTrbq702veieeZ8kKSHeSCwpA4Meta } from "/srv/app/apps/web/src/pages/lk/bonuses/promocode.vue?macro=true";
import { default as subscription_45infozDZvYoYDJfSQvF5Ot5g0fRB2C_9IUCYuEyVzusdB9SoMeta } from "/srv/app/apps/web/src/pages/lk/subscription-info.vue?macro=true";
import { default as _91payload_93_pXGKevnkf35hH8SnTKLEeKRDl6y7o10nXEEt2pbzG4Meta } from "/srv/app/apps/web/src/pages/offers/pay/[payload].vue?macro=true";
import { default as index9BNvviXpjplZP3enljzE_45StWIHkLTeuQ1M_k8gRFNvUMeta } from "/srv/app/apps/web/src/pages/account/bonuses/index.vue?macro=true";
import { default as restore_45password5uuhG_45bWIbQIe7hi1GAt_WKF6rkCxxyk0F8JDh456qgMeta } from "/srv/app/apps/web/src/pages/auth/restore-password.vue?macro=true";
import { default as index7O9s4Vqpi_7_45ynb740VWds3Xv_byIgz_45Iffkg2LRc_cMeta } from "/srv/app/apps/web/src/pages/docs/connect-tv/index.vue?macro=true";
import { default as momentsKbHrM9GAp8149gTJqw2qa5RGnEV8aB1mWSV_45KaZZk74Meta } from "/srv/app/apps/web/src/pages/my-collection/moments.vue?macro=true";
import { default as xiaomiBazukQK_45_45uE0qptYyO1lHb2PAcHnYXC35_45sBUEz6xOEMeta } from "/srv/app/apps/web/src/pages/docs/connect-tv/xiaomi.vue?macro=true";
import { default as yandexvvBQ0ixan44lWN5jG_45Hi_rg74_45e5frRUFhhPMthT5dMMeta } from "/srv/app/apps/web/src/pages/docs/connect-tv/yandex.vue?macro=true";
import { default as subscription_45manageq_Q3gjsXY4jdmqnF3ddsaQwptdS50TAe_YTXHd6SX2EMeta } from "/srv/app/apps/web/src/pages/lk/subscription-manage.vue?macro=true";
import { default as contentsJpwkyRta2GgM4NVq5gff_WF_91a6VrN7G2n_bfSPud0Meta } from "/srv/app/apps/web/src/pages/my-collection/contents.vue?macro=true";
import { default as hisenseGc889kL0y_45uBQ6qxDrDGyG__m7lrOmoCKChSQg8H2O8Meta } from "/srv/app/apps/web/src/pages/docs/connect-tv/hisense.vue?macro=true";
import { default as samsungAIGgrvIfpz3TeN0JUJeGyn5svQzuCiq5kGUo_45p2cvTIMeta } from "/srv/app/apps/web/src/pages/docs/connect-tv/samsung.vue?macro=true";
import { default as referralnpFAqCRT1W8Qz4hmlw27JP7DYzFk4JxYTwKQuSsEY5cMeta } from "/srv/app/apps/web/src/pages/account/bonuses/referral.vue?macro=true";
import { default as apple_45tvCZSE6_45yq_45aryOAS7TO7KJsbdTCSOqG_0rN579GbihnUMeta } from "/srv/app/apps/web/src/pages/docs/connect-tv/apple-tv.vue?macro=true";
import { default as _91promocode_93_jGpk_450QkzrmIpGpaVT_93YnDBSm4saUnzcW1ok_45QyQMeta } from "/srv/app/apps/web/src/pages/offers/promo/[promocode].vue?macro=true";
import { default as use_45checkbox_45mocktDHsm0vAdOYDB_YDtBLwiAQL6MRv_YL_458TIL7O5vyakMeta } from "/srv/app/apps/web/src/pages/sandbox/use-checkbox-mock.ts?macro=true";
import { default as use_45dropdown_45mockU9_EoEpp4u6f_45QxdyrwPu8_XQADhdvg_eH_45wagNJd_cMeta } from "/srv/app/apps/web/src/pages/sandbox/use-dropdown-mock.ts?macro=true";
import { default as promocode43mUY8CU_45ke1RfZxQf5Xa7wuzX25JQl3yCXsmgVSfMoMeta } from "/srv/app/apps/web/src/pages/account/bonuses/promocode.vue?macro=true";
import { default as subscription_45infonZzGbCpuuIHQkF8VQ4_45yyx5UxKdf0tnQjO5ceaKkIUUMeta } from "/srv/app/apps/web/src/pages/account/subscription-info.vue?macro=true";
import { default as password_45link_45expiredEiFTHefD_YoXAtqWAelf8ygPjjiBi2T4gwIKLFqgHHcMeta } from "/srv/app/apps/web/src/pages/auth/password-link-expired.vue?macro=true";
import { default as android_45tv9euyAqKiH6zDbE8lpr_r7GSWYaqhwVMO8sMKmBe2VP8Meta } from "/srv/app/apps/web/src/pages/docs/connect-tv/android-tv.vue?macro=true";
import { default as subscription_45manageM1uF2hP7dbT_459dDUdS5eyxksPxl2GXpPFhJTyY4GbaQMeta } from "/srv/app/apps/web/src/pages/account/subscription-manage.vue?macro=true";
import { default as password_45restore_45emailffZLp0gNZ0urip0mbr__45VIPLD8wNHcXWWDWybOME3_45QMeta } from "/srv/app/apps/web/src/pages/auth/password-restore-email.vue?macro=true";
import { default as password_45restore_45phone8cynrLZ4vLLVeWymsBSergHsx14NZoSUTbJTC3JAbrQMeta } from "/srv/app/apps/web/src/pages/auth/password-restore-phone.vue?macro=true";
import { default as recommendation_45technologiesuOoY3vfwpKyq3Rqk1FyeBmbmrSLH0MaVebyCHRpGUv0Meta } from "/srv/app/apps/web/src/pages/docs/recommendation-technologies.vue?macro=true";
import { default as indexB9zbC0CvEdOckpE8IYdbk3r_45_CxyJQ_1e0L6cQREqhEMeta } from "/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/index.vue?macro=true";
import { default as indexI29Hha6vbaPDGE3L4wVBeLoyxJmQJPnauiAA49U8QLUMeta } from "/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/ep-[episodeId]/index.vue?macro=true";
import { default as playerAhbaBqiHZWYp2uVzwR4uTvsOu_b9_mhv_U5hw7aFdTwMeta } from "/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/ep-[episodeId]/player.vue?macro=true";
import { default as component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta } from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os } from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexncpV7JUWZJH3YmZFeBrI2_6vPlziThoKgoMr_tqcn9cMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchXlAe0LGoRrDdmRV5FyUjWChmpWvku5HSKnPG9tuHr9YMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/search.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    meta: sitemapqudigdyYeLh1CNMn_6x7iSZngQidQ7Ugx4NK12x8W_454Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/sitemap.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: contacts0z2eBwQs_Yagr9_siuFu86ZDHtF6Dx3K658SD6F1lD8Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/contacts.vue")
  },
  {
    name: "lk",
    path: "/lk",
    component: () => import("/srv/app/apps/web/src/pages/lk/index.vue")
  },
  {
    name: "auth-enter",
    path: "/auth/enter",
    meta: enterU2uCVhfiAZICOvk3KxsU09JwQAxTkIXPPjnbgVwsErYMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/enter.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexPhPHVW5VGoY12hYmppCkpUkFL5HAF_lxPNvWZZo6OzEMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/index.vue")
  },
  {
    name: "code",
    path: "/code",
    meta: indexkmN3MLIu5Did9SawaeerOA2lNOGHX3aogyYr_455DFTMwMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/code/index.vue")
  },
  {
    name: "filmy-id",
    path: "/filmy/:id()",
    component: () => import("/srv/app/apps/web/src/pages/filmy/[id].vue")
  },
  {
    name: "gotv",
    path: "/gotv",
    meta: indexy1Hh3pSMQBW8yZJzRd_45LPEXxOEu0I440WmMNueJ2sYEMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/gotv/index.vue")
  },
  {
    name: "lk-account",
    path: "/lk/account",
    component: () => import("/srv/app/apps/web/src/pages/lk/account.vue")
  },
  {
    name: "lk-devices",
    path: "/lk/devices",
    component: () => import("/srv/app/apps/web/src/pages/lk/devices.vue")
  },
  {
    name: "filmy",
    path: "/filmy",
    component: () => import("/srv/app/apps/web/src/pages/filmy/index.vue")
  },
  {
    name: "lk-profiles",
    path: "/lk/profiles",
    component: () => import("/srv/app/apps/web/src/pages/lk/profiles.vue")
  },
  {
    name: "account-info",
    path: "/account/info",
    meta: infoqfhGU6U9IISMiSjTOdVgO54oEyH3_45ZfNvz9OYAeQVzsMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/info.vue")
  },
  {
    name: "filmy-f-hd",
    path: "/filmy/:f()-hd",
    component: () => import("/srv/app/apps/web/src/pages/filmy/[f]-hd.vue")
  },
  {
    name: "movies",
    path: "/movies",
    meta: indexP49QyxmV3RpRCSZ0yf8xO_Z2rmm_453H7tatBrj23kDuwMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/movies/index.vue")
  },
  {
    name: "offers",
    path: "/offers",
    meta: indexGGsBOpVSQgC5i1x1jNizd6ubDMI1EVVdfNVNaf_45Jy0QMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/offers/index.vue")
  },
  {
    name: "serialy-id",
    path: "/serialy/:id()",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[id].vue"),
    children: [
  {
    name: "serialy-id-seasonId-sezon",
    path: ":seasonId()-sezon",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon.vue"),
    children: [
  {
    name: "serialy-id-seasonId-sezon-episodeId-seriya",
    path: ":episodeId()-seriya",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon/[episodeId]-seriya.vue")
  }
]
  }
]
  },
  {
    name: "series",
    path: "/series",
    meta: index_459jyZsNv9dFoxOa1qqB3PvQUurF67zDmbi2TnJjjTxIMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/index.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/srv/app/apps/web/src/pages/account/index.vue")
  },
  {
    name: "movies-f-hd",
    path: "/movies/:f()-hd",
    meta: _91f_93_45hd9OWGyXG0_45KoEt3gEO2_45w0Vx3sHVR8qkiDMQTLgNf0_45QMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/movies/[f]-hd.vue")
  },
  {
    name: "sandbox-icons",
    path: "/sandbox/icons",
    meta: iconsm3C4Ynptyx_dtg5jn_QRS36oaUeIcJy2SP0yivY2JZ8Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/sandbox/icons.vue")
  },
  {
    name: "serialy",
    path: "/serialy",
    component: () => import("/srv/app/apps/web/src/pages/serialy/index.vue")
  },
  {
    name: "series-f-hd",
    path: "/series/:f()-hd",
    meta: _91f_93_45hdsE5362PADSi96rmoMsbkRX_3HvP9BsBQYkQyOuNBwkoMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[f]-hd.vue")
  },
  {
    name: "download",
    path: "/download",
    meta: indexINEm6DGuJx3aKexTKj6T_45VO8bLO0gPOX08ROZLxQJ_450Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/download/index.vue")
  },
  {
    name: "playlists-id",
    path: "/playlists/:id()",
    meta: _91id_93q_DuQd24oCYhILBKzGXvV5a_s3j_hm_hPhCT0eXW9UwMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/playlists/[id].vue")
  },
  {
    name: "playlisty-id",
    path: "/playlisty/:id()",
    component: () => import("/srv/app/apps/web/src/pages/playlisty/[id].vue")
  },
  {
    name: "referral",
    path: "/referral",
    meta: indexjMHE1zgGaYsBoTOjjspKLOfa1n9sL77IyQz1zy1qwk4Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/referral/index.vue")
  },
  {
    name: "serialy-f-hd",
    path: "/serialy/:f()-hd",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[f]-hd.vue")
  },
  {
    name: "account-devices",
    path: "/account/devices",
    meta: devicesA93p4XY12PEBfMUhBFN8wT3r_LyNdtevyVB__45y_451_CcMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/devices.vue")
  },
  {
    name: "my-channel-id",
    path: "/my-channel/:id()",
    meta: _91id_93NtjYwIqdedbP95AMF6R_45dpTDTXqUqym5cVsN_45lbzuhsMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-channel/[id].vue")
  },
  {
    name: "playlists",
    path: "/playlists",
    meta: indexJXyN9pKG3FkgEUPXaDqOhQ_1_arqnoaVx2puWFugAt4Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/playlists/index.vue")
  },
  {
    name: "playlisty",
    path: "/playlisty",
    component: () => import("/srv/app/apps/web/src/pages/playlisty/index.vue")
  },
  {
    name: "telekanaly-id",
    path: "/telekanaly/:id()",
    component: () => import("/srv/app/apps/web/src/pages/telekanaly/[id].vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    meta: indexhK_A4TecSm8g77WlvkcynP_fadTfNkeGE2ZbJmJRnV4Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/thank-you/index.vue")
  },
  {
    name: "account-language",
    path: "/account/language",
    meta: languageQA_effjULT_4FzW6R_45zPsuRmlb2xgil8uERVWtw4_45bcMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/language.vue")
  },
  {
    name: "account-profiles",
    path: "/account/profiles",
    meta: profilesyHc1nwapRMoshXkDFljEt4k6Su98F6yHMhYGiHENeXsMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/profiles.vue")
  },
  {
    name: "collections-id",
    path: "/collections/:id()",
    meta: _91id_93oaZDR7QszohtDvpyOV_45lQztOubsD7SK_45NjUq9yXhZ5sMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/collections/[id].vue")
  },
  {
    name: "download-mob-app",
    path: "/download/mob-app",
    component: () => import("/srv/app/apps/web/src/pages/download/mob-app.vue")
  },
  {
    name: "lk-bonuses",
    path: "/lk/bonuses",
    component: () => import("/srv/app/apps/web/src/pages/lk/bonuses/index.vue")
  },
  {
    name: "my-channel",
    path: "/my-channel",
    meta: indexjNtqEUS3oZSXrFPCnEl0ne9dS4H9mytYfO4IAcwkf7EMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-channel/index.vue")
  },
  {
    name: "telekanaly",
    path: "/telekanaly",
    component: () => import("/srv/app/apps/web/src/pages/telekanaly/index.vue")
  },
  {
    name: "tv-channels-id",
    path: "/tv-channels/:id()",
    meta: _91id_93Ag61c8ExmNliTfxKxxgHJo3xWZYqaKciJgTlXwU44csMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/tv-channels/[id].vue")
  },
  {
    name: "collections",
    path: "/collections",
    meta: indexVEzBmgmy2EfKD8po5mXHk4dQdDZPe91obEjpfbJQW7wMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/collections/index.vue")
  },
  {
    name: "docs-certificates",
    path: "/docs/certificates",
    component: () => import("/srv/app/apps/web/src/pages/docs/certificates.vue")
  },
  {
    name: "movies-id",
    path: "/movies/:id()",
    meta: indexLx9cCQxz_45bJQo6Ur0jgWTvYgbfVBsV6Ot72RJEQlR_450Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/movies/[id]/index.vue")
  },
  {
    name: "series-id",
    path: "/series/:id()",
    meta: index3rSSIaciy9q_45DQI_LIhcY_wbTrbwe_gHAto_XLU_45iSUMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[id]/index.vue")
  },
  {
    name: "tv-channels",
    path: "/tv-channels",
    meta: indexZUdIQgU6ENJS3RlkSIVxnWtZWFbUq2_yqt3W8Jc5I_45gMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/tv-channels/index.vue")
  },
  {
    name: "docs-connect-tv-lg",
    path: "/docs/connect-tv/lg",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/lg.vue")
  },
  {
    name: "docs-mailing-rules",
    path: "/docs/mailing-rules",
    component: () => import("/srv/app/apps/web/src/pages/docs/mailing-rules.vue")
  },
  {
    name: "movies-id-player",
    path: "/movies/:id()/player",
    meta: player9nMy9_45TVRWfa4JBEZZb_726h5OXRndLtguBZssdICDAMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/movies/[id]/player.vue")
  },
  {
    name: "offers-promo-apply",
    path: "/offers/promo/apply",
    meta: applyq81LzxOBP8AO9ZqX4S335bJOV93sCqNmY82Ora4Gt_45AMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/offers/promo/apply.vue")
  },
  {
    name: "auth-enter-password",
    path: "/auth/enter-password",
    meta: enter_45passwordEd_45oyUUvPQNqj7Cw_zKHTcc9Q6y_453wFYGvUmZRoSs40Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/enter-password.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/srv/app/apps/web/src/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-user-agreement",
    path: "/docs/user-agreement",
    component: () => import("/srv/app/apps/web/src/pages/docs/user-agreement.vue")
  },
  {
    name: "lk-bonuses-referral",
    path: "/lk/bonuses/referral",
    component: () => import("/srv/app/apps/web/src/pages/lk/bonuses/referral.vue")
  },
  {
    name: "my-collection",
    path: "/my-collection",
    component: () => import("/srv/app/apps/web/src/pages/my-collection/index.vue")
  },
  {
    name: "docs-connect-tv-sber",
    path: "/docs/connect-tv/sber",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/sber.vue")
  },
  {
    name: "lk-bonuses-promocode",
    path: "/lk/bonuses/promocode",
    component: () => import("/srv/app/apps/web/src/pages/lk/bonuses/promocode.vue")
  },
  {
    name: "lk-subscription-info",
    path: "/lk/subscription-info",
    component: () => import("/srv/app/apps/web/src/pages/lk/subscription-info.vue")
  },
  {
    name: "offers-pay-payload",
    path: "/offers/pay/:payload()",
    meta: _91payload_93_pXGKevnkf35hH8SnTKLEeKRDl6y7o10nXEEt2pbzG4Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/offers/pay/[payload].vue")
  },
  {
    name: "account-bonuses",
    path: "/account/bonuses",
    meta: index9BNvviXpjplZP3enljzE_45StWIHkLTeuQ1M_k8gRFNvUMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/bonuses/index.vue")
  },
  {
    name: "auth-restore-password",
    path: "/auth/restore-password",
    meta: restore_45password5uuhG_45bWIbQIe7hi1GAt_WKF6rkCxxyk0F8JDh456qgMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/restore-password.vue")
  },
  {
    name: "docs-connect-tv",
    path: "/docs/connect-tv",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/index.vue")
  },
  {
    name: "my-collection-moments",
    path: "/my-collection/moments",
    meta: momentsKbHrM9GAp8149gTJqw2qa5RGnEV8aB1mWSV_45KaZZk74Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-collection/moments.vue")
  },
  {
    name: "docs-connect-tv-xiaomi",
    path: "/docs/connect-tv/xiaomi",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/xiaomi.vue")
  },
  {
    name: "docs-connect-tv-yandex",
    path: "/docs/connect-tv/yandex",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/yandex.vue")
  },
  {
    name: "lk-subscription-manage",
    path: "/lk/subscription-manage",
    component: () => import("/srv/app/apps/web/src/pages/lk/subscription-manage.vue")
  },
  {
    name: "my-collection-contents",
    path: "/my-collection/contents",
    meta: contentsJpwkyRta2GgM4NVq5gff_WF_91a6VrN7G2n_bfSPud0Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-collection/contents.vue")
  },
  {
    name: "docs-connect-tv-hisense",
    path: "/docs/connect-tv/hisense",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/hisense.vue")
  },
  {
    name: "docs-connect-tv-samsung",
    path: "/docs/connect-tv/samsung",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/samsung.vue")
  },
  {
    name: "account-bonuses-referral",
    path: "/account/bonuses/referral",
    meta: referralnpFAqCRT1W8Qz4hmlw27JP7DYzFk4JxYTwKQuSsEY5cMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/bonuses/referral.vue")
  },
  {
    name: "docs-connect-tv-apple-tv",
    path: "/docs/connect-tv/apple-tv",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/apple-tv.vue")
  },
  {
    name: "offers-promo-promocode",
    path: "/offers/promo/:promocode()",
    component: () => import("/srv/app/apps/web/src/pages/offers/promo/[promocode].vue")
  },
  {
    name: "sandbox-use-checkbox-mock",
    path: "/sandbox/use-checkbox-mock",
    component: () => import("/srv/app/apps/web/src/pages/sandbox/use-checkbox-mock.ts")
  },
  {
    name: "sandbox-use-dropdown-mock",
    path: "/sandbox/use-dropdown-mock",
    component: () => import("/srv/app/apps/web/src/pages/sandbox/use-dropdown-mock.ts")
  },
  {
    name: "account-bonuses-promocode",
    path: "/account/bonuses/promocode",
    meta: promocode43mUY8CU_45ke1RfZxQf5Xa7wuzX25JQl3yCXsmgVSfMoMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/bonuses/promocode.vue")
  },
  {
    name: "account-subscription-info",
    path: "/account/subscription-info",
    meta: subscription_45infonZzGbCpuuIHQkF8VQ4_45yyx5UxKdf0tnQjO5ceaKkIUUMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/subscription-info.vue")
  },
  {
    name: "auth-password-link-expired",
    path: "/auth/password-link-expired",
    meta: password_45link_45expiredEiFTHefD_YoXAtqWAelf8ygPjjiBi2T4gwIKLFqgHHcMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/password-link-expired.vue")
  },
  {
    name: "docs-connect-tv-android-tv",
    path: "/docs/connect-tv/android-tv",
    component: () => import("/srv/app/apps/web/src/pages/docs/connect-tv/android-tv.vue")
  },
  {
    name: "account-subscription-manage",
    path: "/account/subscription-manage",
    meta: subscription_45manageM1uF2hP7dbT_459dDUdS5eyxksPxl2GXpPFhJTyY4GbaQMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/subscription-manage.vue")
  },
  {
    name: "auth-password-restore-email",
    path: "/auth/password-restore-email",
    meta: password_45restore_45emailffZLp0gNZ0urip0mbr__45VIPLD8wNHcXWWDWybOME3_45QMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/password-restore-email.vue")
  },
  {
    name: "auth-password-restore-phone",
    path: "/auth/password-restore-phone",
    meta: password_45restore_45phone8cynrLZ4vLLVeWymsBSergHsx14NZoSUTbJTC3JAbrQMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/password-restore-phone.vue")
  },
  {
    name: "docs-recommendation-technologies",
    path: "/docs/recommendation-technologies",
    component: () => import("/srv/app/apps/web/src/pages/docs/recommendation-technologies.vue")
  },
  {
    name: "series-id-season-seasonId",
    path: "/series/:id()/season-:seasonId()",
    meta: indexB9zbC0CvEdOckpE8IYdbk3r_45_CxyJQ_1e0L6cQREqhEMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/index.vue")
  },
  {
    name: "series-id-season-seasonId-ep-episodeId",
    path: "/series/:id()/season-:seasonId()/ep-:episodeId()",
    meta: indexI29Hha6vbaPDGE3L4wVBeLoyxJmQJPnauiAA49U8QLUMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/ep-[episodeId]/index.vue")
  },
  {
    name: "series-id-season-seasonId-ep-episodeId-player",
    path: "/series/:id()/season-:seasonId()/ep-:episodeId()/player",
    meta: playerAhbaBqiHZWYp2uVzwR4uTvsOu_b9_mhv_U5hw7aFdTwMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/ep-[episodeId]/player.vue")
  },
  {
    name: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta?.name,
    path: "/poisk/:pathMatch(.*)",
    component: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os
  },
  {
    name: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta?.name,
    path: "/moi-kollekcii/:pathMatch(.*)",
    component: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os
  },
  {
    name: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta?.name,
    path: "/recommendation-technologies/:pathMatch(.*)",
    component: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os
  },
  {
    name: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta?.name,
    path: "/user-agreement/:pathMatch(.*)",
    component: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os
  },
  {
    name: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta?.name,
    path: "/privacy-policy/:pathMatch(.*)",
    component: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os
  },
  {
    name: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta?.name,
    path: "/moments-feed/:pathMatch(.*)",
    component: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os
  },
  {
    name: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5osMeta?.name,
    path: "/kontakty/:pathMatch(.*)",
    component: component_45stub_A13sp5g6aTfEGRwEFamuAfEu9APNv7d0NIEd8yO5os
  }
]