<template>
  <app-modal-wrapper :with-padding="false">
    <img :alt="$t('alt.modals.exploreMyChannel.promo')" src="/images/explore-my-channel.png" />

    <div :class="$style.contentBlock">
      <modal-title :title="$t('exploreMyChannelModal.title')" :is-html="true" />

      <modal-description
        :class="$style.description"
        :description="$t('exploreMyChannelModal.description')"
        :is-html="true"
      />

      <app-button
        :class="$style.button"
        :text="$t('exploreMyChannelModal.button')"
        :aria-label="$t('exploreMyChannelModal.button')"
        :title="$t('exploreMyChannelModal.button')"
        @click="onClick()"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { AnalyticPageName, useMyChannelAnalytics } from '@package/sdk/src/analytics';

import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

const { $analyticSender } = useNuxtApp();
const layoutStore = useLayoutStore();
const router = useRouter();
const myChannelAnalytics = useMyChannelAnalytics($analyticSender);

const onClick = () => {
  layoutStore.setCurrentModalName(null);

  router.push({ name: AppRoute.MyChannel });
};

onMounted(() => {
  myChannelAnalytics.onShowMyChannelOnboardPopup(AnalyticPageName.MyChannel);
});
</script>

<style lang="scss" module>
.contentBlock {
  padding: var(--g-spacing-32);
}

.description {
  margin-bottom: var(--g-spacing-32);
}

.button {
  width: 100%;
}
</style>
