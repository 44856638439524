import useLogger from '@package/logger/src/use-logger';
import { PromoCodeState, PromoOfferBonusType } from '@SDK/api';
import { UnexpectedComponentStateError } from '@SDK/core';
import type { AnyFn } from '@vueuse/core';

import { usePromocodeApi } from '@/code/bonus-programs/use-promocode-api';
import { NotificationLevel } from '@/code/notifications/notification';
import { usePaymentsActions } from '@/code/payments/use-payments-actions';
import { getPromocodeErrorMessage } from '@/code/promocode/get-promocode-error-message';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { ApiError } from '@/platform/http/errors';
import useLocale from '@/platform/localization/use-locale';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';
import { useUserStore } from '@/stores/use-user-store';

export default function usePromocodeActions() {
  const route = useRoute();
  const { translate } = useLocale();
  const logger = useLogger('use-promocode-actions');

  const { applyOfferWithPaymentSystem } = usePaymentsActions();

  const promocodeApi = usePromocodeApi();

  const layoutStore = useLayoutStore();
  const promocodeStore = usePromocodeStore();

  const promocodeCookie = useAppCookie(CookieName.Promocode);

  const { isAuth } = storeToRefs(useSessionStore());
  const { isUserWithActiveSubscription, isUserPartner } = storeToRefs(useUserStore());
  const { isPromocodeCheckSuccess, promocodeCheckInfo, promocodeState, savedPromocode } = storeToRefs(promocodeStore);

  const handlePromocodeError = (error: ApiError) => {
    const promocodeErrorMessage = getPromocodeErrorMessage(error.message);

    // нотификашку показываем только для юзеров с подпиской и партнерских
    // для юзеров без подписки и неавторизованных сообщение об ошибке будет на странице офферов
    if (isUserWithActiveSubscription.value || isUserPartner.value) {
      layoutStore.addNotification({
        level: NotificationLevel.Info,
        message: promocodeErrorMessage,
        hideTimeoutMs: 3000,
        position: 'top',
      });
    }

    promocodeStore.setPromocodeState(PromoCodeState.Error);
    promocodeStore.setPromocodeErrorMessage(promocodeErrorMessage);

    promocodeStore.reset();

    logger.error(error);
  };

  const restoreSavedPromocode = async () => {
    const promocodeQuery = route.query[RouteQuery.Promocode] as string;

    // Если нет сохраненного промокода (не в куках, не в ссылке)
    if (!promocodeCookie.value && !promocodeQuery) {
      return;
    }

    if (promocodeQuery) {
      promocodeCookie.value = promocodeQuery;
    }

    const promocode = promocodeCookie.value;

    try {
      const promocodeData = await promocodeApi.getPromocodeData(promocode);
      promocodeStore.setPromocodeCheckInfo(promocodeData);
      promocodeStore.setSavedPromocode(promocode);

      promocodeStore.setPromocodeState(PromoCodeState.Success);
    } catch (error) {
      if (error instanceof ApiError) {
        handlePromocodeError(error);
      }
    }
  };

  const checkPromocode = async (successCardNotRequiredCheckCallback?: AnyFn) => {
    try {
      promocodeState.value = PromoCodeState.Loading;

      const promocodeData = await promocodeApi.getPromocodeData(savedPromocode.value);
      promocodeStore.setPromocodeCheckInfo(promocodeData);
      setPromocodeActivationMessage();

      const isPromocodeWithCardRequired = promocodeData.cardRequired;

      promocodeStore.setPromocodeState(PromoCodeState.Success);

      promocodeCookie.value = isPromocodeWithCardRequired ? savedPromocode.value : '';

      // Если промокод успешный, но без привязки карты
      if (!isPromocodeWithCardRequired && successCardNotRequiredCheckCallback) {
        Reflect.apply(successCardNotRequiredCheckCallback, undefined, []);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        handlePromocodeError(error);
      }
    }
  };

  const checkQueryPromocode = async (queryPromoCode: string) => {
    promocodeStore.setPromocode(queryPromoCode);
    await checkPromocode();
  };

  const applyPromocode = async (): Promise<void> => {
    try {
      const appliedPromocode = await promocodeApi.applyPromocode(savedPromocode.value);
      promocodeStore.setAppliedBonusInfo(appliedPromocode);

      if (!promocodeCheckInfo.value?.cardRequired) {
        promocodeCookie.value = '';
        promocodeStore.setSavedPromocode('');
      } else {
        promocodeCookie.value = savedPromocode.value;
      }
    } catch (err) {
      promocodeCookie.value = '';

      promocodeStore.setSavedPromocode('');
      promocodeStore.setPromocodeState(PromoCodeState.Error);

      logger.error(err);
    }
  };

  const applyPromocodeOfferWithPaymentSystem = async () => {
    const promoOffer = promocodeCheckInfo.value;

    if (!promoOffer) {
      throw new UnexpectedComponentStateError('promoOffer');
    }

    if (isAuth.value) {
      await applyPromocode();
    }

    return applyOfferWithPaymentSystem(promoOffer.id);
  };

  const setPromocodeActivationMessage = () => {
    if (!isPromocodeCheckSuccess.value) {
      return;
    }

    const message = computed(() => {
      switch (promocodeCheckInfo.value?.bonusType) {
        case PromoOfferBonusType.WithCard:
          return translate('page.registration.cardNeeded.v2');
        case PromoOfferBonusType.WithoutCard:
          return translate('page.registration.freeSubscription.v2');
        case PromoOfferBonusType.WithDiscount:
          return translate('page.registration.cardNeededForDiscount.v2');
      }

      return translate('page.registration.cardNeeded.v2');
    });

    promocodeStore.setPromocodeActivationMessage(message.value);
  };

  const resetPromocode = async () => {
    promocodeStore.reset();

    const query = { ...route.query };
    Reflect.deleteProperty(query, RouteQuery.ResetPromocode);

    await navigateTo({ query }, { replace: true });
  };

  const rejectFromPromocode = async () => {
    await resetPromocode();

    await navigateTo({ name: AppRoute.Offers });
  };

  return {
    restoreSavedPromocode,
    checkPromocode,
    checkQueryPromocode,
    applyPromocode,
    applyPromocodeOfferWithPaymentSystem,
    setPromocodeActivationMessage,
    resetPromocode,
    rejectFromPromocode,
  };
}
