<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM6 3.3C6.3866 3.3 6.7 3.6134 6.7 4V4.001C6.7 4.3876 6.3866 4.701 6 4.701C5.6134 4.701 5.3 4.3876 5.3 4.001V4C5.3 3.6134 5.6134 3.3 6 3.3ZM6.625 6C6.625 5.65482 6.34518 5.375 6 5.375C5.65482 5.375 5.375 5.65482 5.375 6V8C5.375 8.34518 5.65482 8.625 6 8.625C6.34518 8.625 6.625 8.34518 6.625 8V6Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM8 4C8.55229 4 9 4.44772 9 5V5.001C9 5.55328 8.55229 6.001 8 6.001C7.44772 6.001 7 5.55328 7 5.001V5C7 4.44772 7.44772 4 8 4ZM8.875 8C8.875 7.51675 8.48325 7.125 8 7.125C7.51675 7.125 7.125 7.51675 7.125 8V11C7.125 11.4832 7.51675 11.875 8 11.875C8.48325 11.875 8.875 11.4832 8.875 11V8Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 6.875C12.6213 6.875 13.125 7.37868 13.125 8V8.001C13.125 8.62232 12.6213 9.126 12 9.126C11.3787 9.126 10.875 8.62232 10.875 8.001V8C10.875 7.37868 11.3787 6.875 12 6.875ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.1667 16.0002C30.1667 23.8242 23.8241 30.1668 16 30.1668C8.17601 30.1668 1.83337 23.8242 1.83337 16.0002C1.83337 8.17613 8.17601 1.8335 16 1.8335C23.8241 1.8335 30.1667 8.17613 30.1667 16.0002ZM16 8.95833C16.7594 8.95833 17.375 9.57394 17.375 10.3333V10.3343C17.375 11.0937 16.7594 11.7093 16 11.7093C15.2406 11.7093 14.625 11.0937 14.625 10.3343V10.3333C14.625 9.57394 15.2406 8.95833 16 8.95833ZM17.25 16C17.25 15.3096 16.6904 14.75 16 14.75C15.3096 14.75 14.75 15.3096 14.75 16V21.6667C14.75 22.357 15.3096 22.9167 16 22.9167C16.6904 22.9167 17.25 22.357 17.25 21.6667V16Z"
          fill="currentColor"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 9.75C8.07107 9.75 9.75 8.07107 9.75 6C9.75 3.92893 8.07107 2.25 6 2.25C3.92893 2.25 2.25 3.92893 2.25 6C2.25 8.07107 3.92893 9.75 6 9.75ZM6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM6 3.3C6.3866 3.3 6.7 3.6134 6.7 4V4.001C6.7 4.3876 6.3866 4.701 6 4.701C5.6134 4.701 5.3 4.3876 5.3 4.001V4C5.3 3.6134 5.6134 3.3 6 3.3ZM6.625 6C6.625 5.65482 6.34518 5.375 6 5.375C5.65482 5.375 5.375 5.65482 5.375 6V8C5.375 8.34518 5.65482 8.625 6 8.625C6.34518 8.625 6.625 8.34518 6.625 8V6Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 13.75C11.1756 13.75 13.75 11.1756 13.75 8C13.75 4.82436 11.1756 2.25 8 2.25C4.82436 2.25 2.25 4.82436 2.25 8C2.25 11.1756 4.82436 13.75 8 13.75ZM8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM8 4C8.55229 4 9 4.44772 9 5V5.001C9 5.55328 8.55229 6.001 8 6.001C7.44772 6.001 7 5.55328 7 5.001V5C7 4.44772 7.44772 4 8 4ZM8.875 8C8.875 7.51675 8.48325 7.125 8 7.125C7.51675 7.125 7.125 7.51675 7.125 8V11C7.125 11.4832 7.51675 11.875 8 11.875C8.48325 11.875 8.875 11.4832 8.875 11V8Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 6.875C12.6213 6.875 13.125 7.37868 13.125 8V8.001C13.125 8.62232 12.6213 9.126 12 9.126C11.3787 9.126 10.875 8.62232 10.875 8.001V8C10.875 7.37868 11.3787 6.875 12 6.875ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 27.6668C22.4434 27.6668 27.6667 22.4435 27.6667 16.0002C27.6667 9.55684 22.4434 4.3335 16 4.3335C9.55672 4.3335 4.33337 9.55684 4.33337 16.0002C4.33337 22.4435 9.55672 27.6668 16 27.6668ZM16 30.1668C23.8241 30.1668 30.1667 23.8242 30.1667 16.0002C30.1667 8.17613 23.8241 1.8335 16 1.8335C8.17601 1.8335 1.83337 8.17613 1.83337 16.0002C1.83337 23.8242 8.17601 30.1668 16 30.1668ZM16 8.95833C16.7594 8.95833 17.375 9.57394 17.375 10.3333V10.3343C17.375 11.0937 16.7594 11.7093 16 11.7093C15.2406 11.7093 14.625 11.0937 14.625 10.3343V10.3333C14.625 9.57394 15.2406 8.95833 16 8.95833ZM17.25 16C17.25 15.3096 16.6904 14.75 16 14.75C15.3096 14.75 14.75 15.3096 14.75 16V21.6667C14.75 22.357 15.3096 22.9167 16 22.9167C16.6904 22.9167 17.25 22.357 17.25 21.6667V16Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
