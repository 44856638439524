import useLogger from '@package/logger/src/use-logger';
import {
  defaultFeatureToggleValues,
  type FeatureToggle,
  type FeatureToggleItem,
  type FeatureToggleVariantTypeMap,
} from '@package/unleash/src/feature-toggle/index';

import { useFeatureToggleApi } from '@/code/feature-toggle/use-feature-toggle-api';

export const useFeaturesStore = defineStore('feature-toggle', () => {
  const features = ref<FeatureToggleItem[]>([]);
  const featureToggleApi = useFeatureToggleApi();
  const { $emitter } = useNuxtApp();
  const logger = useLogger('use-features-store');

  const fetchFeatures = async () => {
    try {
      features.value = await featureToggleApi.fetchAllToggles();
    } catch (error) {
      logger.error(error);

      features.value = [];
    } finally {
      $emitter.emit('app.features.updated');
    }
  };

  const getFeature = (name: FeatureToggle) => {
    const feature = features.value.find((item) => item.name === name);

    return feature;
  };

  const getDefaultFeature = (name: FeatureToggle) => defaultFeatureToggleValues.find((item) => item.name === name);

  const getStatus = ({ forcedStatus, name }: { forcedStatus?: boolean; name?: FeatureToggle }): boolean => {
    const item = getFeature(name!) || getDefaultFeature(name!);
    return forcedStatus ?? item?.status ?? true;
  };

  const getVariant = <T extends FeatureToggle>(
    name: T,
    defaultValue: FeatureToggleVariantTypeMap[T],
  ): FeatureToggleVariantTypeMap[T] => {
    const value = getFeature(name)?.variant?.value;

    if (value) {
      return value as FeatureToggleVariantTypeMap[T];
    }

    return defaultValue;
  };

  return {
    getStatus,
    getVariant,
    getFeature,
    getDefaultFeature,
    fetchFeatures,
    features,
  };
});
