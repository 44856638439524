<template>
  <app-modal-wrapper :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'removeDeviceModalWrapper')">
    <modal-title :title="$t('lkPage.devices.deleteDeviceConfirmation')" />
    <modal-description :description="$t('lkPage.devices.deleteSessionModalQuestion')" />

    <div :class="$style.buttons">
      <app-button
        variation="button-primary"
        :text="$t('lkPage.devices.rejectButton')"
        :title="$t('ariaLabel.devices.deleteReject')"
        :aria-label="$t('ariaLabel.devices.deleteReject')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'rejectRemoveButton')"
        @click="closeModal"
      />

      <app-button
        variation="link-alert"
        :text="$t('lkPage.devices.applyButton')"
        :title="$t('ariaLabel.devices.deleteConfirm')"
        :aria-label="$t('ariaLabel.devices.deleteConfirm')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'confirmRemoveButton')"
        @click="onDeviceDelete"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';

import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { useLayoutStore } from '@/stores/use-layout-store';

const { $emitter, $analyticSender } = useNuxtApp();
const layoutStore = useLayoutStore();

const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

const onDeviceDelete = () => {
  $emitter.emit('app.modal.delete-device');
};

const closeModal = () => {
  layoutStore.setCurrentModalName(null);
  accountPageAnalytics.onClickDevicesRemoveCancel();
};

onMounted(() => {
  $emitter.on('app.modal.close', closeModal);
});

onBeforeUnmount(() => {
  $emitter.off('app.modal.close', closeModal);
});
</script>

<style lang="scss" module>
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-24);
}
</style>
