import { getUtmParams } from '@/code/auth/use-session-actions';
import { OAuthProviderType } from '@/code/o-auth/o-auth';
import type { OAuthResponse } from '@/code/o-auth/types/o-auth';
import type { IUserCreateSession } from '@/code/user/use-users-api';

export function useOAuthResponse() {
  const mapResponseToAuthBody = ({ provider, token, uuid }: OAuthResponse): Partial<IUserCreateSession> => ({
    credentials: {
      type: provider,
      silent_token: provider === OAuthProviderType.VK ? token : undefined,
      oauth_token: provider === OAuthProviderType.Yandex ? token : undefined,
      uuid,
    },
    user_parameters: getUtmParams(),
  });

  return { mapResponseToAuthBody };
}
