<template>
  <aside :class="$style.menuMobile">
    <app-slot-button :class="$style.backButton" @click="closeLanguageMenu()">
      <icon-arrow size="small" direction="left" />
    </app-slot-button>
    <div :class="$style.languagesList">
      <language-switch-button
        v-for="language in languageList"
        :key="language.value"
        :language="language.value"
        :current-language="appLanguage"
        :class="$style.languagesItem"
        @click="setLanguage(language.value)"
      />
    </div>
  </aside>
</template>

<script lang="ts" setup>
import LanguageSwitchButton from '@/components/app-header/LanguageSwitchButton.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import useAppLangSwitcher from '@/platform/localization/use-app-lang-switcher';
import useHostVariation from '@/platform/variation/use-host-variation';

const { languageList, appLanguage } = useHostVariation();
const { setLanguage } = useAppLangSwitcher();

const emit = defineEmits(['close-language-menu']);

const closeLanguageMenu = () => {
  emit('close-language-menu');
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.menuMobile {
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--z-index-burger-menu);
  display: flex;
  flex-direction: column;
  padding: var(--g-spacing-56) var(--g-spacing-24) var(--g-spacing-24);
  width: 258px;
  height: var(--app-height);
  border-radius: var(--g-border-round-24) 0 0 var(--g-border-round-24);
  background-color: var(--color-bg-primary);
  overflow: auto;
}

.backButton {
  position: absolute;
  top: 10px;
  left: 12px;
  padding: var(--g-spacing-10);
}

.menuNavBlockTitle {
  @include fonts.WebTitle-2;
}

.languagesList {
  position: relative;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    width: 490px;
    height: 1px;
    background-color: var(--color-bg-secondary);
  }
}

.languagesItem {
  &:not(:last-child) {
    margin-bottom: var(--g-spacing-16);
  }

  &:not([disabled]) {
    color: var(--color-text-secondary);
    transition: color 0.3s ease-in-out;
  }

  &:not([disabled]):hover {
    color: var(--color-text-primary);
  }
}

@include breakpoints.max-width-800 {
  .languagesList {
    padding: var(--g-spacing-16) 0 0 0;

    &::before {
      display: none;
    }
  }
}

@media screen and (min-width: 801px) {
  .menuMobile {
    transform: translateX(100%);
  }
}
</style>
