import { ProcessingMapper } from '../base/processing-mapper';
import type { MomentPlaylist } from '../content/types/moment-playlist';
import { MomentMapper } from './moment';
import type { ApiMomentPlaylist } from './moment-types/moment-playlist';

export class MomentPlaylistMapper {
  static mapMany(playlists: ApiMomentPlaylist[]): MomentPlaylist[] {
    return ProcessingMapper.process(playlists.map(MomentPlaylistMapper.map));
  }

  static map({ moments, texts, images, content_type, playlist_type, ...rest }: ApiMomentPlaylist): MomentPlaylist {
    return ProcessingMapper.process({
      ...rest,
      contentType: content_type,
      playlistType: playlist_type,
      moments: MomentMapper.mapMany(moments),
      texts: {
        subtitle: texts.kinom_playlist_subtitle,
      },
      images: {
        // @NOTE: открытый интерфейс, когда будут поля заменить по аналогии с texts
        ...images,
      },
    });
  }
}
