import type { DeviceSession } from '@package/sdk/src/api';

export const useTvCodeConnectionStore = defineStore('tv-code-connection', () => {
  const tvCode = ref('');
  const codeErrorText = ref('');
  const countDown = ref(0);
  const deviceSessions = ref<DeviceSession[]>([]);
  const isSuccessCode = ref(false);
  const isConnectRequestLoading = ref(false);
  const isTvConnectNotificationShown = ref(false);

  const isErrorCode = computed(() => Boolean(codeErrorText.value));
  const sessions = computed<DeviceSession[]>(() => deviceSessions.value);

  return {
    tvCode,
    codeErrorText,
    countDown,
    deviceSessions,
    isSuccessCode,
    isConnectRequestLoading,
    isTvConnectNotificationShown,
    isErrorCode,
    sessions,
  };
});
