<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.62543 5.89953C8.16077 5.44101 8.5 4.76012 8.5 4C8.5 2.61929 7.38071 1.5 6 1.5C4.61929 1.5 3.5 2.61929 3.5 4C3.5 4.76012 3.83924 5.44101 4.37457 5.89953C3.2601 6.48499 2.5 7.65373 2.5 9V10.0455C2.5 10.2965 2.70351 10.5 2.95455 10.5H9.04545C9.29649 10.5 9.5 10.2965 9.5 10.0455V9C9.5 7.65373 8.7399 6.48499 7.62543 5.89953Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.49995 5.33329C8.49995 6.34682 8.04759 7.25469 7.33376 7.86604C8.8198 8.64662 9.83333 10.205 9.83333 12V13.3939C9.83333 13.7287 9.56199 14 9.22727 14H1.10606C0.771342 14 0.5 13.7287 0.5 13.3939V12C0.5 10.205 1.51351 8.64667 2.99951 7.86607C2.28565 7.25472 1.83328 6.34683 1.83328 5.33329C1.83328 3.49236 3.32567 2 5.16662 2C7.00757 2 8.49995 3.49236 8.49995 5.33329ZM10.2499 5.33329C10.2499 4.22945 9.8981 3.20778 9.30044 2.37432C9.76016 2.13517 10.2826 2 10.8367 2C12.6776 2 14.17 3.49236 14.17 5.33329C14.17 6.34682 13.7176 7.25469 13.0038 7.86604C14.4898 8.64662 15.5034 10.205 15.5034 12V13.3939C15.5034 13.7287 15.232 14 14.8973 14H11.5047C11.556 13.8066 11.5833 13.6035 11.5833 13.3939V12C11.5833 10.2544 10.886 8.67305 9.7576 7.51751C10.0729 6.85587 10.2499 6.11501 10.2499 5.33329Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.6667 8.44444C12.6667 9.79577 12.0636 11.0062 11.1119 11.8214C13.0932 12.8622 14.4444 14.94 14.4444 17.3333V19.1919C14.4444 19.6382 14.0827 20 13.6364 20H2.80808C2.36179 20 2 19.6382 2 19.1919V17.3333C2 14.94 3.35129 12.8622 5.33256 11.8214C4.38086 11.0062 3.77778 9.79577 3.77778 8.44444C3.77778 5.98985 5.76762 4 8.22222 4C10.6768 4 12.6667 5.98985 12.6667 8.44444ZM14.6667 8.44444C14.6667 7.03255 14.2127 5.7267 13.4426 4.66495C14.1222 4.2434 14.9238 4 15.7823 4C18.2369 4 20.2267 5.98985 20.2267 8.44444C20.2267 9.79577 19.6236 11.0062 18.6719 11.8214C20.6532 12.8622 22.0045 14.94 22.0045 17.3333V19.1919C22.0045 19.6382 21.6427 20 21.1964 20H16.3265C16.4033 19.7441 16.4445 19.4728 16.4445 19.1919V17.3333C16.4445 15.0117 15.482 12.9167 13.938 11.4231C14.403 10.5324 14.6667 9.5189 14.6667 8.44444Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.9444 10.9628C16.9444 12.8773 16.0899 14.5922 14.7416 15.747C17.5485 17.2214 19.463 20.165 19.463 23.5557V26.1887C19.463 26.8209 18.9505 27.3335 18.3182 27.3335H2.97815C2.34591 27.3335 1.83337 26.8209 1.83337 26.1887V23.5557C1.83337 20.165 3.74778 17.2215 6.55467 15.747C5.20628 14.5922 4.3518 12.8773 4.3518 10.9628C4.3518 7.48545 7.17074 4.6665 10.6481 4.6665C14.1254 4.6665 16.9444 7.48545 16.9444 10.9628ZM19.4443 10.9631C19.4443 9.03057 18.8211 7.24357 17.7647 5.79215C18.7837 5.08265 20.0223 4.66667 21.3581 4.66667C24.8355 4.66667 27.6544 7.48561 27.6544 10.963C27.6544 12.8774 26.8 14.5923 25.4516 15.7471C28.2586 17.2216 30.173 20.1651 30.173 23.5559V26.1889C30.173 26.8211 29.6605 27.3336 29.0283 27.3336H21.7796C21.8985 26.9737 21.9629 26.5889 21.9629 26.189V23.556C21.9629 20.2706 20.5622 17.3146 18.3305 15.2495C19.0392 13.9815 19.4443 12.5192 19.4443 10.9631Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
