<template>
  <section ref="menuEl" :class="$style.menu" data-header-parent-menu>
    <div :class="$style.menuContent">
      <app-header-catalog-column
        :current-content-route="currentContentRoute"
        :columns="3"
        :title="$t('catalog.filter.title.genre')"
        :class="$style.genres"
        :items="genres"
      />
      <app-header-catalog-column
        :current-content-route="currentContentRoute"
        :columns="2"
        :grid-layout="false"
        :title="$t('catalog.filter.title.periods')"
        :class="$style.periods"
        :items="years"
      />
      <app-header-catalog-column
        :current-content-route="currentContentRoute"
        :columns="1"
        :title="$t('catalog.filter.title.country')"
        :items="countries.slice(0, 2)"
      />
      <app-header-catalog-column
        v-if="!isRelease"
        title="Меню отладки"
        :columns="1"
        :current-content-route="currentContentRoute"
        :items="[]"
      >
        <li v-for="item in debugMenuItems" :key="item.route">
          <app-link :class="$style.link" :to="{ name: item.route }">
            {{ item.title }}
          </app-link>
        </li>
      </app-header-catalog-column>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { type ParentLinkName, useMouseInElementCustom } from '@/code/layout/use-mouse-in-element-custom';
import AppHeaderCatalogColumn from '@/components/app-header/AppHeaderDropdownMenuColumn.vue';
import type { ContentRouteName } from '@/components/app-header/use-app-header-variables';
import AppLink from '@/components/ui/AppLink.vue';
import useEnvironment from '@/platform/environment/use-environment';
import { AppRoute } from '@/platform/router/routes';
import { useCatalogStore } from '@/stores/use-catalog-store';

const props = defineProps<{
  currentContentRoute: ContentRouteName;
  parentLinkName: ParentLinkName;
}>();

const emit = defineEmits<{
  (e: 'on-outside', val: boolean): void;
}>();

const catalogStore = useCatalogStore();
const { isRelease } = useEnvironment();

const menuEl = ref<HTMLElement>();

const outsideValidator = ({ relatedTarget }: MouseEvent) => relatedTarget?.dataset?.linkType !== props.parentLinkName;
const mouseOutside = useMouseInElementCustom(menuEl, outsideValidator).isOutside;

const debugMenuItems = [
  { title: 'Настройки', route: AppRoute.SandboxDebugPage },
  { title: 'Компоненты', route: AppRoute.SandboxUiPage },
  // { title: 'Тестовый плеер', route: AppRoute.SandboxPlayerPage },
  { title: 'Иконки', route: AppRoute.SandboxIconsPage },
];

const { countries, genres, years } = storeToRefs(catalogStore);
const menuGridTemplateRows = isRelease ? 'repeat(2, auto)' : 'repeat(3, auto)';

watch(mouseOutside, (val) => emit('on-outside', val));
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.menu {
  margin-top: -16px;
  padding-top: var(--g-spacing-16);
}

.menuContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: v-bind(menuGridTemplateRows);
  padding: var(--g-spacing-24) var(--g-spacing-32);
  width: 624px;
  max-height: calc(100vh - var(--app-header-height) - var(--g-spacing-48));
  border-radius: var(--g-border-round-20);
  background-color: var(--color-bg-secondary);
  column-gap: var(--g-spacing-10);
  row-gap: var(--g-spacing-32);
  overflow-y: scroll;
}

.genres {
  grid-column: 1 / 4;
}

.periods {
  grid-column: 1 / 3;
  grid-row: 2;
}

@include breakpoints.max-width-1024 {
  .menu {
    padding: var(--g-spacing-20) var(--g-spacing-24);
    width: 550px;
  }
}
</style>
