import type { AbstractContentAccessKind } from '../../content';
import type { AvailableContentType, MediaContentType } from '../../content/types/content-type';
import type { Country } from '../../content/types/country';
import type { SeoText } from '../../seo/types/seo-text';

export interface ChannelCategory {
  name: string;
  channels: Channel[];
}

export interface ChannelPlaylist {
  url: string;
  dvrUrl: string;
  startedAt: string;
}

export type TVChannelAccessKind = AbstractContentAccessKind;

export enum TVChannelAccessTypes {
  AllUsers = 'all_users',
  Register = 'register',
  Subscription = 'subscription',
  PartlyFree = 'partly_free',
}

export interface Channel {
  id: string;
  accessKind: TVChannelAccessKind;
  availability: AvailableContentType;
  title: string;
  color: string;
  useDvr?: boolean;
  free: boolean;
  description?: string;
  slug?: string;
  lockedInfo?: string;
  logo?: string;
  logoWhite?: string;
  logoBlack?: string;
  background?: string;
  currentProgram?: ChannelProgram;
  nextProgram?: ChannelProgram;
  programs: ChannelProgram[];
  seoText?: SeoText;
  timeshift?: boolean;
}

export interface ChannelProgram {
  id: string;
  cast: string[];
  genre: string[];
  director: string[];
  title?: string;
  sequenceNumber?: number;
  live?: boolean;
  subtitled?: boolean;
  premiere?: boolean;
  seasonNumber: number;
  episodeNumber: number;
  episodeTitle?: string;
  episodeSynopsis?: string;
  ageLimit: number;
  description?: string;
  background?: string;
  countries: Country[];
  startTime: string;
  duration: number;
  contentId?: string;
  contentType?: MediaContentType;
  contentSlug?: string;
  serialId?: string;
}
