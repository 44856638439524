import { useAccountPageAnalytics } from '@package/sdk/src/analytics';
import type { AppLanguage } from '@package/sdk/src/core/variation/variation';

import getAnalyticPageValue from '@/code/kmza/get-kmza-page-value';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import type { AppRoute } from '@/platform/router/routes';

export default function useAppLangSwitcher() {
  const langCookie = useAppCookie(CookieName.AppLanguage);
  const route = useRoute();
  const { $http, $analyticSender } = useNuxtApp();
  const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

  const setLanguage = (language: AppLanguage) => {
    langCookie.value = language;

    $http.cache.clear();

    accountPageAnalytics.onClickLanguageSelect({
      selectedLang: language,
      page: getAnalyticPageValue(route.name as AppRoute),
    });

    window.location.reload();
  };

  return {
    setLanguage,
  };
}
