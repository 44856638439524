<template>
  <div
    :class="{
      [$style.iconWrapper]: true,
      [$style.iconWrapperMedium]: size === 'medium',
      [$style.mainIconBackground]: isMainProfile,
      [$style.childIconBackground]: isChildProfile,
    }"
  >
    <IconUser v-if="isMainProfile" :size="size" />
    <IconChildProfile v-if="isChildProfile" :size="size" />
  </div>
</template>
<script setup lang="ts">
import { ProfileType } from '@SDK/api';

import type { IconSize } from '@/components/icons/icon-types';
import IconChildProfile from '@/components/icons/IconKindProfile.vue';
import IconUser from '@/components/icons/IconUser.vue';

const props = defineProps<{
  profileKind: ProfileType;
  size: IconSize;
}>();

const isMainProfile = props.profileKind === ProfileType.MAIN;
const isChildProfile = props.profileKind === ProfileType.CHILD;
</script>

<style scoped module lang="scss">
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  color: var(--color-text-primary);
}

.iconWrapperMedium {
  width: 40px;
  height: 40px;
}

.mainIconBackground {
  background-color: var(--color-bg-input);
}

.childIconBackground {
  background: var(--gradient-child-profile);
}
</style>
