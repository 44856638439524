<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.75 1.375C3.71447 1.375 2.875 2.21447 2.875 3.25C2.875 3.51666 2.93067 3.77033 3.03102 4H2.75C2.33579 4 2 4.33579 2 4.75V9.75C2 10.1642 2.33579 10.5 2.75 10.5H5.375V5H6.625V10.5H9.25C9.66421 10.5 10 10.1642 10 9.75V4.75C10 4.33579 9.66421 4 9.25 4H8.96898C9.06933 3.77033 9.125 3.51666 9.125 3.25C9.125 2.21447 8.28553 1.375 7.25 1.375C6.76978 1.375 6.33172 1.55553 6 1.85243C5.66828 1.55553 5.23022 1.375 4.75 1.375ZM5.375 3.25C5.375 2.90482 5.09518 2.625 4.75 2.625C4.40482 2.625 4.125 2.90482 4.125 3.25C4.125 3.59518 4.40482 3.875 4.75 3.875H5.375V3.25ZM6.625 3.875H7.25C7.59518 3.875 7.875 3.59518 7.875 3.25C7.875 2.90482 7.59518 2.625 7.25 2.625C6.90482 2.625 6.625 2.90482 6.625 3.25V3.875Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.125 1.125C4.60622 1.125 3.375 2.35622 3.375 3.875C3.375 4.27581 3.46075 4.65659 3.6149 5H3.125C2.50368 5 2 5.50368 2 6.125V13.625C2 14.2463 2.50368 14.75 3.125 14.75H7.125V6.5H8.875V14.75H12.875C13.4963 14.75 14 14.2463 14 13.625V6.125C14 5.50368 13.4963 5 12.875 5H12.3851C12.5393 4.65659 12.625 4.27581 12.625 3.875C12.625 2.35622 11.3938 1.125 9.875 1.125C9.15038 1.125 8.49122 1.40526 8 1.8633C7.50878 1.40526 6.84962 1.125 6.125 1.125ZM7.125 3.875C7.125 3.32272 6.67728 2.875 6.125 2.875C5.57272 2.875 5.125 3.32272 5.125 3.875C5.125 4.42728 5.57272 4.875 6.125 4.875H7.125V3.875ZM8.875 4.875H9.875C10.4273 4.875 10.875 4.42728 10.875 3.875C10.875 3.32272 10.4273 2.875 9.875 2.875C9.32272 2.875 8.875 3.32272 8.875 3.875V4.875Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.5 3C7.567 3 6 4.567 6 6.5C6 7.0368 6.12085 7.54537 6.33682 8H5.5C4.67157 8 4 8.67157 4 9.5V19.5C4 20.3284 4.67157 21 5.5 21H11V10H13V21H18.5C19.3284 21 20 20.3284 20 19.5V9.5C20 8.67157 19.3284 8 18.5 8H17.6632C17.8792 7.54537 18 7.0368 18 6.5C18 4.567 16.433 3 14.5 3C13.5207 3 12.6353 3.40223 12 4.05051C11.3647 3.40223 10.4793 3 9.5 3ZM11 8H9.5C8.67157 8 8 7.32843 8 6.5C8 5.67157 8.67157 5 9.5 5C10.3284 5 11 5.67157 11 6.5V8ZM14.5 8H13V6.5C13 5.67157 13.6716 5 14.5 5C15.3284 5 16 5.67157 16 6.5C16 7.32843 15.3284 8 14.5 8Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.4582 3.4165C9.81181 3.4165 7.6665 5.56181 7.6665 8.20817C7.6665 8.97132 7.84491 9.69279 8.16228 10.3332H6.7915C5.6179 10.3332 4.6665 11.2846 4.6665 12.4582V26.6248C4.6665 27.7984 5.6179 28.7498 6.7915 28.7498H14.7498V13.1665H17.2498V28.7498H25.2082C26.3818 28.7498 27.3332 27.7984 27.3332 26.6248V12.4582C27.3332 11.2846 26.3818 10.3332 25.2082 10.3332H23.8374C24.1548 9.69279 24.3332 8.97132 24.3332 8.20817C24.3332 5.56181 22.1879 3.4165 19.5415 3.4165C18.1384 3.4165 16.8762 4.01957 15.9998 4.98064C15.1235 4.01957 13.8613 3.4165 12.4582 3.4165ZM11.5986 10.3332H14.7498V8.20817C14.7498 6.94252 13.7238 5.9165 12.4582 5.9165C11.1925 5.9165 10.1665 6.94252 10.1665 8.20817C10.1665 9.16984 10.7588 9.99316 11.5986 10.3332ZM17.2498 10.3332H20.4011C21.2408 9.99316 21.8332 9.16984 21.8332 8.20817C21.8332 6.94252 20.8072 5.9165 19.5415 5.9165C18.2759 5.9165 17.2498 6.94252 17.2498 8.20817L17.2498 10.3332Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
