import type { DSMLAnalyticApi, DsmlValue, SendEventOptions } from '@package/dsml-js/src/core/dsml';
import useLogger from '@package/logger/src/use-logger';
import type { AnalyticEventSender, AnalyticParams, SendEvent } from '@package/sdk/src/analytics';
import { AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import { isDefined, isFunction } from '@package/sdk/src/core';
import { FeatureToggle } from '@package/unleash/src/feature-toggle/index';
import { AppVariation } from '@SDK/core/variation/variation';
import type { H3Event } from 'h3';
import type { RouteLocationNormalized } from 'vue-router';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { DsmlEventSender } from '@/code/kmza/instances/dsml-sender';
import { GoogleEventSender } from '@/code/kmza/instances/google-sender';
import { VkEventSender } from '@/code/kmza/instances/vk-sender';
import { YandexEventSender } from '@/code/kmza/instances/yandex-sender';
import { isMobileDevice } from '@/platform/base/dom';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import useEnvironment from '@/platform/environment/use-environment';
import useMobile from '@/platform/layout/use-mobile';
import { AppRoute } from '@/platform/router/routes';
import useHostVariation from '@/platform/variation/use-host-variation';
import useVariationVariables from '@/platform/variation/use-variation-variables';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useSessionStore } from '@/stores/use-session-store';

declare module 'nuxt/app' {
  interface NuxtApp {
    $sendAnalyticEvent: SendEvent;
    $vkEventSender: VkEventSender;
    $analyticSender: AnalyticEventSender;
    $yandexMetricaEventSender: YandexEventSender;
  }
}

interface FlocktoryApi {
  reInit?: VoidFunction;
}

declare global {
  interface Window {
    VK: VkEventSender;
    flocktory?: FlocktoryApi;
    $dsml: DSMLAnalyticApi;
  }
}

function toDSMLFormat(values: any[]): DsmlValue[] {
  return values.map(({ label, value }) => ({ property: label, value }));
}

const dict: Record<string, AnalyticEventNameNew> = {
  [AppRoute.TV]: AnalyticEventNameNew.ShowTvPage,

  [AppRoute.Search]: AnalyticEventNameNew.ShowSearchPage,

  [AppRoute.Movies]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.MovieFilter]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.Serials]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.SerialFilter]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.Index]: AnalyticEventNameNew.ShowMainPage,

  [AppRoute.Contacts]: AnalyticEventNameNew.ShowSupportPage,
  [AppRoute.MyCollection]: AnalyticEventNameNew.ShowCollectionsPage,
  [AppRoute.MyCollectionKinom]: AnalyticEventNameNew.ShowCollectionsPage,
  [AppRoute.MyCollectionContent]: AnalyticEventNameNew.ShowCollectionsPage,
};

export default defineNuxtPlugin(() => {
  const { dsmlPassword, dsmlLogin, isRelease, appVersion, isDev } = useEnvironment();
  const { $emitter } = useNuxtApp();
  const { getYandexMetricaId, getTopmailId } = useVariationVariables();

  const logger = useLogger('analytics');
  const req = useRequestEvent();
  const { appVariation, isWebviewMode, appLanguage } = useHostVariation();
  const { getVariant } = useFeaturesStore();

  const isMobile = isMobileDevice(req as unknown as H3Event);
  const sessionStore = useSessionStore();
  const { user, userIp, userIpKey } = storeToRefs(sessionStore);
  const route = useRoute();
  const router = useRouter();

  const visitorIdCookie = useAppCookie(CookieName.VisitorId);

  const googleClientId = useAppCookie(CookieName.GoogleAnalyticsId);

  if (googleClientId.value) {
    sessionStore._gaClientId = googleClientId.value.replace(/^GA\d+.\d+./, '');
  }

  const dsml = window.$dsml;
  const dsmlEventSender = new DsmlEventSender();
  const yandexMetricaEventSender = new YandexEventSender();
  const vkEventSender = new VkEventSender();
  const googleEventSender = new GoogleEventSender();

  const yandexMetricaId = getYandexMetricaId(appVariation);
  const vkId = getTopmailId(appVariation);

  const initSenders = () => {
    const dsmlInitPromise = dsmlEventSender.initialize({
      visitorId: visitorIdCookie.value,
      isMobile,
      isRelease,
      userId: user.value?.id,
      profileId: user.value?.currentProfileId,
      appVersion,
      appVariation: appVariation === AppVariation.Ru ? 'ru' : 'am',
      userIp: userIp.value,
      userIpKey: userIpKey.value,
      password: dsmlPassword,
      login: dsmlLogin,
    });

    yandexMetricaEventSender.initialize({ id: yandexMetricaId, isDev });

    if (vkId) {
      vkEventSender.initialize({ id: vkId, isDev });
    }

    googleEventSender.initialize({ isDev });

    dsmlInitPromise.then(() => {
      sendAnalytic({
        event: AnalyticEventNameNew.AutoStartSession,
        page: getKmzaPageValue(route.name as AppRoute),
        values: [
          {
            label: AnalyticPayloadLabelName.CurrentLang,
            value: appLanguage.value,
          },
        ],
      });

      onShowPageAnalytic(route);
      router.afterEach(onShowPageAnalytic);
    });
  };

  const onFeatureFlagsUpdated = () => {
    dsml?.setFeatureFlags({
      [FeatureToggle.AllPlatformTestAA]: getVariant(FeatureToggle.AllPlatformTestAA, ''),
    });
  };

  const sendAnalytic: SendEvent = ({ event, page, values, options = {} }) => {
    // В Webview режиме не отправляем
    if (isWebviewMode.value) {
      return;
    }

    const analyticEvent: SendEventOptions = {
      name: event as string,
      page,
      values: isDefined(values) ? toDSMLFormat(values) : ([] as DsmlValue[]),
      options,
    };

    logger.trace('sendAnalytic', analyticEvent);

    dsmlEventSender.sendEvent(analyticEvent);
    yandexMetricaEventSender.sendYmEvent(event as AnalyticEventNameNew);
    googleEventSender.sendEvent(event as AnalyticEventNameNew, analyticEvent.values);
    vkEventSender.sendEvent(event as AnalyticEventNameNew);
  };

  const reInitFlocktory = () => {
    window.flocktory && isFunction(window.flocktory.reInit) && window.flocktory.reInit();
  };

  const onShowPageAnalytic = (to: RouteLocationNormalized) => {
    const eventName = dict[to.name as AppRoute];

    if (!eventName) {
      return;
    }

    sendAnalytic({
      event: eventName,
      page: getKmzaPageValue(to.name as AppRoute),
    });
  };

  const initInternalAnalytics = () => {
    return {
      sendAnalyticEvent: (params: AnalyticParams) => sendAnalytic(params),
      getCurrentAnalyticPageValue: () => getKmzaPageValue(route.name as AppRoute),
    };
  };

  const analyticSender = initInternalAnalytics();

  initSenders();
  onFeatureFlagsUpdated();

  router.afterEach(reInitFlocktory);

  $emitter.on('app.features.updated', onFeatureFlagsUpdated);

  const initAppsFlyer = () => {
    const getAppsFlyerId = (variation: AppVariation) => {
      switch (variation) {
        case AppVariation.Ru:
        case AppVariation.Am:
          return '60c6d80f-ed60-4b5d-b458-08326ff1c206';
        default:
          return undefined;
      }
    };

    const isMobile = useMobile();
    const { isDev } = useEnvironment();

    const isPWA = () => {
      const isStandaloneViaDisplayMode = window.matchMedia('(display-mode: standalone)').matches;

      const isStandaloneViaNavigator = window.navigator.standalone || false;

      return isStandaloneViaDisplayMode || isStandaloneViaNavigator;
    };

    const injectAnalyticVariables = () => {
      const getAppsflyerSmartBannerScript = (key: string) =>
        `
!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: {{ APPSFLYER_SMARTBANNER_KEY }}}});
AF('banners', 'showBanner')
`.replace('{{ APPSFLYER_SMARTBANNER_KEY }}', `'${key}'`);

      if (isDev) {
        return;
      }
      const appsFlyerId = getAppsFlyerId(appVariation);

      if (isMobile && appsFlyerId && !isPWA()) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = getAppsflyerSmartBannerScript(appsFlyerId);
        document.head.appendChild(script);
      }
    };

    const { appVariation } = useVariationVariables();

    injectAnalyticVariables();
  };

  if (!isWebviewMode.value) {
    initAppsFlyer();
  }

  return {
    provide: {
      analyticSender,
      vkEventSender,
      sendAnalyticEvent: sendAnalytic,
      yandexMetricaEventSender,
    },
  };
});
