import type { CloudPaymentsInvoice } from '@package/sdk/src/api';

const usePaymentsStore = defineStore('pay', () => {
  const isCloudpaymentsWidgetMounted = ref(false);

  const cloudpaymentsPaymentInfoInitialState: CloudPaymentsInvoice = {
    invoiceId: '',
    amount: 0,
    description: '',
    currency: '',
    transactionType: 'auth',
  };
  const _cloudpaymentsPaymentInfo = ref<CloudPaymentsInvoice>(cloudpaymentsPaymentInfoInitialState);

  const setCloudpaymentsPaymentInfo = (response: CloudPaymentsInvoice) => {
    _cloudpaymentsPaymentInfo.value = response;
  };

  const setCloudpaymentsPaymentInfoToDefault = () => {
    _cloudpaymentsPaymentInfo.value = cloudpaymentsPaymentInfoInitialState;
  };

  const cloudpaymentsPaymentInfo = computed<CloudPaymentsInvoice>(() => _cloudpaymentsPaymentInfo.value);

  const setIsCloudpaymentsWidgetMounted = (value: boolean) => {
    isCloudpaymentsWidgetMounted.value = value;
  };

  return {
    cloudpaymentsPaymentInfo,
    isCloudpaymentsWidgetMounted,
    setCloudpaymentsPaymentInfo,
    setIsCloudpaymentsWidgetMounted,
    setCloudpaymentsPaymentInfoToDefault,
  };
});

export default usePaymentsStore;
