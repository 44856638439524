<template>
  <div :class="$style.container">
    <app-slot-button
      :class="$style.closeIcon"
      :title="$t('ariaLabel.modal.closeModal')"
      :aria-label="$t('ariaLabel.modal.closeModal')"
      :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'closeModal')"
      @click="closeModal"
    >
      <icon-close size="large" />
    </app-slot-button>

    <icon-logo :class="$style.logo" size="mini" />
    <my-channel
      ref="myChannelRef"
      :class="$style.myChannelPage"
      :is-modal-mode="true"
      :moments="moments"
      :playlists="modalOptions.playlists"
      :initial-moment-id="modalOptions.selectedMomentId"
      :initial-playlist="initialPlaylist"
      :is-loading-finished="isLoadingFinished"
      :kinom-card-displayed-title="modalOptions.kinomCardDisplayedTitle"
      @on-category-select="onCategorySelect"
      @on-load-more="onLoadMore"
      @click.stop
    />
  </div>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import type { Moment, MomentPlaylist } from '@package/sdk/src/api';
import { DisposableStore, KeyCode } from '@package/sdk/src/core';
import { InvalidPlaylistError } from '@package/sdk/src/core/errors/invalid-playlist-error';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import type { CommonMoment } from '@/code/moments/moments';
import { NotificationLevel } from '@/code/notifications/notification';
import IconClose from '@/components/icons/IconClose.vue';
import IconLogo from '@/components/icons/IconLogo.vue';
import MyChannel from '@/components/my-channel/MyChannel.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import useLocale from '@/platform/localization/use-locale';
import { type DropdownOption, type ModalOptions, useLayoutStore } from '@/stores/use-layout-store';

const { modalOptions: options, setCurrentModalName, addNotification } = useLayoutStore();
const { translate } = useLocale();
const { $emitter, $keyboardHandler } = useNuxtApp();

const logger = useLogger('MyChannelModal.vue');

const router = useRouter();

const modalOptions = options as ModalOptions<'MyChannelModal'>;

const disposableStore = new DisposableStore();

const modalMoments = ref(modalOptions.moments);
const isLoadingFinished = ref(modalOptions.isLoadingFinished || false);

const closeModal = () => {
  setCurrentModalName(null);
};

const myChannelRef = ref<
  ComponentPublicInstance<{
    activeMoment: Moment;
    activeMomentIndex: number;
    selectedDropdownOption: DropdownOption;
    setActiveMoment: (moment: CommonMoment) => void;
  }>
>();

const fetchMoments = async (): Promise<MomentPlaylist | undefined> => {
  if (!modalOptions.fetchCallback) {
    return undefined;
  }

  try {
    return await modalOptions.fetchCallback(selectedOption.value);
  } catch (error) {
    closeModal();

    addNotification({
      level: NotificationLevel.Info,
      message: translate('page.myChannel.playerOpenError'),
      hideTimeoutMs: 4000,
      position: 'top',
    });

    throw new InvalidPlaylistError('playlist');
  }
};

const onCategorySelect = async () => {
  try {
    const fetchedMoments = await fetchMoments();
    modalMoments.value = fetchedMoments?.moments || [];
  } catch (error) {
    logger.error(error);
  }

  myChannelRef.value?.setActiveMoment(moments.value[0]);
};

const onLoadMore = () => {
  $emitter.emit('app.modal.my-channel.load-more');
};

const selectedOption = computed(
  () => myChannelRef.value?.selectedDropdownOption?.value || modalOptions.selectedPlaylistId,
);

const moments = computed(() => modalMoments.value);

const initialPlaylist = modalOptions.playlists.find((playlist) => playlist.value === modalOptions.selectedPlaylistId);

const onMyChannelMomentsUpdated = (moments: CommonMoment[]) => {
  if (!moments.length) {
    isLoadingFinished.value = true;
  }
  modalMoments.value = [...modalMoments.value, ...moments];
};

onMounted(() => {
  $emitter.on('app.modal.my-channel.moments-updated', onMyChannelMomentsUpdated);

  disposableStore.add($keyboardHandler.on(KeyCode.Escape, closeModal));
});

onBeforeUnmount(() => {
  $emitter.off('app.modal.my-channel.moments-updated', onMyChannelMomentsUpdated);

  disposableStore.dispose();
});

watch(
  () => router.currentRoute.value,
  () => {
    closeModal();
  },
);
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.container {
  display: flex;
  flex-direction: column;
  margin: 110px 0 var(--g-spacing-48) 0;
  width: 100%;
}

.closeIcon {
  position: absolute;
  top: 40px;
  right: var(--g-spacing-48);
}

.logo {
  display: none;
  margin: var(--g-spacing-8) var(--g-spacing-16);
}

.myChannelPage {
  height: auto;
}

@include breakpoints.max-width-1024 {
  .container {
    margin: 0;
    height: 100%;
    background-color: var(--color-notheme-bg-stream);
  }

  .myChannelPage {
    width: 100%;
    height: 100%;
  }

  .closeIcon {
    top: var(--g-spacing-16);
    right: var(--g-spacing-16);
  }

  .logo {
    display: block;
  }
}
</style>
