import type { SessionTokens } from '@package/sdk/src/api';
import { type AppLanguage } from '@package/sdk/src/core/variation/variation';
import type { CookieOptions } from 'nuxt/app';

import type { AppTheme } from '@/code/layout/app-theme';
import type { CookieName } from '@/platform/cookies/cookies';

type Nullable<T> = { [K in keyof T]: T[K] };

type CookieValueMap = Nullable<{
  [CookieName.GoogleAnalyticsId]: string;
  [CookieName.VisitorId]: string;

  [CookieName.Auth]: SessionTokens | undefined;

  [CookieName.ReferralModalShowingDate]: Date;

  [CookieName.AppLanguage]: AppLanguage;
  [CookieName.Promocode]: string;
  [CookieName.AppTheme]: AppTheme;
}>;

export default function useAppCookie<T extends CookieName>(key: T, options?: CookieOptions) {
  // @ts-expect-error
  return useCookie<CookieValueMap[T]>(key, options as any);
}
