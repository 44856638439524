import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import SVGDzenLogo from 'static/images/logos/dzen.svg?raw';
import SVGOkLogo from 'static/images/logos/ok.svg?raw';
import SVGTelegramLogo from 'static/images/logos/telegram.svg?raw';
import SVGVkLogo from 'static/images/logos/vk.svg?raw';
import type { FunctionalComponent } from 'vue';

import IconAppGalleryRu from '@/components/icons/IconAppGalleryRu.vue';
import IconAppStoreRu from '@/components/icons/IconAppStoreRu.vue';
import IconGoogleStoreRu from '@/components/icons/IconGoogleStoreRu.vue';
import IconRuStoreRu from '@/components/icons/IconRuStoreRu.vue';
import useLocale from '@/platform/localization/use-locale';
import type { ICompanyInformation, ISocialMediaLink } from '@/platform/variation/variation-types';

import { SocialMediaType } from './variation-types';

const TECH_SUPPORT_EMAIL = 'help@viju.ru';
const TECH_SUPPORT_PHONE = '8 800 775-60-70';
const OFFICE_ADDRESS = '123242, г. Москва, ул. Зоологическая, д.1. стр.1';
const BUSINESS_CENTER = '';

const SocialMediaLink = {
  Telegram: 'https://t.me/viju_kino',
  TelegramBot: 'https://t.me/vijusupport_bot',
  Vk: 'https://vk.me/viju_kino',
  Ok: 'https://ok.ru/vijukino',
  Dzen: 'https://dzen.ru/viju',
};

export default function useRuVariation() {
  const { translate } = useLocale();
  const ruCompanyInformation: ICompanyInformation = {
    supportEmail: TECH_SUPPORT_EMAIL,
    contacts: [
      {
        title: 'Email:',
        value: TECH_SUPPORT_EMAIL,
      },
      {
        title: 'Колл-центр:',
        value: TECH_SUPPORT_PHONE,
      },
      {
        title: 'Офис:',
        value: '+7 495 225-73-01',
      },
      {
        title: 'Факс:',
        value: '+7 495 231-20-52',
      },
    ],
    socials: SocialMediaLink,
    officeAddress: OFFICE_ADDRESS,
    businessCenter: BUSINESS_CENTER,
    workTime: 'Понедельник — пятница с 9:00 до 18:00',
    companyInformation: ['ООО «Виасат Глобал»', 'ИНН: 7717567251', 'ОГРН: 5067746471766'],
  };

  const ruSocialMediaLinks: ISocialMediaLink[] = [
    {
      name: SocialMediaType.Telegram,
      icon: SVGTelegramLogo as unknown as FunctionalComponent,
      href: SocialMediaLink.Telegram,
    },
    {
      name: SocialMediaType.Vk,
      icon: SVGVkLogo as unknown as FunctionalComponent,
      href: SocialMediaLink.Vk,
    },
    {
      name: SocialMediaType.Ok,
      icon: SVGOkLogo as unknown as FunctionalComponent,
      href: SocialMediaLink.Ok,
    },
    {
      name: SocialMediaType.Dzen,
      icon: SVGDzenLogo as unknown as FunctionalComponent,
      href: SocialMediaLink.Dzen,
    },
  ];

  const ruStoreLinks: any[] = [
    {
      link: 'https://redirect.appmetrica.yandex.com/serve/244747204969973673',
      title: translate('ariaLabel.socialMedia.goToStore', { storeName: 'Google Play' }),
      analyticEventName: AnalyticEventNameNew.GotoGooglePlay,
      icon: IconGoogleStoreRu,
    },
    {
      link: 'https://redirect.appmetrica.yandex.com/serve/965323173602036931',
      title: translate('ariaLabel.socialMedia.goToStore', { storeName: 'App Store' }),
      analyticEventName: AnalyticEventNameNew.GotoAppStore,
      icon: IconAppStoreRu,
    },
    {
      link: 'https://redirect.appmetrica.yandex.com/serve/101159552543715578',
      title: translate('ariaLabel.socialMedia.goToStore', { storeName: 'App Gallery' }),
      analyticEventName: AnalyticEventNameNew.GotoAppGalleryStore,
      icon: IconAppGalleryRu,
    },
    {
      link: 'https://redirect.appmetrica.yandex.com/serve/821735490859400200',
      title: translate('ariaLabel.socialMedia.goToStore', { storeName: 'Ru Store' }),
      analyticEventName: AnalyticEventNameNew.GotoRuStore,
      icon: IconRuStoreRu,
    },
  ];
  return {
    ruSocialMediaLinks,
    ruStoreLinks,
    ruCompanyInformation,
  };
}
