<template>
  <app-modal-wrapper
    :max-width="488"
    :with-padding="false"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.TvModal, 'modalWrapper')"
  >
    <img :alt="$t('alt.modals.tvPromo.basicTvChannels')" :class="$style.image" src="/images/basic-tv-channels.webp" />

    <div :class="$style.contentBlock">
      <modal-title :title="$t('tvChannels.popupTitle')" :is-html="true" />

      <div :class="$style.buttons">
        <app-button
          :class="$style.startUseButton"
          :text="$t('common.startWatching')"
          :aria-label="$t('ariaLabel.tvPromoPopup.startWatching')"
          :title="$t('ariaLabel.tvPromoPopup.startWatching')"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.TvModal, 'startWatchingButton')"
          @click="onStartWatchingClick()"
        />

        <app-link
          v-if="!isPartnerUserWithoutSubscription"
          :to="redirectRoute"
          :aria-label="$t('ariaLabel.tvPromoPopup.getSubscription')"
          :title="$t('ariaLabel.tvPromoPopup.getSubscription')"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.TvModal, 'getSubscriptionButton')"
          @click="onGetSubscriptionClick()"
        >
          <app-button
            :class="$style.startUseButton"
            variation="link-primary"
            :text="$t('common.getSubscription')"
            :aria-label="$t('ariaLabel.tvPromoPopup.getSubscription')"
            :title="$t('ariaLabel.tvPromoPopup.getSubscription')"
            @click="onStartWatchingClick()"
          />
        </app-link>
      </div>
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { AnalyticPageName, OfferPageFrom, useSubscribeAnalytics, useTvPageAnalytics } from '@SDK/analytics';
import type { RouteLocationRaw } from 'vue-router';

import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useUserStore } from '@/stores/use-user-store';

const { $analyticSender } = useNuxtApp();
const subscribeAnalytics = useSubscribeAnalytics($analyticSender);
const tvPageAnalytics = useTvPageAnalytics($analyticSender);

const layoutStore = useLayoutStore();
const userStore = useUserStore();
const { injectAdriverScript } = useKmzaDriverEvents();

const { savedPromocode } = storeToRefs(usePromocodeStore());
const { isPartnerUserWithoutSubscription } = storeToRefs(userStore);

const { modalOptions } = storeToRefs(useLayoutStore());

const route = useRoute();

const redirectRoute = computed<RouteLocationRaw>(() => {
  const query = {
    ...route.query,
  };

  if (savedPromocode.value) {
    return {
      name: AppRoute.OffersPromo,
      params: {
        promocode: savedPromocode.value,
      },
      query,
    };
  }

  return {
    name: AppRoute.Offers,
    query,
  };
});

const onClose = () => {
  layoutStore.setCurrentModalName(null);
};

const onStartWatchingClick = () => {
  injectAdriverScript(AdriverAnalyticEvent.StartWatchingButtonTvPromo);
  onClose();
};

const onGetSubscriptionClick = () => {
  injectAdriverScript(AdriverAnalyticEvent.GetSubscription);
  subscribeAnalytics.onGotoOfferPage({
    page: AnalyticPageName.TvChannel,
    from: OfferPageFrom.FreeTV,
  });
  onClose();
};

onMounted(() => {
  if (modalOptions.value) {
    tvPageAnalytics.onShowTvFreePopup(modalOptions.value.channel);
  }
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.image {
  margin-top: var(--g-spacing-40);
  width: 100%;
  max-height: 200px;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: var(--g-spacing-32);
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: var(--g-spacing-16);
  width: 100%;
}

.startUseButton {
  padding-top: var(--g-spacing-16);
  width: 100%;
}

@include breakpoints.max-width-800 {
  .buttons {
    grid-template-columns: 1fr;
    row-gap: var(--g-spacing-8);
  }

  .image {
    max-height: 300px;
    object-fit: cover;
  }
}

@include breakpoints.max-width-540 {
  .contentBlock {
    padding: var(--g-spacing-28) var(--g-spacing-16) var(--g-spacing-28);
  }

  .startUseButton {
    margin-bottom: var(--g-spacing-16);
  }
}
</style>
