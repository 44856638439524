import { ensureEndSlash } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';

import { isEndsWithSlash } from '@/platform/base/string';

export default defineNuxtRouteMiddleware((route) => {
  const path = route.path as string;
  const pathWithSlash = ensureEndSlash(path);
  const isRouteEndsWithSlash = isEndsWithSlash(path);
  const hasQuery = Object.keys(route.query).length;

  if (!isRouteEndsWithSlash && !hasQuery) {
    return navigateTo(pathWithSlash, { redirectCode: HTTPStatusCode.MovedPermanently });
  }
});
