import { isClient } from '@vueuse/core';

const REAL_DEVICE_USER_AGENT = isClient ? window.navigator.userAgent : '';
const getUserAgent = () => REAL_DEVICE_USER_AGENT;

const INSTALLED_USER_AGENT = getUserAgent();

export const isTouchDevice = isClient ? Reflect.has(window, 'ontouchstart') : false;

export const isIOS = /iPad|iPhone|iPod/.test(INSTALLED_USER_AGENT);
export const isFirefox = /Firefox|FxiOS/i.test(INSTALLED_USER_AGENT);

export const isSafari = Boolean(
  isClient
    ? window.navigator.vendor &&
        window.navigator.vendor.includes('Apple') &&
        INSTALLED_USER_AGENT &&
        !INSTALLED_USER_AGENT.includes('CriOS') &&
        !INSTALLED_USER_AGENT.includes('FxiOS')
    : false,
);
