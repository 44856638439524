import type { PageStrings, TPageStringsValue } from '@package/sdk/src/api';
import { PageSlug } from '@package/sdk/src/api';

import { useFrontendPagesApi } from '@/code/frontend-pages/use-frontend-pages-api';

export const usePageStringsStore = defineStore('page-strings', () => {
  const pageStrings = ref({} as PageStrings);
  const frontendPagesApi = useFrontendPagesApi();

  const fetchFrontendPages = async <T extends TPageStringsValue>(slug: PageSlug) => {
    if (!pageStrings.value[slug] || slug === PageSlug.SubscriptionLKInfo) {
      const response = await frontendPagesApi.fetchPage(slug);

      (pageStrings.value[slug] as TPageStringsValue) = response[slug] as TPageStringsValue;
    }

    return pageStrings.value[slug] as T;
  };

  return {
    pageStrings,
    fetchFrontendPages,
  };
});
