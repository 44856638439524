import { useParentalControlAnalytics } from '@SDK/analytics';
import type { Profile } from '@SDK/api';
import { ParentalControlStatus, ParentalControlStep, ProfileType } from '@SDK/api';

import { useTvChannels } from '@/code/channels/use-tv-channels';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import useProfile from '@/code/profile/use-profile';
import { toPlainObject } from '@/platform/base/string';
import { AppRoute } from '@/platform/router/routes';
import { BroadcastChannelEvent } from '@/plugins/broadcast-channel';
import { useLayoutStore } from '@/stores/use-layout-store';
import useParentalControlStore from '@/stores/use-parental-control-store';
import { useSessionStore } from '@/stores/use-session-store';

export default function useSetProfile() {
  const { $analyticSender, $broadcastChannel } = useNuxtApp();
  const route = useRoute();
  const { profile: currentProfile } = useProfile();

  const layoutStore = useLayoutStore();
  const sessionStore = useSessionStore();
  const parentalControlStore = useParentalControlStore();
  const tvChannels = useTvChannels();

  const { currentDeviceParentalControlStatus, isAccountParentalControlSettled } = storeToRefs(sessionStore);
  const { step } = storeToRefs(parentalControlStore);

  const { onClickParentalProfileSelect } = useParentalControlAnalytics($analyticSender);
  const page = getKmzaPageValue(route.name as AppRoute);

  const isProfileButtonDisabled = ref(false);

  const setProfile = async (profile: Profile) => {
    isProfileButtonDisabled.value = true;

    if (currentProfile.value?.id === profile.id) {
      isProfileButtonDisabled.value = false;
      return;
    }

    onClickParentalProfileSelect(profile.ageLimit, page);

    $broadcastChannel.postMessage(toPlainObject({ event: BroadcastChannelEvent.ParentalControl, payload: profile }));

    const isTargetChildProfile = profile.kind === ProfileType.CHILD;
    if (!isTargetChildProfile) {
      layoutStore.setCurrentModalName('ParentalControlProfileModal');
      isProfileButtonDisabled.value = false;
      return;
    }

    if (isAccountParentalControlSettled.value) {
      if (currentDeviceParentalControlStatus.value === ParentalControlStatus.NoChildrenAccess) {
        await sessionStore.setParentalControlStatus({ isChildrenAccess: true });
        await sessionStore.refreshSession();

        isProfileButtonDisabled.value = false;
        return;
      }
    }

    if (!isAccountParentalControlSettled.value) {
      step.value = ParentalControlStep.SetPinCode;
      parentalControlStore.setIsLogoutButtonShown(false);
      layoutStore.setCurrentModalName('ParentalControlModal');

      isProfileButtonDisabled.value = false;
      return;
    }

    await sessionStore.setProfile(profile);
    await sessionStore.refreshSession();

    isProfileButtonDisabled.value = false;

    if (route.name !== AppRoute.AccountProfiles) {
      reloadNuxtApp();
    }

    await tvChannels.fetchTvChannels();
  };

  return { isProfileButtonDisabled, currentProfile, setProfile };
}
