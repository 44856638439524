import type { ChannelCategory } from '@SDK/api';

export const useTvChannelsStore = defineStore('tvStore', () => {
  const categorisedChannels = ref<ChannelCategory[]>([]);

  const setChannels = (channels: ChannelCategory[]) => {
    categorisedChannels.value = channels;
  };

  return {
    categorisedChannels,
    setChannels,
  };
});
