import { useChannelsApi } from '@/code/channels/use-channels-api';
import { useTvChannelsStore } from '@/stores/use-tv-channels-store';

export const useTvChannels = () => {
  const channelsApi = useChannelsApi();
  const tvChannelsStore = useTvChannelsStore();

  const fetchTvChannels = async () => {
    const channels = await channelsApi.fetchCategorizedChannels();
    tvChannelsStore.setChannels(channels);
  };

  return {
    fetchTvChannels,
  };
};
