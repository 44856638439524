<template>
  <section
    ref="wrapperRef"
    :class="{
      [$style.wrapper]: true,
      [$style.wrapperPaddings]: !isModalMode,
      [$style.wrapperMargins]: isModalMode,
    }"
    tabindex="0"
    @keydown.down.prevent="onArrowDownPress"
    @keydown.up.prevent="onArrowUpPress"
    @keydown.enter="onEnterPress"
  >
    <div :class="$style.container">
      <div :class="$style.playerPlaceholder">
        <template v-if="isMyCollectionEmpty">
          <my-channel-empty-my-collection v-if="$isMobile" />
          <icon-logo v-else :size="$isMobile ? 'mini' : 'normal'" />
        </template>

        <my-channel-view
          v-else
          :moments="moments"
          :active-moment="activeMoment"
          :active-moment-index="activeMomentIndex"
          :is-modal-mode="isModalMode"
          :selected-playlist-id="selectedDropdownOption?.value"
          :selected-playlist-title="selectedDropdownOption?.label"
          @on-ready="onPlayerReady"
          @on-screen-rotate="screenRotate"
          @on-like="handleKinomReaction(LikeState.Like)"
          @on-dislike="handleKinomReaction(LikeState.Dislike)"
          @on-remove-reaction="handleKinomReaction(LikeState.Cancel)"
          @on-ended="onEndedMoment"
          @on-change="onChangeMoment"
          @on-load-more="onLoadMore"
          @on-share="onShareClick"
          @on-collection-add="setInCollection(true)"
          @on-collection-remove="setInCollection(false)"
          @on-play="onPlay"
          @on-pause="onPause"
        />
      </div>

      <my-channel-kinoms
        ref="myChannelKinomsRef"
        :moments="moments"
        :selected-dropdown-option="selectedDropdownOption"
        :playlists="playlists"
        :active-moment="activeMoment"
        :active-moment-index="activeMomentIndex"
        :is-my-channel-episodes-button-disabled="isMyChannelEpisodesButtonDisabled"
        :is-loading="isLoading"
        :is-loading-finished="isLoadingFinished"
        :is-my-collection-empty="isMyCollectionEmpty"
        :kinom-card-displayed-title="kinomCardDisplayedTitle"
        @on-dropdown-header-click="onDropdownOpenClick"
        @on-category-select="onCategorySelect"
        @on-moments-list-toggled="onMomentListToggled"
        @on-moment-card-click="onMomentCardClick"
        @on-load-more="onLoadMore"
      />
      <client-only>
        <teleport to="#root-layout">
          <ambient-video-background
            :hexs="hexs"
            :rect-height="140"
            type="mixed"
            :class="{ [$style.modalModeAmbient]: isModalMode }"
          />
        </teleport>
      </client-only>
    </div>
  </section>
</template>

<script lang="ts" setup>
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import type {
  IAmbientModeGradientUpdatedEvent,
  VideoPlayerExternalEvent,
} from '@package/media-player/src/player/modules/event/external-event';
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import { AnalyticPageName, useKinomAnalytics, useMyChannelAnalytics } from '@package/sdk/src/analytics';
import type { Moment } from '@package/sdk/src/api';
import { Category, LikeState } from '@package/sdk/src/api';
import {
  DisposableStore,
  getFirstElement,
  isUndefined,
  KeyCode,
  timeout,
  UnexpectedComponentStateError,
} from '@package/sdk/src/core';
import { onClickOutside, useWindowSize } from '@vueuse/core';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import type { CommonMoment } from '@/code/moments/moments';
import type { MyChannelKinomCardDisplayedTitle } from '@/code/my-channel/my-channel';
import { useMyChannelModal } from '@/code/my-channel/use-my-channel-modal';
import IconLogo from '@/components/icons/IconLogo.vue';
import MyChannelEmptyMyCollection from '@/components/my-channel/MyChannelEmptyMyCollection.vue';
import MyChannelKinoms from '@/components/my-channel/MyChannelKinoms.vue';
import MyChannelView from '@/components/my-channel/MyChannelView.vue';
import AmbientVideoBackground from '@/components/video-player/ambient-video-background.vue';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import type { DropdownOption } from '@/stores/use-layout-store';

interface ChangeMomentOptions {
  index?: number;
}

const props = withDefaults(
  defineProps<{
    moments: CommonMoment[];
    playlists: DropdownOption[];
    kinomCardDisplayedTitle: MyChannelKinomCardDisplayedTitle;
    initialMomentId?: string;
    initialPlaylist?: DropdownOption;
    isModalMode?: boolean;
    isLoading?: boolean;
    isMyChannelEpisodesButtonDisabled?: boolean;
    isLoadingFinished?: boolean;
    currentPage?: number;
  }>(),
  {
    isModalMode: false,
    isLoading: false,
    isMyChannelEpisodesButtonDisabled: false,
    isLoadingFinished: false,
    currentPage: 1,
  },
);

const emit = defineEmits<{
  (e: 'on-load-more'): void;
  (e: 'on-share'): void;
  (e: 'on-category-select', option: DropdownOption): void;
  (e: 'on-moment-card-click', moment: CommonMoment, playlist: string): void;
}>();

const { $analyticSender } = useNuxtApp();
const kinomAnalytics = useKinomAnalytics($analyticSender);
const myChannelAnalytics = useMyChannelAnalytics($analyticSender);

const { isMyChannelPage } = useMyChannelModal();

const route = useRoute();
const { $keyboardHandler } = useNuxtApp();
const disposableStore = new DisposableStore();

const { width } = useWindowSize();

const MOBILE_SCREEN_WIDTH = 480;

const shouldAmbientColorUpdate = ref(true);

const selectedDropdownOption = ref<DropdownOption>();

const isMomentsListOpened = ref(false);
const kinomsRef = ref<HTMLDivElement>();
const focusedOption = ref<HTMLElement>();
const wrapperRef = ref<HTMLElement>();

const myChannelKinomsRef = ref<
  ComponentPublicInstance<{
    isScrolling: boolean;
    isCardPressedToTop: boolean;
    scrollToActiveElement: () => void;
    getKinomSidebarEls: () => HTMLElement[] | Element[];
    setCardsWrapperScrollToTop: (scrollTop: number) => void;
  }>
>();

const hexs = ref<string[]>([]);
const player = ref<VijuPlayer>();

const isLoadedMomentsEmpty = ref(false);

const onLoadMore = () => {
  emit('on-load-more');

  if (!isMyChannelPage.value) {
    return;
  }

  myChannelAnalytics.onAutoMyChannelListUpdated();
};

const setActiveMomentParam = async (momentId: string, playlistId = selectedDropdownOption.value?.value) => {
  if (!props.isModalMode) {
    await navigateTo(
      {
        name: AppRoute.MyChannelMoment,
        params: { id: momentId },
        query: { [RouteQuery.Category]: playlistId },
      },
      { replace: true },
    );
  }
};

const activeMoment = ref<CommonMoment>();

const setActiveMoment = (moment: CommonMoment) => {
  activeMoment.value = moment;
};

const activeMomentIndex = computed(() => props.moments.findIndex((moment) => moment.id === activeMoment.value?.id));

const initActiveMomentModalMode = () => {
  const initialMoment = props.moments.find((moment) => moment.id === props.initialMomentId);

  if (!initialMoment) {
    return;
  }

  selectedDropdownOption.value = props.initialPlaylist ? props.initialPlaylist : props.playlists[0];
  setActiveMoment(initialMoment);
};

const initActiveMoment = (moments: CommonMoment[]) => {
  const categoryValue = route.query[RouteQuery.Category];
  const category = props.playlists.find((option) => option.value === categoryValue);
  const routeMoment = moments.find((moment) => moment.id === route.params.id);

  if (category) {
    selectedDropdownOption.value = category;
  } else {
    selectedDropdownOption.value = props.playlists[0];
  }

  if (routeMoment && category?.value !== Category.MyCollection) {
    setActiveMoment(routeMoment);
  } else {
    setActiveMoment(getFirstElement(moments) as Moment);
  }
};

const initActiveMomentHandler = async () => {
  if (props.isModalMode) {
    initActiveMomentModalMode();
    await timeout(100);
    myChannelKinomsRef.value?.scrollToActiveElement();
  } else {
    initActiveMoment(props.moments);
  }
};

const isMobileScreenWidth = computed(() => width.value <= MOBILE_SCREEN_WIDTH);

const isMyCollectionEmpty = computed(
  () => props.moments.length === 0 && selectedDropdownOption.value?.value === Category.MyCollection,
);

const onDropdownOpenClick = () => {
  myChannelAnalytics.onClickMyChannelFeedSettings();
};

const onEndedMoment = async () => {
  if (!activeMoment.value) {
    return;
  }

  await changeMoment();

  kinomAnalytics.onAutoKinomNext({
    page: getKmzaPageValue(route.name as AppRoute),
    moment: activeMoment.value,
    position: activeMomentIndex.value,
  });
};

const onMomentListToggled = (isOpened: boolean) => {
  if (!player.value) {
    return;
  }

  if (isOpened) {
    player.value.pause();
  } else {
    player.value.play();
  }
};

const handleKinomReaction = (likeState: LikeState) => {
  if (!activeMoment.value) {
    return;
  }

  activeMoment.value.likeState = likeState;

  if (likeState === LikeState.Dislike) {
    window.setTimeout(
      () => changeMoment(),
      ConstantsConfigInstanceWeb.getProperty('changeMomentAfterDislikeTimeoutMs'),
    );
  }
};

const onCategorySelect = async (option: DropdownOption) => {
  isLoadedMomentsEmpty.value = false;
  selectedDropdownOption.value = option;

  myChannelAnalytics.onClickMyChannelFeedSelect({ feed: option.value });
  myChannelKinomsRef.value?.setCardsWrapperScrollToTop(0); // делаем скролл 0, чтобы корректно работал инфинити лоадинг

  if (!props.isModalMode) {
    await navigateTo(
      {
        query: { [RouteQuery.Category]: option.value },
      },
      { replace: true },
    );
  }

  emit('on-category-select', option);
};

const onPlay = () => {
  if (isMomentsListOpened.value && isMobileScreenWidth.value) {
    player.value?.pause();
  }

  shouldAmbientColorUpdate.value = true;
};

const onPause = () => {
  shouldAmbientColorUpdate.value = false;
};

const onAmbientGradientsUpdated = (event: VideoPlayerExternalEvent<IAmbientModeGradientUpdatedEvent>) => {
  hexs.value = event.data.hexs;
};

const onPlayerReady = (plr: VijuPlayer) => {
  plr.on('ambient-mode-gradient-updated', onAmbientGradientsUpdated);

  player.value = plr;
};

const screenRotate = () => {};

const onShareClick = (isPlaying: boolean) => {
  if (!player.value) {
    throw new UnexpectedComponentStateError('player');
  }
  if (isPlaying) {
    player.value.pause();
  } else {
    player.value.play();
  }
};

const onChangeMoment = (index?: number) => {
  if (!activeMoment.value) {
    return;
  }

  if (!player.value) {
    throw new UnexpectedComponentStateError('player');
  }

  changeMoment({ index });

  kinomAnalytics.onClickKinomNext({
    page: getKmzaPageValue(route.name as AppRoute),
    moment: activeMoment.value,
    position: index,
  });
};

const changeMoment = async (options: ChangeMomentOptions = {}) => {
  if (!props.moments?.length) {
    return;
  }

  const { index } = options;

  if (!player.value) {
    throw new UnexpectedComponentStateError('player');
  }

  player.value.endMediaSession();

  if (!isUndefined(index)) {
    setActiveMoment(props.moments[index]);

    await setActiveMomentParam(activeMoment.value?.id as string);

    myChannelKinomsRef.value?.scrollToActiveElement();

    return;
  }

  if (activeMomentIndex.value < props.moments.length - 1) {
    setActiveMoment(props.moments[activeMomentIndex.value + 1]);
    myChannelKinomsRef.value?.scrollToActiveElement();
  } else {
    setActiveMoment(getFirstElement(props.moments) as Moment);
    myChannelKinomsRef.value?.setCardsWrapperScrollToTop(0);
    player.value.play();
  }

  const isSingleMomentPlaylist = props.moments.length === 1;

  if (isSingleMomentPlaylist) {
    player.value.setConfigProperty('video.kinom.isPlayButtonHidden', false);
  }

  setActiveMoment(props.moments[activeMomentIndex.value]);
  await setActiveMomentParam(activeMoment.value?.id as string);
};

const onArrowUpPress = () => {
  if (!myChannelKinomsRef.value) {
    return;
  }

  myChannelKinomsRef.value.isScrolling = true;

  const prevEl = focusedOption.value?.previousElementSibling as HTMLElement;

  if (prevEl) {
    focusedOption.value = prevEl;
    prevEl.focus();
  }
};

const onArrowDownPress = () => {
  if (!myChannelKinomsRef.value) {
    return;
  }

  myChannelKinomsRef.value.isScrolling = true;

  if (!focusedOption.value) {
    focusedOption.value = myChannelKinomsRef.value?.getKinomSidebarEls()[0] as HTMLElement;
    focusedOption.value?.focus();
    return;
  }

  const nextEl = focusedOption.value.nextElementSibling as HTMLElement;

  if (nextEl) {
    focusedOption.value = nextEl;
    nextEl.focus();
  }
};

const onArrowLeft = () => {
  const newIndex = activeMomentIndex.value - 1;

  if (newIndex < 0) {
    return;
  }

  changeMoment({ index: newIndex });
};

const onArrowRight = () => {
  const newIndex = activeMomentIndex.value + 1;

  if (newIndex >= props.moments.length) {
    return;
  }

  changeMoment({ index: newIndex });
};

const onEnterPress = () => {
  if (!props.moments) {
    return;
  }

  const focusedOptionIndex = myChannelKinomsRef.value
    ?.getKinomSidebarEls()
    ?.findIndex((item) => item === focusedOption.value);

  onMomentCardClick(props.moments[focusedOptionIndex], focusedOptionIndex);
};

const onMomentCardClick = async (moment: CommonMoment, index: number) => {
  if (activeMoment.value?.id === moment.id || !selectedDropdownOption.value || !myChannelKinomsRef.value) {
    return;
  }

  if (!player.value) {
    throw new UnexpectedComponentStateError('player');
  }

  kinomAnalytics.onClickKinomSelect({
    page: getKmzaPageValue(route.name as AppRoute),
    moment: activeMoment.value as Moment,
    position: index,
  });

  player.value.endMediaSession();

  focusedOption.value = myChannelKinomsRef.value.getKinomSidebarEls()[index] as HTMLElement;

  setActiveMoment(moment);
  await setActiveMomentParam(moment.id);

  myChannelKinomsRef.value.isCardPressedToTop = false;

  emit('on-moment-card-click', moment, selectedDropdownOption.value.value);

  if (isMomentsListOpened.value) {
    isMomentsListOpened.value = false;
  }
};

const setInCollection = (state: boolean) => {
  if (!activeMoment.value) {
    return;
  }

  activeMoment.value.inUserCollection = state;
};

onClickOutside(kinomsRef, () => {
  isMomentsListOpened.value = false;
});

defineExpose({
  activeMoment: activeMoment.value,
  setActiveMoment,
  initActiveMoment,
  activeMomentIndex,
  selectedDropdownOption,
});

watch(
  () => props.moments,
  (value) => {
    if (!props.isModalMode && !activeMoment.value && value.length) {
      initActiveMoment(value);
    }
  },
);

watch(
  activeMomentIndex,
  (index) => {
    const nextMoment = props.moments[index + 1] as Moment;

    if (!nextMoment?.hls) {
      return;
    }

    player.value?.requestSaveMediaOffline(nextMoment.hls);
  },
  { immediate: true },
);

onMounted(async () => {
  await initActiveMomentHandler();

  if (props.isModalMode) {
    kinomAnalytics.onShowKinomPage({
      page: AnalyticPageName.Kinom,
      moment: activeMoment.value,
    });
  } else {
    myChannelAnalytics.onShowMyChannelPage();
  }

  disposableStore.add($keyboardHandler.on(KeyCode.ArrowLeft, onArrowLeft));
  disposableStore.add($keyboardHandler.on(KeyCode.ArrowRight, onArrowRight));

  wrapperRef.value?.focus();
});

onBeforeUnmount(() => {
  disposableStore.dispose();
  clearNuxtData('my-channel-id');
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.wrapper {
  --my-channel-desktop-kinoms-width: 400px;

  --aspect-ratio: calc(16 / 9);
  --my-channel-player-height: calc(100vw * 0.75 / var(--aspect-ratio));
  --my-channel-player-max-height: calc(100vh - 150px);
  --my-channel-player-height-mobile: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 85px);
  outline: none;
  overflow: hidden;
}

.wrapperMargins {
  margin: var(--g-spacing-40) var(--g-spacing-48) 0;
}

.wrapperPaddings {
  padding: var(--g-spacing-40) var(--g-spacing-48) 0;
}

.container {
  z-index: 1;

  -webkit-transform: translate3d(0, 0, 0);
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 400px;
  grid-template-rows: 1fr;
  justify-content: center;
  width: 100%;
  height: var(--my-channel-player-height);
  max-height: var(--my-channel-player-max-height);

  border-radius: var(--g-border-round-32);
  background-color: var(--color-notheme-bg-stream);
  overflow: hidden;
}

.playerPlaceholder {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--my-channel-player-height);
  max-height: var(--my-channel-player-max-height);
  background-color: var(--color-notheme-bg-stream);
}

.modalModeAmbient {
  z-index: var(--z-index-ambient);
}

@include breakpoints.max-width-1024 {
  .wrapper {
    margin: 0;
    padding: 0;
    height: var(--my-channel-player-height-mobile);
    max-height: 100%;
  }

  .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: var(--viewport-height);
    max-height: 100%;
    border-radius: 0;
  }

  .playerPlaceholder {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}
</style>
