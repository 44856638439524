<template>
  <app-sdk-input
    ref="inputRef"
    :mask="mask"
    :mask-options="maskOptions"
    :model-value="modelValue"
    :disabled="disabled"
    :autofocus="autofocus"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :spellcheck="spellcheck"
    :name="name"
    :type="type"
    :max-length="maxLength"
    :variation="variation"
    :size="size"
    :with-right-button="withRightButton"
    :button-text="buttonText"
    :has-button="hasButton"
    :error-message="errorMessage"
    :button-data-id="buttonDataId"
    :is-auto-complete="isAutoComplete"
    :has-clear-icon="hasClearIcon"
    :is-catalog-search="isCatalogSearch"
    @blur="onBlur"
    @click="onButtonClick"
    @clear="onClearClick"
    @update:model-value="onInput"
  >
    <template v-if="$slots.leadingIcon" #leadingIcon>
      <slot name="leadingIcon" />
    </template>
    <template v-if="$slots.trailingControl" #trailingControl>
      <slot name="trailingControl" />
    </template>
  </app-sdk-input>
</template>

<script lang="ts" setup>
import type { InputMask } from '@package/sdk/src/core';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import AppSdkInput from '@package/ui/src/components/AppInput.vue';

import { detectInputAutoFill } from '@/platform/base/dom';

type InputSize = 'small' | 'medium' | 'large';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    disabled?: boolean;
    mask?: string;
    maskOptions?: InputMask;
    autofocus?: boolean;
    placeholder?: string;
    autocomplete?: 'email' | 'new-password' | 'current-password' | 'off';
    spellcheck?: boolean;
    name?: 'login' | 'password' | 'promocode';
    type?: 'text' | 'password' | 'email';
    maxLength?: number;
    variation?: 'primary' | 'secondary';
    size?: InputSize;
    withRightButton?: boolean;
    buttonText?: string;
    hasButton?: boolean;
    hasClearIcon?: boolean;
    errorMessage?: string;
    buttonDataId?: string;
    isCatalogSearch?: boolean;
  }>(),
  {
    type: 'text',
    variation: 'primary',
    size: 'large',
    hasButton: false,
    hasClearIcon: false,
    isCatalogSearch: false,
  },
);

const inputRef = ref<
  ComponentPublicInstance<{
    inputEl: HTMLInputElement;
  }>
>();

const isAutoComplete = ref();

const inputEl = computed(() => inputRef.value?.$refs.inputEl);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'click'): void;
  (e: 'clear'): void;
  (e: 'blur'): void;
  (e: 'autocomplete-applied', value: boolean): void;
}>();

onMounted(async () => {
  if (!inputEl.value) {
    throw new UnexpectedComponentStateError('inputEl');
  }

  if (props.autofocus) {
    await nextTick();
    window.setTimeout(() => inputEl.value?.focus(), 250);
  }
});

const onBlur = () => {
  emit('blur');
};

const onButtonClick = () => {
  emit('click');
};

const onClearClick = () => {
  emit('clear');
};

const onInput = async (text: string) => {
  if (!inputEl.value) {
    return;
  }

  emit('update:modelValue', text);
  isAutoComplete.value = await detectInputAutoFill(inputEl.value);
  emit('autocomplete-applied', isAutoComplete.value);
};

defineExpose({ inputEl, isAutoComplete });
</script>
