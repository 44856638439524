import revive_payload_client_M5FSMC1heRLURUyQ9I_AWXIh5SrZCx7GAdkKjAbVGik from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dRVpuM7T3xC4Rf8ve1fRKW8R5axDub47Tgo_8vu_3NQ from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xcGJ38V_2ExuAsCl9SfWpzEFzs9zEpocl3W2_MKfdbI from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_g_vWAwPwYIOoaNzJS2dFW1TOOiif0oxRy2Lnol6bbbU from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_t6IQ25FIeHwSVWI_tYRjNL4gOvajyQAmtHZ_Pe_QBc8 from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_6vzyUohZ8oFfUfe2tvKRShdyJlbCGPLMdxoBKz1Lk5o from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_w7gj4JCmZTWe1iSZ7Pl7xgMOFTN__zhkipmdRr6EzXE from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_wRAQMrIYe8vFVkOCQuREb_B4IM7IXydpLR567b0gOH0 from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_@vue+composition-api@1.7.2_vue@3.5.13_typescript@5.8.2___magicast@0.3.5_ty_4gdnzknne3lomvladi4a44la2a/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/srv/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_cyGONF3IGHdTrAT6g8tWqsJD0qXvNFUES40M27nkYtc from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_5gYv_FMKUlfCEOWktQDVBZwLw9YX1aoWf_dUBPV2Uc4 from "/srv/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_QLysAI9tB97L2bzOlofE1CCM7z0mrrx5vv9zFYwD4YU from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.5_vite@6.2.3_@types+node@22.13.14_jiti@2.4.2_sass@1.86.0_te_fbeahcfvplrtqtjrro3x3yhzpu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_rWwIVimSHNetASlnU5k_FF27v_XzRMxqnlEPE298514 from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.5_vite@6.2.3_@types+node@22.13.14_jiti@2.4.2_sass@1.86.0_te_fbeahcfvplrtqtjrro3x3yhzpu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import global_variables_injector_fMTQQvpHjBljBaMR6tA77I6YzTxVsoThordBabomzVw from "/srv/app/apps/web/src/plugins/global-variables-injector.ts";
import i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og from "/srv/app/apps/web/src/plugins/i18n.ts";
import fetch_chunk_error_gsPWI5Euu7ml4rUERNgf_7MO_Pulb1ctWqSz3M77rYY from "/srv/app/apps/web/src/plugins/fetch-chunk-error.ts";
import http_client_KGptwfSZsDWzfVxLSLO3uWDs_qSG_UkmiXaaXMywdA0 from "/srv/app/apps/web/src/plugins/http-client.ts";
import sentry__dL__xYpF2Fkv524f9GL2snsEqbEoTBS8vypTeIHH0g from "/srv/app/apps/web/src/plugins/sentry.ts";
import o_auth_jNAfpOV0y5uCDDfhc7nRV2oiOxiaW3lziq3wg6zTTUM from "/srv/app/apps/web/src/plugins/o-auth.ts";
import broadcast_channel_ZNGU3FhUm17AFMG7WWVYJ_A_AiEMZ0YqlzSm6ak2NEg from "/srv/app/apps/web/src/plugins/broadcast-channel.ts";
import boot_tttSpXDz3bTtFj0Ho5N64C16RvR03__dKmr_crM9Or0 from "/srv/app/apps/web/src/plugins/boot.ts";
import analytics_M_uwSskwPh7vNwYj_BE2k7BH7YCyT5CMzCIIotCkN_4 from "/srv/app/apps/web/src/plugins/analytics.ts";
export default [
  revive_payload_client_M5FSMC1heRLURUyQ9I_AWXIh5SrZCx7GAdkKjAbVGik,
  unhead_dRVpuM7T3xC4Rf8ve1fRKW8R5axDub47Tgo_8vu_3NQ,
  router_xcGJ38V_2ExuAsCl9SfWpzEFzs9zEpocl3W2_MKfdbI,
  payload_client_g_vWAwPwYIOoaNzJS2dFW1TOOiif0oxRy2Lnol6bbbU,
  navigation_repaint_client_t6IQ25FIeHwSVWI_tYRjNL4gOvajyQAmtHZ_Pe_QBc8,
  check_outdated_build_client_6vzyUohZ8oFfUfe2tvKRShdyJlbCGPLMdxoBKz1Lk5o,
  chunk_reload_client_w7gj4JCmZTWe1iSZ7Pl7xgMOFTN__zhkipmdRr6EzXE,
  plugin_vue3_wRAQMrIYe8vFVkOCQuREb_B4IM7IXydpLR567b0gOH0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_cyGONF3IGHdTrAT6g8tWqsJD0qXvNFUES40M27nkYtc,
  nuxt_plugin_5gYv_FMKUlfCEOWktQDVBZwLw9YX1aoWf_dUBPV2Uc4,
  pwa_icons_QLysAI9tB97L2bzOlofE1CCM7z0mrrx5vv9zFYwD4YU,
  pwa_client_rWwIVimSHNetASlnU5k_FF27v_XzRMxqnlEPE298514,
  global_variables_injector_fMTQQvpHjBljBaMR6tA77I6YzTxVsoThordBabomzVw,
  i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og,
  fetch_chunk_error_gsPWI5Euu7ml4rUERNgf_7MO_Pulb1ctWqSz3M77rYY,
  http_client_KGptwfSZsDWzfVxLSLO3uWDs_qSG_UkmiXaaXMywdA0,
  sentry__dL__xYpF2Fkv524f9GL2snsEqbEoTBS8vypTeIHH0g,
  o_auth_jNAfpOV0y5uCDDfhc7nRV2oiOxiaW3lziq3wg6zTTUM,
  broadcast_channel_ZNGU3FhUm17AFMG7WWVYJ_A_AiEMZ0YqlzSm6ak2NEg,
  boot_tttSpXDz3bTtFj0Ho5N64C16RvR03__dKmr_crM9Or0,
  analytics_M_uwSskwPh7vNwYj_BE2k7BH7YCyT5CMzCIIotCkN_4
]