import type { Ref } from 'vue';

import type { AppRoute } from '@/platform/router/routes';

export type ParentLinkName = AppRoute.Movies | AppRoute.Serials | AppRoute.Account | AppRoute.TV;

export const useMouseInElementCustom = (
  el: Ref<HTMLElement | undefined>,
  outsideValidator?: (event: MouseEvent) => boolean,
) => {
  const isOutside = ref(true);

  const mouseEnterHandler = () => {
    isOutside.value = false;
  };

  const mouseLeaveHandler = (event: MouseEvent) => {
    if (outsideValidator) {
      isOutside.value = outsideValidator(event);
    } else {
      isOutside.value = true;
    }
  };

  onMounted(() => {
    if (el.value) {
      el.value.addEventListener('mouseenter', mouseEnterHandler, { passive: true });
      el.value.addEventListener('mouseleave', mouseLeaveHandler, { passive: true });
    }
  });

  onUnmounted(() => {
    if (el.value) {
      el.value.removeEventListener('mouseenter', mouseEnterHandler);
      el.value.removeEventListener('mouseleave', mouseLeaveHandler);
    }
  });

  return {
    isOutside,
  };
};
