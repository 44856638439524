<template>
  <svg width="152" height="48" viewBox="0 0 152 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="151" height="47" rx="9.5" stroke="currentColor" />
    <path
      d="M66.5243 36.9246H64.1375L60.3501 31.712H58.3578V36.9246H56.326V23.2123H61.218C62.1649 23.2123 63.0131 23.3939 63.7627 23.7569C64.5123 24.12 65.0909 24.6257 65.4986 25.2741C65.9194 25.9094 66.1298 26.642 66.1298 27.4719C66.1298 28.5093 65.8076 29.3975 65.1632 30.1365C64.5189 30.8627 63.6575 31.3424 62.5791 31.5759L66.5243 36.9246ZM58.3578 25.0601V29.8837H61.218C62.0596 29.8837 62.737 29.6632 63.2498 29.2224C63.7758 28.7686 64.0389 28.185 64.0389 27.4719C64.0389 26.7587 63.7758 26.1817 63.2498 25.7409C62.737 25.2871 62.0596 25.0601 61.218 25.0601H58.3578Z"
      fill="currentColor"
    />
    <path
      d="M71.9169 37.1192C71.1936 37.1192 70.5427 36.9441 69.964 36.594C69.3854 36.231 68.9317 35.7382 68.6029 35.1159C68.2741 34.4805 68.1098 33.7673 68.1098 32.9763V26.869H70.0429V32.8208C70.0429 33.5857 70.2599 34.2082 70.6938 34.6879C71.1279 35.1677 71.6868 35.4076 72.3706 35.4076C73.0807 35.4076 73.6725 35.1612 74.1459 34.6685C74.6193 34.1757 74.8561 33.5598 74.8561 32.8208V26.869H76.7893V36.9246H75.4084L74.9547 35.4076C74.626 35.9522 74.1919 36.3736 73.6527 36.6718C73.1267 36.97 72.5481 37.1192 71.9169 37.1192Z"
      fill="currentColor"
    />
    <path
      d="M83.9956 37.1191C82.7199 37.1191 81.6087 36.7949 80.6618 36.1466C79.7281 35.4853 79.0903 34.5906 78.7484 33.4625L80.7013 33.1513C81.0827 33.8774 81.5364 34.4156 82.0624 34.7656C82.5884 35.1028 83.2328 35.2714 83.9956 35.2714C84.7714 35.2714 85.4026 35.1028 85.8893 34.7656C86.3758 34.4156 86.6191 33.9423 86.6191 33.3458C86.6191 32.9049 86.481 32.5354 86.2049 32.2372C85.9418 31.9389 85.6065 31.699 85.1988 31.5175C84.7912 31.3359 84.2585 31.135 83.601 30.9146C82.72 30.6293 81.9966 30.344 81.4312 30.0588C80.8657 29.7734 80.3857 29.3715 79.9912 28.8529C79.6098 28.3212 79.4191 27.647 79.4191 26.8301C79.4191 26.065 79.6098 25.3972 79.9912 24.8267C80.3857 24.2432 80.9249 23.7959 81.6087 23.4846C82.2926 23.1734 83.0553 23.0179 83.8969 23.0179C85.0673 23.0179 86.0733 23.3226 86.915 23.932C87.7566 24.5285 88.355 25.3518 88.7101 26.4022L86.7967 26.7133C86.5336 26.1169 86.1589 25.663 85.6722 25.3518C85.1857 25.0277 84.6137 24.8656 83.9561 24.8656C83.246 24.8656 82.6608 25.0341 82.2005 25.3713C81.7402 25.6955 81.5101 26.1428 81.5101 26.7133C81.5101 27.1412 81.6416 27.5043 81.9046 27.8025C82.1808 28.1008 82.5161 28.3406 82.9106 28.5222C83.3183 28.7037 83.8575 28.9112 84.5281 29.1446C85.3829 29.4298 86.0931 29.7216 86.6585 30.0198C87.2372 30.3051 87.7237 30.7136 88.1183 31.2452C88.5128 31.7769 88.7101 32.4446 88.7101 33.2485C88.7101 34.0266 88.4997 34.7073 88.0788 35.2907C87.6712 35.8743 87.1057 36.3281 86.3824 36.6522C85.6722 36.9635 84.8766 37.1191 83.9956 37.1191Z"
      fill="currentColor"
    />
    <path
      d="M96.8745 35.252L96.6973 36.9247H94.9021C93.8364 36.9247 93.0283 36.6654 92.4758 36.1467C91.9233 35.628 91.6467 34.8824 91.6467 33.91V28.5612H89.8915V26.869H91.6467L92.1799 23.9516H93.5798V26.869H96.6973V28.5612H93.5798V33.91C93.5798 34.8047 94.0206 35.252 94.9021 35.252H96.8745Z"
      fill="currentColor"
    />
    <path
      d="M103.322 37.1189C102.283 37.1189 101.356 36.8985 100.541 36.4576C99.7258 36.0167 99.0878 35.4008 98.627 34.6098C98.167 33.8189 97.9366 32.9177 97.9366 31.9063C97.9366 30.895 98.167 29.9937 98.627 29.2028C99.0878 28.4118 99.7258 27.7959 100.541 27.3551C101.356 26.9012 102.283 26.6743 103.322 26.6743C104.361 26.6743 105.288 26.9012 106.103 27.3551C106.919 27.7959 107.556 28.4118 108.017 29.2028C108.477 29.9937 108.707 30.895 108.707 31.9063C108.707 32.9177 108.477 33.8189 108.017 34.6098C107.556 35.4008 106.919 36.0167 106.103 36.4576C105.288 36.8985 104.361 37.1189 103.322 37.1189ZM103.322 35.3684C104.322 35.3684 105.13 35.0508 105.749 34.4154C106.38 33.78 106.696 32.9437 106.696 31.9063C106.696 30.869 106.38 30.0327 105.749 29.3973C105.13 28.7489 104.322 28.4248 103.322 28.4248C102.322 28.4248 101.514 28.7489 100.896 29.3973C100.277 30.0327 99.9684 30.869 99.9684 31.9063C99.9684 32.9307 100.277 33.767 100.896 34.4154C101.514 35.0508 102.322 35.3684 103.322 35.3684Z"
      fill="currentColor"
    />
    <path
      d="M110.781 36.9246V26.869H112.182L112.597 28.3084C112.899 27.8026 113.274 27.4395 113.721 27.2192C114.167 26.9857 114.701 26.869 115.319 26.869H115.792L115.95 28.6973H114.845C114.135 28.6973 113.602 28.8983 113.248 29.3002C112.892 29.7022 112.714 30.2468 112.714 30.934V36.9246H110.781Z"
      fill="currentColor"
    />
    <path
      d="M127 31.8285C127 32.0878 126.994 32.2888 126.981 32.4314H118.557C118.663 33.3262 119.018 34.0393 119.623 34.571C120.228 35.1026 120.997 35.3684 121.93 35.3684C122.575 35.3684 123.147 35.2452 123.647 34.9989C124.159 34.7395 124.548 34.4024 124.811 33.9875L126.626 34.2792C126.231 35.1869 125.593 35.8871 124.712 36.3798C123.844 36.8725 122.877 37.1189 121.813 37.1189C120.8 37.1189 119.893 36.8985 119.091 36.4576C118.288 36.0038 117.664 35.3814 117.217 34.5905C116.769 33.7995 116.546 32.9047 116.546 31.9063C116.546 30.895 116.769 29.9937 117.217 29.2028C117.664 28.4118 118.281 27.7959 119.07 27.3551C119.859 26.9012 120.76 26.6743 121.773 26.6743C122.773 26.6743 123.666 26.9012 124.455 27.3551C125.258 27.7959 125.882 28.4118 126.33 29.2028C126.777 29.9807 127 30.856 127 31.8285ZM121.773 28.3664C120.997 28.3664 120.326 28.5933 119.761 29.0472C119.208 29.501 118.84 30.1104 118.656 30.8755H124.89C124.719 30.1234 124.351 29.5204 123.785 29.0666C123.233 28.5999 122.562 28.3664 121.773 28.3664Z"
      fill="currentColor"
    />
    <path
      d="M57.9247 16.4118C58.8128 15.0737 58.8957 13.0724 58.8957 12.1842V11.8289H60.9799V16.4118H57.9247ZM56.326 16.4118V18.5197H57.0957V17.0987H61.8563V18.5197H62.626V16.4118H61.7852V11.1421H58.1023V12.1132C58.1023 12.9184 58.0668 15.0974 57.0602 16.4118H56.326Z"
      fill="currentColor"
    />
    <path
      d="M63.4597 14.0908C63.4597 15.8908 64.8571 17.2408 66.5979 17.2408C68.3387 17.2408 69.7479 15.8908 69.7479 14.0908C69.7479 12.3263 68.3387 11 66.5979 11C64.8571 11 63.4597 12.3263 63.4597 14.0908ZM64.2887 14.0908C64.2887 12.7408 65.319 11.7224 66.5979 11.7224C67.8769 11.7224 68.919 12.7408 68.919 14.0908C68.919 15.4882 67.8769 16.5066 66.5979 16.5066C65.319 16.5066 64.2887 15.4882 64.2887 14.0908Z"
      fill="currentColor"
    />
    <path
      d="M70.8495 14.1263C70.8495 15.9737 72.2113 17.2408 73.9758 17.2408C74.8877 17.2408 75.7758 16.8737 76.2969 16.2934L75.9298 15.6895C75.4561 16.2105 74.71 16.5066 74.0232 16.5066C72.6969 16.5066 71.6784 15.5237 71.6784 14.1263C71.6784 12.7053 72.6969 11.7224 73.9995 11.7224C74.6627 11.7224 75.3258 12.0303 75.7403 12.4566L76.1548 11.8526C75.6219 11.2724 74.7574 11 73.9758 11C72.2232 11 70.8495 12.2908 70.8495 14.1263Z"
      fill="currentColor"
    />
    <path d="M79.1507 17.0987H79.956V11.8526H82.1349V11.1421H76.9836V11.8526H79.1507V17.0987Z" fill="currentColor" />
    <path
      d="M82.9455 18.5908L82.6376 19.2303C82.6376 19.2303 83.0757 19.5855 83.6915 19.5855C84.4849 19.5855 85.0889 19.1 85.3968 18.3421L88.3336 11.1421H87.4692L85.752 15.4882C85.6336 15.7842 85.5507 16.1158 85.5507 16.1158H85.527C85.527 16.1158 85.4205 15.7842 85.302 15.4882L83.5613 11.1421H82.6731L85.1481 17.0395L84.7928 17.8921C84.556 18.4368 84.177 18.8632 83.656 18.8632C83.277 18.8632 82.9455 18.5908 82.9455 18.5908Z"
      fill="currentColor"
    />
    <path d="M89.6051 17.0987H90.4103V11.8526H93.7853V17.0987H94.5906V11.1421H89.6051V17.0987Z" fill="currentColor" />
    <path
      d="M96.5091 17.0987H97.3144V14.3987H100.796V17.0987H101.601V11.1421H100.796V13.6882H97.3144V11.1421H96.5091V17.0987Z"
      fill="currentColor"
    />
    <path
      d="M103.115 14.0908C103.115 15.8908 104.512 17.2408 106.253 17.2408C107.994 17.2408 109.403 15.8908 109.403 14.0908C109.403 12.3263 107.994 11 106.253 11C104.512 11 103.115 12.3263 103.115 14.0908ZM103.944 14.0908C103.944 12.7408 104.974 11.7224 106.253 11.7224C107.532 11.7224 108.574 12.7408 108.574 14.0908C108.574 15.4882 107.532 16.5066 106.253 16.5066C104.974 16.5066 103.944 15.4882 103.944 14.0908Z"
      fill="currentColor"
    />
    <path
      d="M113.868 17.0987H116.141C117.479 17.0987 118.296 16.4592 118.296 15.4289C118.296 14.7303 117.858 14.15 117.089 13.9961V13.9724C117.669 13.7592 117.989 13.2737 117.989 12.6579C117.989 11.675 117.231 11.1421 116.023 11.1421H113.868V17.0987ZM114.673 13.7237V11.7579H115.893C116.733 11.7579 117.16 12.0658 117.16 12.7053C117.16 13.3447 116.745 13.7237 115.94 13.7237H114.673ZM114.673 16.4711V14.3395H116.023C116.946 14.3395 117.468 14.7184 117.468 15.4171C117.468 16.0566 117.018 16.4711 116.035 16.4711H114.673Z"
      fill="currentColor"
    />
    <path
      d="M42.8238 29.4909L40.384 28.8768C40.0902 28.792 39.8827 28.5257 39.8723 28.2161L39.5678 19.1692C39.4797 17.9717 38.5974 17.0194 37.6755 16.7389C37.6237 16.7232 37.5684 16.7444 37.5378 16.7893C37.5067 16.8348 37.5187 16.8979 37.5621 16.9319C37.7898 17.1094 38.4164 17.6888 38.4164 18.6883L38.4146 30.5169C38.4146 31.6591 37.3433 32.4968 36.2384 32.2186L33.7608 31.5951C33.4862 31.4983 33.2953 31.2405 33.2853 30.9433L32.9809 21.8957C32.8928 20.6982 32.0105 19.7459 31.0886 19.4655C31.0368 19.4497 30.9815 19.471 30.9509 19.5158C30.9198 19.5614 30.9318 19.6245 30.9752 19.6584C31.2029 19.8359 31.8295 20.4154 31.8295 21.4149L31.8277 31.2746L31.8291 31.2749V33.2446C31.8291 34.3868 30.7577 35.2245 29.6528 34.9463L22.655 33.1852C21.6821 32.9403 21 32.0636 21 31.058V19.2109C21 18.0687 22.0713 17.231 23.1762 17.5092L27.5856 18.6188V16.4833C27.5856 15.341 28.6569 14.5034 29.7618 14.7814L34.1709 15.8911V13.7555C34.1709 12.6133 35.2422 11.7756 36.3472 12.0537L43.345 13.8149C44.3179 14.0596 45 14.9365 45 15.942V27.7891C45 28.9314 43.9287 29.769 42.8238 29.4909Z"
      fill="currentColor"
    />
  </svg>
</template>
