import { toPixel } from '@package/sdk/src/core';
import { useWindowSize } from '@vueuse/core';

import { calculateHeightAspectRatio } from '@/platform/base/number';
import useMobile from '@/platform/layout/use-mobile';
import useScreenOrientation from '@/platform/layout/use-screen-orientation';

const DEFAULT_SITE_PADDING = 42; // 16 * 2;

export default function useWindowWidthPlayerRatio() {
  const isMobile = useMobile();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { orientation } = useScreenOrientation();

  const height = computed(() => {
    if (!isMobile) {
      return 'initial';
    }

    if (orientation.value === 'horizontal') {
      return toPixel(calculateHeightAspectRatio(windowHeight.value + DEFAULT_SITE_PADDING * 2, 16, 9));
    }

    return toPixel(calculateHeightAspectRatio(windowWidth.value - DEFAULT_SITE_PADDING, 16, 9));
  });

  return {
    playerHeight: height,
  };
}
