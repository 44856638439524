<template>
  <section :class="$style.notificationContainer">
    <div :class="$style.wrapper">
      <icon-checkmark size="small" :class="$style.check" />
      <p :class="$style.text" v-html="$t('tvCodePage.newDeviceConnectedTimer', { countDown })" />
      <app-slot-button :class="$style.iconClose" @click="onCloseButtonClick()">
        <icon-close size="small" />
      </app-slot-button>
    </div>
  </section>
</template>

<script setup lang="ts">
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import IconClose from '@/components/icons/IconClose.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import { useTvCodeConnectionStore } from '@/stores/use-tv-code-connection-store';

const { countDown, isTvConnectNotificationShown } = storeToRefs(useTvCodeConnectionStore());

const onCloseButtonClick = () => {
  isTvConnectNotificationShown.value = false;
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.notificationContainer {
  position: fixed;
  top: var(--app-header-height);
  right: 80px;
  z-index: var(--z-index-tooltip);
  display: block;
  max-width: 450px;
  overflow: hidden;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--g-spacing-12) var(--g-spacing-16);
  border-radius: var(--g-border-round-10);
  background-color: var(--color-bg-secondary);
}

.check {
  align-self: flex-start;
  margin-top: var(--g-spacing-2);
  margin-right: var(--g-spacing-12);
  color: var(--color-icon-accent);
}

.text {
  color: var(--color-text-primary);

  @include fonts.WebBody-3;
}

.iconClose {
  margin-left: var(--g-spacing-28);
  color: var(--color-text-primary);
}

@include breakpoints.max-width-800 {
  .notificationContainer {
    top: var(--app-header-height--mobile);
    left: var(--g-spacing-16);
    right: var(--g-spacing-16);
  }

  .text br {
    display: none;
  }
}
</style>
