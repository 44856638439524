<template>
  <div
    ref="sliderRef"
    :class="{
      [$style.slider]: true,
    }"
    tabindex="0"
    @keydown.left="onKeydownLeft"
    @keydown.right="onKeydownRight"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
    @mouseover="onMouseOver"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mouseleave="onMouseLeave"
    @mousemove="onMouseMove"
    @click.capture="onClick"
    @wheel="onWheel"
    @dragstart.prevent
    @dragend.prevent
    @drag.prevent
    @select.prevent
  >
    <app-slot-button
      v-show="isPrevSlideAvailable"
      :aria-label="$t('slider.buttons.arrowLeft')"
      tabindex="0"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Common, 'navPrevSlide')"
      :class="{
        [$style.navigationButton]: true,
        [$style.buttonPrev]: true,
        [$style.buttonMobile]: isMobile,
      }"
      @click="switchPrevSlide()"
    >
      <span :class="$style.buttonIcon">
        <icon-chevron size="medium" direction="left" />
      </span>
    </app-slot-button>

    <div
      ref="slidesRef"
      :class="{
        [$style.slides]: true,
        [$style.transition]: !isTouched,
      }"
      :style="translateStyles"
    >
      <app-slider-slide
        v-for="(item, index) in items"
        :key="item.id"
        :space-between="toPixel(spaceBetweenInPx)"
        :is-scaled="isScaled"
        :data-active-slide="currentSlideIndex === index ? 'active' : undefined"
      >
        <slot :key="item.id" :item="item" :index="index" />
      </app-slider-slide>
    </div>

    <app-slot-button
      v-show="isNextSlideAvailable"
      :aria-label="$t('slider.buttons.arrowLeft')"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Common, 'navNextSlide')"
      :class="{
        [$style.navigationButton]: true,
        [$style.buttonNext]: true,
        [$style.buttonMobile]: isMobile,
      }"
      @click="switchNextSlide()"
    >
      <span :class="$style.buttonIcon">
        <icon-chevron size="medium" direction="right" />
      </span>
    </app-slot-button>
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { debounce, toPixel } from '@package/sdk/src/core';
import { useResizeObserver, useWindowSize } from '@vueuse/core';

import AppSliderSlide from '@/components/app-slider/AppSliderSlide.vue';
import IconChevron from '@/components/icons/IconChevron.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import { getFullscreenElement } from '@/platform/base/dom';
import useMobile from '@/platform/layout/use-mobile';

type DeviceSize =
  | 'mobile'
  | 'smallTablet'
  | 'mediumTablet'
  | 'bigTablet'
  | 'smallDesktop'
  | 'mediumDesktop'
  | 'bigDesktop'
  | 'largeDesktop';

export type SliderBreakpoints = Record<DeviceSize, number>;

type SliderItem = {
  id: string;
};

interface Props {
  items: SliderItem[];
  breakpoints: SliderBreakpoints;
  isScaled?: boolean;
  withKeyboardNavigation?: boolean;
  loop?: boolean;
  loopSlideDuration?: number;
  autoplay?: boolean;
  scrollOnWheel?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isScaled: true,
  withKeyboardNavigation: true,
  loop: false,
  loopSlideDuration: 5000,
  items: () => [],
  breakpoints: () => ({
    mobile: 1,
    smallTablet: 2,
    mediumTablet: 2,
    bigTablet: 3,
    smallDesktop: 4,
    mediumDesktop: 4,
    bigDesktop: 5,
    largeDesktop: 5,
  }),
  autoplay: false,
  scrollOnWheel: true,
});

const breakpointsMap: Record<string, DeviceSize> = {
  2561: 'largeDesktop',
  1921: 'bigDesktop',
  1441: 'mediumDesktop',
  1281: 'smallDesktop',
  1025: 'bigTablet',
  801: 'mediumTablet',
  541: 'smallTablet',
  0: 'mobile',
};

const emit = defineEmits<{
  (e: 'on-slide-right', slideIndex: number): void;
  (e: 'on-slide-left', slideIndex: number): void;
  (e: 'on-slide-change', slideIndex: number): void;
}>();

let loopSlidesIntervalId = 0;

const MIN_PIXELS_DIFF_TO_SWIPE = 50;

const { width } = useWindowSize();
const isMobile = useMobile();

const isSliding = ref(false);
const isVerticalScrollEnabled = ref(false);

const touchPosition = reactive({
  startX: 0,
  startY: 0,
  endX: 0,
  endY: 0,
});

const sliderRef = ref<HTMLDivElement>();
const slidesRef = ref<HTMLDivElement>();

const isMouseHovered = ref(false);
const isTouched = ref(false);

const mobileWidth = 800;

const spaceBetweenInPx = computed(() => (width.value > mobileWidth ? 8 : 4));

const translateX = ref(0);
const scrollLeft = ref(0);

const currentSlideIndex = ref(0);
const slideWidthInPx = ref(0);

const lastSlidesContainerWidth = ref(0);

const toPercent = (value: number) => `${100 / value}%`;

// поидеи передавать все брейкпоинты не обязательно, если одной из получившихся значений будет сломаное,
// то будут применяться стили предыдущего брейкпоинта
// но лучше передавать, чтобы избежать каких-нибудь не очевидных неприятностей
const mobileSlideWidth = toPercent(props.breakpoints.mobile);
const smallTabletSlideWidth = toPercent(props.breakpoints.smallTablet);
const mediumTabletSlideWidth = toPercent(props.breakpoints.mediumTablet);
const bigTabletSlideWidth = toPercent(props.breakpoints.bigTablet);
const smallDesktopSlideWidth = toPercent(props.breakpoints.smallDesktop);
const mediumDesktopSlideWidth = toPercent(props.breakpoints.mediumDesktop);
const bigDesktopSlideWidth = toPercent(props.breakpoints.bigDesktop);
const largeDesktopSlideWidth = toPercent(props.breakpoints.largeDesktop);

const itemsLength = computed(() => props.items.length);

const isItemsLengthGreaterThanOne = computed(() => itemsLength.value > 1);

const isPrevSlideAvailable = computed(
  () => (props.loop || currentSlideIndex.value > 0) && isItemsLengthGreaterThanOne.value,
);
const isNextSlideAvailable = computed(() => {
  const isNextPageAvailable = currentSlideIndex.value < props.items.length - slidesPerView.value;

  return (props.loop || isNextPageAvailable) && isItemsLengthGreaterThanOne.value;
});

const translateStyles = computed(() => ({ transform: `translateX(-${translateX.value}px)` }));

const slidesPerView = computed(() => {
  const mapValues: string[] = Object.keys(breakpointsMap);

  for (let i = mapValues.length - 1; i > 0; i--) {
    if (width.value >= Number(mapValues[i])) {
      return props.breakpoints[breakpointsMap[mapValues[i]]];
    }
  }

  return props.breakpoints.mobile;
});

// умножаем spaceBetween на два т.к отступ с двух сторон
const slideWidthWithMargins = computed(() => slideWidthInPx.value + 2 * spaceBetweenInPx.value);

const slidesPageWidth = computed(() => slidesPerView.value * slideWidthWithMargins.value);

const isLastSlide = computed(() => currentSlideIndex.value === props.items.length - slidesPerView.value);

// промо слайдер - 1 слайд на экране и значение пропса loop = true
const isSingleSlideViewWithLoop = computed(() => props.loop && slidesPerView.value === 1);

const debouncedSetIsTouchToFalse = debounce(() => {
  isTouched.value = false;
}, 200);

const getFirstSlideClientWidth = () => {
  if (!slidesRef.value) {
    return 0;
  }

  return slidesRef.value.children[0]?.clientWidth;
};

const setCurrentSlideIndexToDefault = () => {
  currentSlideIndex.value = 0;
};

const getDiff = (firstValue: number, secondValue: number) => Math.abs(firstValue - secondValue);

const setTranslateOnScroll = (pageX: number) => {
  if (!slidesRef.value) {
    return;
  }

  const isScrollPossible = translateX.value < slidesRef.value?.scrollWidth - slidesPageWidth.value;
  const isScrollLeft = touchPosition.startX < pageX;

  if (isTouched.value && (isScrollPossible || isScrollLeft)) {
    const x = pageX - slidesRef.value.offsetLeft;
    const walk = x - touchPosition.startX;

    if (scrollLeft.value - walk >= 0) {
      translateX.value = scrollLeft.value - walk;
    }
  }
};

const onClick = (e: MouseEvent) => {
  const hasActiveFullScreenElement = Boolean(getFullscreenElement());

  // В фуллскрине не обрабатываем клики
  if (hasActiveFullScreenElement) {
    return;
  }

  if (e.pageX !== touchPosition.startX) {
    e.preventDefault();
    e.stopPropagation();
  }
};

const onWheel = (e: WheelEvent) => {
  if (!props.scrollOnWheel) {
    return;
  }

  if (!slidesRef.value) {
    return;
  }

  debouncedSetIsTouchToFalse();

  scrollLeft.value = translateX.value;

  // если скроллим вниз и если доступна всего одна страница (или меньше) слайдов
  if (Math.abs(e.deltaX) < Math.abs(e.deltaY) || itemsLength.value <= slidesPerView.value) {
    return;
  }

  // превентим свайп влево/вправо, чтобы не было перехода не предыдущюю/следующюю страницу
  e.preventDefault();

  isTouched.value = true;

  const nextTranslateXValue = translateX.value + e.deltaX;
  const maxTranslateX = (itemsLength.value - slidesPerView.value) * slideWidthWithMargins.value;
  const isMaxAvailableTranslate = nextTranslateXValue >= maxTranslateX;

  // если находимся на последней странице
  if (isMaxAvailableTranslate) {
    isTouched.value = false;
    calculateTranslateX();

    return;
  }

  // если находимся на первом слайде
  if (nextTranslateXValue <= 0) {
    isTouched.value = false;
    currentSlideIndex.value = 0;
    calculateTranslateX();

    return;
  }

  translateX.value = translateX.value + e.deltaX;
  calculateCurrentSlideIndex();
};

const onMouseDown = (e: MouseEvent) => {
  if (!slidesRef.value) {
    return;
  }

  // сетаем здесь touchPosition.startX, чтобы в обработчике onClick корректно обработать клики если isSingleSlideViewWithLoop === true
  // т.к в таком слайдере выключен скролл через указатель мыши
  if (isSingleSlideViewWithLoop.value) {
    touchPosition.startX = e.pageX - slidesRef.value.offsetLeft;

    return;
  }

  isTouched.value = true;
  touchPosition.startX = e.pageX - slidesRef.value.offsetLeft;
  touchPosition.startY = e.pageY;
  scrollLeft.value = translateX.value;
};

const onMouseUp = (e: MouseEvent) => {
  if (!slidesRef.value || isSingleSlideViewWithLoop.value) {
    return;
  }

  touchPosition.endY = e.pageY;
  touchPosition.endX = e.pageX;

  const startEndXDiff = getDiff(touchPosition.startX, touchPosition.endX);

  const isDiffEnoughForSwipe = startEndXDiff > MIN_PIXELS_DIFF_TO_SWIPE; // можем регулировать чувствительность переключения слайдера

  if (!isDiffEnoughForSwipe) {
    isTouched.value = false;
    calculateTranslateX();

    return;
  }

  if (slidesPerView.value === 1) {
    changeSlide();
  }

  isTouched.value = false;

  if (touchPosition.startX > touchPosition.endX) {
    emit('on-slide-left', currentSlideIndex.value);
  }
};

const onMouseMove = (e: MouseEvent) => {
  // сетаем translateX только если зажата левая кнопка мыши
  if (e.buttons === 1) {
    setTranslateOnScroll(e.pageX);
  }
};

const onMouseOver = () => {
  isMouseHovered.value = true;

  disableSlidesLoopInterval();
};

const onMouseLeave = () => {
  isMouseHovered.value = false;
  isTouched.value = false;

  activateSlidesLoopInterval();
};

const onTouchStart = (e: TouchEvent) => {
  if (!slidesRef.value) {
    return;
  }

  touchPosition.startY = e.touches[0].clientY;
  touchPosition.startX = e.touches[0].clientX - slidesRef.value.offsetLeft;
  scrollLeft.value = translateX.value;

  isTouched.value = true;
};

const onTouchMove = (e: TouchEvent) => {
  const diffX = getDiff(touchPosition.startX, e.touches[0].clientX);
  const diffY = getDiff(touchPosition.startY, e.touches[0].clientY);

  if (!isSliding.value) {
    isVerticalScrollEnabled.value = diffX > diffY; // считаем один раз isVerticalScroll, чтобы избежать пограничных случаев
    isSliding.value = true;
  }

  if (isVerticalScrollEnabled.value) {
    e.preventDefault();
    e.stopPropagation();
    setTranslateOnScroll(e.touches[0].clientX);
  }
};

const onTouchEnd = (e: TouchEvent) => {
  // Проверяем, что юзер не просто тапнул на слайд 2 раза
  if (!isSliding.value) {
    return;
  }

  touchPosition.endX = e.changedTouches[0].clientX;
  touchPosition.endY = e.changedTouches[0].clientY;

  isTouched.value = false;
  isSliding.value = false;
  isVerticalScrollEnabled.value = false;
  changeSlide();
};

const calculateTranslateX = () => {
  const slidesLeft = Math.abs(props.items.length - currentSlideIndex.value);

  if (slidesLeft === 0) {
    translateX.value = 0;
    currentSlideIndex.value = 0;

    return;
  }

  if (slidesLeft < slidesPerView.value && currentSlideIndex.value !== 0) {
    translateX.value = currentSlideIndex.value + slideWidthWithMargins.value * slidesLeft;

    return;
  }

  translateX.value = currentSlideIndex.value * slideWidthWithMargins.value;
};

const activateSlidesLoopInterval = () => {
  if (props.loop && !props.autoplay) {
    loopSlidesIntervalId = window.setInterval(switchNextSlide, props.loopSlideDuration, true);
  }
};

const disableSlidesLoopInterval = () => {
  if (props.loop && loopSlidesIntervalId) {
    window.clearInterval(loopSlidesIntervalId);
  }
};

const updateSlidesLoopIntervalTime = () => {
  if (loopSlidesIntervalId && !isMouseHovered.value) {
    window.clearInterval(loopSlidesIntervalId);
    loopSlidesIntervalId = window.setInterval(switchNextSlide, props.loopSlideDuration, true);
  }
};
const onSlideChange = () => {
  emit('on-slide-change', currentSlideIndex.value);
};

const changeSlide = () => {
  const diffX = getDiff(touchPosition.startX, touchPosition.endX);
  const diffY = getDiff(touchPosition.startY, touchPosition.endY);

  if (diffY > diffX) {
    calculateTranslateX(); // пересчитывается translate при скролле слайдера сверху вниз

    return;
  }

  if (touchPosition.startX > touchPosition.endX) {
    switchNextSlide();
  }
  if (touchPosition.startX < touchPosition.endX) {
    switchPrevSlide();
  }
};

const switchPrevSlide = () => {
  if (!isPrevSlideAvailable.value) {
    return;
  }

  if (currentSlideIndex.value === 0 && isSingleSlideViewWithLoop.value) {
    // если первый слайд в промо слайдере
    currentSlideIndex.value = itemsLength.value - slidesPerView.value;
    isTouched.value = true;
    translateX.value = slideWidthWithMargins.value * (itemsLength.value - 1);

    window.setTimeout(() => {
      isTouched.value = false;
    }, ConstantsConfigInstanceWeb.getProperty('afterSwipeMobileTimeoutMs'));
  } else if (currentSlideIndex.value > 0 && currentSlideIndex.value >= slidesPerView.value) {
    // если можно пролистать полную страницу назад
    currentSlideIndex.value = currentSlideIndex.value - slidesPerView.value;
  } else if (currentSlideIndex.value === 0) {
    currentSlideIndex.value = itemsLength.value - slidesPerView.value;
  } else if (currentSlideIndex.value < slidesPerView.value) {
    // если не можем пролистать полную страницу назад
    currentSlideIndex.value = 0;
  }

  updateSlidesLoopIntervalTime();
  emit('on-slide-right', currentSlideIndex.value);
  onSlideChange();
};

const switchNextSlide = (isAutoSlide = false) => {
  if (!isNextSlideAvailable.value) {
    return;
  }

  if (isLastSlide.value && isSingleSlideViewWithLoop.value) {
    // если последний слайд в промо слайдере
    currentSlideIndex.value = 0;
    isTouched.value = true;
    translateX.value = 0;

    window.setTimeout(() => {
      isTouched.value = false;
    }, ConstantsConfigInstanceWeb.getProperty('afterSwipeMobileTimeoutMs'));
  } else if (isLastSlide.value) {
    // когда последний слайд и нужно уйти на первый
    currentSlideIndex.value = 0;
  } else if (currentSlideIndex.value + slidesPerView.value > props.items.length - slidesPerView.value) {
    // если следующая страница будет не полная, перелистываем сколько осталось
    currentSlideIndex.value = props.items.length - slidesPerView.value;
  } else {
    // листаем по странице
    currentSlideIndex.value = currentSlideIndex.value + slidesPerView.value;
  }

  updateSlidesLoopIntervalTime();

  if (!isAutoSlide) {
    // не нужно отправлять ивент, если слайд сменился автоматически
    emit('on-slide-left', currentSlideIndex.value);
  }

  onSlideChange();
};

const onKeydownLeft = () => {
  if (props.withKeyboardNavigation) {
    switchPrevSlide();
  }
};

const onKeydownRight = () => {
  if (props.withKeyboardNavigation) {
    switchNextSlide();
  }
};

const calculateCurrentSlideIndex = () => {
  // @ts-expect-error
  slideWidthInPx.value = getFirstSlideClientWidth();
  currentSlideIndex.value = Math.round(translateX.value / Number(slideWidthWithMargins.value));
};

const { stop: stopResizeObserver } = useResizeObserver(slidesRef, (entries) => {
  const entry = entries[0];
  const { width } = entry.contentRect;

  // @ts-expect-error
  slideWidthInPx.value = getFirstSlideClientWidth();

  if (lastSlidesContainerWidth.value !== width) {
    currentSlideIndex.value = 0;
  }

  lastSlidesContainerWidth.value = width;
});

onMounted(() => {
  calculateCurrentSlideIndex();
  activateSlidesLoopInterval();
});

onBeforeUnmount(() => {
  disableSlidesLoopInterval();

  stopResizeObserver();
});

watch(slidesPerView, () => {
  currentSlideIndex.value = 0;
});

watch(currentSlideIndex, () => {
  if (!isTouched.value) {
    calculateTranslateX();
  }
});

watch(isTouched, () => {
  if (slidesPerView.value > 1) {
    calculateCurrentSlideIndex();
    translateX.value = currentSlideIndex.value * slideWidthWithMargins.value;
  }
});

defineExpose({
  currentSlide: currentSlideIndex.value,
  nextSlide: switchNextSlide,
  setCurrentSlideIndexToDefault,
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.slider {
  position: relative;
  width: 100%;
  outline: none;
}

.mobile.slider {
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.slides {
  position: relative;
  display: grid;
  box-sizing: border-box;
}

.slides.transition {
  transition: transform 0.4s ease;
}

.iconRotated {
  transform: rotate(180deg);
}

.navigationButton {
  z-index: 1;
}

.buttonPrev,
.buttonNext {
  height: 80%;

  &:hover {
    .buttonIcon {
      background-color: var(--color-state-bg-button-hover);
    }
  }
}

.buttonMobile {
  display: none;
}

.buttonPrev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 0.2s ease;
}

.buttonNext {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-button-ghost-80);
  transition: all 0.2s ease;

  &:hover {
    :global {
      animation: headShake 3s;
      animation-delay: 1.5s;
      animation-iteration-count: 1;
    }
  }
}

.iconPrev {
  margin-right: var(--g-spacing-4);
}

.iconNext {
  margin-left: var(--g-spacing-4);
}

// 'mobile' | 'smallTablet' | 'mediumTablet' | 'bigTablet' | 'smallDesktop' | 'mediumDesktop' | 'bigDesktop' | 'largeDesktop';
@include breakpoints.min-width-1921 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(bigDesktopSlideWidth));
  }
}

@include breakpoints.min-width-2560 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(largeDesktopSlideWidth));
  }
}

@include breakpoints.max-width-1920 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(mediumDesktopSlideWidth));
  }
}

@include breakpoints.max-width-1440 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(smallDesktopSlideWidth));
  }
}

@include breakpoints.max-width-1280 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(bigTabletSlideWidth));
  }
}

@include breakpoints.max-width-1024 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(mediumTabletSlideWidth));
  }
}

@include breakpoints.max-width-800 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(smallTabletSlideWidth));
  }
}

@include breakpoints.max-width-540 {
  .slides {
    grid-template-columns: repeat(v-bind(itemsLength), v-bind(mobileSlideWidth));
  }
}
</style>
