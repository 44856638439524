import type { ReferralProgram } from '@package/sdk/src/api';
import { FeatureToggle } from '@package/unleash/src/feature-toggle/index';
import { isSameDay } from 'date-fns';

import { useReferralApi } from '@/code/bonus-programs/use-referral-api';
import { CookieName, cookies } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { AppRoute } from '@/platform/router/routes';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';
import { useUserStore } from '@/stores/use-user-store';

export default function useReferralModal() {
  const referralApi = useReferralApi();
  const route = useRoute();
  const layoutStore = useLayoutStore();
  const { getStatus } = useFeaturesStore();
  const { isAuth } = storeToRefs(useSessionStore());
  const { isUserPartner } = storeToRefs(useUserStore());

  const referralPrograms = ref<ReferralProgram>();

  const referralPopupShowDate = useAppCookie(CookieName.ReferralModalShowingDate, {
    maxAge: cookies.referralPopupShowDate.maxAge,
    path: '/',
  });

  const checkShowDate = computed(() => {
    if (!referralPopupShowDate.value) {
      return true;
    }

    return isSameDay(new Date(referralPopupShowDate.value), new Date());
  });

  const isReferralAvailable = computed(
    () => isAuth.value && !isUserPartner.value && referralPrograms.value?.available && checkShowDate.value,
  );

  const isMainPage = computed(() => route.name === AppRoute.Index);

  const onReferralAvailable = () => {
    if (!isReferralAvailable.value || !isMainPage.value || !checkIsReferralEnabled()) {
      return;
    }

    layoutStore.setCurrentModalName('ReferralModal');
  };

  const fetchReferralProgram = async () => {
    referralPrograms.value = (await referralApi.getReferralPrograms())[0];
  };

  const checkIsReferralEnabled = () => {
    return getStatus({ name: FeatureToggle.IsReferralProgramPopup });
  };

  return {
    onReferralAvailable,
    fetchReferralProgram,
    checkIsReferralEnabled,
    referralPrograms,
  };
}
