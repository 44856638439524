import validate from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/pages/runtime/validate.js";
import ensure_45url_45ends_45slash_45global from "/srv/app/apps/web/src/middleware/ensure-url-ends-slash.global.ts";
import protecting_45parental_45global from "/srv/app/apps/web/src/middleware/protecting-parental.global.ts";
import protecting_45routes_45global from "/srv/app/apps/web/src/middleware/protecting-routes.global.ts";
import manifest_45route_45rule from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  ensure_45url_45ends_45slash_45global,
  protecting_45parental_45global,
  protecting_45routes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  catalog: () => import("/srv/app/apps/web/src/middleware/catalog.ts")
}