import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/account.vue").then(m => m.default || m)),
  auth: defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/auth.vue").then(m => m.default || m)),
  catalog: defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/catalog.vue").then(m => m.default || m)),
  "content-layout": defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/content-layout.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/default.vue").then(m => m.default || m)),
  "my-channel": defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/my-channel.vue").then(m => m.default || m)),
  "my-collection": defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/my-collection.vue").then(m => m.default || m)),
  "player-layout": defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/player-layout.vue").then(m => m.default || m)),
  sandbox: defineAsyncComponent(() => import("/srv/app/apps/web/src/layouts/sandbox.vue").then(m => m.default || m))
}