<template>
  <div :class="$style.controls">
    <div :class="$style.controlButtons">
      <app-slot-button
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'likeButton')"
        :aria-label="$t('ariaLabel.myChannelControls.like')"
        :title="$t('ariaLabel.myChannelControls.like')"
        @click="onLikeClick"
      >
        <icon-thumb :class="$style.controlIcon" :is-active="isLiked" />
      </app-slot-button>
      <app-slot-button
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'dislikeButton')"
        :aria-label="$t('ariaLabel.myChannelControls.dislike')"
        :title="$t('ariaLabel.myChannelControls.dislike')"
        @click="onDislikeClick"
      >
        <icon-thumb variant="down" :class="$style.controlIcon" :is-active="isDisliked" />
      </app-slot-button>

      <my-channel-bookmark
        :item="activeMoment as Moment"
        :max-local-items-length="5"
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'addToCollectionButton')"
        @max-local-content-length="showRegistrationModal"
        @add="onCollectionAdd"
        @remove="onCollectionRemove"
      />

      <app-slot-button
        :ref="(comp) => (shareButtonRef = comp?.$el as HTMLButtonElement)"
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'shareButton')"
        :aria-label="$t('ariaLabel.myChannelControls.share')"
        :title="$t('ariaLabel.myChannelControls.share')"
        @click="onShareClick"
      >
        <icon-share size="large" :filled="true" :class="$style.controlIcon" />
      </app-slot-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useKinomAnalytics } from '@package/sdk/src/analytics';
import { LikeState, type Moment } from '@package/sdk/src/api';
import { onClickOutside } from '@vueuse/core';

import { useLikes } from '@/code/collections/use-likes';
import { useLocalLikes } from '@/code/collections/use-local-likes';
import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import type { CommonMoment } from '@/code/moments/moments';
import IconShare from '@/components/icons/IconShare.vue';
import IconThumb from '@/components/icons/IconThumb.vue';
import MyChannelBookmark from '@/components/my-channel/MyChannelBookmark.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import type { AppRoute } from '@/platform/router/routes';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useMyChannelStore } from '@/stores/use-my-channel-store';
import { useSessionStore } from '@/stores/use-session-store';

const emit = defineEmits<{
  (e: 'on-like', moment: CommonMoment): void;
  (e: 'on-dislike', moment: CommonMoment): void;
  (e: 'on-remove-reaction', moment: CommonMoment): void;
  (e: 'on-share'): void;
  (e: 'on-collection-add'): void;
  (e: 'on-collection-remove'): void;
  (e: 'on-click-outside'): void;
}>();

const props = defineProps<{
  activeMoment: CommonMoment;
  myChannelSharingOptionsListRef?: HTMLDivElement;
}>();

const route = useRoute();

const isLiked = ref(false);
const isDisliked = ref(false);

const shareButtonRef = ref<HTMLButtonElement>();

const { like, removeLike, removeDislike, dislike } = useLikes();
const { $analyticSender } = useNuxtApp();

const { hasLikeInStorage, hasDislikeInStorage, getLocalLikesLength } = useLocalLikes();
const { onClickKinomShare } = useKinomAnalytics($analyticSender);

const myChannelStore = useMyChannelStore();
const { isAuth } = storeToRefs(useSessionStore());

const checkIsLiked = () => hasLikeInStorage(props.activeMoment.id) || props.activeMoment.likeState === LikeState.Like;
const checkIsDisliked = () =>
  hasDislikeInStorage(props.activeMoment.id) || props.activeMoment.likeState === LikeState.Dislike;

const setIsLiked = () => {
  isLiked.value = checkIsLiked();
};

const setIsDisliked = () => {
  isDisliked.value = checkIsDisliked();
};

const showRegistrationModal = () => {
  myChannelStore.setMyChannelPopupState(LocalStorageKey.MyChannelRegistrationPopupShown, true);
};

const checkIsLikesLimitReached = () => {
  if (!isAuth.value) {
    const localLikesLength = getLocalLikesLength();

    if (localLikesLength >= 20) {
      showRegistrationModal();

      return true;
    }
  }

  return false;
};

const onLikeClick = async () => {
  if (checkIsLiked()) {
    await removeLike(props.activeMoment);
    emit('on-remove-reaction', props.activeMoment);

    return;
  }

  // Неавторизованные, могут добавить только 20 единиц контента себе
  const isLimitReached = checkIsLikesLimitReached();

  if (isLimitReached && !hasDislikeInStorage(props.activeMoment.id)) {
    return;
  }

  like(props.activeMoment);
  emit('on-like', props.activeMoment);
};

const onDislikeClick = async () => {
  if (checkIsDisliked()) {
    await removeDislike(props.activeMoment);
    emit('on-remove-reaction', props.activeMoment);

    return;
  }

  const isLimitReached = checkIsLikesLimitReached();

  if (isLimitReached && !hasLikeInStorage(props.activeMoment.id)) {
    return;
  }

  await dislike(props.activeMoment);
  emit('on-dislike', props.activeMoment);
};

const onCollectionAdd = () => {
  emit('on-collection-add');
};

const onCollectionRemove = () => {
  emit('on-collection-remove');
};

const onShareClick = () => {
  emit('on-share');
  onClickKinomShare({ page: getKmzaPageValue(route.name as AppRoute), moment: props.activeMoment });
};

onClickOutside(
  () => props.myChannelSharingOptionsListRef,
  () => {
    emit('on-click-outside');
  },
  { ignore: [shareButtonRef] },
);

watch(
  () => props.activeMoment,
  () => {
    setIsLiked();
    setIsDisliked();
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.controls {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-left: var(--g-spacing-16);
}

.controlButtons {
  display: grid;
  grid-template-rows: repeat(4, var(--g-spacing-48));
  row-gap: var(--g-spacing-12);
  align-items: center;
}

.controlIcon {
  cursor: pointer;
}
</style>
