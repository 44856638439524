<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.30672 7.42283C8.74326 6.80982 9 6.05989 9 5.25C9 3.17893 7.32107 1.5 5.25 1.5C3.17893 1.5 1.5 3.17893 1.5 5.25C1.5 7.32107 3.17893 9 5.25 9C6.05989 9 6.80982 8.74326 7.42283 8.30672L9.55806 10.4419C9.80214 10.686 10.1979 10.686 10.4419 10.4419C10.686 10.1979 10.686 9.80214 10.4419 9.55806L8.30672 7.42283Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4231 10.1856C12.1003 9.25677 12.5 8.11256 12.5 6.875C12.5 3.7684 9.9816 1.25 6.875 1.25C3.7684 1.25 1.25 3.7684 1.25 6.875C1.25 9.9816 3.7684 12.5 6.875 12.5C8.11256 12.5 9.25677 12.1003 10.1856 11.4231L13.3813 14.6187C13.723 14.9604 14.277 14.9604 14.6187 14.6187C14.9604 14.277 14.9604 13.723 14.6187 13.3813L11.4231 10.1856Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.4633 15.0491C17.4274 13.7873 18 12.2105 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.2105 18 13.7873 17.4274 15.0491 16.4633L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L16.4633 15.0491Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.22 20.4523C23.6479 18.6431 24.5 16.3586 24.5 13.875C24.5 8.00697 19.743 3.25 13.875 3.25C8.00697 3.25 3.25 8.00697 3.25 13.875C3.25 19.743 8.00697 24.5 13.875 24.5C16.3586 24.5 18.6431 23.6479 20.4523 22.22L26.4494 28.2172C26.9376 28.7054 27.7291 28.7054 28.2172 28.2172C28.7054 27.7291 28.7054 26.9376 28.2172 26.4494L22.22 20.4523Z"
          fill="currentColor"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.75 5.25C7.75 6.63071 6.63071 7.75 5.25 7.75C3.86929 7.75 2.75 6.63071 2.75 5.25C2.75 3.86929 3.86929 2.75 5.25 2.75C6.63071 2.75 7.75 3.86929 7.75 5.25ZM7.42283 8.30672C6.80982 8.74326 6.05989 9 5.25 9C3.17893 9 1.5 7.32107 1.5 5.25C1.5 3.17893 3.17893 1.5 5.25 1.5C7.32107 1.5 9 3.17893 9 5.25C9 6.05989 8.74326 6.80982 8.30672 7.42283L10.4419 9.55806C10.686 9.80214 10.686 10.1979 10.4419 10.4419C10.1979 10.686 9.80214 10.686 9.55806 10.4419L7.42283 8.30672Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.75 6.875C10.75 9.0151 9.0151 10.75 6.875 10.75C4.7349 10.75 3 9.0151 3 6.875C3 4.7349 4.7349 3 6.875 3C9.0151 3 10.75 4.7349 10.75 6.875ZM10.1856 11.4231C9.25677 12.1003 8.11256 12.5 6.875 12.5C3.7684 12.5 1.25 9.9816 1.25 6.875C1.25 3.7684 3.7684 1.25 6.875 1.25C9.9816 1.25 12.5 3.7684 12.5 6.875C12.5 8.11256 12.1003 9.25677 11.4231 10.1856L14.6187 13.3813C14.9604 13.723 14.9604 14.277 14.6187 14.6187C14.277 14.9604 13.723 14.9604 13.3813 14.6187L10.1856 11.4231Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5ZM15.0491 16.4633C13.7873 17.4274 12.2105 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.2105 17.4274 13.7873 16.4633 15.0491L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L15.0491 16.4633Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 13.875C22 18.3623 18.3623 22 13.875 22C9.38769 22 5.75 18.3623 5.75 13.875C5.75 9.38769 9.38769 5.75 13.875 5.75C18.3623 5.75 22 9.38769 22 13.875ZM20.4523 22.22C18.6431 23.6479 16.3586 24.5 13.875 24.5C8.00697 24.5 3.25 19.743 3.25 13.875C3.25 8.00697 8.00697 3.25 13.875 3.25C19.743 3.25 24.5 8.00697 24.5 13.875C24.5 16.3586 23.6479 18.6431 22.22 20.4523L28.2172 26.4494C28.7054 26.9376 28.7054 27.7291 28.2172 28.2172C27.7291 28.7054 26.9376 28.7054 26.4494 28.2172L20.4523 22.22Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
