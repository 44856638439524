<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          d="M2 10.2448V1.75C2 1.33579 2.33579 1 2.75 1H9.25C9.66421 1 10 1.33579 10 1.75V10.2448C10 10.8891 9.24106 11.2335 8.75612 10.8092L6.32925 8.68569C6.14074 8.52074 5.85926 8.52074 5.67075 8.68569L3.24388 10.8092C2.75894 11.2335 2 10.8891 2 10.2448Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          d="M2 14.3672V1.625C2 1.00368 2.50368 0.5 3.125 0.5H12.875C13.4963 0.5 14 1.00368 14 1.625V14.3672C14 15.3337 12.8616 15.8503 12.1342 15.2138L8.49388 12.0285C8.21111 11.7811 7.78889 11.7811 7.50612 12.0285L3.86582 15.2138C3.13841 15.8503 2 15.3337 2 14.3672Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          d="M4 20.4895V3.5C4 2.67157 4.67157 2 5.5 2H18.5C19.3284 2 20 2.67158 20 3.5V20.4895C20 21.7783 18.4821 22.467 17.5122 21.6184L12.6585 17.3714C12.2815 17.0415 11.7185 17.0415 11.3415 17.3714L6.48776 21.6184C5.51788 22.467 4 21.7783 4 20.4895Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          d="M4.66663 28.027V3.9585C4.66663 2.78489 5.61802 1.8335 6.79162 1.8335H25.2083C26.3819 1.8335 27.3333 2.78489 27.3333 3.9585V28.027C27.3333 29.8527 25.183 30.8285 23.809 29.6262L16.9328 23.6096C16.3987 23.1423 15.6012 23.1423 15.0671 23.6096L8.19095 29.6262C6.81696 30.8285 4.66663 29.8527 4.66663 28.027Z"
          fill="currentColor"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.25 9.14289L4.84762 7.74497C5.50741 7.16765 6.49259 7.16765 7.15238 7.74497L8.75 9.14289V2.25H3.25V9.14289ZM2 1.75V10.2448C2 10.8891 2.75894 11.2335 3.24388 10.8092L5.67075 8.68569C5.85926 8.52074 6.14074 8.52074 6.32925 8.68569L8.75612 10.8092C9.24106 11.2335 10 10.8891 10 10.2448V1.75C10 1.33579 9.66421 1 9.25 1H2.75C2.33579 1 2 1.33579 2 1.75Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.75 12.9898L6.35374 10.7115C7.2963 9.88679 8.7037 9.88679 9.64626 10.7115L12.25 12.9898V2.25H3.75V12.9898ZM13.2866 13.8968L13.2859 13.8962L13.2866 13.8968ZM2 1.625V14.3672C2 15.3337 3.13841 15.8503 3.86582 15.2138L7.50612 12.0285C7.78889 11.7811 8.21111 11.7811 8.49388 12.0285L12.1342 15.2138C12.8616 15.8503 14 15.3337 14 14.3672V1.625C14 1.00368 13.4963 0.5 12.875 0.5H3.125C2.50368 0.5 2 1.00368 2 1.625Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 19.3877L10.0245 15.8662C11.1556 14.8765 12.8444 14.8765 13.9755 15.8662L18 19.3877V4H6V19.3877ZM4 3.5V20.4895C4 21.7783 5.51788 22.467 6.48776 21.6184L11.3415 17.3714C11.7185 17.0415 12.2815 17.0415 12.6585 17.3714L17.5122 21.6184C18.4821 22.467 20 21.7783 20 20.4895V3.5C20 2.67158 19.3284 2 18.5 2H5.5C4.67157 2 4 2.67157 4 3.5Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.16663 27.2006V4.3335H24.8333V27.2006L18.5791 21.7282C17.1024 20.4361 14.8975 20.4361 13.4208 21.7282L15.0671 23.6096L13.4208 21.7282L7.16663 27.2006ZM4.66663 3.9585V28.027C4.66663 29.8527 6.81696 30.8285 8.19095 29.6262L15.0671 23.6096C15.6012 23.1423 16.3987 23.1423 16.9328 23.6096L23.809 29.6262C25.183 30.8285 27.3333 29.8527 27.3333 28.027V3.9585C27.3333 2.78489 26.3819 1.8335 25.2083 1.8335H6.79162C5.61802 1.8335 4.66663 2.78489 4.66663 3.9585Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
