<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.99884 6.00207C7.24124 6.00207 8.24841 4.9949 8.24841 3.7525C8.24841 2.5101 7.24124 1.50293 5.99884 1.50293C4.75644 1.50293 3.74927 2.5101 3.74927 3.7525C3.74927 4.9949 4.75644 6.00207 5.99884 6.00207ZM9.36278 6.78193L9.5684 7.0043C9.93259 7.53311 10.1232 8.11025 10.1232 8.62696C10.1232 10.0764 8.06964 11.2515 5.99897 11.2515C3.9283 11.2515 1.87476 10.0764 1.87476 8.62696C1.87476 8.09403 2.07749 7.49683 2.46433 6.95466L2.58967 6.81211C2.88983 6.47071 3.42122 6.47955 3.7891 6.7466C4.40318 7.19237 5.15864 7.45524 5.9755 7.45524C6.80006 7.45524 7.56206 7.18739 8.17923 6.73395C8.53949 6.46926 9.05928 6.4537 9.36278 6.78193Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99845 8.00301C9.65499 8.00301 10.9979 6.66012 10.9979 5.00358C10.9979 3.34704 9.65499 2.00415 7.99845 2.00415C6.34192 2.00415 4.99903 3.34704 4.99903 5.00358C4.99903 6.66012 6.34192 8.00301 7.99845 8.00301ZM12.4835 9.04249L12.7577 9.33899C13.2433 10.0441 13.4974 10.8136 13.4974 11.5025C13.4974 13.4352 10.7594 15.0019 7.99846 15.0019C5.23757 15.0019 2.49951 13.4352 2.49951 11.5025C2.49951 10.792 2.76983 9.99569 3.28561 9.27281L3.45272 9.08274C3.85294 8.62753 4.56146 8.63932 5.05197 8.99538C5.87074 9.58974 6.87803 9.94024 7.96717 9.94024C9.06658 9.94024 10.0826 9.58311 10.9055 8.97852C11.3858 8.6256 12.0789 8.60485 12.4835 9.04249Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9977 12.0046C14.4825 12.0046 16.4968 9.9903 16.4968 7.50549C16.4968 5.02068 14.4825 3.00635 11.9977 3.00635C9.51289 3.00635 7.49855 5.02068 7.49855 7.50549C7.49855 9.9903 9.51289 12.0046 11.9977 12.0046ZM18.7253 13.5636L19.1366 14.0083C19.8649 15.0659 20.2461 16.2202 20.2461 17.2536C20.2461 20.1526 16.139 22.5026 11.9977 22.5026C7.85635 22.5026 3.74927 20.1526 3.74927 17.2536C3.74927 16.1878 4.15474 14.9934 4.92842 13.909L5.17909 13.6239C5.77941 12.9411 6.84219 12.9588 7.57795 13.4929C8.80612 14.3844 10.317 14.9102 11.9508 14.9102C13.5999 14.9102 15.1239 14.3745 16.3582 13.4676C17.0787 12.9382 18.1183 12.9071 18.7253 13.5636Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9969 16.006C19.31 16.006 21.9958 13.3202 21.9958 10.0072C21.9958 6.69408 19.31 4.0083 15.9969 4.0083C12.6838 4.0083 9.99805 6.69408 9.99805 10.0072C9.99805 13.3202 12.6838 16.006 15.9969 16.006ZM24.9671 18.085L25.5154 18.678C26.4866 20.0881 26.9948 21.6272 26.9948 23.0051C26.9948 26.8703 21.5187 30.0037 15.9969 30.0037C10.4751 30.0037 4.99902 26.8703 4.99902 23.0051C4.99902 21.5839 5.53966 19.9914 6.57122 18.5456L6.90545 18.1655C7.70588 17.2551 9.12291 17.2786 10.1039 17.9908C11.7415 19.1795 13.7561 19.8805 15.9343 19.8805C18.1332 19.8805 20.1652 19.1662 21.811 17.957C22.7717 17.2512 24.1577 17.2097 24.9671 18.085Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
