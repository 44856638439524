<template>
  <app-slot-button :is-disabled="isButtonDisabled" :class="$style.languageButton">
    <img :class="$style.languageItemIcon" :src="getLanguageIconTitle(props.language)" :alt="props.language" />
    <span
      :class="{
        [$style.languageItemCurrent]: isLanguageCurrent,
      }"
    >
      {{ getAppLanguageTitle(props.language) }}
    </span>
  </app-slot-button>
</template>

<script lang="ts" setup>
import type { AppLanguage } from '@package/sdk/src/core/variation/variation';

import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import { getAppLanguageTitle, getLanguageIconTitle } from '@/platform/localization/language';

const props = defineProps<{
  language: AppLanguage;
  currentLanguage: AppLanguage;
}>();

const isLanguageCurrent = computed(() => props.currentLanguage === props.language);

const isButtonDisabled = computed(() => props.language === props.currentLanguage);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.languageButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--g-spacing-16);
  width: 100%;
  border-radius: var(--g-border-round-12);
  cursor: pointer;

  @include fonts.WebBody-1;

  &:disabled {
    color: var(--color-notheme-text-disabled);
    cursor: auto;
  }
}

.languageItemCurrent {
  color: var(--color-text-accent);
}

.languageItemIcon {
  margin-right: var(--g-spacing-16);
  width: 24px;
  height: 24px;
}

.languageItemMark {
  margin-left: auto;
  color: var(--color-notheme-text-disabled);

  @include fonts.WebBody-2;
}

.greenCheckIcon {
  color: var(--color-icon-accent);
}

@include breakpoints.max-width-800 {
  .languageButton {
    padding: var(--g-spacing-6) 0;
  }
}
</style>
