import routerOptions0 from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_encoding@0.1.13_eslint@8.57._3xxsxjemjjtlff56ihnqditzt4/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/srv/app/apps/web/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}