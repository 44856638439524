import type { ApiDeviceSession, ApiSession, DeviceSession, Session } from '@package/sdk/src/api';
import { DeviceSessionMapper, SessionMapper } from '@package/sdk/src/api';

import type { IUserCreateSession } from '@/code/user/use-users-api';
import { ENDPOINTS } from '@/platform/http';

export function useSessionsApi() {
  const app = useNuxtApp();

  const getSessions = (active = false): Promise<DeviceSession[]> => {
    return app.$http
      .get<ApiDeviceSession[]>(ENDPOINTS.SESSIONS_BASE, { query: { active } })
      .then(DeviceSessionMapper.mapMany);
  };

  const refresh = (refreshToken: string): Promise<Session> => {
    return app.$http
      .post<ApiSession>(
        ENDPOINTS.SESSIONS_REFRESH,
        { refresh_token: refreshToken },
        {
          signRequest: true,
          isForceAPIAuthToken: true,
        },
      )
      .then(SessionMapper.map);
  };

  const restore = (token: string, userInfo: IUserCreateSession): Promise<Session> => {
    return app.$http
      .post<ApiSession>(
        ENDPOINTS.SESSIONS_BASE,
        {
          access_token: token,
          ...userInfo,
        },
        {
          signRequest: true,
        },
      )
      .then(SessionMapper.map);
  };

  return { getSessions, refresh, restore };
}
