import type { UserLite } from '@SDK/api';
import { UserType } from '@SDK/api';

export const useUserStore = defineStore('user', () => {
  const userLite = ref<UserLite>();

  const setUserLite = (value: UserLite) => {
    userLite.value = value;
  };

  const resetUserLite = () => {
    userLite.value = undefined;
  };

  const isUserWithActiveSubscription = computed(() => Boolean(userLite.value?.isSubscriptionActive));

  const isUserPartner = computed(() => userLite.value?.userType === UserType.PARTNER);

  const isPartnerUserWithoutSubscription = computed(() => isUserPartner.value && !isUserWithActiveSubscription.value);
  const isUserWithoutSubscriptionAndNotPartner = computed(
    () => !isUserWithActiveSubscription.value && !isUserPartner.value,
  );

  return {
    userLite,
    setUserLite,
    resetUserLite,
    isUserWithActiveSubscription,
    isUserPartner,
    isPartnerUserWithoutSubscription,
    isUserWithoutSubscriptionAndNotPartner,
  };
});
