<template>
  <client-only>
    <div v-if="isSpotlightShown" :class="$style.spotlight">
      <div :class="$style.blur" />
      <div :class="$style.spotlightWrapper">
        <div :class="[$style.light, $style.firstLight]" />
        <div :class="[$style.light, $style.secondLight]" />
        <div :class="[$style.light, $style.thirdLight]" />
        <div :class="[$style.light, $style.fourthLight]" />
        <div :class="[$style.light, $style.fifthLight]" />
      </div>
    </div>
  </client-only>
</template>

<script lang="ts" setup>
import { AppRoute } from '@/platform/router/routes';

const route = useRoute();

const isSpotlightShown = computed(() => {
  const routesWithSpotlight = [
    AppRoute.AuthEnter,
    AppRoute.Offers,
    AppRoute.OffersPromo,
    AppRoute.OffersPromoApply,
    AppRoute.AuthEnter,
    AppRoute.AuthEnterPassword,
  ];

  return routesWithSpotlight.includes(route.name);
});
</script>

<style module lang="scss">
@use '@/assets/breakpoints' as breakpoints;

.spotlight {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  backdrop-filter: blur(50px);
}

.spotlightWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 720px;
  height: 100%;
}

.light {
  position: absolute;
  height: 100%;
}

.firstLight,
.secondLight {
  width: var(--g-spacing-32);
  background: linear-gradient(
    180deg,
    rgba(0, 224, 255, 0.3) 0%,
    rgba(0, 224, 255, 1) 51.5%,
    rgba(0, 224, 255, 0.3) 100%
  );
  opacity: 0.4;
}

.thirdLight,
.fourthLight,
.fifthLight {
  width: 200px;
  background: linear-gradient(
    180deg,
    rgba(0, 199, 178, 0.4) 0%,
    rgba(0, 199, 178, 1) 49.5%,
    rgba(0, 199, 178, 0.4) 100%
  );
  opacity: 0.1;
}

.firstLight {
  left: 270px;
  transform: rotate(-10deg);
  animation: 6s first-line infinite ease-out;
}

.secondLight {
  left: 490px;
  transform: rotate(10deg);
  animation: 6s second-line infinite ease-out;
}

.thirdLight {
  left: 540px;
  transform: rotate(10deg);
  animation: 6s third-line infinite ease-out;
}

.fourthLight {
  left: 360px;
  transform: rotate(10deg);
  animation: 6s fourth-line infinite ease-out;
}

.fifthLight {
  left: 280px;
  transform: rotate(-10deg);
  opacity: 0;
  animation: 6s fifth-line infinite ease-out;
}

@keyframes first-line {
  25% {
    transform: translateX(-20px) rotate(-10deg);
    opacity: 0;
  }
  50% {
    transform: translateX(-40px) rotate(-10deg);
    opacity: 0.4;
  }
  75% {
    transform: translateX(-10px) rotate(-10deg);
    opacity: 0.4;
  }
  100% {
    transform: translateX(0) rotate(-10deg);
    opacity: 0.4;
  }
}

@keyframes second-line {
  25% {
    transform: translateX(-60px) rotate(10deg);
  }
  50% {
    transform: translateX(-20px) rotate(10deg);
    opacity: 0.4;
  }
  75% {
    transform: translateX(110px) rotate(10deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(10deg);
    opacity: 0.4;
  }
}

@keyframes third-line {
  20% {
    transform: translateX(-20px) rotate(10deg);
    opacity: 0.1;
  }
  50% {
    transform: translateX(-150px) rotate(10deg);
    opacity: 0;
  }
  75% {
    transform: translateX(-50px) rotate(10deg);
    opacity: 0.1;
  }
  100% {
    transform: translateX(0) rotate(10deg);
  }
}

@keyframes fourth-line {
  25% {
    transform: translateX(-90deg) rotate(10deg);
  }
  50% {
    transform: translateX(-80px) rotate(10deg);
  }
  75% {
    transform: translateX(-130px) rotate(10deg);
  }
  100% {
    transform: translateX(0) rotate(10deg);
  }
}

@keyframes fifth-line {
  25% {
    transform: translateX(100px) rotate(-10deg);
    opacity: 0.1;
  }
  50% {
    transform: translateX(180px) rotate(-10deg);
    opacity: 0.1;
  }
  75% {
    transform: translateX(57px) rotate(-10deg);
    opacity: 0.1;
  }
}

@include breakpoints.max-width-1024 {
  .spotlight {
    display: none;
  }
}
</style>
