<template>
  <div
    :class="{
      [$style.item]: true,
      [$style.itemWithIcon]: option.icon,
      [$style.secondary]: variant === 'secondary',
      [$style.fade]: withFade,
      selected: isSelected,
    }"
    @click="onOptionClick"
  >
    <app-img
      v-if="hasIcon && !hasImageError"
      :image-class="$style.image"
      :src="option.icon"
      :with-src-set="false"
      :alt="$t('alt.dropdown.option')"
    />
    <dropdown-item-default-icon v-else-if="hasIcon && hasImageError" />

    <div
      ref="labelWrapperEl"
      :class="{
        [$style.labelWrapper]: true,
        [$style.labelWrapperPadding]: hasIcon,
      }"
    >
      <span
        ref="labelEl"
        :title="option.label"
        :class="{ [$style.labelTransition]: widthToTranslateInPx && withFade && !isMobile }"
      >
        {{ option.label }}
      </span>
    </div>

    <transition name="fade">
      <icon-checkmark v-if="isSelected" :class="$style.selectedIcon" size="small" />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { timeout, toPixel } from '@package/sdk/src/core';

import DropdownItemDefaultIcon from '@/components/app-dropdown/DropdownItemDefaultIcon.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import AppImg from '@/components/ui/AppImg.vue';
import useImageLoadError from '@/platform/image/handle-load-error';
import useMobile from '@/platform/layout/use-mobile';
import type { DropdownOption } from '@/stores/use-layout-store';

const props = withDefaults(
  defineProps<{
    option: DropdownOption;
    isSelected: boolean;
    variant: 'primary' | 'secondary';
    isDropdownOpened: boolean;
    withFade?: boolean;
  }>(),
  {
    withFade: false,
  },
);

const emit = defineEmits<{
  (e: 'click', option: DropdownOption): void;
}>();

const { hasImageError } = useImageLoadError(props.option?.icon || '');

const isMobile = useMobile();

const labelEl = ref<HTMLDivElement>();
const labelWrapperEl = ref<HTMLDivElement>();
const widthToTranslateInPx = ref('');

const onOptionClick = () => {
  emit('click', props.option);
};

const hasIcon = computed(() => props.option.icon && props.variant === 'primary');

watch(
  () => props.isDropdownOpened,
  async () => {
    if (!labelEl.value || !labelWrapperEl.value) {
      return;
    }

    await timeout(100);
    widthToTranslateInPx.value = `-${toPixel(labelEl.value?.offsetWidth - labelWrapperEl.value?.clientWidth)}`;
  },
);
</script>

<style lang="scss" module>
.item {
  display: grid;
  grid-template-columns: 1fr var(--g-spacing-16);
  justify-content: space-between;
  align-items: center;
  padding: var(--g-spacing-12) var(--g-spacing-16);
  height: 56px;

  border-bottom: 1px solid var(--color-stroke-input-border);
  background-color: var(--color-notheme-bg-secondary-80);

  transition: background-color 0.2s ease;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
}

.itemWithIcon {
  grid-template-columns: auto 1fr var(--g-spacing-16);
}

.item.fade {
  gap: var(--g-spacing-4);

  &:hover,
  &:focus {
    .labelWrapper:after {
      opacity: 0;
      transition: none;
    }

    .labelTransition {
      transform: translateX(v-bind(widthToTranslateInPx));
      display: inline-block;
      transition: all 1s linear;
      transition-delay: 1.5s;
    }
  }
}

.secondary {
  display: flex;
  justify-content: space-between;
  height: 36px;
  border: none;

  &:hover,
  &:focus {
    background-color: var(--color-state-bg-button-pressed);
  }
}

.iconRight {
  width: 16px;
  height: 16px;
}

.labelWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.labelWrapperPadding {
  padding-left: var(--g-spacing-12);
}

.item.fade .labelWrapper:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  background: var(--gradient-notheme-tretiary-right-to-left);
  opacity: 1;
  content: '';
  transition: all 0.4s ease;
}

.image {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.selectedIcon {
  justify-self: flex-end;
}
</style>
