import { BaseOAuthProvider } from '@/code/o-auth//base-provider';
import type { OAuthResponse } from '@/code/o-auth//types/o-auth';
import { OAuthProviderType, OAuthState } from '@/code/o-auth/o-auth';

/**
 * @description
 *  Авторизация через социальные сети, работает в целом достаточно просто, и ее можно описать в трех шагах.
 *  Авторизация в ВК работает аналогично, поэтому для примера отпишу только здесь.
 *
 *  Для авторизации, нужно сообщить нашему бэку email (или телефон) пользователя, плюс точно быть уверенным в том, что этот email принадлежит ему.
 *  При обычной авторизации, пользователь верифицирует email с помощью пароля. В случае с oauth - верификацию email делает социальная сеть.
 *
 *  Практическая схема такая:
 *  1) Нужно сгенерировать ссылку примерно такого формата: `https://oauth.yandex.ru/?token=<YANDEX_APP_ID>&redirect_url=https://viju.ru/auth/
 *  2) В этой ссылке, важно указать айди приложения в Яндекс (за приложения отвечают продакты, айди брать у них), и ссылку, куда вернуть назад
 *  3) Айди приложения нужно для того, чтобы Яндекс понимал что к нему обращается реальный сервис
 *  4) После перехода юзера по этой ссылке, Яндекс берет свою авторизацию, и обратно нам возвращает нам информацию о юзере,
 *  5) и что самое важное, token. Этот токен (после того как юзер вернулся), мы передаем нашему беку. Бек с этим токеном идет в Яндекс,
 *  6) и Яндекс уже по токену возвращает всю информацию, и говорит нам о том, что юзер существует на самом деле.
 *  7)  наш бек уже на этот email заводит сессию.
 *  8) Напомню, в ВК (да и во всех oauth операторах), работает аналогичная схема
 */
export class YandexOAuthProvider extends BaseOAuthProvider {
  type = OAuthProviderType.Yandex;
  responseQueryName = 'access_token';

  public createOAuthUri(): string {
    const url = new URL('https://oauth.yandex.ru/authorize');
    url.searchParams.set('response_type', 'token');
    url.searchParams.set('force_confirm', 'yes');
    url.searchParams.set('display', 'popup');
    url.searchParams.set('redirect_uri', this.redirectUri);
    url.searchParams.set('client_id', String(useRuntimeConfig().public.OAUTH_YANDEX_CLIENT_ID));
    return url.href;
  }

  public override handleOAuthResponseUri(uri = window.location.href) {
    const token = new RegExp(`${this.responseQueryName}=([^&]+)`).exec(uri)?.[1];
    const state = token ? OAuthState.Success : OAuthState.Failed;
    const response = { provider: this.type, state, token } as OAuthResponse;
    window.opener.postMessage(JSON.stringify(response), window.location.origin);
  }
}
