<template>
  <ul ref="navListEl" role="list" :class="{ [$style.lkNavbar]: true, [$style.lkNavbarDropdown]: isDropdownMenu }">
    <transition-group name="list">
      <li
        v-if="isAllowedManageProfile"
        key="account"
        :class="$style.lkNavbarItem"
        :data-route-name="$AppRoute.AccountInfo"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'accountNavItem')"
      >
        <app-link
          :to="{ name: $AppRoute.AccountInfo }"
          :class="$style.lkNavbarItemLink"
          @click="onSidebarPageClick($AppRoute.AccountInfo)"
        >
          <icon-profile size="small" :class="$style.lkNavbarItemIcon" />
          <span :class="$style.lkNavbarItemText">{{ $t('lk.navBar.accountLink') }}</span>
        </app-link>
      </li>
      <li
        v-if="isAllowedManageProfile"
        key="devices"
        :class="$style.lkNavbarItem"
        :data-route-name="$AppRoute.AccountDevices"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'devicesNavItem')"
      >
        <app-link
          :to="{ name: $AppRoute.AccountDevices }"
          :class="$style.lkNavbarItemLink"
          @click="onSidebarPageClick($AppRoute.AccountDevices)"
        >
          <icon-devices size="small" :filled="true" :class="$style.lkNavbarItemIcon" />
          <span :class="$style.lkNavbarItemText">{{ $t('lk.navBar.devicesLink') }}</span>
        </app-link>
      </li>
      <li
        v-if="isAllowedManageProfile"
        key="subscription"
        :class="$style.lkNavbarItem"
        :data-route-name="$AppRoute.AccountSubscriptionInfo"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'subscriptionNavItem')"
      >
        <app-link
          :to="{ name: $AppRoute.AccountSubscriptionInfo }"
          :class="$style.lkNavbarItemLink"
          @click="onSidebarPageClick($AppRoute.AccountSubscriptionInfo)"
        >
          <icon-subscription size="small" :class="$style.lkNavbarItemIcon" />
          <span :class="$style.lkNavbarItemText">{{ $t('lk.navBar.subscriptionLink') }}</span>
        </app-link>
      </li>
      <li
        v-if="isBonusesLinkShown"
        key="bonuses"
        :class="$style.lkNavbarItem"
        :data-route-name="$AppRoute.AccountBonusesPromocode"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'bonusesNavItem')"
      >
        <app-link
          :to="{ name: $AppRoute.AccountBonusesPromocode }"
          :class="{ [$style.lkNavbarItemLink]: true, [$style.active]: isBonusesReferralTabActive }"
          @click="onSidebarPageClick($AppRoute.AccountBonusesPromocode)"
        >
          <icon-ticket size="small" :class="$style.lkNavbarItemIcon" />
          <span :class="$style.lkNavbarItemText">{{ $t('lk.navBar.bonusesLink') }}</span>
        </app-link>
      </li>
      <li
        v-if="isProfileLinkShown"
        key="profiles"
        :class="$style.lkNavbarItem"
        :data-route-name="$AppRoute.AccountProfiles"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'profilesNavItem')"
      >
        <app-link
          :to="{ name: $AppRoute.AccountProfiles }"
          :class="$style.lkNavbarItemLink"
          @click="onSidebarPageClick($AppRoute.AccountProfiles)"
        >
          <icon-profiles size="small" :class="$style.lkNavbarItemIcon" />
          <span :class="$style.lkNavbarItemText">{{ $t('lk.navBar.profilesLink') }}</span>
        </app-link>
      </li>
      <li
        v-if="isAccountLanguageMenuShown && isAllowedManageProfile"
        key="language"
        :class="$style.lkNavbarItem"
        :data-route-name="$AppRoute.AccountLanguage"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'languageNavItem')"
      >
        <app-link :to="{ name: $AppRoute.AccountLanguage }" :class="$style.lkNavbarItemLink">
          <icon-language size="small" :filled="false" :class="$style.lkNavbarItemIcon" />
          <span :class="$style.lkNavbarItemText">{{ $t('lk.navBar.languageLink') }}</span>
        </app-link>
      </li>
      <li
        key="logout"
        :class="[$style.lkNavbarItem, $style.logoutItem]"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'logoutNavItem')"
      >
        <app-slot-button :class="$style.lkNavbarItemLink" @click="onLogoutClick()">
          <icon-exit size="small" :filled="true" :class="$style.lkNavbarItemIcon" />
          <span :class="{ [$style.lkNavbarItemText]: true, [$style.lkNavbarItemIOS]: isIOS }">
            {{ $t('lk.navBar.logoutLink') }}
          </span>
        </app-slot-button>
      </li>
    </transition-group>
  </ul>
</template>

<script setup lang="ts">
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isIOS } from '@vueuse/core';

import { useBreadcrumbs } from '@/code/breadcrumbs/use-breadcrumbs';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import useProfile from '@/code/profile/use-profile';
import useReferralModal from '@/code/referral/use-referral-modal';
import useAppHeaderVariables from '@/components/app-header/use-app-header-variables';
import IconDevices from '@/components/icons/IconDevices.vue';
import IconExit from '@/components/icons/IconExit.vue';
import IconLanguage from '@/components/icons/IconLanguage.vue';
import IconProfile from '@/components/icons/IconProfile.vue';
import IconProfiles from '@/components/icons/IconProfiles.vue';
import IconSubscription from '@/components/icons/IconSubscription.vue';
import IconTicket from '@/components/icons/IconTicket.vue';
import AppLink from '@/components/ui/AppLink.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import { AppRoute } from '@/platform/router/routes';
import useHostVariation from '@/platform/variation/use-host-variation';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

const sessionsStore = useSessionStore();
const layoutStore = useLayoutStore();
const route = useRoute();

const { $analyticSender } = useNuxtApp();
const { setDefaultBreadcrumbs } = useBreadcrumbs();
const { isPartnerUser } = storeToRefs(sessionsStore);
const { shouldPassParentalControlPrompt } = useProfile();
const { appLanguage, isPromocodeEnabled } = useHostVariation();
const { checkIsReferralEnabled } = useReferralModal();

const accountPageAnalytics = useAccountPageAnalytics($analyticSender);
const { isAllowedManageProfile } = useProfile();
const { isAccountLanguageMenuShown } = useAppHeaderVariables();

const navListEl = ref<HTMLElement>();

const isProfileLinkShown = computed(() => shouldPassParentalControlPrompt.value);
const isBonusesReferralTabActive = computed(() => route.name === AppRoute.AccountBonusesReferral);

const isBonusesLinkShown = computed(() => {
  if (!isPromocodeEnabled && !checkIsReferralEnabled()) {
    return false;
  }

  return isAllowedManageProfile.value && !isPartnerUser.value;
});

defineProps<{
  isDropdownMenu?: boolean;
}>();

const onLogoutClick = () => {
  layoutStore.setCurrentModalName('LogoutModal');
  accountPageAnalytics.onClickExit();
};

const onSidebarPageClick = (routeName: AppRoute) => {
  const page = getKmzaPageValue(route.name as AppRoute);

  const handlers: Record<string, () => void> = {
    [AppRoute.AccountLanguage]: () =>
      accountPageAnalytics.onClickLanguageChange({ currentLang: appLanguage.value, page }),
    [AppRoute.AccountInfo]: () => accountPageAnalytics.onGotoAccountPage(page),
    [AppRoute.AccountDevices]: () => accountPageAnalytics.onGotoDevicesPage(page),
    [AppRoute.AccountSubscriptionInfo]: () => accountPageAnalytics.onGotoSubscribePage(page),
    [AppRoute.AccountBonuses]: () => accountPageAnalytics.onGotoBonusesPage(page),
    [AppRoute.AccountBonusesPromocode]: () => accountPageAnalytics.onGotoBonusesPage(page),
    [AppRoute.AccountBonusesReferral]: () => accountPageAnalytics.onGotoBonusesPage(page),
    [AppRoute.AccountProfiles]: () => accountPageAnalytics.onGotoProfilesPage(page),
  };

  handlers[routeName]();
};

setDefaultBreadcrumbs();

onMounted(() => {
  if (!navListEl.value) {
    throw new UnexpectedComponentStateError('navListEl');
  }

  const navListItem = navListEl.value.querySelector<HTMLElement>(`[data-route-name="${route.name}"]`);

  if (!navListItem) {
    return console.warn('Expect navListItem to be defined');
  }

  navListItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.lk {
  display: flex;
  min-height: calc(100vh - var(--app-header-height));
}

.lkNavbar {
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  padding: var(--g-spacing-20);
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-secondary);
}

.lkNavbarDropdown {
  padding-top: var(--g-spacing-12);
}

.lkNavbarItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  list-style: none;
  margin-bottom: var(--g-spacing-4);

  &:last-child {
    margin-bottom: 0;
  }
}

.logoutItem {
  position: relative;
  margin-top: var(--g-spacing-24);

  &::before {
    content: '';
    position: absolute;
    top: -12px;
    left: -20px;
    right: -20px;
    height: 1px;
    background-color: var(--color-stroke-primary);
  }
}

.lkNavbarItemLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--g-spacing-10) var(--g-spacing-16);
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: var(--g-border-round-12);
  color: var(--color-text-secondary);

  @include fonts.WebLabel-2;

  &:hover {
    color: var(--color-text-primary);
  }
}

.lkNavbarItemIcon {
  margin-right: var(--g-spacing-12);
}

.lkNavbarItemText {
  color: inherit;
  white-space: nowrap;
  transition: color 0.3s ease-in-out;
}

:global(.router-link-active) {
  &.lkNavbarItemLink {
    border-color: var(--color-stroke-border-accent);
    color: var(--color-text-accent);

    .lkNavbarItemIcon {
      color: var(--color-icon-accent);
    }
  }
}

@include breakpoints.max-width-800 {
  .lk {
    min-height: calc(100vh - var(--app-header-height--mobile));
  }

  .logoutItem {
    &::before {
      display: none;
    }
  }
}

@include breakpoints.max-width-640 {
  .lk {
    flex-direction: column;
    height: calc(100vh - var(--app-header-height--mobile) - var(--app-body-offset-padding));
  }

  .lkNavbar {
    position: relative;
    flex-direction: row;
    padding-bottom: 0;
    padding-left: var(--g-spacing-16);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: unset;
    background-color: transparent;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .lkNavbarItemIOS {
    padding-right: var(--g-spacing-24);
  }

  .lkNavbarItem {
    position: relative;
    margin: 0;
    margin-right: var(--g-spacing-16);

    &:last-child {
      margin: 0;
    }
  }

  .lkNavbarItemIcon {
    display: none;
  }

  .lkNavbarItemLink {
    padding: 0;
    border: none;
    font-size: 28px;
    line-height: 56px;
  }

  :global(.router-link-active) {
    &.lkNavbarItemLink {
      background-color: transparent;
      color: var(--color-text-primary);

      &::after {
        content: '';
        position: absolute;
        bottom: 9px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--color-stroke-accent);
      }
    }
  }

  .active {
    background-color: transparent;
    color: var(--color-text-primary);

    &::after {
      content: '';
      position: absolute;
      bottom: 9px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-stroke-active);
    }
  }
}
</style>
