export const maxAge = {
  ONE_HOUR: 60 * 60,
  ONE_DAY: 60 * 60 * 24,
  NINETY_DAYS: 60 * 60 * 24 * 90,
  ONE_WEEK: 60 * 60 * 24 * 7,
  ONE_MONTH: 60 * 60 * 24 * 30,
  ONE_YEAR: 60 * 60 * 24 * 365,
};

/**
 * Важно: Если добавляете новую кук сюда, обязательно добавляйте префикс `viju.{name}`.
 * Это нужно для того, чтобы случайно эти сраные рекламные скрипты не перезаписали наши куки своими. (В теории, это возможно)
 */
export enum CookieName {
  // id счетчика google аналитики
  GoogleAnalyticsId = '_ga',
  /**
   * Айди юзера. По сути это просто кука, которая генерируется на фронте и хранится тут же все время. Пока юзер не почистит куки самостоятельно
   * С ее помощью, можно как-то идентифицировать устройство юзера
   * @type {CookieName.VisitorId}
   */
  VisitorId = 'visitor-id',
  // Супер важная кука: тут хранятся токены для авторизации
  Auth = 'auth',
  // TODO: выпилить эту куку, когда будете убирать рефералку
  ReferralModalShowingDate = 'referral-popup-show-date',
  // Локальное время
  ClientTimezone = 'viju.client_timezone',
  // Текущий язык юзера
  AppLanguage = 'viju.app_language',
  // Текущая тема юзера
  AppTheme = 'viju.app_theme',
  // Текущий промокод сохраненный на будущее
  Promocode = 'viju.promocode_info',
}

export const cookies = {
  visitorId: {
    maxAge: maxAge.ONE_YEAR,
  },
  auth: {
    maxAge: maxAge.ONE_YEAR + maxAge.ONE_MONTH,
  },
  referralPopupShowDate: {
    maxAge: maxAge.ONE_MONTH + maxAge.ONE_WEEK,
  },
  promoCode: {
    maxAge: maxAge.ONE_WEEK,
  },
  clientTimezone: {
    maxAge: maxAge.ONE_MONTH,
  },
};
