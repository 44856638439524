import type { MediaContentType, WatchingItem } from '@package/sdk/src/api';
import { Disposable } from '@package/sdk/src/core';
import { IndexedDBStorage } from '@package/sdk/src/core/database/indexed-db';

export class LocalWatchingItemDatabase extends Disposable {
  private readonly indexedDB = new IndexedDBStorage({
    tableName: 'watching-item',
    databaseName: 'local-watching-item-database',
    timeoutForReading: 2500,
  });

  constructor() {
    super();
  }

  public add(key: string, value: LocalWatchingDbStoredItem) {
    return this.indexedDB.write(key, value);
  }

  public remove(key: string) {
    return this.indexedDB.delete(key);
  }

  public read<T>(key: string) {
    return this.indexedDB.read<T>(key);
  }

  public readAll<T>() {
    return this.indexedDB.readAll<T>();
  }

  public clear() {
    this.indexedDB.clear();
  }
}

export interface LocalWatchingDbStoredItem {
  localWatchingItem: WatchingItem;
  duration: number;
  contentType: MediaContentType;
}
