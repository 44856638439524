<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          d="M7.92797 2.48211C8.03113 2.17263 8.46887 2.17263 8.57203 2.48211L8.64677 2.70633C8.98465 3.71996 9.78004 4.51535 10.7937 4.85323L11.0179 4.92797C11.3274 5.03113 11.3274 5.46887 11.0179 5.57203L10.7937 5.64677C9.78005 5.98465 8.98465 6.78004 8.64678 7.79367L8.57203 8.01789C8.46888 8.32737 8.03113 8.32737 7.92797 8.01789L7.85323 7.79367C7.51535 6.78005 6.71996 5.98465 5.70633 5.64678L5.48211 5.57203C5.17263 5.46888 5.17263 5.03113 5.48211 4.92797L5.70633 4.85323C6.71996 4.51535 7.51535 3.71996 7.85323 2.70633L7.92797 2.48211Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBigStar]: true }"
        />
        <path
          d="M4.26254 7.71147C4.33852 7.48355 4.66091 7.48355 4.73688 7.71147L4.79193 7.8766C5.04077 8.62312 5.62655 9.2089 6.37307 9.45774L6.5382 9.51279C6.76612 9.58876 6.76612 9.91115 6.5382 9.98713L6.37307 10.0422C5.62656 10.291 5.04077 10.8768 4.79193 11.6233L4.73688 11.7884C4.66091 12.0164 4.33852 12.0164 4.26254 11.7884L4.2075 11.6233C3.95866 10.8768 3.37287 10.291 2.62636 10.0422L2.46123 9.98713C2.2333 9.91115 2.2333 9.58876 2.46123 9.51279L2.62636 9.45774C3.37287 9.20891 3.95866 8.62312 4.2075 7.8766L4.26254 7.71147Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBottomStar]: true }"
        />
        <path
          d="M2.76279 0.211471C2.83876 -0.0164534 3.16115 -0.0164539 3.23713 0.21147L3.29217 0.376603C3.54101 1.12312 4.1268 1.7089 4.87331 1.95774L5.03844 2.01279C5.26637 2.08876 5.26637 2.41115 5.03844 2.48713L4.87331 2.54217C4.1268 2.79101 3.54101 3.3768 3.29217 4.12331L3.23713 4.28844C3.16115 4.51637 2.83876 4.51637 2.76279 4.28844L2.70774 4.12331C2.45891 3.3768 1.87312 2.79101 1.1266 2.54217L0.961471 2.48713C0.733547 2.41115 0.733546 2.08876 0.96147 2.01279L1.1266 1.95774C1.87312 1.70891 2.4589 1.12312 2.70774 0.376604L2.76279 0.211471Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedTopStar]: true }"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          d="M10.5706 3.30948C10.7082 2.89684 11.2918 2.89684 11.4294 3.30948L11.529 3.60844C11.9795 4.95994 13.0401 6.02047 14.3916 6.47097L14.6905 6.57062C15.1032 6.70817 15.1032 7.29183 14.6905 7.42938L14.3916 7.52903C13.0401 7.97953 11.9795 9.04006 11.529 10.3916L11.4294 10.6905C11.2918 11.1032 10.7082 11.1032 10.5706 10.6905L10.471 10.3916C10.0205 9.04006 8.95994 7.97953 7.60844 7.52903L7.30948 7.42938C6.89684 7.29183 6.89684 6.70817 7.30948 6.57062L7.60844 6.47097C8.95994 6.02047 10.0205 4.95994 10.471 3.60844L10.5706 3.30948Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBigStar]: true }"
        />
        <path
          d="M5.68363 10.2821C5.78493 9.97822 6.21479 9.97822 6.31609 10.2821L6.38948 10.5023C6.72127 11.4977 7.50232 12.2787 8.49767 12.6105L8.71784 12.6839C9.02174 12.7852 9.02174 13.215 8.71785 13.3163L8.49767 13.3897C7.50232 13.7215 6.72127 14.5026 6.38948 15.4979L6.31609 15.7181C6.21479 16.022 5.78493 16.022 5.68363 15.7181L5.61024 15.4979C5.27846 14.5026 4.49741 13.7215 3.50206 13.3897L3.28188 13.3163C2.97798 13.215 2.97798 12.7852 3.28188 12.6839L3.50206 12.6105C4.49741 12.2787 5.27846 11.4977 5.61024 10.5023L5.68363 10.2821Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBottomStar]: true }"
        />
        <path
          d="M3.68363 0.282124C3.78493 -0.0217751 4.21479 -0.0217758 4.31609 0.282123L4.38948 0.5023C4.72127 1.49765 5.50232 2.2787 6.49767 2.61049L6.71784 2.68388C7.02174 2.78518 7.02174 3.21503 6.71785 3.31633L6.49767 3.38973C5.50232 3.72151 4.72127 4.50256 4.38948 5.49791L4.31609 5.71809C4.21479 6.02199 3.78493 6.02199 3.68363 5.71809L3.61024 5.49791C3.27846 4.50256 2.49741 3.72151 1.50206 3.38973L1.28188 3.31633C0.977981 3.21503 0.97798 2.78518 1.28188 2.68388L1.50206 2.61049C2.49741 2.2787 3.27846 1.49765 3.61024 0.502301L3.68363 0.282124Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedTopStar]: true }"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          d="M15.8559 4.96422C16.0623 4.34526 16.9377 4.34526 17.1441 4.96422L17.2935 5.41266C17.9693 7.43991 19.5601 9.0307 21.5873 9.70645L22.0358 9.85593C22.6547 10.0623 22.6547 10.9377 22.0358 11.1441L21.5873 11.2935C19.5601 11.9693 17.9693 13.5601 17.2936 15.5873L17.1441 16.0358C16.9378 16.6547 16.0623 16.6547 15.8559 16.0358L15.7065 15.5873C15.0307 13.5601 13.4399 11.9693 11.4127 11.2936L10.9642 11.1441C10.3453 10.9378 10.3453 10.0623 10.9642 9.85593L11.4127 9.70645C13.4399 9.0307 15.0307 7.43991 15.7065 5.41266L15.8559 4.96422Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBigStar]: true }"
        />
        <path
          d="M8.52533 15.4234C8.67728 14.9676 9.32206 14.9676 9.47401 15.4234L9.5841 15.7537C10.0818 17.2467 11.2534 18.4183 12.7464 18.916L13.0766 19.0261C13.5325 19.178 13.5325 19.8228 13.0766 19.9747L12.7464 20.0848C11.2534 20.5825 10.0818 21.7541 9.5841 23.2471L9.47401 23.5774C9.32206 24.0332 8.67728 24.0332 8.52533 23.5774L8.41524 23.2471C7.91757 21.7541 6.74599 20.5825 5.25296 20.0848L4.9227 19.9747C4.46685 19.8228 4.46685 19.178 4.9227 19.0261L5.25296 18.916C6.74599 18.4183 7.91757 17.2467 8.41524 15.7537L8.52533 15.4234Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBottomStar]: true }"
        />
        <path
          d="M5.52533 0.42343C5.67728 -0.0324185 6.32206 -0.0324196 6.47401 0.423429L6.5841 0.753694C7.08178 2.24672 8.25335 3.4183 9.74638 3.91597L10.0766 4.02606C10.5325 4.17801 10.5325 4.8228 10.0766 4.97475L9.74638 5.08483C8.25336 5.58251 7.08178 6.75409 6.5841 8.24711L6.47401 8.57738C6.32206 9.03323 5.67728 9.03323 5.52533 8.57738L5.41524 8.24711C4.91757 6.75409 3.74599 5.58251 2.25296 5.08483L1.9227 4.97475C1.46685 4.8228 1.46685 4.17801 1.9227 4.02606L2.25296 3.91597C3.74599 3.4183 4.91757 2.24672 5.41524 0.753695L5.52533 0.42343Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedTopStar]: true }"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          d="M21.1412 6.61896C21.4163 5.79368 22.5837 5.79368 22.8588 6.61896L23.0581 7.21688C23.9591 9.91988 26.0801 12.0409 28.7831 12.9419L29.381 13.1412C30.2063 13.4163 30.2063 14.5837 29.381 14.8588L28.7831 15.0581C26.0801 15.9591 23.9591 18.0801 23.0581 20.7831L22.8588 21.381C22.5837 22.2063 21.4163 22.2063 21.1412 21.381L20.9419 20.7831C20.0409 18.0801 17.9199 15.9591 15.2169 15.0581L14.619 14.8588C13.7937 14.5837 13.7937 13.4163 14.619 13.1412L15.2169 12.9419C17.9199 12.0409 20.0409 9.91988 20.9419 7.21688L21.1412 6.61896Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBigStar]: true }"
        />
        <path
          d="M11.3673 20.5642C11.5699 19.9564 12.4296 19.9564 12.6322 20.5642L12.779 21.0046C13.4425 22.9953 15.0046 24.5574 16.9953 25.221L17.4357 25.3678C18.0435 25.5704 18.0435 26.4301 17.4357 26.6327L16.9953 26.7795C15.0046 27.443 13.4425 29.0051 12.779 30.9958L12.6322 31.4362C12.4296 32.044 11.5699 32.044 11.3673 31.4362L11.2205 30.9958C10.5569 29.0051 8.99481 27.443 7.00411 26.7795L6.56376 26.6327C5.95596 26.4301 5.95596 25.5704 6.56376 25.3678L7.00411 25.221C8.99481 24.5574 10.5569 22.9953 11.2205 21.0046L11.3673 20.5642Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedBottomStar]: true }"
        />
        <path
          d="M7.36727 0.564247C7.56987 -0.0435502 8.42958 -0.0435517 8.63218 0.564246L8.77896 1.0046C9.44253 2.9953 11.0046 4.55741 12.9953 5.22097L13.4357 5.36776C14.0435 5.57036 14.0435 6.43007 13.4357 6.63267L12.9953 6.77945C11.0046 7.44302 9.44253 9.00512 8.77897 10.9958L8.63218 11.4362C8.42958 12.044 7.56987 12.044 7.36727 11.4362L7.22048 10.9958C6.55692 9.00513 4.99481 7.44302 3.00411 6.77945L2.56376 6.63267C1.95596 6.43007 1.95596 5.57036 2.56376 5.36776L3.00411 5.22097C4.99481 4.55741 6.55692 2.9953 7.22048 1.0046L7.36727 0.564247Z"
          fill="currentColor"
          :class="{ [$style.animated]: isAnimated, [$style.animatedTopStar]: true }"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = withDefaults(
  defineProps<{
    size: IconSize;
    isAnimated?: boolean;
  }>(),
  {
    isAnimated: false,
  },
);

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}

.animated {
  animation: 1s infinite ease-in-out;
  animation-name: flashing;
}

.animatedBigStar {
  animation-delay: 0;
}

.animatedBottomStar {
  animation-delay: 0.3s;
}

.animatedTopStar {
  animation-delay: 0.6s;
}

@keyframes flashing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.95);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
