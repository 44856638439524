import { isClient } from '@vueuse/core';

import { AnalyticEventType, getAnalyticApplicationType } from '@/code/kmza/get-kmza-application-type-value';

export enum WeboramaAnalyticEvent {
  AppCounter = 'app_counter',
  AppCounterAfterTimer = 'app_counter_after_timer',
  SuccessAuthorization = 'success_authorization',
  SuccessRegistration = 'success_registration',
  HasLinkedCard = 'has_linked_card',
}

const dict: Record<WeboramaAnalyticEvent, string> = {
  [WeboramaAnalyticEvent.AppCounter]:
    'https://wcm-ru.frontend.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=9454&a.cp=16&a.ct=d',
  [WeboramaAnalyticEvent.AppCounterAfterTimer]:
    'https://wcm-ru.frontend.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=9454&a.cp=12&a.ct=d',
  [WeboramaAnalyticEvent.SuccessAuthorization]:
    'https://wcm-ru.frontend.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=9454&a.cp=15&a.ct=d',
  [WeboramaAnalyticEvent.SuccessRegistration]:
    'https://wcm-ru.frontend.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=9454&a.cp=13&a.ct=d',
  [WeboramaAnalyticEvent.HasLinkedCard]:
    'https://wcm-ru.frontend.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=9454&a.cp=14&a.ct=d',
};

export function useKmzaWeboramaEvents() {
  const injectWeboramaPixel = (event: WeboramaAnalyticEvent) => {
    const pixel = document.createElement('img');

    const pixelSrc = dict[event];

    if (!pixelSrc) {
      return;
    }

    pixel.alt = 'weborama pixel';
    pixel.dataset.type = getAnalyticApplicationType(AnalyticEventType.WEBORAMA, event);
    pixel.width = 0;
    pixel.height = 0;
    pixel.src = pixelSrc;

    document.body.appendChild(pixel);
  };

  const removeWeboramaPixel = (event: WeboramaAnalyticEvent) => {
    if (!isClient) {
      return;
    }

    const pixelType = getAnalyticApplicationType(AnalyticEventType.WEBORAMA, event);

    const weboramaPixel = document.body.querySelector(`img[data-type="${pixelType}"]`);

    weboramaPixel?.remove();
  };

  const removeWeboramaSessionPixels = () => {
    removeWeboramaPixel(WeboramaAnalyticEvent.HasLinkedCard);
    removeWeboramaPixel(WeboramaAnalyticEvent.SuccessAuthorization);
    removeWeboramaPixel(WeboramaAnalyticEvent.SuccessRegistration);
  };

  return {
    injectWeboramaPixel,
    removeWeboramaPixel,
    removeWeboramaSessionPixels,
  };
}
