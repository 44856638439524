<template>
  <app-slot-button
    :is-disabled="isDisabled"
    :class="$style.button"
    :title="ariaLabel"
    :aria-label="ariaLabel"
    @click="onClick"
  >
    <div
      :class="{
        [$style.momentsListButton]: true,
        [$style.active]: isActive,
      }"
    >
      <icon-episodes size="medium" />
    </div>
  </app-slot-button>
</template>

<script lang="ts" setup>
import IconEpisodes from '@/components/icons/IconEpisodes.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import useLocale from '@/platform/localization/use-locale';

const props = defineProps<{
  isActive: boolean;
  isDisabled: boolean;
}>();

const { translate } = useLocale();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const ariaLabel = computed(() =>
  props.isActive
    ? translate('ariaLabel.myChannel.closeEpisodesButton')
    : translate('ariaLabel.myChannel.openEpisodesButton'),
);

const onClick = () => {
  emit('click');
};
</script>

<style lang="scss" module>
.button {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
}

.button[disabled] {
  background-color: var(--color-state-bg-secondary-disabled);
}

.momentsListButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-input);
  transition: 0.3s background-color ease;
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}
</style>
