import { ProfileType } from '@package/sdk/src/api';

import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';
import { useUserStore } from '@/stores/use-user-store';

export default function useProfile() {
  const sessionStore = useSessionStore();

  const { profiles, parentalControlModalState: parentalControlModalStateStore, user } = storeToRefs(sessionStore);
  const {
    isCanceledSubscription,
    hasLinkedCard,
    isSubscriptionExists,
    isSubscriptionOutdated,
    isManagedPaymentSubscription,
  } = storeToRefs(useOffersStore());
  const { isUserWithActiveSubscription } = storeToRefs(useUserStore());

  const profile = computed(() =>
    user.value ? profiles.value?.find((p) => p.id === user.value?.currentProfileId) : undefined,
  );

  const mainProfile = computed(() => profiles.value?.find((x) => x.kind === ProfileType.MAIN));

  const parentalControlModalState = computed({
    get: () => parentalControlModalStateStore.value,
    set: (val) => sessionStore.parentalControlModalStateUpdated(val),
  });

  const isChildProfile = computed(() => profile.value?.kind === ProfileType.CHILD);

  const isMainProfile = computed(() => profile.value?.kind === ProfileType.MAIN);

  const hasOrHadSubscription = computed(
    () =>
      isSubscriptionExists.value &&
      (isUserWithActiveSubscription.value || isCanceledSubscription.value || isSubscriptionOutdated.value),
  );

  const shouldPassParentalControlPrompt = computed(
    () => hasOrHadSubscription.value && hasLinkedCard.value && isManagedPaymentSubscription.value,
  );

  const isAllowedManageProfile = computed(() => !isChildProfile.value);

  return {
    isAllowedManageProfile,
    parentalControlModalState,
    shouldPassParentalControlPrompt,
    profile,
    mainProfile,
    isMainProfile,
    isChildProfile,
  };
}
