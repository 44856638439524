<template>
  <app-link
    :to="{
      name: contentRouteName,
      params: { id: option.slug || option.id },
    }"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Search, 'contentItem')"
    :class="$style.option"
    @click="onSearchOptionClick(option, index)"
  >
    <div :class="$style.optionPoster">
      <app-img :alt="option.title" :src="option.poster" :width="94" :image-class="$style.optionPosterImage" use-stub />
    </div>
    <div>
      <p :class="$style.optionTitle">{{ option.title }}</p>
      <small :class="$style.optionSubtitle">{{ option.titleEn }}</small>
    </div>
  </app-link>
</template>

<script lang="ts" setup>
import { useContentPageAnalytics } from '@package/sdk/src/analytics';
import { type Media, MediaContentType } from '@package/sdk/src/api';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { AppRoute } from '@/platform/router/routes';

const props = defineProps<{
  index: number;
  option: Media;
  searchText: string;
}>();

const route = useRoute();
const { $analyticSender } = useNuxtApp();
const contentPageAnalytics = useContentPageAnalytics($analyticSender);

const onSearchOptionClick = (option: Media, index: number) => {
  contentPageAnalytics.onGotoItemPageFromSearch({
    title: option.title,
    contentType: option.contentType,
    searchText: props.searchText,
    numberInResults: index,
    itemId: option.serialId || option.id,
    page: getKmzaPageValue(route.name as AppRoute),
  });
};

const isMovieContent = props.option.contentType === MediaContentType.MOVIE;
const contentRouteName = isMovieContent ? AppRoute.MovieCard : AppRoute.SerialCard;
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.option {
  display: flex;
  align-items: center;
  padding: var(--g-spacing-10) var(--g-spacing-16);
  transition: background-color 0.3s ease-in-out;
}

.optionPoster {
  position: relative;
  flex-shrink: 0;
  margin-right: var(--g-spacing-16);
  width: 60px;
  height: 84px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary);
}

.optionPosterImage {
  flex-shrink: 0;
  width: 60px;
  height: 84px;
  border-radius: var(--g-border-round-12);
  object-fit: cover;
}

.optionPosterImage.stub {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.optionTitle {
  margin-bottom: var(--g-spacing-8);
  color: var(--color-text-primary);

  @include fonts.WebLabel-1;
}

.optionSubtitle {
  color: var(--color-text-secondary);

  @include fonts.WebCaption-1;
}

@include breakpoints.max-width-375 {
  .optionPoster {
    display: none;
  }
}
</style>
