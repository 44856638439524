<template>
  <app-slot-button ref="buttonEl" :title="ariaLabel" :aria-label="ariaLabel" @click="onClick">
    <icon-bookmark size="large" :filled="inCollection" :class="$style.bookmarkIcon" />
  </app-slot-button>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { useKinomAnalytics } from '@package/sdk/src/analytics';
import type { CollectionItem, Moment } from '@package/sdk/src/api';
import { CollectionContentType } from '@package/sdk/src/api';

import { useCollections } from '@/code/collections/use-collections';
import { useLocalCollections } from '@/code/collections/use-local-collections';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import IconBookmark from '@/components/icons/IconBookmark.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import useLocale from '@/platform/localization/use-locale';
import type { AppRoute } from '@/platform/router/routes';
import { useSessionStore } from '@/stores/use-session-store';

const props = defineProps<{
  item: Moment;
  maxLocalItemsLength?: number;
}>();

const emit = defineEmits<{
  (e: 'remove'): void;
  (e: 'add'): void;
  (e: 'max-local-content-length'): void;
}>();

const logger = useLogger('MyChannelBookmark.vue');

const route = useRoute();

const { translate } = useLocale();

const buttonEl = ref<HTMLElement>();

const { addToCollection, removeFromCollection } = useCollections();
const { hasInStorage, getLocalStorageContentDataLength } = useLocalCollections();

const { isAuth } = storeToRefs(useSessionStore());
const { $analyticSender } = useNuxtApp();

const kinomAnalytics = useKinomAnalytics($analyticSender);

const ariaLabel = computed(() =>
  inCollection.value ? translate('content.deleteFromCollection') : translate('content.addToCollection'),
);

const inCollection = ref(false);

const setInCollection = () => {
  inCollection.value = isAuth.value ? props.item.inUserCollection || false : hasInStorage(props.item.id);
};

const onRemoveFromCollection = async () => {
  const moment = props.item;

  const success = await removeFromCollection(moment.id);

  if (!success) {
    return logger.error('#onRemoveFromCollection');
  }

  kinomAnalytics.onClickCollectionsKinomRemove({
    page: getKmzaPageValue(route.name as AppRoute),
    moment,
  });

  emit('remove');
};

const onAddToCollection = async () => {
  const moment = props.item;

  if (!isAuth.value && props.maxLocalItemsLength) {
    const localCollectionsLength = getLocalStorageContentDataLength();

    if (localCollectionsLength >= props.maxLocalItemsLength) {
      emit('max-local-content-length');

      return;
    }
  }

  const item: CollectionItem = {
    id: moment.id,
    contentType: CollectionContentType.ContentMoment,
    content: moment,
  };

  const success = await addToCollection(item);

  kinomAnalytics.onClickCollectionsKinomSet({
    page: getKmzaPageValue(route.name as AppRoute),
    moment,
  });

  if (!success) {
    return;
  }

  emit('add');
};

const onClick = async () => {
  inCollection.value ? await onRemoveFromCollection() : await onAddToCollection();
  setInCollection();
};

watch(() => props.item, setInCollection, { immediate: true });
</script>

<style lang="scss" module>
.bookmarkIcon {
  cursor: pointer;
}
</style>
