<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.62543 5.89953C8.16077 5.44101 8.5 4.76012 8.5 4C8.5 2.61929 7.38071 1.5 6 1.5C4.61929 1.5 3.5 2.61929 3.5 4C3.5 4.76012 3.83924 5.44101 4.37457 5.89953C3.2601 6.48499 2.5 7.65373 2.5 9V10.0455C2.5 10.2965 2.70351 10.5 2.95455 10.5H9.04545C9.29649 10.5 9.5 10.2965 9.5 10.0455V9C9.5 7.65373 8.7399 6.48499 7.62543 5.89953Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.4381 7.84929C11.2411 7.16151 11.75 6.14018 11.75 5C11.75 2.92893 10.0711 1.25 8 1.25C5.92893 1.25 4.25 2.92893 4.25 5C4.25 6.14018 4.75885 7.16151 5.56185 7.84929C3.89015 8.72749 2.75 10.4806 2.75 12.5V14.0682C2.75 14.4447 3.05526 14.75 3.43182 14.75H12.5682C12.9447 14.75 13.25 14.4447 13.25 14.0682V12.5C13.25 10.4806 12.1098 8.72749 10.4381 7.84929Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.2509 11.7991C16.3215 10.882 17 9.52024 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 9.52024 7.67847 10.882 8.74913 11.7991C6.52021 12.97 5 15.3075 5 18V20.0909C5 20.593 5.40701 21 5.90909 21H18.0909C18.593 21 19 20.593 19 20.0909V18C19 15.3075 17.4798 12.97 15.2509 11.7991Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.6054 15.7153C22.1222 14.4162 23.0834 12.487 23.0834 10.3333C23.0834 6.42132 19.9121 3.25 16 3.25C12.088 3.25 8.91671 6.42132 8.91671 10.3333C8.91671 12.487 9.87787 14.4162 11.3946 15.7153C8.237 17.3741 6.08337 20.6856 6.08337 24.5V27.4621C6.08337 28.1734 6.65998 28.75 7.37125 28.75H24.6288C25.3401 28.75 25.9167 28.1734 25.9167 27.4621V24.5C25.9167 20.6856 23.7631 17.3741 20.6054 15.7153Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
