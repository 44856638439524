<template>
  <section ref="menuEl" :class="$style.accountMenu" data-header-parent-menu>
    <div :class="$style.accountMenuContent">
      <div v-if="isProfileLinkShown" :class="$style.accountMenuWrapper">
        <profile-item
          v-for="item in profiles"
          :key="item.id"
          :current-profile="currentProfile as Profile"
          :profile="item"
          :is-button-disabled="isProfileButtonDisabled"
          :is-dropdown-menu="true"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'profileItemButton')"
          :class="$style.profileItem"
          @click="setProfile(item)"
        />
      </div>
      <account-navigation-menu :is-dropdown-menu="true" />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Profile } from '@package/sdk/src/api';
import { ParentalControlStatus } from '@package/sdk/src/api';

import { type ParentLinkName, useMouseInElementCustom } from '@/code/layout/use-mouse-in-element-custom';
import useSetProfile from '@/code/profile/use-set-profile';
import AccountNavigationMenu from '@/components/lk/AccountNavigationMenu.vue';
import ProfileItem from '@/components/lk/ProfileItem.vue';
import { useSessionStore } from '@/stores/use-session-store';

const sessionStore = useSessionStore();
const { profiles, currentDeviceParentalControlStatus } = storeToRefs(sessionStore);
const { setProfile, isProfileButtonDisabled, currentProfile } = useSetProfile();

const props = defineProps<{
  parentLinkName: ParentLinkName;
}>();

const emit = defineEmits<{
  (e: 'on-outside', val: boolean): void;
}>();

const menuEl = ref<HTMLElement>();

const outsideValidator = ({ relatedTarget }: MouseEvent) => relatedTarget?.dataset?.linkType !== props.parentLinkName;
const mouseOutside = useMouseInElementCustom(menuEl, outsideValidator).isOutside;

const isProfileLinkShown = computed(
  () => currentDeviceParentalControlStatus.value === ParentalControlStatus.ChildrenAccess,
);

watch(mouseOutside, (val) => emit('on-outside', val));
</script>

<style lang="scss" module>
.accountMenu {
  position: absolute;
  top: var(--app-header-height);
  right: var(--g-spacing-48);
  margin-top: -24px;
  padding-top: var(--g-spacing-24);
  width: 292px;
}

.accountMenuContent {
  border-radius: var(--g-border-round-24);
  background-color: var(--color-bg-secondary);
  overflow: hidden;
}

.accountMenuWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--g-spacing-20) var(--g-spacing-20) var(--g-spacing-12);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;

    background-color: var(--color-stroke-primary);
  }
}

.profileItem {
  border: none;
}
</style>
