<template>
  <button
    :disabled="isProfileButtonDisabled"
    :class="$style.profileButton"
    :aria-label="$t('ariaLabel.parentalControl.switchProfile', { profileName: title })"
    :title="$t('ariaLabel.parentalControl.switchProfile', { profileName: title })"
  >
    <span :class="$style.imageWrapper">
      <ProfilesIconComponent :profile-kind="profileKind" :size="size" />
    </span>
    <span :class="$style.title">{{ title }}</span>
    <span :class="$style.ageLimit">{{ ageLimit }}</span>
  </button>
</template>

<script lang="ts" setup>
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import type { ProfileType } from '@SDK/api';
import { useWindowSize } from '@vueuse/core';

import ProfilesIconComponent from '@/components/lk/ProfilesIconComponent.vue';

defineProps<{
  title: string;
  ageLimit: string;
  isProfileButtonDisabled?: boolean;
  profileKind: ProfileType;
}>();

const { width: windowWidth } = useWindowSize();

const size = computed(() => {
  const mobileBreakpoint = ConstantsConfigInstanceWeb.getProperty('mobileLayoutBreakpointPx');
  return windowWidth.value <= mobileBreakpoint ? 'medium' : 'large';
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.profileButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 0;
  background: none;
  cursor: pointer;
  appearance: none;

  &:hover .imageWrapper {
    border-color: var(--color-stroke-accent);
  }
}

.imageWrapper {
  border: 4px solid transparent;
  border-radius: 50%;
  transition: border-color 0.15s ease-in-out;
}

.title {
  margin-top: var(--g-spacing-12);
  color: var(--color-text-primary);

  @include fonts.WebSubtitle-2;
}

.ageLimit {
  margin-top: var(--g-spacing-4);
  color: var(--color-text-tertiary);

  @include fonts.WebCaption-1;
}

@include breakpoints.max-width-800 {
  .profileButton {
    flex-direction: row;
    padding: 0 var(--g-spacing-16);
    height: 76px;
    border-radius: var(--g-border-round-12);
    background-color: var(--color-bg-secondary);
  }

  .imageWrapper {
    border-width: 2px;
  }

  .title {
    margin-top: 0;
    margin-left: var(--g-spacing-16);
  }

  .ageLimit {
    margin-left: auto;
  }
}
</style>
