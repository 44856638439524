<template>
  <div ref="wrapperEl" :class="$style.codeInput">
    <input
      ref="inputEl"
      :value="innerModel"
      :class="{
        [$style.input]: true,
        [$style.inputError]: isError,
        [$style.inputSuccess]: isSuccess,
      }"
      type="text"
      inputmode="numeric"
      autocomplete="one-time-code"
      :maxlength="codeLength"
      @input="onInput"
      @keydown="onKeydown"
    />
  </div>
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError, vibrate } from '@package/sdk/src/core';
import { onlyDigits } from '@package/sdk/src/core/regexp';

import { isSafari } from '@/platform/base/platform';

const props = withDefaults(
  defineProps<{
    isSuccess?: boolean;
    isError?: boolean;
    autofocus?: boolean;
    modelValue?: string;
    codeLength?: number;
    isSetImmediate?: boolean;
  }>(),
  {
    isSuccess: false,
    isError: false,
    autofocus: false,
    modelValue: '',
    codeLength: 4,
    isSetImmediate: false,
  },
);

const inputEl = ref<HTMLElement>();
const wrapperEl = ref<HTMLElement>();

const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void;
  (e: 'submit'): void;
}>();

const innerModel = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    const isMaxLengthLimit = value.length >= props.codeLength;

    if (isMaxLengthLimit) {
      emit('update:modelValue', props.modelValue + value[value.length - 1]);
      emit('submit');
      return;
    }

    emit('update:modelValue', value);
  },
});

const onInput = (event: InputEvent) => {
  const element = event.target as HTMLInputElement;
  const value = element.value;

  innerModel.value = onlyDigits(value);
  element.value = innerModel.value;
};

const $style = useCssModule();

const onKeydown = (event: KeyboardEvent) => {
  if (!isSafari) {
    return;
  }

  const currentValue = innerModel.value;

  /**
   * В Safari аттрибут maxlength не работает корректно, МегаКЕК.
   * Делаем preventDefault, чтобы событие нажатие клавиши не вызывалось на инпуте, если мы уже достигли максимум длины строки
   * Если нажали Backspace (убираем символ), то не запрещаем это.
   */
  if (currentValue.length >= props.codeLength && event.key !== 'Backspace') {
    event.preventDefault();
  }
};

watch(
  () => props.isError,
  (val) => {
    if (val) {
      vibrate([200, 30, 200]);
    }
  },
);

const resetInnerModel = () => {
  innerModel.value = '';
};

onMounted(async () => {
  if (!wrapperEl.value) {
    throw new UnexpectedComponentStateError('wrapperEl');
  }

  if (props.autofocus) {
    await nextTick();
    inputEl.value.focus();
  }
});

defineExpose({
  resetInnerModel,
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.codeInput {
  caret-color: var(--color-stroke-accent);
  width: var(--local-pin-code-input-width, 296px);
}

.input {
  width: inherit;
  height: 56px;
  border: 0;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-input);
  color: var(--color-text-primary);
  text-align: center;

  @include fonts.WebHeadline-1;

  &:focus {
    border: 2px solid var(--color-stroke-accent);
  }
}

.inputSuccess {
  border: 2px solid var(--color-stroke-accent);

  &:focus {
    border: 2px solid var(--color-stroke-accent);
  }
}

.inputError {
  border: 1px solid var(--color-stroke-border-negative);

  &:focus {
    border: 1px solid var(--color-stroke-border-negative);
  }
}
</style>
