<template>
  <div :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'chooseProfileModal')">
    <modal-title :title="$t('parentalControl.modal.chooseProfile')" />

    <div :class="$style.profilesContainer">
      <profile-button
        v-for="profile in profiles"
        :key="profile.id"
        :title="profile.name"
        :profile-kind="profile.kind"
        :age-limit="
          profile.kind === ProfileType.MAIN
            ? `${profile.ageLimit}+`
            : `${$t('parentalControl.modal.fromZeroToLimit', { ageLimit: profile.ageLimit })}`
        "
        :is-profile-button-disabled="isProfileButtonDisabled"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'chooseProfileButton')"
        @click="$emit('selected', profile)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';
import type { Profile } from '@package/sdk/src/api';
import { ProfileType } from '@package/sdk/src/api';

import ModalTitle from '@/components/modals/ModalTitle.vue';
import ProfileButton from '@/components/parental-control/ProfileButton.vue';
import { useSessionStore } from '@/stores/use-session-store';

defineProps<{
  isProfileButtonDisabled?: boolean;
}>();

defineEmits<{
  (e: 'selected', data: Profile): void;
}>();

const sessionStore = useSessionStore();
const { profiles } = storeToRefs(sessionStore);
const { $analyticSender } = useNuxtApp();

const { onAutoParentalProfilesDisplayed } = useParentalControlAnalytics($analyticSender);

onMounted(() => {
  onAutoParentalProfilesDisplayed();
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.profilesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
}

@include breakpoints.max-width-800 {
  .profilesContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    row-gap: var(--g-spacing-16);
  }
}
</style>
