import type { ChannelProgram, ContentAccessKind, ContentMoment, Media } from '@package/sdk/src/api';
import { ContentAccessTypes, MediaContentType } from '@package/sdk/src/api';
import { ensureEndSlash } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';
import normalizeUrl from 'normalize-url';
import type { RouteLocationNamedRaw } from 'vue-router';

import type { CommonMoment } from '@/code/moments/moments';
import useEnvironment from '@/platform/environment/use-environment';
import { AppRoute } from '@/platform/router/routes';
import { useUserStore } from '@/stores/use-user-store';

interface GetContentRouteOptions {
  isPlayerPage?: boolean;
  episodeId?: number;
  seasonId?: number;
}

export function useRouteUtils() {
  const route = useRoute();
  const router = useRouter();
  const headers = useRequestHeaders();
  const { isDev } = useEnvironment();

  const { isUserWithActiveSubscription, isUserPartner } = storeToRefs(useUserStore());

  const createExternalLink = (url: string) => ensureEndSlash(`${fullHostName.value}${url}`);

  const fullHostName = computed(() =>
    normalizeUrl(isClient ? window.location.host : `${headers.host}`, { forceHttps: !isDev }),
  );

  const fullRoute = computed(() =>
    route.path === '/'
      ? fullHostName.value
      : normalizeUrl(`${fullHostName.value}${route.path}/`, { removeTrailingSlash: false }),
  );

  const getContentExternalLink = (content: Media) => {
    const { slug, contentType } = content;

    const isMovieContent = contentType === MediaContentType.MOVIE;

    const contentRoute = router.resolve({
      name: isMovieContent ? AppRoute.MovieCard : AppRoute.SerialCard,
      params: { id: slug },
    });

    return createExternalLink(contentRoute.path);
  };

  const getContentRoute = (
    content: Media | CommonMoment | ContentMoment | ChannelProgram,
    options: GetContentRouteOptions = {},
  ): RouteLocationNamedRaw => {
    const { slug, serialId, watchingItem } = content as Media;
    const { contentId, contentSlug, contentType } = content as CommonMoment;
    const { seasonNumber: season, episodeNumber: episode } = content as ContentMoment;

    const id = (() => {
      if (contentType === MediaContentType.EPISODE) {
        return watchingItem?.slug || (serialId as string);
      }

      return contentSlug || slug || contentId || content.id;
    })();

    const params: Record<string, string> = { id };

    const seasonNumber = (() => {
      if (contentType !== MediaContentType.EPISODE && contentType !== MediaContentType.SERIAL) {
        return;
      }

      return options.seasonId || season || watchingItem?.seasonNumber;
    })();

    const episodeNumber = (() => {
      if (contentType !== MediaContentType.EPISODE && contentType !== MediaContentType.SERIAL) {
        return;
      }

      return options.episodeId || episode || watchingItem?.episodeNumber;
    })();

    if (seasonNumber) {
      Reflect.set(params, 'seasonId', String(seasonNumber));
    }

    if (episodeNumber) {
      Reflect.set(params, 'episodeId', String(episodeNumber));
    }

    const name = (() => {
      const isMovieContent = contentType === MediaContentType.MOVIE;
      const isSerialContent = contentType === MediaContentType.SERIAL || contentType === MediaContentType.EPISODE;

      const isMoviePlayerPage = isMovieContent && options.isPlayerPage;
      const isSerialPlayerPage = isSerialContent && options.isPlayerPage;

      if (isMoviePlayerPage) {
        return AppRoute.MoviePlayer;
      }

      if (isSerialPlayerPage) {
        return AppRoute.SerialPlayer;
      }

      if (isMovieContent) {
        return AppRoute.MovieCard;
      }

      if (episodeNumber) {
        return AppRoute.SerialEpisodeCard;
      }

      if (seasonNumber) {
        return AppRoute.SerialSeasonCard;
      }

      return AppRoute.SerialCard;
    })();

    return { name, params, query: route.query };
  };

  const getBackUrlToContentCard = (backRoute = router.currentRoute.value) => {
    const isMoviePlayerPage = backRoute.name === AppRoute.MoviePlayer;
    const isSerialPlayerPage = backRoute.name === AppRoute.SerialPlayer;

    // Если переход из плеера фильма, возвращаем на страницу фильма
    if (isMoviePlayerPage) {
      return router.resolve({ name: AppRoute.MovieCard }).fullPath;
    }

    // Если переход из плеера сериала, возвращаем на страницу сериала
    if (isSerialPlayerPage) {
      return router.resolve({ name: AppRoute.SerialEpisodeCard }).fullPath;
    }
  };

  const shouldRedirectToPlayerPage = (accessKind: ContentAccessKind) =>
    isUserWithActiveSubscription.value || (accessKind === ContentAccessTypes.AllUsers && !isUserPartner.value);

  const parseBackRoutePathAndQuery = (pathWithQuery: string) => {
    const query: Record<string, string> = {};

    const splitPath = pathWithQuery.split('?');
    let path;
    let queryString;

    if (splitPath.length === 1) {
      return {
        path: splitPath[0],
        query: {},
      };
    }

    if (splitPath.length > 1) {
      path = splitPath[0];
      queryString = splitPath[1];
    } else {
      path = undefined;
      queryString = splitPath[0];
    }

    const pairs = queryString.split('&');

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      const queryKey = decodeURIComponent(key);

      query[queryKey] = decodeURIComponent(value || '');
    });

    return { path, query };
  };

  return {
    fullRoute,
    fullHostName,
    createExternalLink,
    getContentExternalLink,
    getContentRoute,
    getBackUrlToContentCard,
    shouldRedirectToPlayerPage,
    parseBackRoutePathAndQuery,
  };
}
