<template>
  <app-modal-wrapper
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'deleteAccountHasActiveSubscriptionModal')"
  >
    <modal-title :title="$t('lk.deleteAccount.hasSubscriptionModalTitle')" />
    <modal-description :description="$t('lk.deleteAccount.hasSubscriptionModalDescription')" />

    <div :class="$style.buttons">
      <app-button
        variation="button-primary"
        :text="$t('lk.deleteAccount.hasSubscriptionModalButtonText')"
        :title="$t('lk.deleteAccount.hasSubscriptionModalButtonText')"
        :aria-label="$t('lk.deleteAccount.hasSubscriptionModalButtonText')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'rejectRemoveButton')"
        @click="closeModal"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';

import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { useLayoutStore } from '@/stores/use-layout-store';

const layoutStore = useLayoutStore();

const { $analyticSender, $emitter } = useNuxtApp();
const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

const closeModal = () => {
  layoutStore.setCurrentModalName(null);
  accountPageAnalytics.onClickAccountRemoveCancel();
};

onMounted(() => {
  $emitter.on('app.modal.close', closeModal);
});

onBeforeUnmount(() => {
  $emitter.off('app.modal.close', closeModal);
});
</script>

<style lang="scss" module>
.buttons {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-24);
}
</style>
