<template>
  <app-button
    :title="$t('ariaLabel.header.promocodeButton')"
    :aria-label="$t('ariaLabel.header.promocodeButton')"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'actionButton')"
    :text="actionButtonText"
    size="small"
    variation="button-primary"
    @click="onActionButtonClick"
  />
</template>

<script setup lang="ts">
import { useHeaderActionButtons } from '@/code/promocode/use-header-action-buttons';
import AppButton from '@/components/ui/AppButton.vue';
import { useOffersStore } from '@/stores/use-offers-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';

const { currentOffer } = storeToRefs(useOffersStore());
const { promocodeCheckInfo } = storeToRefs(usePromocodeStore());

const { onActionButtonClick } = useHeaderActionButtons();

const actionButtonText = computed(() =>
  promocodeCheckInfo.value ? promocodeCheckInfo.value.ctaText : currentOffer.value.ctaText,
);
</script>
