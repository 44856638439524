<template>
  <!-- Первая модалка, на которой спрашивают точно ли хотите отписаться. -->
  <app-modal-wrapper
    :max-width="360"
    :min-height="200"
    :data-tid="
      $getTestElementIdentifier(
        $ElementTestIdentifierScope.CancelSubscriptionModal,
        'confirmDisableAutoRenewModalWrapper',
      )
    "
  >
    <modal-title :title="$t('accountPage.renewalTrialModal.title')" />
    <modal-description :description="$t('accountPage.renewalTrialModal.description')" />

    <p :class="$style.warning">
      <icon-info size="extra-small" :filled="false" />
      <span>
        {{ $t('accountPage.renewalTrialModal.warning') }}
      </span>
    </p>

    <div :class="$style.buttons">
      <app-button
        variation="button-primary"
        :aria-label="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
        :title="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
        :text="$t('accountPage.renewalTrialModal.stayOnSite')"
        :data-tid="
          $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'cancelDisableButton')
        "
        @click="onCloseModal"
      />

      <app-button
        :aria-label="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
        :title="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
        variation="link-alert"
        :text="$t('accountPage.renewalTrialModal.cancelSubscription')"
        :data-tid="
          $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'confirmDisableButton')
        "
        @click="cancelConfirm"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useSubscriptionAnalytics } from '@package/sdk/src/analytics';

import AppModalWrapper from '@/components/app-modal/AppModalWrapper.vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

const layoutStore = useLayoutStore();

const { $analyticSender } = useNuxtApp();
const subscriptionAnalytics = useSubscriptionAnalytics($analyticSender);

const onCloseModal = () => {
  subscriptionAnalytics.onClickSubscribeManagePopup(false);

  layoutStore.setCurrentModalName(null);
  // Человек решил остаться, уводим его на главную
  navigateTo({ name: AppRoute.Index });
};

const cancelConfirm = () => {
  subscriptionAnalytics.onClickSubscribeManagePopup(true);

  layoutStore.setCurrentModalName('ReasonsDisableAutoRenewalModal');
};

onMounted(() => {
  subscriptionAnalytics.onShowSubscribeManagePopup();
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-32);
}

.warning {
  display: flex;
  align-items: center;
  gap: var(--g-spacing-12);
  margin: var(--g-spacing-32) 0;
  padding: var(--g-spacing-12) var(--g-spacing-16);
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-notification-negative);
  color: var(--color-text-negative);

  @include fonts.WebLabel-3();
}
</style>
