import type { Compilation, Media, Moment, Serial } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { isUndefinedOrNull, UnexpectedComponentStateError } from '@package/sdk/src/core';

import { useBreadcrumbs } from '@/code/breadcrumbs/use-breadcrumbs';
import { useCompilationApi } from '@/code/compilation/use-compilations-api';
import { useSerialApi } from '@/code/content/use-serial-api';
import { useContentMomentsApi } from '@/code/moments/use-content-moments-api';
import { useMovieApi } from '@/code/movie/use-movie-api';
import { useSimilarContentApi } from '@/code/similar-content/use-similar-content-api';
import useLocale from '@/platform/localization/use-locale';
import { AppRoute } from '@/platform/router/routes';
import type { BreadcrumbsItem } from '@/stores/use-layout-store';

export interface CreateCatalogContentTitleOptions {
  season: number;
  episode: number;
  isSeasonPage?: boolean;
  isEpisodePage?: boolean;
}

export interface CatalogContentManifest {
  src: string;
  token: string;
  closingCreditsStartsAt?: number;
}

export interface ContentPageData {
  content: Media;
  similarContents: Media[];
  moments: Moment[];
  compilations: Compilation[];
}

export default function useFetchCatalogContent() {
  const serialApi = useSerialApi();
  const movieApi = useMovieApi();
  const { fetchContentCompilations } = useCompilationApi();
  const similarContentApi = useSimilarContentApi();
  const contentMomentsApi = useContentMomentsApi();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { translate } = useLocale();

  const router = useRouter();
  const route = computed(() => router.currentRoute.value);

  // Что мы находимся на странице фильма
  const isMovieContent = computed(
    () => route.value.name === AppRoute.MovieCard || route.value.name === AppRoute.MoviePlayer,
  );
  // Что мы находимся на странице сериала
  const serialPageRoutesArray = [
    AppRoute.SerialCard,
    AppRoute.SerialSeasonCard,
    AppRoute.SerialEpisodeCard,
    AppRoute.SerialPlayer,
  ];
  const isSerialContent = computed(() => serialPageRoutesArray.includes(route.value.name as AppRoute));
  // ID контента
  const slug = computed<string>(() => route.value.params.id as string);
  // Номер сезона
  const seasonId = computed(() => route.value.params.seasonId);
  // Номер серии
  const episodeId = computed(() => route.value.params.episodeId);

  // Номер сезона (в integer)
  const paramsSeasonNumber = computed(() => Number(seasonId.value || 1));
  // Номер серии (в integer)
  const paramsEpisodeNumber = computed(() => Number(episodeId.value || 1));

  /**
   * Выставляем хлебные крошки (лежат в футере сайта)
   */
  const setContentBreadcrumbs = (result: Media) => {
    if (isUndefinedOrNull(result)) {
      throw new UnexpectedComponentStateError('result');
    }

    const contentRoute = isMovieContent.value ? AppRoute.Movies : AppRoute.Serials;
    const { title } = result;

    const breadcrumbLabelName = isMovieContent.value
      ? translate('contentPage.breadcrumbs.movies')
      : translate('contentPage.breadcrumbs.serials');

    const breadcrumbs: BreadcrumbsItem[] = [
      { url: '', label: translate('seo.breadcrumbs.mainPage') },
      { url: router.resolve({ name: contentRoute }).path, label: breadcrumbLabelName },
      { url: route.value.path, label: title },
    ];

    if (isSerialContent.value) {
      if (seasonId.value) {
        const seasonRoute = router.resolve({ name: AppRoute.SerialSeasonCard, params: { seasonId: seasonId.value } });

        breadcrumbs.push({
          url: seasonRoute.fullPath,
          label: translate('content.seasons.count1', { count: paramsSeasonNumber.value }),
        });
      }

      if (episodeId.value) {
        const episodeRoute = router.resolve({
          name: AppRoute.SerialEpisodeCard,
          params: { seasonId: seasonId.value, episodeId: episodeId.value },
        });

        breadcrumbs.push({
          url: episodeRoute.fullPath,
          label: translate('content.episode.count1', { count: paramsEpisodeNumber.value }),
        });
      }
    }

    setBreadcrumbs(breadcrumbs);
  };

  const createCatalogContentTitle = (
    content: Media,
    options: CreateCatalogContentTitleOptions = { season: 1, episode: 1 },
  ) => {
    const { title: _title } = content;

    const isMovieContent = content.contentType === MediaContentType.MOVIE;
    const { season, episode, isSeasonPage, isEpisodePage } = options;

    if (isMovieContent || (!isSeasonPage && !isEpisodePage)) {
      return _title;
    }

    const serialContent = content as Serial;
    const seasonNumberIndex = serialContent?.seasons.findIndex((_season) => _season.number === season);
    const serialContentSeasonNumber = serialContent?.seasons[seasonNumberIndex]?.number;

    if (serialContentSeasonNumber && serialContentSeasonNumber !== season) {
      return translate('content.serial.titleWithSeasonAndEpisode', {
        title: _title,
        season: serialContentSeasonNumber,
        episode,
      });
    }

    if (isSeasonPage && !isEpisodePage) {
      return translate('content.serial.titleWithSeason', { title: _title, season });
    }

    return translate('content.serial.titleWithSeasonAndEpisode', { title: _title, season, episode });
  };

  const getSeasonLink = (number: number) => {
    return {
      name: AppRoute.SerialSeasonCard,
      params: { id: slug.value, seasonId: number || paramsSeasonNumber.value },
    };
  };

  const getEpisodeLink = (number: number, season?: number) => ({
    name: AppRoute.SerialEpisodeCard,
    params: {
      id: slug.value,
      seasonId: season || paramsSeasonNumber.value,
      episodeId: number || paramsEpisodeNumber.value,
    },
    query: route.value.query,
  });

  const fetchCompilations = async (): Promise<Compilation[]> => {
    if (!slug.value) {
      return [];
    }

    try {
      const compilations = await fetchContentCompilations(slug.value);

      return compilations;
    } catch (error) {
      return [];
    }
  };

  const fetchSimilar = async (): Promise<Media[]> => {
    if (!slug.value) {
      return [];
    }

    try {
      return await similarContentApi.fetchItems(slug.value);
    } catch (error) {
      return [];
    }
  };

  const fetchMoments = async (): Promise<Moment[]> => {
    if (!slug.value) {
      return [];
    }

    try {
      const moments = await contentMomentsApi.fetchMoments(slug.value);

      return moments;
    } catch (error) {
      return [];
    }
  };

  const fetchMovie = () => movieApi.fetchMovie(slug.value);

  const fetchSerial = () => serialApi.fetchSerial(slug.value);

  const fetchMovieManifest = async (id: string): Promise<CatalogContentManifest> => {
    const manifest = await movieApi.getStreamManifest(id);

    return {
      src: manifest.hls,
      token: manifest.watchingItemToken,
    };
  };

  const fetchSerialManifest = async (id: string): Promise<CatalogContentManifest> => {
    const manifest = await serialApi.getStreamManifest(id);

    return {
      src: manifest.hls,
      token: manifest.watchingItemToken,
      closingCreditsStartsAt: manifest.credits?.closingCreditsStartsAt,
    };
  };

  const fetchContent = () => {
    const fetchContentFunc: () => Promise<Media> = isMovieContent.value ? fetchMovie : fetchSerial;

    return fetchContentFunc();
  };

  const fetchContentPageData = async (): Promise<ContentPageData> => {
    const content = await fetchContent();

    const [momentsPromiseResult, similarPromiseResult, compilationsPromiseResult] = await Promise.allSettled<any[]>([
      fetchMoments(),
      fetchSimilar(),
      fetchCompilations(),
    ]);

    let moments: Moment[] = [];
    let similarContents: Media[] = [];
    let compilations: Compilation[] = [];

    if (momentsPromiseResult.status === 'fulfilled') {
      moments = momentsPromiseResult.value;
    }

    if (similarPromiseResult.status === 'fulfilled') {
      similarContents = similarPromiseResult.value;
    }

    if (compilationsPromiseResult.status === 'fulfilled') {
      compilations = compilationsPromiseResult.value;
    }

    return {
      content,
      similarContents,
      moments,
      compilations,
    };
  };

  return {
    seasonId,
    episodeId,
    paramsSeasonNumber,
    paramsEpisodeNumber,
    isMovieContent,
    isSerialContent,
    slug,
    getSeasonLink,
    getEpisodeLink,
    createCatalogContentTitle,
    setContentBreadcrumbs,
    fetchMovie,
    fetchMovieManifest,
    fetchSerial,
    fetchSerialManifest,
    fetchSimilar,
    fetchMoments,
    fetchCompilations,
    fetchContent,
    fetchContentPageData,
  };
}
