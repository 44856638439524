<template>
  <svg width="152" height="48" viewBox="0 0 152 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="151" height="47" rx="9.5" stroke="currentColor" />
    <path
      d="M54.5987 16.4118C55.4868 15.0737 55.5697 13.0724 55.5697 12.1842V11.8289H57.6539V16.4118H54.5987ZM53 16.4118V18.5197H53.7697V17.0987H58.5303V18.5197H59.3V16.4118H58.4592V11.1421H54.7763V12.1132C54.7763 12.9184 54.7408 15.0974 53.7342 16.4118H53Z"
      fill="currentColor"
    />
    <path
      d="M60.1338 14.0908C60.1338 15.8908 61.5311 17.2408 63.2719 17.2408C65.0127 17.2408 66.4219 15.8908 66.4219 14.0908C66.4219 12.3263 65.0127 11 63.2719 11C61.5311 11 60.1338 12.3263 60.1338 14.0908ZM60.9627 14.0908C60.9627 12.7408 61.993 11.7224 63.2719 11.7224C64.5509 11.7224 65.593 12.7408 65.593 14.0908C65.593 15.4882 64.5509 16.5066 63.2719 16.5066C61.993 16.5066 60.9627 15.4882 60.9627 14.0908Z"
      fill="currentColor"
    />
    <path
      d="M67.5235 14.1263C67.5235 15.9737 68.8854 17.2408 70.6498 17.2408C71.5617 17.2408 72.4498 16.8737 72.9709 16.2934L72.6038 15.6895C72.1301 16.2105 71.384 16.5066 70.6972 16.5066C69.3709 16.5066 68.3525 15.5237 68.3525 14.1263C68.3525 12.7053 69.3709 11.7224 70.6735 11.7224C71.3367 11.7224 71.9998 12.0303 72.4143 12.4566L72.8288 11.8526C72.2959 11.2724 71.4314 11 70.6498 11C68.8972 11 67.5235 12.2908 67.5235 14.1263Z"
      fill="currentColor"
    />
    <path d="M75.8247 17.0987H76.63V11.8526H78.8089V11.1421H73.6576V11.8526H75.8247V17.0987Z" fill="currentColor" />
    <path
      d="M79.6195 18.5908L79.3116 19.2303C79.3116 19.2303 79.7497 19.5855 80.3655 19.5855C81.159 19.5855 81.7629 19.1 82.0708 18.3421L85.0076 11.1421H84.1432L82.4261 15.4882C82.3076 15.7842 82.2247 16.1158 82.2247 16.1158H82.2011C82.2011 16.1158 82.0945 15.7842 81.9761 15.4882L80.2353 11.1421H79.3471L81.8221 17.0395L81.4668 17.8921C81.23 18.4368 80.8511 18.8632 80.33 18.8632C79.9511 18.8632 79.6195 18.5908 79.6195 18.5908Z"
      fill="currentColor"
    />
    <path d="M86.2791 17.0987H87.0844V11.8526H90.4594V17.0987H91.2646V11.1421H86.2791V17.0987Z" fill="currentColor" />
    <path
      d="M93.1831 17.0987H93.9884V14.3987H97.47V17.0987H98.2752V11.1421H97.47V13.6882H93.9884V11.1421H93.1831V17.0987Z"
      fill="currentColor"
    />
    <path
      d="M99.7886 14.0908C99.7886 15.8908 101.186 17.2408 102.927 17.2408C104.668 17.2408 106.077 15.8908 106.077 14.0908C106.077 12.3263 104.668 11 102.927 11C101.186 11 99.7886 12.3263 99.7886 14.0908ZM100.618 14.0908C100.618 12.7408 101.648 11.7224 102.927 11.7224C104.206 11.7224 105.248 12.7408 105.248 14.0908C105.248 15.4882 104.206 16.5066 102.927 16.5066C101.648 16.5066 100.618 15.4882 100.618 14.0908Z"
      fill="currentColor"
    />
    <path
      d="M110.542 17.0987H112.815C114.153 17.0987 114.97 16.4592 114.97 15.4289C114.97 14.7303 114.532 14.15 113.763 13.9961V13.9724C114.343 13.7592 114.663 13.2737 114.663 12.6579C114.663 11.675 113.905 11.1421 112.697 11.1421H110.542V17.0987ZM111.347 13.7237V11.7579H112.567C113.407 11.7579 113.834 12.0658 113.834 12.7053C113.834 13.3447 113.419 13.7237 112.614 13.7237H111.347ZM111.347 16.4711V14.3395H112.697C113.62 14.3395 114.142 14.7184 114.142 15.4171C114.142 16.0566 113.692 16.4711 112.709 16.4711H111.347Z"
      fill="currentColor"
    />
    <path
      d="M101.073 23.155V33.2121C101.073 34.6773 101.767 35.4119 103.155 35.4161C103.414 35.415 103.673 35.3927 103.928 35.3494C104.164 35.3137 104.396 35.2545 104.62 35.1727L104.385 33.7778C104.214 33.8268 104.038 33.856 103.86 33.8646C103.748 33.8744 103.635 33.8592 103.531 33.8202C103.426 33.7813 103.332 33.7196 103.255 33.6399C103.129 33.4859 103.066 33.2276 103.066 32.865V23L101.073 23.155Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.0428 32.2486H59.4836L60.5942 35.4161H62.6423L58.194 23.5912H56.3475L52 35.4161H53.9563L55.0428 32.2486ZM58.9133 30.6448H55.6026L56.7779 27.2415C56.9455 26.7197 57.101 26.2086 57.2444 25.7083C57.4029 26.2749 57.5634 26.7914 57.726 27.2575L58.9133 30.6448Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.2336 26.7054C63.2745 27.4763 63.2949 28.182 63.2949 28.8227L63.301 38.9635L65.2282 38.7949V35.2282C65.7701 35.4238 66.3402 35.5245 66.9146 35.526C67.6478 35.5409 68.3711 35.3491 69.0058 34.9715C69.6234 34.5867 70.1138 34.0195 70.4132 33.3441C70.7689 32.5313 70.9413 31.6465 70.9176 30.7557C70.9176 29.7905 70.7704 28.9928 70.476 28.3627C70.2499 27.8167 69.8705 27.3525 69.3863 27.0292C68.9021 26.7059 68.3351 26.5383 67.7578 26.5478C66.7899 26.5478 65.9319 26.9679 65.1837 27.8081C65.1574 27.4381 65.1103 27.0699 65.0427 26.7054H63.2336ZM68.9506 30.866C68.9506 31.9089 68.7543 32.7013 68.3618 33.2432C67.9704 33.7852 67.3996 34.0562 66.6494 34.0562C66.3952 34.0562 66.1418 34.0282 65.8935 33.9727C65.6616 33.9245 65.4361 33.8478 65.222 33.7442V29.111C65.487 28.8235 65.7918 28.5777 66.1266 28.3816C66.4439 28.2002 66.8014 28.1063 67.1645 28.109C67.4163 28.1015 67.6662 28.1571 67.8924 28.271C68.1187 28.3849 68.3145 28.5537 68.463 28.7628C68.7881 29.1976 68.9506 29.8987 68.9506 30.866Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.165 28.8216C72.165 28.1812 72.1441 27.4758 72.1022 26.7053H73.9128C73.9807 27.0695 74.0273 27.4376 74.0523 27.8075C74.8025 26.9677 75.6605 26.5479 76.6264 26.5479C77.204 26.5381 77.7713 26.7054 78.2557 27.0285C78.7402 27.3517 79.1198 27.8159 79.3461 28.3618C79.6384 28.9916 79.7851 29.7889 79.7861 30.7536C79.8103 31.6438 79.6384 32.5282 79.2832 33.3406C78.9833 34.0159 78.4924 34.5827 77.8743 34.9672C77.2402 35.3448 76.5175 35.5365 75.7847 35.5214C75.2103 35.52 74.6402 35.4193 74.0983 35.2239V38.7887L72.165 38.9635V28.8216ZM77.2319 33.2399C77.6244 32.6982 77.8206 31.9062 77.8206 30.8638C77.8206 29.897 77.6581 29.1963 77.3331 28.7617C77.1846 28.5527 76.9888 28.384 76.7625 28.2701C76.5363 28.1563 76.2864 28.1007 76.0346 28.1083C75.6715 28.1054 75.3139 28.1993 74.9967 28.3807C74.6616 28.5763 74.3568 28.822 74.0922 29.1097V33.7406C74.3058 33.8439 74.5307 33.9205 74.7621 33.9689C75.0109 34.0244 75.2648 34.0524 75.5195 34.0524C76.2717 34.0524 76.8436 33.7815 77.235 33.2399H77.2319Z"
      fill="currentColor"
    />
    <path
      d="M81.6661 32.7053C82.1089 33.5591 82.8031 34.262 83.6582 34.7225C84.6004 35.2052 85.6526 35.4431 86.7147 35.4136C87.4547 35.4127 88.1923 35.3304 88.9137 35.1681C89.6401 35.0067 90.3469 34.7692 91.0219 34.4598V29.6123H86.3679V31.1463H89.0728V33.484C88.718 33.6098 88.352 33.703 87.9797 33.7623C87.5943 33.826 87.2042 33.8579 86.8134 33.8577C85.9552 33.8567 85.2445 33.6711 84.6813 33.301C84.1205 32.9335 83.6879 32.4057 83.4418 31.7889C83.1695 31.0907 83.0366 30.3474 83.0504 29.5998C83.0265 28.7705 83.2009 27.9473 83.5596 27.1964C83.8716 26.5637 84.3707 26.0383 84.9916 25.6889C85.6473 25.3362 86.3859 25.1593 87.1332 25.176C88.192 25.1979 89.2322 25.4533 90.177 25.9235L90.6878 24.4114C90.1411 24.1504 89.567 23.9491 88.9757 23.8109C88.3429 23.663 87.6945 23.5896 87.0441 23.592C85.9719 23.576 84.911 23.8091 83.9478 24.2722C83.0313 24.7218 82.2719 25.4295 81.7664 26.305C81.236 27.2089 80.9708 28.3081 80.9708 29.6029C80.9708 30.7955 81.2026 31.8296 81.6661 32.7053Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M97.5393 35.2584C97.4693 34.8846 97.4267 34.5058 97.4119 34.1251C97.0999 34.505 96.7192 34.8161 96.2919 35.0404C95.8096 35.2963 95.2752 35.4251 94.7349 35.4156C94.2941 35.4213 93.8577 35.3229 93.4579 35.1276C93.0774 34.9387 92.7587 34.6353 92.5424 34.2559C92.3063 33.8244 92.1899 33.3321 92.2061 32.8347C92.1922 32.4572 92.2619 32.0815 92.4098 31.7371C92.5576 31.3927 92.7795 31.089 93.0579 30.8501C93.6258 30.3571 94.573 30.1102 95.8994 30.1091C96.3951 30.1086 96.8902 30.1461 97.3808 30.2212V30.0546C97.3808 29.2992 97.2277 28.7668 96.9216 28.4576C96.6154 28.1483 96.1754 27.9927 95.6016 27.9906C95.1713 27.9954 94.7434 28.0566 94.3275 28.1727C93.9203 28.2785 93.5235 28.4244 93.1424 28.6086L92.8461 27.1827C93.2692 26.9933 93.7088 26.8473 94.1587 26.7469C94.7224 26.6123 95.299 26.5459 95.8771 26.5492C96.4902 26.5317 97.1002 26.6461 97.6697 26.8854C98.1708 27.118 98.5789 27.527 98.8237 28.042C99.1003 28.5878 99.2385 29.323 99.2385 30.2477V33.6457C99.2385 33.9991 99.2578 34.5345 99.2993 35.2537L97.5393 35.2584ZM96.4282 33.6753C96.7877 33.5012 97.1105 33.2535 97.3778 32.9468V31.3606C96.9652 31.3091 96.5504 31.2801 96.1349 31.2735C95.3665 31.2735 94.8352 31.4042 94.5409 31.6657C94.3949 31.7945 94.2789 31.9567 94.2018 32.1399C94.1248 32.3232 94.0887 32.5225 94.0964 32.7227C94.0964 33.146 94.2031 33.4574 94.4164 33.6566C94.6583 33.8734 94.97 33.9847 95.2875 33.9679C95.6839 33.9622 96.0739 33.8621 96.4282 33.6753Z"
      fill="currentColor"
    />
    <path
      d="M105.803 33.2121V23.155L107.795 23V32.865C107.795 33.2276 107.858 33.4859 107.986 33.6399C108.062 33.7197 108.156 33.7814 108.261 33.8204C108.365 33.8593 108.478 33.8745 108.589 33.8646C108.768 33.8559 108.945 33.8268 109.116 33.7778L109.35 35.1727C109.126 35.2547 108.894 35.3139 108.658 35.3494C108.402 35.3927 108.143 35.415 107.884 35.4161C106.496 35.4119 105.802 34.6773 105.803 33.2121Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M111.94 31.2331H117.627V30.7162C117.639 29.9999 117.506 29.2884 117.234 28.6237C116.984 28.0145 116.559 27.4902 116.011 27.115C115.46 26.7371 114.774 26.5482 113.953 26.5482C113.22 26.5337 112.496 26.7203 111.864 27.0869C111.248 27.4586 110.757 28 110.451 28.6439C110.095 29.4084 109.921 30.243 109.944 31.0837C109.919 31.8876 110.081 32.6865 110.419 33.4191C110.724 34.0483 111.222 34.5669 111.843 34.9014C112.478 35.2439 113.256 35.4152 114.18 35.4152C115.312 35.4327 116.433 35.1946 117.458 34.7192L117.022 33.3802C116.625 33.5456 116.214 33.6769 115.794 33.7726C115.384 33.8677 114.964 33.9168 114.542 33.9189C112.899 33.9189 112.032 33.0237 111.94 31.2331ZM115.163 28.454C115.473 28.7949 115.648 29.3056 115.685 29.9876H111.991C112.093 29.3108 112.312 28.8012 112.648 28.4586C112.81 28.2896 113.006 28.1563 113.224 28.0674C113.443 27.9786 113.678 27.9363 113.914 27.9433C114.147 27.9337 114.38 27.9744 114.596 28.0626C114.811 28.1508 115.005 28.2844 115.163 28.454Z"
      fill="currentColor"
    />
    <path
      d="M118.874 28.8919C118.874 28.413 118.853 27.684 118.81 26.705H120.683C120.716 26.9476 120.746 27.2363 120.777 27.5725C120.807 27.9087 120.826 28.183 120.837 28.3923C121.038 28.0526 121.268 27.7301 121.523 27.4282C121.743 27.1704 122.009 26.9554 122.308 26.7938C122.624 26.6257 122.977 26.541 123.335 26.548C123.603 26.5427 123.872 26.5752 124.131 26.6447L123.88 28.3717C123.669 28.3098 123.45 28.2798 123.231 28.2829C122.793 28.2733 122.362 28.3985 121.998 28.6413C121.559 28.971 121.179 29.3702 120.871 29.8228V35.4161H118.874V28.8919Z"
      fill="currentColor"
    />
    <path
      d="M124.723 38.7117C124.943 38.7876 125.169 38.8457 125.397 38.8855C125.688 38.9379 125.982 38.9635 126.277 38.962C126.838 38.979 127.395 38.851 127.9 38.5889C128.371 38.317 128.772 37.9241 129.066 37.4455C129.45 36.8129 129.759 36.1315 129.986 35.417L133 26.5474H131.11L129.569 31.5276C129.376 32.1559 129.209 32.789 129.067 33.4269C128.946 32.7703 128.786 32.1227 128.588 31.4878L127.07 26.5538H125.056L128.079 35.382L127.958 35.7009C127.542 36.8427 126.919 37.4136 126.089 37.4136C125.906 37.4119 125.723 37.3938 125.542 37.3594C125.35 37.3243 125.19 37.2876 125.063 37.2509L124.723 38.7117Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.7261 27.1183L28.3586 27.9388H27.5218L29.3013 24.0002H30.0245L31.7967 27.9388H30.9376L30.5748 27.1183H28.7261ZM29.0312 26.4077H30.27L29.6485 24.9976L29.0312 26.4077Z"
      fill="currentColor"
    />
    <path d="M42.1912 27.9359H43V24H42.1912V27.9359Z" fill="currentColor" />
    <path
      d="M38.9723 26.2451H40.4631V25.5275H38.9723V24.7208H41.1365V24.0029H38.1638V27.9385H41.2143V27.2207H38.9723V26.2451Z"
      fill="currentColor"
    />
    <path
      d="M35.7589 26.7101L34.842 24H34.1729L33.256 26.7101L32.3637 24.0022H31.4912L32.8994 27.941H33.5777L34.496 25.3537L35.4144 27.941H36.0987L37.5031 24.0022H36.6532L35.7589 26.7101Z"
      fill="currentColor"
    />
    <path
      d="M26.2872 26.256C26.2872 26.8967 25.9611 27.239 25.3688 27.239C24.7734 27.239 24.4455 26.8868 24.4455 26.2286V24.0027H23.6258V26.256C23.6258 27.3644 24.2573 28 25.3577 28C26.469 28 27.1061 27.3523 27.1061 26.2231V24.0002H26.2872V26.256Z"
      fill="currentColor"
    />
    <path
      d="M21.6703 24.0002H22.4895V27.9412H21.6703V26.3407H19.8197V27.9412H19V24.0002H19.8197V25.5899H21.6703V24.0002Z"
      fill="currentColor"
    />
    <path
      d="M26 14C26 16.757 28.2427 19 31 19C33.757 19 36 16.757 36 14H35.2937C35.2937 16.3674 33.3674 18.2935 31 18.2935C28.6326 18.2935 26.7063 16.3674 26.7063 14H26Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 17.4159C16 11.2539 18.2543 9 24.4175 9H37.5825C43.7457 9 46 11.2539 46 17.4159V30.5841C46 36.7461 43.7416 39 37.5785 39H24.4175C18.2543 39 16 36.7461 16 30.5841V17.4159ZM18.6046 11.6044C17.5588 12.6499 17 14.412 17 17.4159V30.5841C17 33.588 17.5588 35.3501 18.6046 36.3956C19.6504 37.4413 21.413 38 24.4175 38H37.5785C40.5829 38 42.3467 37.4413 43.3936 36.3954C44.4403 35.3497 45 33.5876 45 30.5841V17.4159C45 14.412 44.4412 12.6499 43.3954 11.6044C42.3496 10.5587 40.587 10 37.5825 10H24.4175C21.413 10 19.6504 10.5587 18.6046 11.6044Z"
      fill="currentColor"
    />
  </svg>
</template>
