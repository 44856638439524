<template>
  <app-modal-wrapper>
    <enter-pin-code-modal-prompt @success="onSuccessPinCode" @unsuccessful="onUnsuccessfulPinCode" />
  </app-modal-wrapper>
</template>
<script setup lang="ts">
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';
import type { Profile } from '@package/sdk/src/api';

import { useTvChannels } from '@/code/channels/use-tv-channels';
import useProfile from '@/code/profile/use-profile';
import EnterPinCodeModalPrompt from '@/components/parental-control/EnterPinCodeModalPrompt.vue';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

const layoutStore = useLayoutStore();
const sessionStore = useSessionStore();
const { $analyticSender } = useNuxtApp();
const route = useRoute();

const { onAutoParentalCodeInvalid } = useParentalControlAnalytics($analyticSender);

const { mainProfile } = useProfile();
const tvChannels = useTvChannels();

const onUnsuccessfulPinCode = () => {
  onAutoParentalCodeInvalid();
};

const onSuccessPinCode = async () => {
  await sessionStore.setProfile(mainProfile.value as Profile);
  await sessionStore.refreshSession();
  layoutStore.setCurrentModalName(null);

  if (route.name !== AppRoute.AccountProfiles) {
    reloadNuxtApp();
  }

  await tvChannels.fetchTvChannels();
};
</script>
