<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg
      :class="{
        [$style.up]: direction === 'up',
        [$style.down]: direction === 'down',
        [$style.left]: direction === 'left',
        [$style.right]: direction === 'right',
      }"
      :viewBox="getSVGViewboxByIconSize(props.size)"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.55806 8.44194C1.80214 8.68602 2.19786 8.68602 2.44194 8.44194L6 4.88388L9.55806 8.44194C9.80214 8.68602 10.1979 8.68602 10.4419 8.44194C10.686 8.19786 10.686 7.80214 10.4419 7.55806L6.44194 3.55806C6.19786 3.31398 5.80214 3.31398 5.55806 3.55806L1.55806 7.55806C1.31398 7.80214 1.31398 8.19786 1.55806 8.44194Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.38128 11.6187C1.72299 11.9604 2.27701 11.9604 2.61872 11.6187L8 6.23744L13.3813 11.6187C13.723 11.9604 14.277 11.9604 14.6187 11.6187C14.9604 11.277 14.9604 10.723 14.6187 10.3813L8.61872 4.38128C8.27701 4.03957 7.72299 4.03957 7.38128 4.38128L1.38128 10.3813C1.03957 10.723 1.03957 11.277 1.38128 11.6187Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.2045 16.7955C3.64384 17.2348 4.35616 17.2348 4.7955 16.7955L12 9.59099L19.2045 16.7955C19.6438 17.2348 20.3562 17.2348 20.7955 16.7955C21.2348 16.3562 21.2348 15.6438 20.7955 15.2045L12.7955 7.2045C12.3562 6.76517 11.6438 6.76517 11.2045 7.2045L3.2045 15.2045C2.76517 15.6438 2.76517 16.3562 3.2045 16.7955Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.3611 22.3058C4.89807 22.8427 5.76868 22.8427 6.30565 22.3058L16 12.6114L25.6944 22.3058C26.2314 22.8427 27.102 22.8427 27.639 22.3058C28.176 21.7688 28.176 20.8982 27.639 20.3612L16.9723 9.69456C16.4353 9.15759 15.5647 9.15759 15.0278 9.69456L4.3611 20.3612C3.82413 20.8982 3.82413 21.7688 4.3611 22.3058Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  direction: 'up' | 'down' | 'right' | 'left';
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}

.up {
  transform: rotate(0deg);
}

.down {
  transform: rotate(180deg);
}

.right {
  transform: rotate(90deg);
}

.left {
  transform: rotate(-90deg);
}
</style>
