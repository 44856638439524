<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.75 2C1.33579 2 1 2.33579 1 2.75V9.25C1 9.66421 1.33579 10 1.75 10H10.25C10.6642 10 11 9.66421 11 9.25V2.75C11 2.33579 10.6642 2 10.25 2H1.75ZM6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.625 2C1.00368 2 0.5 2.50368 0.5 3.125V12.875C0.5 13.4963 1.00368 14 1.625 14H14.375C14.9963 14 15.5 13.4963 15.5 12.875V3.125C15.5 2.50368 14.9963 2 14.375 2H1.625ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.5 4C2.67157 4 2 4.67157 2 5.5V18.5C2 19.3284 2.67157 20 3.5 20H20.5C21.3284 20 22 19.3284 22 18.5V5.5C22 4.67157 21.3284 4 20.5 4H3.5ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.95825 4.6665C2.78465 4.6665 1.83325 5.6179 1.83325 6.7915V25.2082C1.83325 26.3818 2.78465 27.3332 3.95825 27.3332H28.0416C29.2152 27.3332 30.1666 26.3818 30.1666 25.2082V6.7915C30.1666 5.6179 29.2152 4.6665 28.0416 4.6665H3.95825ZM16 21.6667C19.1296 21.6667 21.6667 19.1296 21.6667 16C21.6667 12.8704 19.1296 10.3333 16 10.3333C12.8704 10.3333 10.3333 12.8704 10.3333 16C10.3333 19.1296 12.8704 21.6667 16 21.6667Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
