import type { FeatureToggleItem } from '@package/unleash/src/feature-toggle/index';

export function useFeatureToggleApi() {
  const { $unleashApi } = useNuxtApp();

  const fetchAllToggles = (): Promise<FeatureToggleItem[]> => {
    return $unleashApi.fetchByAliases();
  };

  return { fetchAllToggles };
}
