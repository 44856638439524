import { type Bonus, PromoCodeState, type PromoOfferV2 } from '@package/sdk/src/api';

import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';

export const usePromocodeStore = defineStore('promocode', () => {
  const promocodeCookie = useAppCookie(CookieName.Promocode);

  const promocode = ref('');
  const setPromocode = (value: string) => {
    promocode.value = value;
  };

  const savedPromocode = ref('');
  const setSavedPromocode = (value: string) => {
    savedPromocode.value = value;
  };

  const promocodeCheckInfo = ref<PromoOfferV2>();
  const setPromocodeCheckInfo = (value?: PromoOfferV2) => {
    promocodeCheckInfo.value = value;
  };

  const appliedBonusInfo = ref<Bonus>();
  const setAppliedBonusInfo = (value: Bonus) => {
    appliedBonusInfo.value = value;
  };

  const promocodeState = ref(PromoCodeState.NotSettled);
  const setPromocodeState = (value: PromoCodeState) => {
    promocodeState.value = value;
  };

  const promocodeActivationMessage = ref('');
  const setPromocodeActivationMessage = (value: string) => {
    promocodeActivationMessage.value = value;
  };

  const promocodeErrorMessage = ref('');
  const setPromocodeErrorMessage = (value: string) => {
    promocodeErrorMessage.value = value;
  };

  const reset = () => {
    promocodeCookie.value = '';

    promocode.value = '';
    savedPromocode.value = '';
    promocodeCheckInfo.value = undefined;
    appliedBonusInfo.value = undefined;
    promocodeActivationMessage.value = '';
    promocodeState.value = PromoCodeState.NotSettled;
  };

  const isPromocodeCardRequired = computed(() => promocodeCheckInfo.value?.cardRequired);
  const isPromocodeCheckError = computed(() => promocodeState.value === PromoCodeState.Error);
  const isPromocodeCheckSuccess = computed(() => promocodeState.value === PromoCodeState.Success);
  const isPromocodeCheckRequestLoading = computed(() => promocodeState.value === PromoCodeState.Loading);
  const isPromocodeCheckNotSettled = computed(() => promocodeState.value === PromoCodeState.NotSettled);

  return {
    reset,
    promocode,
    setPromocode,
    savedPromocode,
    setSavedPromocode,
    promocodeCheckInfo,
    setPromocodeCheckInfo,
    appliedBonusInfo,
    setAppliedBonusInfo,
    promocodeState,
    setPromocodeState,
    promocodeActivationMessage,
    setPromocodeActivationMessage,
    promocodeErrorMessage,
    setPromocodeErrorMessage,

    isPromocodeCardRequired,
    isPromocodeCheckError,
    isPromocodeCheckSuccess,
    isPromocodeCheckNotSettled,
    isPromocodeCheckRequestLoading,
  };
});
