<template>
  <app-slot-button
    :is-disabled="isButtonDisabled"
    :class="{
      [$style.profilesItem]: true,
      [$style.profilesItemActive]: isProfileActive,
      [$style.profilesItemInDropdown]: isDropdownMenu,
    }"
  >
    <div :class="$style.profilesItemWrapper">
      <div
        :class="{ [$style.profilesItemIcon]: true, [$style.profilesItemIconBorder]: isDropdownMenu && isProfileActive }"
      >
        <profilesIconComponent size="medium" :profile-kind="profile.kind" />
      </div>

      <div :class="{ [$style.dropdownTextWrapper]: isDropdownMenu, [$style.lkTextWrapper]: !isDropdownMenu }">
        <p :class="{ [$style.profileText]: true, [$style.profileTextActive]: isProfileActive }">
          {{ profileText }}
        </p>
        <small :class="$style.profilesSubText">{{ profileSubText }}</small>
      </div>
      <IconCheckmark v-if="isProfileActive && isDropdownMenu" size="small" :class="$style.iconCheckmark" />
    </div>
  </app-slot-button>
</template>

<script lang="ts" setup>
import type { Profile } from '@package/sdk/src/api';
import { ParentalControlStatus, ProfileType } from '@package/sdk/src/api';

import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import profilesIconComponent from '@/components/lk/ProfilesIconComponent.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import useLocale from '@/platform/localization/use-locale';
import { useSessionStore } from '@/stores/use-session-store';

const props = defineProps({
  currentProfile: { type: Object as PropType<Profile>, required: true },
  profile: { type: Object as PropType<Profile>, required: true },
  isButtonDisabled: { type: Boolean, required: false },
  isDropdownMenu: { type: Boolean, required: false },
});

const { translate } = useLocale();
const { currentDeviceParentalControlStatus } = storeToRefs(useSessionStore());

const isProfileActive = computed(() => props.profile?.id === props.currentProfile?.id);

const profileText = computed(() => {
  if (
    props.profile?.kind === ProfileType.CHILD &&
    currentDeviceParentalControlStatus.value === ParentalControlStatus.NoChildrenAccess
  ) {
    return translate('parentalControl.profiles.addChildProfile');
  }

  return props.profile?.name;
});

const profileSubText = computed(() =>
  props.profile?.kind === ProfileType.MAIN
    ? `${props.profile.ageLimit}+`
    : translate('parentalControl.modal.fromZeroToLimit', { ageLimit: props.profile.ageLimit }),
);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.profilesItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--g-spacing-12);
  max-width: 440px;
  border: 1px solid var(--color-stroke-input-border);
  border-radius: var(--g-border-round-12);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-state-bg-button-hover);
  }
}

.profilesItemInDropdown {
  padding: var(--g-spacing-8);
}

.profileText {
  color: var(--color-text-primary);

  @include fonts.WebBody-2;
}

.profilesItemActive {
  border-color: var(--color-stroke-accent);
  cursor: default;

  &:hover {
    background-color: initial;
  }
}

.profilesItemWrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: auto;
  width: 100%;
}

.lkTextWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdownTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profilesSubText {
  color: var(--color-text-tertiary);

  @include fonts.WebCaption-1;
}

.profilesItemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--g-spacing-16);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-bg-input);
}

.profilesItemIconBorder {
  border: 2px solid var(--color-stroke-accent);
  overflow: hidden;
}

.iconCheckmark {
  margin-left: auto;
  color: var(--color-stroke-accent);
}

.profileTextActive {
  color: var(--color-stroke-accent);
}
</style>
