<template>
  <button
    tabindex="0"
    :class="$style.button"
    :disabled="isDisabled"
    :title="title"
    type="button"
    :aria-label="ariaLabel"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style module lang="scss">
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  color: var(--color-text-primary);
  cursor: pointer;
}
</style>
