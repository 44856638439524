<template>
  <section ref="menuEl" :class="$style.tvMenu" data-header-parent-menu>
    <div :class="$style.tvMenuContent">
      <app-header-catalog-column
        v-for="item in categorisedChannels"
        v-show="item.channels.length > 0"
        :key="item.name"
        :title="item.name"
        :columns="3"
        :items="item.channels"
        :current-content-route="AppRoute.TVChannel"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { type ParentLinkName, useMouseInElementCustom } from '@/code/layout/use-mouse-in-element-custom';
import AppHeaderCatalogColumn from '@/components/app-header/AppHeaderDropdownMenuColumn.vue';
import { AppRoute } from '@/platform/router/routes';
import { useTvChannelsStore } from '@/stores/use-tv-channels-store';

const { categorisedChannels } = storeToRefs(useTvChannelsStore());

const props = defineProps<{
  parentLinkName: ParentLinkName;
}>();

const emit = defineEmits<{
  (e: 'on-outside', val: boolean): void;
}>();

const menuEl = ref<HTMLElement>();

const outsideValidator = ({ relatedTarget }: MouseEvent) => relatedTarget?.dataset?.linkType !== props.parentLinkName;
const mouseOutside = useMouseInElementCustom(menuEl, outsideValidator).isOutside;

watch(mouseOutside, (val) => emit('on-outside', val));
</script>

<style lang="scss" module>
.tvMenu {
  margin-top: -16px;
  padding-top: var(--g-spacing-16);
}

.tvMenuContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: var(--g-spacing-32);
  padding: var(--g-spacing-24) var(--g-spacing-32);
  width: 624px;
  max-height: 424px;
  border-radius: var(--g-border-round-24);
  background-color: var(--color-bg-secondary);
  overflow-y: scroll;
}
</style>
