import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import useLogger from '@package/logger/src/use-logger';
import {
  AnalyticPageName,
  OfferPageFrom,
  useAuthPageAnalytics,
  useSubscribeAnalytics,
} from '@package/sdk/src/analytics';
import { PromoCodeState } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import type { RouteLocationRaw } from 'vue-router';

import { useAdmitadUserId } from '@/code/admitad/use-admitad-user-id';
import { useLikes } from '@/code/collections/use-likes';
import { useLocalCollections } from '@/code/collections/use-local-collections';
import { useKmzaWeboramaEvents, WeboramaAnalyticEvent } from '@/code/kmza/use-webama-kmza';
import { NotificationLevel } from '@/code/notifications/notification';
import type { OAuthResponse } from '@/code/o-auth/types/o-auth';
import { useOAuthResponse } from '@/code/o-auth/useOAuthResponse';
import usePromocodeActions from '@/code/promocode/use-promocode-actions';
import useTvPromoPopupActions from '@/code/tv/use-tv-promo-popup-actions';
import { useUserActions } from '@/code/user/use-user-actions';
import type { IUserCreateSession } from '@/code/user/use-users-api';
import { useUsersApi } from '@/code/user/use-users-api';
import useWatchingItems from '@/code/watching-items/use-watching-items';
import useLocale from '@/platform/localization/use-locale';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import useAppSessionStorage, { SessionStorageKey } from '@/platform/session-storage/use-app-session-storage';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { BroadcastChannelEvent } from '@/plugins/broadcast-channel';
import { useAuthRegistrationStore } from '@/stores/use-auth-registration-store';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import useParentalControlStore from '@/stores/use-parental-control-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';
import { useSubscriptionStore } from '@/stores/use-subscription-store';
import { useUserStore } from '@/stores/use-user-store';

export interface INavigateToAuthOptions {
  offerId: string;
  offerRedirectUrl?: string;
}

interface RedirectPageOptions {
  redirectFromFreeEpisode?: boolean;
}

export default function useAuthActions() {
  const route = useRoute();
  const router = useRouter();
  const { translate } = useLocale();
  const app = useNuxtApp();

  const logger = useLogger('use-auth-actions');
  const authPageAnalytics = useAuthPageAnalytics(app.$analyticSender);
  const subscribeAnalytics = useSubscribeAnalytics(app.$analyticSender);

  const usersApi = useUsersApi();

  const sessionStore = useSessionStore();
  const offersStore = useOffersStore();
  const promocodeStore = usePromocodeStore();
  const authRegStore = useAuthRegistrationStore();
  const layoutStore = useLayoutStore();
  const parentalControlStore = useParentalControlStore();
  const localStorage = useLocalStorage();
  const featuresStore = useFeaturesStore();
  const subscriptionStore = useSubscriptionStore();
  const userStore = useUserStore();

  const { user } = storeToRefs(sessionStore);
  const {
    appliedBonusInfo,
    promocodeCheckInfo,
    savedPromocode,
    isPromocodeCardRequired,
    isPromocodeCheckSuccess,
    isPromocodeCheckError,
  } = storeToRefs(promocodeStore);
  const {
    isUserWithActiveSubscription,
    isUserPartner,
    isPartnerUserWithoutSubscription,
    isUserWithoutSubscriptionAndNotPartner,
  } = storeToRefs(userStore);

  const { uploadLocalWatchingItems } = useWatchingItems();

  const { sendAdmitadEvent } = useAdmitadUserId();
  const { uploadLocalCollections } = useLocalCollections();
  const { uploadLocalLikes } = useLikes();
  const { mapResponseToAuthBody } = useOAuthResponse();
  const { restoreSavedPromocode, checkPromocode, applyPromocode } = usePromocodeActions();
  const { getTvPromoRedirectFlag, getTvRedirectUrl, removeFlag } = useTvPromoPopupActions();
  const { injectWeboramaPixel, removeWeboramaSessionPixels } = useKmzaWeboramaEvents();
  const { getBackUrlToContentCard, parseBackRoutePathAndQuery } = useRouteUtils();
  const { fetchUserLite } = useUserActions();

  const isOfferRedirectSkipped = useAppSessionStorage<boolean>(SessionStorageKey.SkipOfferRedirect, false);

  const backUrl = computed(() => route.query[RouteQuery.BackRoute] || '/');
  const isBackUrlToOffers = computed(() => backUrl.value.includes(AppRoute.Offers));

  const mainPageUrl = {
    name: AppRoute.Index,
    query: route.query,
  };

  const doRedirectOfferPage = (options: RedirectPageOptions = {}) => {
    if (options.redirectFromFreeEpisode) {
      const backUrl = getBackUrlToContentCard();

      return navigateTo(getOfferPageRoutesToRedirect(backUrl));
    }

    navigateTo(getOfferPageRoutesToRedirect());
  };

  const getOfferPageRoutesToRedirect = (backUrl = router.currentRoute.value.fullPath) => {
    if (savedPromocode.value) {
      return {
        name: AppRoute.OffersPromo,
        params: { promocode: savedPromocode.value },
        query: { [RouteQuery.BackRoute]: backUrl },
      };
    }

    return {
      name: AppRoute.Offers,
      query: { [RouteQuery.BackRoute]: backUrl },
    };
  };

  const navigateToAuth = (options: INavigateToAuthOptions) => {
    const { offerId } = options;

    // После того как юзер "явно" выбрал оффер, мы это запоминаем - и после возврата обратно, отправим сразу в ПС
    authRegStore.setIsImmediatePaymentRedirect(true);

    navigateTo({
      name: AppRoute.AuthEnter,
      query: {
        [RouteQuery.BackRoute]: route.query[RouteQuery.BackRoute] || '/',
        [RouteQuery.OfferId]: offerId,
      },
    });
  };

  const sendToRegistrationPage = (options: RedirectPageOptions = {}) => {
    const backRoute = router.currentRoute.value;
    const query = { [RouteQuery.BackRoute]: backRoute.fullPath };

    if (options.redirectFromFreeEpisode) {
      const backUrl = getBackUrlToContentCard();

      if (backUrl) {
        Reflect.set(query, RouteQuery.BackRoute, backUrl);
      }

      Reflect.set(query, RouteQuery.IsRedirectFromFreeEpisode, true);
    }

    navigateTo({
      name: AppRoute.AuthEnter,
      query,
    });
  };

  const navigateToPayment = (offerId: string) => {
    authRegStore.setIsImmediatePaymentRedirect(true);

    navigateTo({ name: AppRoute.Offers, query: { [RouteQuery.OfferId]: offerId } });
  };

  const doUpdateLocalCollections = () => {
    uploadLocalCollections();
    uploadLocalLikes();
  };

  const onDidUserCreateSession = async () => {
    // Обновляем список фича-флагов
    await restoreSavedPromocode();

    // Загружаем локальные коллекции в бек
    doUpdateLocalCollections();
    sendAdmitadEvent();

    app.$http.cache.clear();

    if (isUserWithoutSubscriptionAndNotPartner.value) {
      await offersStore.loadOffer();
    }

    if (user.value?.id) {
      app.$yandexMetricaEventSender.setYandexMetricaUserID(user.value.id);
    }

    await uploadLocalWatchingItems();
  };

  const onDidUserLogin = async () => {
    await onDidUserCreateSession();

    injectWeboramaPixel(WeboramaAnalyticEvent.SuccessAuthorization);
  };

  const onDidUserRegister = async () => {
    await onDidUserCreateSession();

    injectWeboramaPixel(WeboramaAnalyticEvent.SuccessRegistration);
  };

  const onDidUserLogout = async () => {
    removeFlag();
    removeWeboramaSessionPixels();

    localStorage.remove(LocalStorageKey.MyChannelOnboardingPopupShown);
    localStorage.remove(LocalStorageKey.MyChannelRegistrationPopupShown);
    localStorage.remove(LocalStorageKey.MyChannelDislikePopupShown);
    localStorage.remove(LocalStorageKey.MyChannelLikePopupShown);
    localStorage.remove(LocalStorageKey.MyChannelWatchAndRatePopupShown);

    app.$http.cache.clear();

    authRegStore.reset();
    promocodeStore.reset();
    parentalControlStore.reset();
    subscriptionStore.resetSubscriptionData();
    userStore.resetUserLite();
    authRegStore.setIsImmediatePaymentRedirect(false);
    await restoreSavedPromocode();
    await offersStore.loadOffer();
  };

  const validateTvPromoPopupUrl = () => {
    const tvPromoRedirectUrlAfterReg = getTvPromoRedirectFlag();

    if (!tvPromoRedirectUrlAfterReg) {
      return;
    }

    return getTvRedirectUrl();
  };

  const getValidationRestorePasswordToken = async (token: string) => {
    try {
      await usersApi.checkResetPasswordToken(token);
      return true;
    } catch (error) {
      logger.error(error);

      return false;
    }
  };

  const getPromocodeRedirectRoute = () => {
    const storedPromocode = savedPromocode.value;

    if (!storedPromocode) {
      return undefined;
    }

    // Если нет сохраненного промо-оффера
    if (!promocodeCheckInfo.value) {
      console.warn('expect to be defined promocodeCheckInfo.promoOffer');
      return undefined;
    }

    // Если нет применненого бонуса
    if (!appliedBonusInfo.value) {
      console.warn('expect to be defined appliedBonusInfo');
      return undefined;
    }

    const backRoute = getBackRouteRedirectUrl();

    if (isPromocodeCheckError.value) {
      promocodeStore.setPromocodeState(PromoCodeState.NotSettled);

      return {
        name: AppRoute.Offers,
        query: backRoute.query,
      };
    }

    return {
      name: AppRoute.OffersPromo,
      params: { promocode: storedPromocode },
      query: backRoute.query,
    };
  };

  const getRedirectRouteTvPromoPopup = (): RouteLocationRaw | undefined => {
    const url = validateTvPromoPopupUrl();

    if (!url) {
      return undefined;
    }

    return {
      path: url,
      query: route.query,
    };
  };

  const getBackRouteRedirectUrl = () => {
    const parsedRoute = parseBackRoutePathAndQuery(String(backUrl.value));

    // @ts-expect-error
    parsedRoute.query = {
      ...parsedRoute.query,
      ...route.query,
    };

    if (isPromocodeCheckError.value) {
      Reflect.deleteProperty(parsedRoute.query, RouteQuery.OfferId);
    }

    return parsedRoute;
  };

  const getSkippedOfferRedirectRoute = () => {
    if (!isOfferRedirectSkipped.value) {
      return undefined;
    }

    isOfferRedirectSkipped.value = false;

    const backRoute = getBackRouteRedirectUrl();

    return isBackUrlToOffers.value ? mainPageUrl : backRoute;
  };

  const getRedirectRouteAfterRegistration = () => {
    const skippedRedirectRoute = getSkippedOfferRedirectRoute();
    if (skippedRedirectRoute) {
      return skippedRedirectRoute;
    }

    const tvRouteRedirectRoute = getRedirectRouteTvPromoPopup();
    if (tvRouteRedirectRoute) {
      return tvRouteRedirectRoute;
    }

    // Если промокод не активирован, возвращаем на страницу офферов
    if (!isPromocodeCheckSuccess.value) {
      subscribeAnalytics.onGotoOfferPage({
        page: AnalyticPageName.Account,
        from: OfferPageFrom.AfterAuth,
      });

      return {
        name: AppRoute.Offers,
        query: route.query,
      };
    }

    // если промик валидный и без привязки карты показываем уведомление о применении
    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      layoutStore.addNotification({
        level: NotificationLevel.Info,
        position: 'top',
        message: translate('promocode.successEnjoyWatching'),
        hideTimeoutMs: ConstantsConfigInstanceWeb.getProperty('promocodeNotificationHideTimeoutMs'),
      });

      return parseBackRoutePathAndQuery(String(backUrl.value));
    }

    if (isPromocodeCheckSuccess.value && isPromocodeCardRequired.value) {
      return getPromocodeRedirectRoute();
    }

    throw new UnexpectedComponentStateError('getNewUserRedirectRoute');
  };

  const getRedirectRouteAfterAuthorization = async (): Promise<RouteLocationRaw | undefined> => {
    const skippedRedirectRoute = getSkippedOfferRedirectRoute();
    if (skippedRedirectRoute) {
      return skippedRedirectRoute;
    }

    const tvRouteRedirectRoute = getRedirectRouteTvPromoPopup();
    if (tvRouteRedirectRoute) {
      return tvRouteRedirectRoute;
    }

    // обычный, активная подписка
    if (isUserWithActiveSubscription.value && !isUserPartner.value) {
      // Если вводил промокод до этого
      if (isPromocodeCheckSuccess.value && promocodeCheckInfo.value) {
        layoutStore.addNotification({
          level: NotificationLevel.Info,
          position: 'top',
          message: translate('promocode.successEnjoyWatching'),
          hideTimeoutMs: ConstantsConfigInstanceWeb.getProperty('promocodeNotificationHideTimeoutMs'),
        });
      }

      const parsedRoute = parseBackRoutePathAndQuery(String(backUrl.value));

      return isBackUrlToOffers.value ? mainPageUrl : parsedRoute;
    }

    // Партнерский, активная подписка
    if (isUserWithActiveSubscription.value && isUserPartner.value) {
      const backRoute = getBackRouteRedirectUrl();

      return isBackUrlToOffers.value ? mainPageUrl : backRoute;
    }

    // обычный, без подписки
    if (isUserWithoutSubscriptionAndNotPartner.value) {
      const promocodeRoute = getPromocodeRedirectRoute();
      if (promocodeRoute) {
        return promocodeRoute;
      }

      subscribeAnalytics.onGotoOfferPage({
        page: AnalyticPageName.Account,
        from: OfferPageFrom.AfterAuth,
      });

      const backRoute = getBackRouteRedirectUrl();
      return {
        name: AppRoute.Offers,
        query: backRoute.query,
      };
    }

    // Партнерский, без подписки
    if (isPartnerUserWithoutSubscription.value) {
      const backRoute = getBackRouteRedirectUrl();

      return isBackUrlToOffers.value ? mainPageUrl : backRoute;
    }
  };

  const doLoginUser = async () => {
    app.$emitter.emit('user.login.complete');

    const route = await getRedirectRouteAfterAuthorization();
    logger.info('Login user - redirect', route);

    await sessionStore.updateCurrentUser();

    app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Auth });

    return route;
  };

  const loginUser = async (body: Partial<IUserCreateSession>) => {
    await sessionStore.loginUser(body);
    await fetchUserLite();

    if (isPromocodeCheckSuccess.value) {
      // Если промокод был успешно применен, забываем про всякие редиректы на ТВ попап
      await checkPromocode(removeFlag);
      await applyPromocode();

      if (!isPromocodeCardRequired.value) {
        await fetchUserLite();
      }
    }

    await fetchUserLite();

    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      await sessionStore.loadSession({ forceLoadUser: false });
    }

    await featuresStore.fetchFeatures();

    authPageAnalytics.onSuccessAuthorization({ authMethod: 'login' });

    return doLoginUser();
  };

  const doRegisterUser = async () => {
    if (isPromocodeCheckSuccess.value) {
      await checkPromocode(removeFlag);
      await applyPromocode();
    }

    await fetchUserLite();

    const route = await getRedirectRouteAfterRegistration();
    logger.info('Registered user - redirect', route);

    app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Auth });

    app.$emitter.emit('user.registration.complete');

    return route;
  };

  const registerUser = async (body: Partial<IUserCreateSession>) => {
    await sessionStore.registerUser(body);

    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      await sessionStore.loadSession({ forceLoadUser: false });
    }

    await featuresStore.fetchFeatures();

    authPageAnalytics.onSuccessRegistration({ authMethod: 'login' });

    return doRegisterUser();
  };

  const loginUserSocialAuth = async (response: OAuthResponse) => {
    const session = await sessionStore.loginUserSocialAuth(mapResponseToAuthBody(response));

    if (session.process === 'sign-in') {
      authPageAnalytics.onSuccessAuthorization({ authMethod: 'social_media_login', oauth: response.provider });
      await fetchUserLite();
      return doLoginUser();
    }

    if (session.process === 'sign-up') {
      authPageAnalytics.onSuccessRegistration({ authMethod: 'social_media_login', oauth: response.provider });
      return doRegisterUser();
    }

    return logger.error(`unexpected session.process value ${session.process}`);
  };

  const deleteUser = async () => {
    try {
      await usersApi.deleteAccount();
      await sessionStore.deleteSession();
      await featuresStore.fetchFeatures();

      app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.DeleteAccount });

      app.$emitter.emit('user.logout.complete');
    } catch (error) {
      logger.error(error);
    } finally {
      navigateTo({ name: AppRoute.Index });
    }
  };

  const logoutUser = async () => {
    try {
      sessionStore.forgetSession();
      await featuresStore.fetchFeatures();

      app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Logout });

      app.$emitter.emit('user.logout.complete');
    } catch (e) {
      logger.error(e);
    } finally {
      promocodeStore.setPromocodeErrorMessage('');
      await navigateTo({ name: AppRoute.Index });
    }
  };

  return {
    deleteUser,
    doRedirectOfferPage,
    getOfferPageRoutesToRedirect,
    getBackRouteRedirectUrl,
    navigateToPayment,
    navigateToAuth,
    sendToRegistrationPage,
    loginUserSocialAuth,
    loginUser,
    logoutUser,
    registerUser,
    onDidUserLogout,
    getValidationRestorePasswordToken,
    onDidUserLogin,
    onDidUserRegister,
    getRedirectRouteAfterRegistration,
    getRedirectRouteAfterAuthorization,
  };
}
