<template>
  <div :class="$style.control">
    <div
      :class="{
        [$style.inputWrapper]: true,
        [$style.inputDisabled]: disabled,
        [$style.primary]: variation === 'primary',
        [$style.secondary]: variation === 'secondary',
        [$style.hasButton]: hasButton,
        [$style.small]: size === 'small',
        [$style.medium]: size === 'medium',
        [$style.large]: size === 'large',
        [$style.withLeadingIcon]: $slots.leadingIcon,
        [$style.withTrailingIcon]: $slots.trailingControl || $slots.clearIcon,
      }"
    >
      <span v-if="$slots.leadingIcon" :class="$style.leadingIcon">
        <slot name="leadingIcon" />
      </span>

      <input
        ref="inputEl"
        :class="{
          [$style.input]: true,
          [$style.inputDisabled]: disabled,
          [$style.inputPasswordHidden]: type === 'password',
          [$style.inputCatalogSearch]: isCatalogSearch,
        }"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :spellcheck="spellcheck"
        :autofocus="autofocus"
        :name="name"
        :type="type"
        :maxlength="maxLength"
        :value="modelValue"
        @blur="onBlur"
        @input="onInput"
      />

      <app-button
        v-if="hasButton && buttonText"
        :data-id="buttonDataId"
        :text="buttonText"
        :class="$style.button"
        size="small"
        @click="onButtonClick"
      />

      <span :class="$style.trailingControl">
        <slot v-if="$slots.trailingControl" name="trailingControl" />
        <app-slot-button v-if="hasClearIcon" @click="onClearClick">
          <icon-clear
            size="small"
            :class="{ [$style.iconClear]: true, [$style.iconClearAutoComplete]: isAutoComplete }"
          />
        </app-slot-button>
      </span>
    </div>

    <p v-if="errorMessage" :class="$style.error">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import IMask from 'imask';
import { nextTick } from 'vue';

import IconClear from '../icons/IconClear.vue';
import AppButton from './AppButton.vue';
import AppSlotButton from './AppSlotButton.vue';

export default {
  components: {
    AppButton,
    AppSlotButton,
    IconClear,
  },
  props: {
    modelValue: { type: String, required: true },
    disabled: { type: Boolean },
    autofocus: { type: Boolean },
    placeholder: { type: String },
    /** @type {'email'|'new-password'|'current-password'|'off'} */
    autocomplete: { type: String },
    spellcheck: { type: Boolean },
    /** @type {'login' | 'password' | 'promocode'} */
    name: { type: String },
    /** @type {'text'|'password' |'email'} */
    type: { type: String, default: 'text' },
    maxLength: { type: Number },
    /** @type {'primary'|'secondary'} */
    variation: { type: String, default: 'primary' },
    /** @type {'small'|'medium'|'large'} */
    size: { type: String, default: 'large' },
    withRightButton: { type: Boolean },
    buttonText: { type: String },
    hasButton: { type: Boolean, default: false },
    errorMessage: { type: String },
    buttonDataId: { type: String },
    mask: { type: String, required: false, default: undefined },
    maskOptions: { type: Object, required: false, default: () => {} },
    isAutoComplete: { type: Boolean },
    hasClearIcon: { type: Boolean },
    isCatalogSearch: { type: Boolean },
  },
  emits: ['update:modelValue', 'click', 'clear', 'autocomplete-applied', 'blur'],
  data() {
    return {
      imask: null,
    };
  },
  async mounted() {
    /** @type {HTMLInputElement} */
    const inputEl = this.$refs.inputEl;

    if (!inputEl) {
      throw new UnexpectedComponentStateError('inputEl');
    }

    if (this.$props.autofocus) {
      await nextTick();
      window.setTimeout(() => inputEl.focus(), 250);
    }
    if (Object.keys(this.maskOptions || {}).length) {
      this.createMask();
    }
  },
  methods: {
    createMask() {
      const inputEl = this.$refs.inputEl;
      this.imask = IMask(inputEl, this.maskOptions);
    },
    onBlur(event) {
      if (event) {
        this.$emit('blur', event);
      }
    },
    onButtonClick() {
      this.$emit('click');
    },
    onInput(event) {
      if (event) {
        /** @type {HTMLInputElement} */
        const target = event.target;

        // кейс когда вставляем + в начало последовательности 77777 и хотим, чтобы применилась маска
        const shouldUpdateMaskValue = event.inputType === 'insertText' && event.data === '+';
        if (this.imask && shouldUpdateMaskValue) {
          const position = target.value.length;
          this.imask.updateValue();
          // ставим курсор в конец инпута
          this.imask.cursorPos = position;
        }

        // в этот момент к target.value еще не применена маска
        // в target.value будет '7 (9999', но значение должно быть уже '7 (999) 9'
        // поэтому приходится делать таймаут
        if (this.imask) {
          window.setTimeout(() => {
            this.$emit('update:modelValue', target.value);
          });

          return;
        }

        this.$emit('update:modelValue', target.value);
      }
    },
    onClearClick() {
      this.$emit('clear');
    },
  },
};
</script>

<style module lang="scss">
@use '../styles/fonts' as fonts;

.control {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputWrapper {
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  border-radius: var(--g-border-round-12);
}

.input {
  width: 100%;
  border: none;
  border-radius: var(--g-border-round-12);
  outline: none;
  background-color: var(--color-bg-input);
  color: var(--color-text-primary);
  caret-color: var(--color-bg-accent);

  @include fonts.WebBody-2;

  &::placeholder {
    color: var(--color-text-quaternary);

    @include fonts.WebBody-2;
  }
}

.error {
  margin-top: var(--g-spacing-4);
  color: var(--color-text-negative);

  @include fonts.WebLabel-3;
}

.primary:focus-within {
  outline: 1px solid var(--color-stroke-accent);
}

.inputDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.inputPasswordHidden {
  letter-spacing: 6px;
}

.inputCatalogSearch {
  background-color: var(--color-bg-input);
}

.iconClear {
  color: var(--color-icon-tertiary);
}

.iconClearAutoComplete {
  color: var(--color-icon-accent);
}

.leadingIcon,
.trailingControl {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leadingIcon {
  left: var(--g-spacing-16);
}

.trailingControl {
  right: var(--g-spacing-16);
}

.secondary .leadingIcon {
  left: var(--g-spacing-8);
}

.large .leadingIcon,
.large .trailingIcon {
  width: var(--g-spacing-24);
  height: var(--g-spacing-24);
}

.medium .leadingIcon,
.medium .trailingIcon {
  width: var(--g-spacing-16);
  height: var(--g-spacing-16);
}

.small .leadingIcon,
.small .trailingIcon {
  width: var(--g-spacing-16);
  height: var(--g-spacing-16);
}

.large {
  height: var(--g-spacing-56);

  .input {
    padding: var(--g-spacing-16);
  }
}

.medium {
  height: var(--g-spacing-48);

  .input {
    padding: var(--g-spacing-12) var(--g-spacing-16);
  }
}

.small {
  height: var(--g-spacing-40);

  .input {
    padding: var(--g-spacing-8) var(--g-spacing-16);
  }
}

.large.withLeadingIcon {
  .input {
    padding-left: calc(var(--g-spacing-16) + var(--g-spacing-24) + var(--g-spacing-16));
  }
}

.medium.withLeadingIcon {
  .input {
    padding-left: calc(var(--g-spacing-16) + var(--g-spacing-16) + var(--g-spacing-16));
  }
}

.small.withLeadingIcon {
  .input {
    padding-left: calc(var(--g-spacing-16) + var(--g-spacing-16) + var(--g-spacing-12));
  }
}

.large.withTrailingIcon {
  .input {
    padding-right: calc(var(--g-spacing-16) + var(--g-spacing-24) + var(--g-spacing-16));
  }
}

.medium.withTrailingIcon {
  .input {
    padding-right: calc(var(--g-spacing-16) + var(--g-spacing-16) + var(--g-spacing-16));
  }
}

.small.withTrailingIcon {
  .input {
    padding-right: calc(var(--g-spacing-16) + var(--g-spacing-16) + var(--g-spacing-12));
  }
}

.small.withLeadingIcon.secondary {
  .input {
    padding-left: calc(var(--g-spacing-8) + var(--g-spacing-16) + var(--g-spacing-8));
  }
}

.hasButton {
  padding: var(--g-spacing-8) var(--g-spacing-8) var(--g-spacing-8) var(--g-spacing-16);
}

.large.hasButton {
  .input {
    padding-left: 0;
  }
}

.large.hasButton.withLeadingIcon {
  .input {
    padding-left: calc(var(--g-spacing-8) + var(--g-spacing-16) + var(--g-spacing-12));
  }
}

.button {
  width: fit-content;
  height: 100%;
}

@media (max-width: 800px) {
  .small .inputCatalogSearch {
    padding: var(--g-spacing-12) var(--g-spacing-16);
  }
}
</style>
