export interface AppConfig {
  clientCacheVersion: number;
}

export const useSystemStore = defineStore('system-store', () => {
  const isVpnDetected = ref();
  const config = ref<AppConfig>({
    clientCacheVersion: 0,
  });

  return {
    isVpnDetected,
    config,
  };
});
