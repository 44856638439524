import type { ApiMedia, ApiWatchingItem, Media } from '@package/sdk/src/api';
import { MediaMapper, WatchingItemMapper } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';

import useProfile from '@/code/profile/use-profile';
import type { LocalWatchingDbStoredItem } from '@/code/watching-items/local-watching-item-database';
import { ENDPOINTS } from '@/platform/http';
import { useSessionStore } from '@/stores/use-session-store';

interface UpdateWatchingItemOptions {
  contentId: string;
  offset: number;
  duration: number;
  token?: string;
}

export function useWatchingItemsApi() {
  const { isAuth } = storeToRefs(useSessionStore());
  const { $http, $localWatchingItemDb } = useNuxtApp();
  const { profile } = useProfile();

  const updateWatchingItem = (options: UpdateWatchingItemOptions) => {
    const { contentId, offset, duration, token } = options;
    const profileId = profile.value?.id;

    if (!profileId) {
      throw new UnexpectedComponentStateError('profileId');
    }

    // Math.trunc() для округления к секундам. API требует int.
    return $http.put<void>(
      ENDPOINTS.PROFILES_WATCHING_ITEM_CONTENTS,
      { offset: Math.trunc(offset), duration },
      {
        params: {
          profile_id: profileId,
          content_id: contentId,
        },
        headers: { HMAC: token },
      },
    );
  };

  const fetchWatchingItem = async (contentId: string) => {
    if (!contentId) {
      throw new UnexpectedComponentStateError('contentId');
    }

    if (!isAuth.value) {
      const storedLocalWatchingItem = await $localWatchingItemDb.read<LocalWatchingDbStoredItem>(contentId);

      return storedLocalWatchingItem?.value.localWatchingItem;
    }

    const profileId = profile.value?.id;

    if (!profileId) {
      throw new UnexpectedComponentStateError('profileId');
    }

    return $http
      .get<ApiWatchingItem[]>(ENDPOINTS.PROFILES_WATCHING_ITEM_CONTENTS, {
        signRequest: true,
        params: {
          profile_id: profileId,
          content_id: contentId,
        },
      })
      .then((res: ApiWatchingItem[]) => {
        if (res[0]) {
          return WatchingItemMapper.map(res[0]);
        }

        return undefined;
      });
  };

  const fetchContinueWatchItems = (): Promise<Media[]> => {
    return $http
      .get<ApiMedia[]>(ENDPOINTS.CONTENT_WATCHING_ITEMS_CONTINUE)
      .then((items: ApiMedia[]) => MediaMapper.mapMany(items, { freezeData: false }));
  };

  const hideItem = (contentId: string) => {
    const profileId = profile.value?.id;

    if (!profileId) {
      throw new UnexpectedComponentStateError('profileId');
    }

    return $http.delete<void>(ENDPOINTS.PROFILES_WATCHING_ITEM_CONTENTS, {
      params: {
        profile_id: profileId,
        content_id: contentId,
      },
    });
  };

  return { fetchContinueWatchItems, hideItem, updateWatchingItem, fetchWatchingItem };
}
