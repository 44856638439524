<template>
  <app-slot-button :class="$style.connectTvButton" @click="onClick">
    <icon-smart-tv size="small" />
    <span
      :class="{
        [$style.connectTvButtonText]: true,
        [$style.connectTvButtonTextShown]: !isActionButtonShown,
      }"
    >
      {{ $t('header.connectTV') }}
    </span>
  </app-slot-button>
</template>

<script lang="ts" setup>
import { useAccountPageAnalytics } from '@SDK/analytics';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { useHeaderActionButtons } from '@/code/promocode/use-header-action-buttons';
import IconSmartTv from '@/components/icons/IconSmartTv.vue';
import AppSlotButton from '@/components/ui/AppSlotButton.vue';
import { AppRoute } from '@/platform/router/routes';
const { $analyticSender } = useNuxtApp();
const { isActionButtonShown } = useHeaderActionButtons();

const route = useRoute();
const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

const onClick = () => {
  const page = getKmzaPageValue(route.name as AppRoute);
  accountPageAnalytics.onGotoDevicesPage(page);

  navigateTo({
    name: AppRoute.AccountDevices,
    query: route.query,
  });
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.connectTvButton {
  flex-shrink: 0;
  padding: var(--g-spacing-8) var(--g-spacing-12);
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-button-ghost-80);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-state-bg-button-hover);
  }

  @include fonts.WebLabel-2;
}

.connectTvButtonText {
  margin-left: var(--g-spacing-8);
}

@include breakpoints.max-width-1024 {
  .connectTvButtonText {
    display: none;
  }
}

@include breakpoints.max-width-800 {
  .connectTvButtonTextShown {
    display: inline;
  }
}
</style>
