import AbstractConstantsConfig from '@package/constants/code/abstract-constants-config';

const CONSTANTS = {
  requestTimeoutMsClient: 10000,
  requestTimeoutMsServer: 7500,
  defaultApiBaseUrl: 'https://api.stg.testvip.ru',
  defaultApiBaseUrlAm: 'https://api.dev.viju.am',
  defaultFlagsBaseUrl: 'https://flags.testvip.ru',
  defaultFlagsBaseUrlAm: 'https://flags.testvip.ru',
  apiLocalHostNameRls: 'https://api.rls.testvip.ru',
  apiLocalHostNameStg: 'https://api.stg.testvip.ru',
  host: '127.0.0.1',
  oAuthVkAppId: '51496104',
  oAuthYandexClientId: 'f43c468fcae14535bc63df0af5793048',
  smartCaptchaSiteKey: '2ivvGkf7b43zDXTZjG6WZdX1UPPP8LBO8DUi47iS',
  yandexCaptchaSecretKey: '2ivvGkf7b43zDXTZjG6Wvd2JyKTZYWc8IlmOfsd4',
  contentTimestampMediaNextDefault: -20,
  myChannelPerPageItems: 15,
  contentSearchMaxItems: 3,
  tvPinCodeLength: 5,
  mobileLayoutBreakpointPx: 800,
  afterSwipeMobileTimeoutMs: 50,
  myChannelDoubleTapTimeoutMs: 300,
  contentsPerPageSize: 25,
  momentsPerPageSize: 25,
  defaultDateFormat: 'DD-MM-YYYY',
  oneHourMs: 3600000,
  oneDayMs: 86400000,
  cacheVersion: 'CACHE_VERSION',
  weboramaSuccessfullyVisitorTimeoutMs: 30000,
  myChannelOnboardingPopupTimeoutMs: 6000,
  changeMomentAfterDislikeTimeoutMs: 800,
  updateVpnOnStatusTimeoutMs: 10000,
  updateVpnOffStatusTimeoutMs: 180000,
  hideLikeIconTimeoutMs: 1500,
  vpnLabelHideTimeoutMs: 10000,
  promocodeNotificationHideTimeoutMs: 3000,
  teleportPlayerSliderTimeoutMs: 250,
  myChannelSwipeOffsetPx: 200,
  scrollHeightToShowHeaderBackgroundPx: 20,
  userWatchingItemUpdateThreshold: 0.95,
  showScrollTopButtonOffsetPx: 200,
  dropdownFadeWidthPx: 16,
  dropdownTitleSpeedCoefficient: 10,
  headerParentMenu: 'data-header-parent-menu',
};

class ConstantsConfigWeb extends AbstractConstantsConfig<typeof CONSTANTS> {
  constructor(constantsMap: typeof CONSTANTS) {
    super(constantsMap);
  }
}
const ConstantsConfigInstanceWeb = new ConstantsConfigWeb(CONSTANTS);

export default ConstantsConfigInstanceWeb;
