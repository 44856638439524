import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';

export function useParentalControlAnalytics(sender: AnalyticEventSender) {
  const onAutoParentalSupportDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoParentalSupportDisplayed,
      page: AnalyticPageName.Account,
    });
  };

  const onAutoParentalKidsAccessDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoParentalKidsAccessDisplayed,
      page: AnalyticPageName.Account,
    });
  };

  const onClickParentalKidsAccessAnswer = (kidsAccess: boolean) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickParentalKidsAccessAnswer,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.KidsAccess,
          value: kidsAccess,
        },
      ],
    });
  };

  const onAutoParentalCodeCreateDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoParentalCodeCreateDisplayed,
      page: AnalyticPageName.Account,
    });
  };

  const onClickParentalCodeCreate = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickParentalCodeCreate,
      page: AnalyticPageName.Account,
    });
  };

  const onAutoParentalProfilesDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoParentalProfilesDisplayed,
      page: AnalyticPageName.Account,
    });
  };

  const onClickParentalProfileSelect = (ageLimit: number, page: AnalyticPageName) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickParentalProfileSelect,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.AgeLimit,
          value: ageLimit,
        },
      ],
    });
  };

  const onAutoParentalCodeEnterDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoParentalCodeEnterDisplayed,
      page: AnalyticPageName.Account,
    });
  };

  const onAutoParentalCodeInvalid = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoParentalCodeInvalid,
      page: AnalyticPageName.Account,
    });
  };

  return {
    onAutoParentalSupportDisplayed,
    onAutoParentalKidsAccessDisplayed,
    onClickParentalKidsAccessAnswer,
    onAutoParentalCodeCreateDisplayed,
    onClickParentalCodeCreate,
    onAutoParentalProfilesDisplayed,
    onClickParentalProfileSelect,
    onAutoParentalCodeEnterDisplayed,
    onAutoParentalCodeInvalid,
  };
}
