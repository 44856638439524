<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H9.75C10.1642 1.5 10.5 1.83579 10.5 2.25V7.75C10.5 8.16421 10.1642 8.5 9.75 8.5H6.625V9.375H8.5C8.84518 9.375 9.125 9.65482 9.125 10C9.125 10.3452 8.84518 10.625 8.5 10.625H6H3.5C3.15482 10.625 2.875 10.3452 2.875 10C2.875 9.65482 3.15482 9.375 3.5 9.375H5.375V8.5H2.25C1.83579 8.5 1.5 8.16421 1.5 7.75V2.25Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.25 2.375C1.25 1.75368 1.75368 1.25 2.375 1.25H13.625C14.2463 1.25 14.75 1.75368 14.75 2.375V10.625C14.75 11.2463 14.2463 11.75 13.625 11.75H8.875V13.125H11.75C12.2332 13.125 12.625 13.5168 12.625 14C12.625 14.4832 12.2332 14.875 11.75 14.875H8H4.25C3.76675 14.875 3.375 14.4832 3.375 14C3.375 13.5168 3.76675 13.125 4.25 13.125H7.125V11.75H2.375C1.75368 11.75 1.25 11.2463 1.25 10.625V2.375Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 4.5C3 3.67157 3.67157 3 4.5 3H19.5C20.3284 3 21 3.67157 21 4.5V15.5C21 16.3284 20.3284 17 19.5 17H13V19H17C17.5523 19 18 19.4477 18 20C18 20.5523 17.5523 21 17 21H12H7C6.44772 21 6 20.5523 6 20C6 19.4477 6.44772 19 7 19H11V17H4.5C3.67157 17 3 16.3284 3 15.5V4.5Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.25 5.375C3.25 4.2014 4.2014 3.25 5.375 3.25H26.625C27.7986 3.25 28.75 4.2014 28.75 5.375V20.9583C28.75 22.1319 27.7986 23.0833 26.625 23.0833H17.25V26.0833H23.0833C23.7737 26.0833 24.3333 26.643 24.3333 27.3333C24.3333 28.0237 23.7737 28.5833 23.0833 28.5833H16H8.91667C8.22631 28.5833 7.66667 28.0237 7.66667 27.3333C7.66667 26.643 8.22631 26.0833 8.91667 26.0833H14.75V23.0833H5.375C4.20139 23.0833 3.25 22.1319 3.25 20.9583V5.375Z"
          fill="currentColor"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.375 2.25C1.375 1.76675 1.76675 1.375 2.25 1.375H9.75C10.2332 1.375 10.625 1.76675 10.625 2.25V7.75C10.625 8.23325 10.2332 8.625 9.75 8.625H6.625V9.375H8.5C8.84518 9.375 9.125 9.65482 9.125 10C9.125 10.3452 8.84518 10.625 8.5 10.625H6H3.5C3.15482 10.625 2.875 10.3452 2.875 10C2.875 9.65482 3.15482 9.375 3.5 9.375H5.375V8.625H2.25C1.76675 8.625 1.375 8.23325 1.375 7.75V2.25ZM6 7.375H9.375V2.625H2.625V7.375H6Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.125 2.375C1.125 1.68464 1.68464 1.125 2.375 1.125H13.625C14.3154 1.125 14.875 1.68464 14.875 2.375V10.625C14.875 11.3154 14.3154 11.875 13.625 11.875H8.875V13.125H11.75C12.2332 13.125 12.625 13.5168 12.625 14C12.625 14.4832 12.2332 14.875 11.75 14.875H8H4.25C3.76675 14.875 3.375 14.4832 3.375 14C3.375 13.5168 3.76675 13.125 4.25 13.125H7.125V11.875H2.375C1.68464 11.875 1.125 11.3154 1.125 10.625V2.375ZM8 10.125H13.125V2.875H2.875V10.125H8Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 4.5C3 3.67157 3.67157 3 4.5 3H19.5C20.3284 3 21 3.67157 21 4.5V15.5C21 16.3284 20.3284 17 19.5 17H13V19H17C17.5523 19 18 19.4477 18 20C18 20.5523 17.5523 21 17 21H12H7C6.44772 21 6 20.5523 6 20C6 19.4477 6.44772 19 7 19H11V17H4.5C3.67157 17 3 16.3284 3 15.5V4.5ZM12 15H19V5H5V15H12Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.41675 5.37484C3.41675 4.29328 4.29352 3.4165 5.37508 3.4165H26.6251C27.7066 3.4165 28.5834 4.29328 28.5834 5.37484V20.9582C28.5834 22.0397 27.7066 22.9165 26.6251 22.9165H17.2501V26.0832H23.0834C23.7738 26.0832 24.3334 26.6428 24.3334 27.3332C24.3334 28.0235 23.7738 28.5832 23.0834 28.5832H16.0001H8.91675C8.22639 28.5832 7.66675 28.0235 7.66675 27.3332C7.66675 26.6428 8.22639 26.0832 8.91675 26.0832H14.7501V22.9165H5.37508C4.29352 22.9165 3.41675 22.0397 3.41675 20.9582V5.37484ZM16.0001 20.4165H26.0834V5.9165H5.91675V20.4165H16.0001Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
