import { getCurrencyByType } from '@package/sdk/src/api';
import type { SubscriptionInfoV2 } from '@SDK/api';
import { UserSubscriptionStatus, UserSubscriptionStore } from '@SDK/api/subscriptions/types/subscriptionV2-enums';
import { isAfter } from 'date-fns';

export const useSubscriptionStore = defineStore('subscription', () => {
  const subscription = ref<SubscriptionInfoV2>();

  const setSubscriptionData = (value: SubscriptionInfoV2) => {
    subscription.value = value;
  };

  const resetSubscriptionData = () => {
    subscription.value = undefined;
  };

  const isSubscriptionActive = computed(() => Boolean(subscription.value?.isActive));
  const isSubscriptionExists = computed(() => Boolean(subscription.value));

  const subscriptionStoreType = computed(() => subscription.value?.store);

  const isSubscriptionTrial = computed(() => subscription.value?.status === UserSubscriptionStatus.Trial);
  const isSubscriptionNormal = computed(() => subscription.value?.status === UserSubscriptionStatus.NormalPeriod);
  const isSubscriptionGrace = computed(() => subscription.value?.status === UserSubscriptionStatus.GracePeriod);
  const isSubscriptionInitial = computed(() => subscription.value?.status === UserSubscriptionStatus.Initial);
  const isSubscriptionCanceled = computed(() => subscription.value?.status === UserSubscriptionStatus.Canceled);

  const isSubscriptionPartner = computed(() => subscriptionStoreType.value === UserSubscriptionStore.Partner);
  const isSubscriptionApple = computed(() => subscriptionStoreType.value === UserSubscriptionStore.Apple);
  const isSubscriptionGoogle = computed(() => subscriptionStoreType.value === UserSubscriptionStore.Google);
  const isSubscriptionSber = computed(() => subscriptionStoreType.value === UserSubscriptionStore.Sber);
  const isSubscriptionViju = computed(() => subscriptionStoreType.value === UserSubscriptionStore.Viju);

  const isSubscriptionRecurrent = computed(() => subscription.value?.isRenew);
  const isPaymentCardLinked = computed(() => subscription.value?.isCardLinked && isSubscriptionViju.value);

  const isSubscriptionSberRecurrent = computed(() => isSubscriptionSber.value && isSubscriptionRecurrent.value);

  const isSubscriptionManaged = computed(
    () => isSubscriptionActive.value && (isPaymentCardLinked.value || isSubscriptionSberRecurrent.value),
  );

  const isSubscriptionFromMobileStore = computed(() => isSubscriptionApple.value || isSubscriptionGoogle.value);

  const subscriptionCurrency = computed(() =>
    subscription.value ? getCurrencyByType(subscription.value.currency) : '',
  );
  const subscriptionBasePrice = computed(() => subscription.value?.baseAmount);
  const subscriptionNextPaymentAmount = computed(() => subscription.value?.nextPaymentAmount);

  const isSubscriptionOutdated = computed(() =>
    subscription.value ? isAfter(new Date(), new Date(subscription.value.endsAt)) : true,
  );

  return {
    subscription,
    setSubscriptionData,
    resetSubscriptionData,
    isSubscriptionActive,
    isSubscriptionExists,
    subscriptionStoreType,
    isSubscriptionTrial,
    isSubscriptionNormal,
    isSubscriptionGrace,
    isSubscriptionInitial,
    isSubscriptionCanceled,
    isSubscriptionRecurrent,
    isPaymentCardLinked,
    isSubscriptionPartner,
    isSubscriptionApple,
    isSubscriptionGoogle,
    isSubscriptionSber,
    isSubscriptionViju,
    isSubscriptionManaged,
    isSubscriptionSberRecurrent,
    isSubscriptionFromMobileStore,
    subscriptionCurrency,
    subscriptionBasePrice,
    subscriptionNextPaymentAmount,
    isSubscriptionOutdated,
  };
});
