import type { ApiMomentPlaylist, MomentPlaylist } from '@package/sdk/src/api';
import { MomentPlaylistMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useMomentPlaylistsApi() {
  const { $http } = useNuxtApp();

  const fetchPlaylist = (id: string): Promise<MomentPlaylist> => {
    return $http
      .get<ApiMomentPlaylist>(ENDPOINTS.MOMENTS_PLAYLISTS_ID, { params: { id } })
      .then(MomentPlaylistMapper.map);
  };

  return { fetchPlaylist };
}
