import type { ApiMedia, Media } from '@package/sdk/src/api';
import { MediaMapper } from '@package/sdk/src/api';

import { PLATFORM_NAME } from '@/code/environment/platform';
import { ENDPOINTS } from '@/platform/http';

export function useSearchApi() {
  const { $http } = useNuxtApp();

  const getContentSemanticSearch = (searchText: string, visitorId?: string, semantic = false): Promise<Media[]> => {
    return $http
      .get<ApiMedia[]>(ENDPOINTS.CONTENT_SEARCH_SEMANTIC, {
        query: {
          term: searchText,
          visitor_id: visitorId,
          page: 1,
          perPage: 30,
          limit: 30,
          fully_typed_query: semantic,
          device_type: PLATFORM_NAME,
        },
      })
      .then(MediaMapper.mapMany);
  };

  const cancelSemanticSearchRequest = () => {
    return $http.cancelRequest(ENDPOINTS.CONTENT_SEARCH_SEMANTIC.path);
  };

  return { getContentSemanticSearch, cancelSemanticSearchRequest };
}
