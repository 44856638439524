import useLogger from '@package/logger/src/use-logger';

import { useUsersApi } from '@/code/user/use-users-api';
import { CookieName, cookies } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { useJwtDecoder } from '@/platform/network/use-jwt-decoder';
import { useUserStore } from '@/stores/use-user-store';

export function useUserActions() {
  const jwtDecoder = useJwtDecoder();
  const authTokensCookie = useAppCookie(CookieName.Auth, { maxAge: cookies.auth.maxAge, path: '/' });
  const userApi = useUsersApi();
  const logger = useLogger('use-user-actions');

  const { setUserLite } = useUserStore();

  async function fetchUserLite() {
    if (!authTokensCookie.value) {
      return;
    }

    const tokenPayload = jwtDecoder.decode(authTokensCookie.value.token);
    const userId = tokenPayload?.user_id;

    if (userId) {
      try {
        const userLiteData = await userApi.fetchCurrentUserLite(userId);
        setUserLite(userLiteData);
      } catch (error) {
        logger.error(error);
      }
    }
  }

  return {
    fetchUserLite,
  };
}
