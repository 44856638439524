<template>
  <app-sdk-img
    v-if="normalizedSrc"
    :alt="alt"
    :image-class="imageClass || undefined"
    :fetchpriority="imageFetchPriority"
    :loading="loading"
    :src="normalizedSrc"
    :with-src-set="withSrcSet"
    :srcset="srcSet"
    @error="onImageLoadingError"
    @load="onLoad"
  />
</template>

<script lang="ts" setup>
import CDNImageManager from '@package/content-utils/src/code/cdn/cdn-image-manager';
import AppSdkImg from '@package/ui/src/components/AppImg.vue';
import * as Sentry from '@sentry/browser';

import type { ImageFetchPriority } from '@/code/image/image-fetch-priority';
import { ImageFetchingError } from '@/code/image/image-fetching-error';
import useEnvironment from '@/platform/environment/use-environment';
import { Stub } from '@/platform/image/Img';
import { useHostname } from '@/platform/network/use-hostname';

const props = withDefaults(
  defineProps<{
    width?: number;
    src: string;
    preSrc?: string;
    alt?: string;
    useStub?: boolean;
    kinomStub?: boolean;
    beltStub?: boolean;
    lazy?: boolean;
    imageClass?: string;
    withSrcSet?: boolean;
    imageFetchPriority?: ImageFetchPriority;
  }>(),
  {
    width: 0,
    useStub: false,
    kinomStub: false,
    beltStub: false,
    lazy: true,
    alt: '',
    src: '',
    withSrcSet: true,
    imageFetchPriority: 'auto',
  },
);

if (props.src) {
  CDNImageManager.addSource(props.src, {
    src: props.src,
    width: props.width,
  });
}

const RETINA_SIZES = ['2x', '3x'];

const route = useRoute();
const hostName = useHostname();
const { isRelease } = useEnvironment();

const loading = computed(() => (props.lazy ? 'lazy' : 'eager'));

const normalizedSrc = computed(() => {
  const src = props.src;

  if (!src || loadingError.value) {
    return '';
  }

  if (!props.width || !props.withSrcSet) {
    return src;
  }

  return `${src}?w=${props.width}`;
});

const srcSet = computed(() =>
  RETINA_SIZES.reduce((a, b, currentIndex) => {
    const normalizedIndex = currentIndex + 2;

    const sizeSrc = `${props.src}?w=${props.width * normalizedIndex} ${b}`;
    const _a = a || normalizedSrc.value;

    return _a + ', ' + sizeSrc;
  }, ''),
);

const stub = computed(() => {
  if (props.kinomStub) {
    return Stub.Kinom;
  } else if (props.beltStub) {
    return Stub.Belt;
  }

  return Stub.Default;
});

const showStubDefault = computed(() => props.useStub && !props.src);
const getSource = (src: string) => {
  if (!props.width) {
    return src;
  }

  return props.width > 0 ? `${src}?w=${props.width}` : src || (showStubDefault.value ? stub.value : '');
};

const loadingError = ref(showStubDefault.value);

// @NOTE: если включена ленивая загрузка и имеется шкальная картинка,
// то показываем ее
const innerSrc = ref(getSource(props.lazy && props.preSrc ? props.preSrc : props.src));

// @NOTE: после загрузки шакальной картинки при ленивой загрузке,
// меняем ее на оригинальную картинку
const onLoad = () => {
  if (!props.lazy || loadingError.value) {
    return;
  }

  innerSrc.value = getSource(props.src);
};

const onImageLoadingError = () => {
  if (isRelease) {
    const payload = {
      pageUrl: `${hostName}${route.fullPath}`,
      imageUrl: normalizedSrc.value,
    };

    const error = new ImageFetchingError(payload);
    Sentry.captureException(error);
  }

  if (!props.useStub) {
    return;
  }

  innerSrc.value = stub.value;
  loadingError.value = true;
};

watch(
  () => props.src,
  () => {
    innerSrc.value = getSource(props.lazy && props.preSrc ? props.preSrc : props.src);
    loadingError.value = false;
  },
);
</script>
