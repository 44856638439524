<template>
  <div ref="playerEl" :class="{ [$style.player]: true }" />
</template>

<script lang="ts" setup>
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';

import type { CommonMoment } from '@/code/moments/moments';
import useAsyncPlayer from '@/code/player/use-async-player';
import usePlayerEventHooks from '@/code/player/use-player-event-hooks';
import useMobile from '@/platform/layout/use-mobile';

const props = defineProps<{
  moment: CommonMoment;
}>();

const emit = defineEmits<{
  (e: 'can-play', player: VijuPlayer): void;
  (e: 'ready', player: VijuPlayer): void;
  (e: 'ended'): void;
}>();

const playerEl = ref<HTMLElement>();

const { loadPlayer } = useAsyncPlayer();
const isMobile = useMobile();
const { WebVijuPlayer } = await loadPlayer();

const player = new WebVijuPlayer({
  posterSrc: props.moment.preview,
  projector: 'my-channel',
  loop: false,
  autoplay: true,
  preload: 'auto',
  autoStartLoad: true,
  muted: true,
});

markRaw(player);

usePlayerEventHooks({ player });

player.setConfigProperties({
  'content.media': props.moment,
  'experimental.feature.customIOSFullscreen': false,
  'experimental.feature.ambientMode': !isMobile,
  'experimental.feature.viewportIntersectionPlayback': false,
  'experimental.feature.lazyInteractionLoading': false,
  'video.controls': {
    volume: true,
    videoState: true,
    skipPlayback: false,
    fullscreen: false,
  },
});

player.on('ended', () => emit('ended'));

watch(
  () => props.moment,
  (value) => {
    if (!props.moment.hls) {
      throw new UnexpectedComponentStateError('moment.hls');
    }

    player.setConfigProperty('video.poster', value.preview);
    player.setConfigProperty('content.media', value);
    player.load({ src: props.moment.hls, id: props.moment.id });
  },
);

player.on('can-play', () => {
  emit('can-play', player);
  player.play();
});

onMounted(async () => {
  player.mount(playerEl.value as HTMLElement);

  await nextTick();

  if (!props.moment.hls) {
    throw new UnexpectedComponentStateError('moment.hls');
  }

  player.load({ src: props.moment.hls });
  emit('ready', player);
});

onBeforeUnmount(() => {
  player.dispose();
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.carousel {
  position: relative;
  display: block;
  height: fit-content;
}

.player {
  position: absolute;
  z-index: var(--z-index-moment-card-player);
  width: 100%;
  height: 100%;
}

.playerHidden {
  visibility: hidden;
}

@include breakpoints.max-width-1024 {
  .player {
    & > div {
      border-radius: 0;
    }
  }
}
</style>
