import useLogger from '@package/logger/src/use-logger';
import { getWebDetailedDeviceName } from '@package/sdk/src/core';
import * as Sentry from '@sentry/browser';
import { v4 } from 'uuid';

import { getOperationSystemName, PLATFORM_NAME } from '@/code/environment/platform';
import type { IUserCreateSession } from '@/code/user/use-users-api';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import useEnvironment from '@/platform/environment/use-environment';

const logger = useLogger('get-device-info.ts');

export default function getDeviceInfo(): IUserCreateSession {
  const os = getOperationSystemName();
  const { browserName, browserVersion, osVersion } = getWebDetailedDeviceName();
  const { appVersion } = useEnvironment();
  const visitorIdCookie = useAppCookie(CookieName.VisitorId);

  const deviceName = (() => {
    if (!browserVersion) {
      return `v.${osVersion} / ${browserName}`;
    }

    return `v.${osVersion} / ${browserName} v.${browserVersion}`;
  })();

  if (!visitorIdCookie.value) {
    Sentry.captureException({
      message: 'Getting visitor-id from cookies to use as device uid, but no value is present',
    });

    logger.error('visitorIdCookie not present');

    visitorIdCookie.value = v4();
  }

  return {
    device: {
      type: PLATFORM_NAME,
      os,
      app_version: appVersion,
      name: deviceName,
      uid: visitorIdCookie.value,
    },
  };
}
