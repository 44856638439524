import type { ApiCountry } from '@package/sdk/src/api';
import { CountryMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useCountriesApi() {
  const { $http } = useNuxtApp();

  const fetchCountries = async () => {
    try {
      const data = await $http.get<ApiCountry[]>(ENDPOINTS.COUNTRIES).then(CountryMapper.mapMany);
      return data;
    } catch (error) {
      return [];
    }
  };

  return { fetchCountries };
}
