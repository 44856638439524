<template>
  <app-button
    :class="$style.promocodeButton"
    :title="$t('ariaLabel.header.promocodeButton')"
    :aria-label="$t('ariaLabel.header.promocodeButton')"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'promocodeButton')"
    :text="text"
    size="small"
    variation="button-secondary"
    @click="onPromocodeButtonClick()"
  >
    <icon-gift v-if="isGiftIconShown" size="small" />
  </app-button>
</template>

<script lang="ts" setup>
import { useHeaderActionButtons } from '@/code/promocode/use-header-action-buttons';
import IconGift from '@/components/icons/IconGift.vue';
import AppButton from '@/components/ui/AppButton.vue';

const { isPromocodeButtonShown, onPromocodeButtonClick } = useHeaderActionButtons();

defineProps<{
  isGiftIconShown: boolean;
  text: string;
}>();

defineExpose({
  isPromocodeButtonShown,
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.promocodeButton {
  flex-shrink: 0;
  height: 40px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-button-ghost-80);
  white-space: nowrap;

  &:hover:not([disabled]) {
    background-color: var(--color-state-bg-button-hover);
  }
}
</style>
