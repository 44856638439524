<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 1.5C1.83579 1.5 1.5 1.83579 1.5 2.25V9.75C1.5 10.1642 1.83579 10.5 2.25 10.5H6.75C7.16421 10.5 7.5 10.1642 7.5 9.75L7.5 6.625H8.4999C8.31583 6.86984 8.33521 7.2191 8.55806 7.44194C8.80214 7.68602 9.19786 7.68602 9.44194 7.44194L10.4419 6.44194C10.686 6.19786 10.686 5.80214 10.4419 5.55806L9.44194 4.55806C9.19786 4.31398 8.80214 4.31398 8.55806 4.55806C8.33521 4.7809 8.31583 5.13016 8.4999 5.375H7.5V2.25C7.5 1.83579 7.16421 1.5 6.75 1.5H2.25Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.375 1.25C1.75368 1.25 1.25 1.75368 1.25 2.375V13.625C1.25 14.2463 1.75368 14.75 2.375 14.75H9.125C9.74632 14.75 10.25 14.2463 10.25 13.625V8.875L11.8876 8.875L11.8813 8.88128C11.5396 9.22299 11.5396 9.77701 11.8813 10.1187C12.223 10.4604 12.777 10.4604 13.1187 10.1187L14.6187 8.61872C14.9604 8.27701 14.9604 7.72299 14.6187 7.38128L13.1187 5.88128C12.777 5.53957 12.223 5.53957 11.8813 5.88128C11.5396 6.22299 11.5396 6.77701 11.8813 7.11872L11.8876 7.125H10.25V2.375C10.25 1.75368 9.74632 1.25 9.125 1.25H2.375Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.5 3C3.67157 3 3 3.67157 3 4.5V19.5C3 20.3284 3.67157 21 4.5 21H13.5C14.3284 21 15 20.3284 15 19.5L15 13L17.5858 13L17.2929 13.2929C16.9024 13.6834 16.9024 14.3166 17.2929 14.7071C17.6834 15.0976 18.3166 15.0976 18.7071 14.7071L20.7071 12.7071C21.0976 12.3166 21.0976 11.6834 20.7071 11.2929L18.7071 9.29289C18.3166 8.90237 17.6834 8.90237 17.2929 9.29289C16.9024 9.68342 16.9024 10.3166 17.2929 10.7071L17.5858 11H15V4.5C15 3.67157 14.3284 3 13.5 3H4.5Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.375 3.25C4.20139 3.25 3.25 4.2014 3.25 5.375V26.625C3.25 27.7986 4.20139 28.75 5.375 28.75H18.125C19.2986 28.75 20.25 27.7986 20.25 26.625L20.25 17.25H24.3156L23.6161 17.9494C23.128 18.4376 23.128 19.2291 23.6161 19.7172C24.1043 20.2054 24.8957 20.2054 25.3839 19.7172L28.2172 16.8839C28.7054 16.3957 28.7054 15.6043 28.2172 15.1161L25.3839 12.2828C24.8957 11.7946 24.1043 11.7946 23.6161 12.2828C23.128 12.7709 23.128 13.5624 23.6161 14.0505L24.3156 14.75H20.25L20.25 5.375C20.25 4.20139 19.2986 3.25 18.125 3.25H5.375Z"
          fill="currentColor"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.75 9.25V2.75H6.25V9.25H2.75ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H6.75C7.16421 1.5 7.5 1.83579 7.5 2.25V5.375H8.4999C8.31583 5.13016 8.33521 4.7809 8.55806 4.55806C8.80214 4.31398 9.19786 4.31398 9.44194 4.55806L10.4419 5.55806C10.686 5.80214 10.686 6.19786 10.4419 6.44194L9.44194 7.44194C9.19786 7.68602 8.80214 7.68602 8.55806 7.44194C8.33521 7.2191 8.31583 6.86984 8.4999 6.625H7.5L7.5 9.75C7.5 10.1642 7.16421 10.5 6.75 10.5H2.25C1.83579 10.5 1.5 10.1642 1.5 9.75V2.25Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 13V3H8.5V13H3ZM1.25 2.375C1.25 1.75368 1.75368 1.25 2.375 1.25H9.125C9.74632 1.25 10.25 1.75368 10.25 2.375V7.125H11.8876L11.8813 7.11872C11.5396 6.77701 11.5396 6.22299 11.8813 5.88128C12.223 5.53957 12.777 5.53957 13.1187 5.88128L14.6187 7.38128C14.9604 7.72299 14.9604 8.27701 14.6187 8.61872L13.1187 10.1187C12.777 10.4604 12.223 10.4604 11.8813 10.1187C11.5396 9.77701 11.5396 9.22299 11.8813 8.88128L11.8876 8.875L10.25 8.875V13.625C10.25 14.2463 9.74632 14.75 9.125 14.75H2.375C1.75368 14.75 1.25 14.2463 1.25 13.625V2.375Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 19V5H13V19H5ZM3 4.5C3 3.67157 3.67157 3 4.5 3H13.5C14.3284 3 15 3.67157 15 4.5V11H17.5858L17.2929 10.7071C16.9024 10.3166 16.9024 9.68342 17.2929 9.29289C17.6834 8.90237 18.3166 8.90237 18.7071 9.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L18.7071 14.7071C18.3166 15.0976 17.6834 15.0976 17.2929 14.7071C16.9024 14.3166 16.9024 13.6834 17.2929 13.2929L17.5858 13L15 13L15 19.5C15 20.3284 14.3284 21 13.5 21H4.5C3.67157 21 3 20.3284 3 19.5V4.5Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.75 26.25V5.75H17.75V26.25H5.75ZM3.25 5.375C3.25 4.2014 4.20139 3.25 5.375 3.25H18.125C19.2986 3.25 20.25 4.20139 20.25 5.375L20.25 14.75H24.3156L23.6161 14.0505C23.128 13.5624 23.128 12.7709 23.6161 12.2828C24.1043 11.7946 24.8957 11.7946 25.3839 12.2828L28.2172 15.1161C28.7054 15.6043 28.7054 16.3957 28.2172 16.8839L25.3839 19.7172C24.8957 20.2054 24.1043 20.2054 23.6161 19.7172C23.128 19.2291 23.128 18.4376 23.6161 17.9494L24.3156 17.25H20.25L20.25 26.625C20.25 27.7986 19.2986 28.75 18.125 28.75H5.375C4.20139 28.75 3.25 27.7986 3.25 26.625V5.375Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
