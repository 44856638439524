import useLogger from '@package/logger/src/use-logger';

export default defineNuxtPlugin({
  name: 'nuxt:chunk-reload',
  setup(nuxtApp) {
    const router = useRouter();
    const logger = useLogger('fetch-chunk-error');

    const chunkErrors = new Set();

    router.beforeEach(() => chunkErrors.clear());

    // @ts-expect-error
    nuxtApp.hook('app:chunkError', ({ error }) => chunkErrors.add(error));

    router.onError((error: Error) => logger.error(error));
  },
});
