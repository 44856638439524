<template>
  <app-modal-wrapper v-if="step !== ParentalControlStep.Hidden" :with-padding="withPadding">
    <parental-control-modal-children-have-access-prompt
      v-if="step === ParentalControlStep.ChildrenHaveAccessPrompt"
      @confirm="onChildrenHaveAccessConfirm"
    />

    <choose-profile-modal-prompt
      v-else-if="step === ParentalControlStep.ChooseProfile"
      :is-profile-button-disabled="isChooseProfileButtonDisabled"
      @selected="onProfileSelected"
    />

    <pin-code-modal-prompt
      v-else-if="step === ParentalControlStep.SetPinCode"
      :is-logout-button-shown="isLogoutButtonShown"
      :is-request-loading="isSetPinCodeRequestLoading"
      @set="onPinCodeSet"
      @cancel="step = ParentalControlStep.ChooseProfile"
    />

    <check-pin-code-modal-prompt
      v-else-if="step === ParentalControlStep.CheckPinCode"
      @success="onSuccessPinCode"
      @unsuccessful="onUnsuccessfulPinCode"
    />

    <parental-control-pin-code-success-prompt
      v-else-if="step === ParentalControlStep.Success"
      :pin-code="pinCode"
      @ok="onSuccessOk"
    />
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';
import type { Profile } from '@package/sdk/src/api';
import {
  ParentalControlModalState,
  ParentalControlStatus,
  ParentalControlStep,
  ProfileType,
} from '@package/sdk/src/api';

import { useTvChannels } from '@/code/channels/use-tv-channels';
import useProfile from '@/code/profile/use-profile';
import CheckPinCodeModalPrompt from '@/components/parental-control/CheckPinCodeModalPrompt.vue';
import ChooseProfileModalPrompt from '@/components/parental-control/ChooseProfileModalPrompt.vue';
import ParentalControlModalChildrenHaveAccessPrompt from '@/components/parental-control/ParentalControlModalChildrenHaveAccessPrompt.vue';
import ParentalControlPinCodeSuccessPrompt from '@/components/parental-control/ParentalControlPinCodeSuccessPrompt.vue';
import PinCodeModalPrompt from '@/components/parental-control/PinCodeModalPrompt.vue';
import { toPlainObject } from '@/platform/base/string';
import { BroadcastChannelEvent } from '@/plugins/broadcast-channel';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import useParentalControlStore from '@/stores/use-parental-control-store';
import { useSessionStore } from '@/stores/use-session-store';
import { useUserStore } from '@/stores/use-user-store';

const parentalControlStore = useParentalControlStore();
const tvChannels = useTvChannels();

const { step, isLogoutButtonShown, pinCode } = storeToRefs(parentalControlStore);

const { $broadcastChannel, $emitter, $analyticSender } = useNuxtApp();

const pinCodeWasSettled = ref(false);

const isChooseProfileButtonDisabled = ref(false);

const {
  onClickParentalCodeCreate,
  onClickParentalProfileSelect,
  onAutoParentalCodeInvalid,
  onClickParentalKidsAccessAnswer,
} = useParentalControlAnalytics($analyticSender);

const { mainProfile, parentalControlModalState: modalState } = useProfile();

const sessionStore = useSessionStore();
const layoutStore = useLayoutStore();

const { isAccountParentalControlSettled, currentDeviceParentalControlStatus } = storeToRefs(sessionStore);

const { isCanceledSubscription, isSubscriptionExists, isSubscriptionOutdated } = storeToRefs(useOffersStore());
const { isUserWithActiveSubscription } = storeToRefs(useUserStore());

const withPadding = computed(
  () => !(step.value === ParentalControlStep.Success || step.value === ParentalControlStep.ChildrenHaveAccessPrompt),
);

const onChildrenHaveAccessConfirm = async (childrenHaveAccess: boolean) => {
  onClickParentalKidsAccessAnswer(childrenHaveAccess);

  if (!childrenHaveAccess) {
    await sessionStore.setParentalControlStatus({ isChildrenAccess: false });
    modalState.value = ParentalControlModalState.ChosenProfile;
    step.value = ParentalControlStep.Hidden;

    layoutStore.setCurrentModalName(null);

    return;
  }

  modalState.value = ParentalControlModalState.HasChildren;

  if (!isAccountParentalControlSettled.value) {
    step.value = ParentalControlStep.SetPinCode;
    return;
  }

  if (isAccountParentalControlSettled.value) {
    await sessionStore.setParentalControlStatus({ isChildrenAccess: true });
    step.value = ParentalControlStep.ChooseProfile;
  }
};

const onProfileSelected = async (profile: Profile) => {
  isChooseProfileButtonDisabled.value = true;
  onClickParentalProfileSelect(profile.ageLimit);

  if (profile.kind === ProfileType.CHILD) {
    // сменили профиль
    await sessionStore.setProfile(profile);

    await tvChannels.fetchTvChannels();

    // обновляем объект сессии (так как из-за профиля выше, обновился токен)
    await sessionStore.loadSession({ forceRefreshToken: true });

    // чистим киномы в моем канале, чтобы загрузить только детские
    clearNuxtData('my-channel');

    layoutStore.forceReRenderPage();

    modalState.value = ParentalControlModalState.ChosenProfile;
    step.value = ParentalControlStep.Hidden;

    $broadcastChannel.postMessage(toPlainObject({ event: BroadcastChannelEvent.ParentalControl, payload: profile }));

    return;
  }

  if (isAccountParentalControlSettled.value) {
    step.value = ParentalControlStep.CheckPinCode;
    return;
  }

  step.value =
    currentDeviceParentalControlStatus.value === ParentalControlStatus.ChildrenAccess
      ? ParentalControlStep.CheckPinCode
      : ParentalControlStep.SetPinCode;

  isChooseProfileButtonDisabled.value = false;
};

const onPinCodeSet = (newPinCode: string) => {
  parentalControlStore.setPinCode(newPinCode);

  return onPinCodeRepeated();
};

const isSetPinCodeRequestLoading = ref(false);

const onPinCodeRepeated = async () => {
  isSetPinCodeRequestLoading.value = true;

  try {
    await sessionStore.setParentalControlStatus({
      code: pinCode.value,
      isChildrenAccess: true,
    });

    await sessionStore.setProfile(mainProfile.value as Profile);
    await sessionStore.refreshSession();

    pinCodeWasSettled.value = true;

    step.value = ParentalControlStep.Success;
    onClickParentalCodeCreate();
  } finally {
    isSetPinCodeRequestLoading.value = false;
  }
};

const onUnsuccessfulPinCode = () => {
  onAutoParentalCodeInvalid();
};

const onSuccessPinCode = async () => {
  if (currentDeviceParentalControlStatus.value === ParentalControlStatus.NotSet) {
    await sessionStore.setParentalControlStatus({ isChildrenAccess: true });
  }

  await sessionStore.setProfile(mainProfile.value as Profile);
  await tvChannels.fetchTvChannels();
  await sessionStore.refreshSession();

  $broadcastChannel.postMessage(
    toPlainObject({ event: BroadcastChannelEvent.ParentalControl, payload: mainProfile.value }),
  );

  modalState.value = ParentalControlModalState.ChosenProfile;
  step.value = ParentalControlStep.Hidden;

  $emitter.emit('app.modal.parental-control.pin-code-success');
};

const onSuccessOk = async () => {
  parentalControlStore.setPinCode('');

  if (pinCodeWasSettled.value) {
    modalState.value = ParentalControlModalState.ChosenProfile;
    step.value = ParentalControlStep.Hidden;
    await sessionStore.refreshSession();
    layoutStore.setCurrentModalName(null);
    return;
  }

  step.value = ParentalControlStep.ChooseProfile;
};

const initializeStep = () => {
  if (step.value !== ParentalControlStep.Hidden) {
    return;
  }

  if (layoutStore.currentModalName === 'ParentalControlModal') {
    layoutStore.setCurrentModalName(null);
  }
};

watch(
  [modalState, isSubscriptionExists, isSubscriptionOutdated, isCanceledSubscription, isUserWithActiveSubscription],
  () => {
    initializeStep();
  },
  { immediate: true },
);
</script>
