<template>
  <app-icon :width="bounds.width" :height="bounds.height">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          d="M0 3.76618C0 4.10718 0.135459 4.4342 0.376577 4.67532C0.617695 4.91644 0.944722 5.0519 1.28571 5.0519C1.62671 5.0519 1.95373 4.91644 2.19485 4.67532C2.43597 4.4342 2.57143 4.10718 2.57143 3.76618C2.57143 3.42519 2.43597 3.09816 2.19485 2.85705C1.95373 2.61593 1.62671 2.48047 1.28571 2.48047C0.944722 2.48047 0.617695 2.61593 0.376577 2.85705C0.135459 3.09816 0 3.42519 0 3.76618ZM9.42857 3.76618C9.42857 4.10718 9.56403 4.4342 9.80515 4.67532C10.0463 4.91644 10.3733 5.0519 10.7143 5.0519C11.0553 5.0519 11.3823 4.91644 11.6234 4.67532C11.8645 4.4342 12 4.10718 12 3.76618C12 3.42519 11.8645 3.09816 11.6234 2.85705C11.3823 2.61593 11.0553 2.48047 10.7143 2.48047C10.3733 2.48047 10.0463 2.61593 9.80515 2.85705C9.56403 3.09816 9.42857 3.42519 9.42857 3.76618ZM10.0714 6.76618H8.78304C8.67054 6.76618 8.57411 6.8519 8.56607 6.9644C8.46428 8.29029 7.35268 9.33761 6 9.33761C4.64732 9.33761 3.53304 8.29029 3.43393 6.9644C3.42589 6.8519 3.32946 6.76618 3.21696 6.76618H1.92857C1.89951 6.76615 1.87074 6.77202 1.84402 6.78346C1.81729 6.79489 1.79318 6.81164 1.77313 6.83269C1.75309 6.85373 1.73753 6.87864 1.72742 6.90589C1.7173 6.93313 1.71283 6.96215 1.71429 6.99118C1.83214 9.24922 3.70982 11.0519 6 11.0519C8.29018 11.0519 10.1679 9.24922 10.2857 6.99118C10.2872 6.96215 10.2827 6.93313 10.2726 6.90589C10.2625 6.87864 10.2469 6.85373 10.2269 6.83269C10.2068 6.81164 10.1827 6.79489 10.156 6.78346C10.1293 6.77202 10.1005 6.76615 10.0714 6.76618Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          d="M0 5.0219C0 5.47656 0.180612 5.9126 0.502103 6.23409C0.823593 6.55558 1.25963 6.73619 1.71429 6.73619C2.16894 6.73619 2.60498 6.55558 2.92647 6.23409C3.24796 5.9126 3.42857 5.47656 3.42857 5.0219C3.42857 4.56725 3.24796 4.13121 2.92647 3.80972C2.60498 3.48823 2.16894 3.30762 1.71429 3.30762C1.25963 3.30762 0.823593 3.48823 0.502103 3.80972C0.180612 4.13121 0 4.56725 0 5.0219ZM12.5714 5.0219C12.5714 5.47656 12.752 5.9126 13.0735 6.23409C13.395 6.55558 13.8311 6.73619 14.2857 6.73619C14.7404 6.73619 15.1764 6.55558 15.4979 6.23409C15.8194 5.9126 16 5.47656 16 5.0219C16 4.56725 15.8194 4.13121 15.4979 3.80972C15.1764 3.48823 14.7404 3.30762 14.2857 3.30762C13.8311 3.30762 13.395 3.48823 13.0735 3.80972C12.752 4.13121 12.5714 4.56725 12.5714 5.0219ZM13.4286 9.0219H11.7107C11.5607 9.0219 11.4321 9.13619 11.4214 9.28619C11.2857 11.054 9.80357 12.4505 8 12.4505C6.19643 12.4505 4.71071 11.054 4.57857 9.28619C4.56786 9.13619 4.43929 9.0219 4.28929 9.0219H2.57143C2.53268 9.02185 2.49432 9.02969 2.45869 9.04493C2.42306 9.06018 2.3909 9.08251 2.36417 9.11057C2.33745 9.13864 2.31671 9.17184 2.30322 9.20817C2.28973 9.2445 2.28378 9.2832 2.28571 9.3219C2.44286 12.3326 4.94643 14.7362 8 14.7362C11.0536 14.7362 13.5571 12.3326 13.7143 9.3219C13.7162 9.2832 13.7103 9.2445 13.6968 9.20817C13.6833 9.17184 13.6626 9.13864 13.6358 9.11057C13.6091 9.08251 13.5769 9.06018 13.5413 9.04493C13.5057 9.02969 13.4673 9.02185 13.4286 9.0219Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          d="M0 7.53285C0 8.21484 0.270917 8.86889 0.753154 9.35113C1.23539 9.83337 1.88944 10.1043 2.57143 10.1043C3.25341 10.1043 3.90747 9.83337 4.3897 9.35113C4.87194 8.86889 5.14286 8.21484 5.14286 7.53285C5.14286 6.85087 4.87194 6.19682 4.3897 5.71458C3.90747 5.23234 3.25341 4.96143 2.57143 4.96143C1.88944 4.96143 1.23539 5.23234 0.753154 5.71458C0.270917 6.19682 0 6.85087 0 7.53285ZM18.8571 7.53285C18.8571 8.21484 19.1281 8.86889 19.6103 9.35113C20.0925 9.83337 20.7466 10.1043 21.4286 10.1043C22.1106 10.1043 22.7646 9.83337 23.2468 9.35113C23.7291 8.86889 24 8.21484 24 7.53285C24 6.85087 23.7291 6.19682 23.2468 5.71458C22.7646 5.23234 22.1106 4.96143 21.4286 4.96143C20.7466 4.96143 20.0925 5.23234 19.6103 5.71458C19.1281 6.19682 18.8571 6.85087 18.8571 7.53285ZM20.1429 13.5329H17.5661C17.3411 13.5329 17.1482 13.7043 17.1321 13.9293C16.9286 16.5811 14.7054 18.6757 12 18.6757C9.29464 18.6757 7.06607 16.5811 6.86786 13.9293C6.85179 13.7043 6.65893 13.5329 6.43393 13.5329H3.85714C3.79901 13.5328 3.74147 13.5445 3.68803 13.5674C3.63459 13.5903 3.58635 13.6238 3.54626 13.6659C3.50617 13.708 3.47506 13.7578 3.45483 13.8123C3.4346 13.8668 3.42566 13.9248 3.42857 13.9829C3.66429 18.4989 7.41964 22.1043 12 22.1043C16.5804 22.1043 20.3357 18.4989 20.5714 13.9829C20.5743 13.9248 20.5654 13.8668 20.5452 13.8123C20.5249 13.7578 20.4938 13.708 20.4537 13.6659C20.4136 13.6238 20.3654 13.5903 20.312 13.5674C20.2585 13.5445 20.201 13.5328 20.1429 13.5329Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          d="M0 10.0438C0 10.9531 0.361223 11.8252 1.00421 12.4682C1.64719 13.1112 2.51926 13.4724 3.42857 13.4724C4.33789 13.4724 5.20996 13.1112 5.85294 12.4682C6.49592 11.8252 6.85714 10.9531 6.85714 10.0438C6.85714 9.13449 6.49592 8.26242 5.85294 7.61944C5.20996 6.97646 4.33789 6.61523 3.42857 6.61523C2.51926 6.61523 1.64719 6.97646 1.00421 7.61944C0.361223 8.26242 0 9.13449 0 10.0438ZM25.1429 10.0438C25.1429 10.9531 25.5041 11.8252 26.1471 12.4682C26.79 13.1112 27.6621 13.4724 28.5714 13.4724C29.4807 13.4724 30.3528 13.1112 30.9958 12.4682C31.6388 11.8252 32 10.9531 32 10.0438C32 9.13449 31.6388 8.26242 30.9958 7.61944C30.3528 6.97646 29.4807 6.61523 28.5714 6.61523C27.6621 6.61523 26.79 6.97646 26.1471 7.61944C25.5041 8.26242 25.1429 9.13449 25.1429 10.0438ZM26.8571 18.0438H23.4214C23.1214 18.0438 22.8643 18.2724 22.8429 18.5724C22.5714 22.1081 19.6071 24.901 16 24.901C12.3929 24.901 9.42143 22.1081 9.15714 18.5724C9.13571 18.2724 8.87857 18.0438 8.57857 18.0438H5.14286C5.06535 18.0437 4.98863 18.0594 4.91737 18.0899C4.84611 18.1204 4.7818 18.165 4.72835 18.2211C4.6749 18.2773 4.63342 18.3437 4.60644 18.4163C4.57947 18.489 4.56755 18.5664 4.57143 18.6438C4.88571 24.6652 9.89286 29.4724 16 29.4724C22.1071 29.4724 27.1143 24.6652 27.4286 18.6438C27.4324 18.5664 27.4205 18.489 27.3936 18.4163C27.3666 18.3437 27.3251 18.2773 27.2717 18.2211C27.2182 18.165 27.1539 18.1204 27.0826 18.0899C27.0114 18.0594 26.9347 18.0437 26.8571 18.0438Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { getBoundsByIconSize, getSVGViewboxByIconSize, type IconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>
