export enum UserSubscriptionStatus {
  Initial = 'initial',
  Trial = 'trial',
  NormalPeriod = 'normal_period',
  GracePeriod = 'grace_period',
  Canceled = 'canceled',
}

export enum UserSubscriptionStore {
  Apple = 'appstore',
  Google = 'googleplay',
  Partner = 'partner',
  Sber = 'sber',
  Viju = 'viju',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Default = 'viju',
}
