import type {
  ApiChannel,
  ApiChannelCategory,
  ApiChannelPlaylist,
  Channel,
  ChannelCategory,
  ChannelPlaylist,
} from '@package/sdk/src/api';
import { ChannelCategoryMapper, ChannelMapper, ChannelPlaylistMapper } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';
import { startOfDay } from 'date-fns';

import { ENDPOINTS } from '@/platform/http';
import useLocale from '@/platform/localization/use-locale';

interface GetChannelProgramArgs {
  id: string;
  clientTimezone: number;
  forToday?: boolean;
  from?: string; // format: '2021-10-05'
  to?: string; // format: '2021-10-05'
}

export function useChannelsApi() {
  const { $http } = useNuxtApp();
  const { formatDate, formatDateWithCustomFormat } = useLocale();

  const fetchChannels = (): Promise<Channel[]> => {
    const query = {
      with_multiple_logos: true,
    };

    return $http.get<ApiChannel[]>(ENDPOINTS.TV_CHANNELS, { query }).then(ChannelMapper.mapMany);
  };

  const fetchCategorizedChannels = (): Promise<ChannelCategory[]> => {
    const query = {
      with_multiple_logos: true,
    };

    return $http
      .get<ApiChannelCategory[]>(ENDPOINTS.TV_CATEGORIES, {
        query,
      })
      .then(ChannelCategoryMapper.mapMany);
  };

  const fetchChannelPlaylist = (id: string): Promise<ChannelPlaylist | undefined> => {
    return $http
      .get<ApiChannelPlaylist>(ENDPOINTS.TV_CHANNELS_PLAYLIST_ID, {
        params: { id },
      })
      .then(ChannelPlaylistMapper.map);
  };

  const fetchChannelProgram = ({ id, clientTimezone }: GetChannelProgramArgs): Promise<Channel> => {
    if (!id) {
      throw new UnexpectedComponentStateError('id');
    }

    const tz = `+0${clientTimezone}:00`;
    const timezoneShiftInHours = clientTimezone * 60 * 60 * 1000;
    const additionalTimezoneHours = isClient ? 0 : timezoneShiftInHours;
    const nowDate = new Date(Date.now() + additionalTimezoneHours);
    const nextDate = startOfDay(new Date().setDate(nowDate.getDate() + 1));

    const formattedNowDate = formatDate(nowDate);
    const formattedNextDate = formatDate(nextDate);

    const hoursMinutesSecondsNowDate = formatDateWithCustomFormat(nowDate, 'pp');
    const hoursMinutesSecondsNextDate = formatDateWithCustomFormat(nextDate, 'pp');

    const from = formattedNowDate + 'T' + hoursMinutesSecondsNowDate + tz;
    const to = formattedNextDate + 'T' + hoursMinutesSecondsNextDate + tz;

    const query = { from, to, with_multiple_logos: true };

    return $http
      .get<ApiChannel>(ENDPOINTS.TV_CHANNELS_PROGRAM_ID, {
        params: { id },
        query,
      })
      .then(ChannelMapper.map);
  };

  const fetchChannelsRecommendations = (): Promise<Channel[]> => {
    const query = {
      with_multiple_logos: true,
    };

    return $http
      .get<ApiChannel[]>(ENDPOINTS.TV_CHANNELS_RECOMMENDATIONS, {
        query,
      })
      .then(ChannelMapper.mapMany);
  };

  return {
    fetchChannels,
    fetchCategorizedChannels,
    fetchChannelProgram,
    fetchChannelPlaylist,
    fetchChannelsRecommendations,
  };
}
